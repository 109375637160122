import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';
import fetchMethodRequest from '../../../../../config/service';
import TableRender from '../../../DataTable/TableRender';
import apiCalls from '../../../../../config/apiCalls';
import RolePermissions from '../../../DataTable/Permissions';

export default class Categories extends PureComponent {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    constructor() {
        super();
        this.state = {
            tabType: 'Categories',
            apiUrl: apiCalls.Categories,
            settingInfo: {},
            useTypes: [],
            categoryTypes: []
        };
    }

    componentDidMount = async () => {
        let distributors = await this.getDistributorDrodownValues();
        let clinics = await this.getClinicDropDownValues();

        return fetchMethodRequest('GET', apiCalls.Settings)
            .then(async (response) => {
                if (response && response.settings && response.settings[0]) {
                    if (response.settings[0]) {
                        let categoryTypes = this.getCategoryDrodownValues(response.settings[0]);
                        let useTypes = this.getUseTypeDrodownValues(response.settings[0]);
                        this.setState({
                            categoryTypes: categoryTypes,
                            useTypes: useTypes,
                            distributors: distributors,
                            clinics: clinics
                        });
                    }
                }
            });
    }

    getTableFields = () => {
        let statusTypes = [
            { label: 'All', value: null },
            { label: 'Active', value: 'Active' },
            { label: 'InActive', value: 'InActive' },
            { label: 'Pending', value: 'Pending' }
        ];

        let data = [
            { textAlign: 'left', width: 135, field: 'categoryName', header: 'Category Name', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 135, field: 'categoryType', header: 'Category Type', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 135, field: 'useType', header: 'Use Type', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 105, field: 'status', header: 'Status ', filterElement: statusTypes, filter: true, sortable: false },
            { textAlign: 'center', width: 90, field: 'Actions', header: 'Actions', filter: false, sortable: false },
        ];
        let screenPermissions = RolePermissions.screenPermissions('Categories');
        if (screenPermissions && screenPermissions != 'edit') {
            data.pop();
        }
        return data;
    };

    getFormFields = async () => {
        return ([
            {
                required: true, value: '', type: 'autoComplete', name: 'companyId',
                label: 'Company Name', id: 'company', placeholder: 'Search company',
                searchApi: apiCalls.ActiveCompanies, searchField: 'name', disabled: true
            },
            {
                required: true, value: '', type: 'text', name: 'categoryName', label: 'Category Name',
                id: 'categoryName', placeholder: 'Category Name'
            },
            {
                required: true, value: '', type: 'dropDown', name: 'categoryType', label: 'Select Category Type',
                id: 'categoryType', placeholder: 'Category Type', options: this.state.categoryTypes
            },
            {
                required: true, value: '', type: 'dropDown', name: 'useType', label: 'Select Use Type',
                id: 'useType', placeholder: 'Use Type', options: this.state.useTypes
            }

        ]);
    }

    getDistributorDrodownValues = () => {
        return fetchMethodRequest('GET', apiCalls.Distributors)
            .then(async (response) => {
                if (response && response.distributors && response.distributors[0]) {
                    if (response.distributors[0].displayName) {
                        let distributorNames = response.distributors;
                        let modifiedDistributorNames = [];
                        for (let distributor of distributorNames) {
                            modifiedDistributorNames.push({
                                label: distributor.displayName,
                                value: distributor._id
                            })
                        }
                        return modifiedDistributorNames
                    }
                }
            });
    }

    getClinicDropDownValues = () => {
        return fetchMethodRequest('GET', apiCalls.Clinics)
            .then(async (response) => {
                if (response && response.clinics && response.clinics[0]) {
                    if (response.clinics[0].name) {
                        let clinicNames = response.clinics;
                        let modifiedClinicNames = [];
                        for (let clinic of clinicNames) {
                            modifiedClinicNames.push({
                                label: clinic.name,
                                value: clinic._id
                            })
                        }
                        return modifiedClinicNames
                    }
                }
            });
    }

    getCategoryDrodownValues = (settingInfo) => {
        if (settingInfo && settingInfo.categoryType) {
            let categoryTypes = settingInfo.categoryType;
            let modifiedCategoryTypes = [];
            for (let category of categoryTypes) {
                modifiedCategoryTypes.push({
                    label: category,
                    value: category
                })
            }
            return modifiedCategoryTypes;
        }
    }

    getUseTypeDrodownValues = (settingInfo) => {
        if (settingInfo && settingInfo.useType) {
            let useTypes = settingInfo.useType;
            let modifiedUseTypes = [];
            for (let category of useTypes) {
                modifiedUseTypes.push({
                    label: category,
                    value: category
                })
            }
            return modifiedUseTypes;
        }
    }

    render() {
        return (
            <div>
                <TableRender type={'Categories'}
                    globalSearch={this.state.tabType == 'Products' ? 'Product Name' : 'Category Name'}
                    tableFields={this.getTableFields}
                    formFields={this.getFormFields} {...this.props}
                    apiUrl={this.state.apiUrl}
                    tabType={this.state.tabType}
                />
            </div>
        );
    }
}