import React from "react";
import TableRender from "../../../DataTable/TableRender";

export default class ActiveCompanies extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cartSublocationsList: [],
            clinicNames: [],
            type: "Active Companies"
        };
    }
    componentDidMount = async () => {
        let path = window.location.pathname.slice(window.location.pathname.lastIndexOf('/') + 1, window.location.pathname.length)
        await this.setState({
            path: path
        })
    }

    static getDerivedStateFromProps = (props, state) => {
        if (state.type !== props.match.path.slice(1, props.match.path.length)) {
            if (props.match.path) {
                state.type = `${props.match.path.slice(1, props.match.path.length).charAt(0).toUpperCase()
                    + props.match.path.slice(2, props.match.path.length)} in companies`
            }
            return null;
        } else {
            return null;
        }
    }

    getTableFields = async () => {
        let data = [
            { textAlign: 'center', width: 500, field: 'name', header: 'Company', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 500, field: 'email', header: 'Company Email', filter: true, sortable: true, placeholder: 'Search' },
        ];
        return [...data];
    };

    render() {
        return (
            <div>
                <TableRender
                    type={this.state.type}
                    name="Active Companies"
                    globalSearch='Company Name/Company Email'
                    tableFields={this.getTableFields}
                    {...this.props}
                />
            </div>
        );
    }
}
