import React from "react";
import TableRender from "../../../DataTable/TableRender";
import RolePermissions from '../../../DataTable/Permissions';

// config
import apiCalls from "../../../../../config/apiCalls";
import fetchMethodRequest from "../../../../../config/service";

export default class CartLocations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cartSublocationsList: [],
            clinicNames: [],
            statusTypes: [
                { label: 'All', value: null },
                { label: 'Pending', value: 'Pending' },
                { label: 'Active', value: 'Active' },
                { label: 'InActive', value: 'InActive' },
            ],
            days: [
                { label: 'Sunday', value: 'Sunday' },
                { label: 'Monday', value: 'Monday' },
                { label: 'Tuesday', value: 'Tuesday' },
                { label: 'Wednesday', value: 'Wednesday' },
                { label: 'Thursday', value: 'Thursday' },
                { label: 'Friday', value: 'Friday' },
                { label: 'Saturday', value: 'Saturday' }
            ],
            tabsList: [
                { name: 'Cart Locations' }, { name: 'Supply Cabinets' }, { name: 'OR Suites' }
            ],
            tabType: 'Cart Locations'
        };
    }

    componentDidMount = () => {
        this.getCompanyNamesValues();
    }

    getTableFields = async (type) => {
        if (type) {
            this.setState({
                tabType: type
            })
        }
        let data = [];
        if (type == 'Supply Cabinets') {
            data = this.getCabinetTableFields();
        } else if (type == 'OR Suites') {
            data = this.getOperatingRoomTableFields();
        } else {
            data = this.getCartLocationTableFields();
        }

        let screenPermissions = RolePermissions.screenPermissions('Storage Unit');
        if (screenPermissions && screenPermissions != 'edit') {
            data.pop();
        }
        return [...data];
    };

    getCartLocationTableFields = () => {
        let data = [
            { textAlign: 'left', width: 100, field: 'companyName', header: 'Company', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 100, field: 'cart', header: 'Cart', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 120, field: 'clinicName', header: 'Facility Name', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 120, field: 'cartLocation', header: 'Address', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 75, field: 'status', header: 'Status ', filterElement: this.state.statusTypes, filter: true, sortable: false },
            { textAlign: 'left', width: 75, field: 'Actions', header: 'Actions', filter: false, sortable: false },
        ];
        return data;
    }

    getCabinetTableFields = () => {
        let data = [
            { textAlign: 'left', width: 100, field: 'companyName', header: 'Company', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 100, field: 'cart', header: 'Supply Cabinet', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 120, field: 'clinicName', header: 'Facility Name', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 120, field: 'cartLocation', header: 'Address', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 75, field: 'status', header: 'Status ', filterElement: this.state.statusTypes, filter: true, sortable: false },
            { textAlign: 'left', width: 75, field: 'Actions', header: 'Actions', filter: false, sortable: false },
        ];
        return data;
    }

    getOperatingRoomTableFields = () => {
        let data = [
            { textAlign: 'left', width: 100, field: 'companyName', header: 'Company', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 100, field: 'cart', header: 'OR Suite', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 100, field: 'surgeryType', header: 'Surgery Type', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 120, field: 'clinicName', header: 'Facility Name', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 120, field: 'cartLocation', header: 'Address', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 75, field: 'status', header: 'Status ', filterElement: this.state.statusTypes, filter: true, sortable: false },
            { textAlign: 'left', width: 75, field: 'Actions', header: 'Actions', filter: false, sortable: false },
        ];
        return data;
    }

    getFormFields = (type) => {
        let fields = [];
        if (type == 'Supply Cabinets') {
            fields = this.getCabinetFormFields();
        } else if (type == 'OR Suites') {
            fields = this.getOperatingRoomFormFields();
        } else {
            fields = this.getCartLocationFormFields();
        }
        return fields;
    }

    getCartLocationFormFields = () => {
        return ([
            {
                required: true, value: '', type: 'autoComplete', name: 'companyId',
                label: 'Company Name', id: 'company', placeholder: 'Search company',
                searchApi: apiCalls.ActiveCompanies, searchField: 'name', call: 'GET'
            },
            {
                required: true, value: '', type: 'dropDown',
                name: 'clinicName', label: 'Facility', id: 'clinic', placeholder: 'Facility Name'
            },
            { required: true, value: '', type: 'text', name: 'cart', label: 'Cart Name', id: 'Cart Name', placeholder: 'Cart Name' },
            { value: '', type: 'text', name: 'building', label: 'Building', id: 'Building', placeholder: 'Building' },
            { required: true, value: '', type: 'text', name: 'area', label: 'Area', id: 'Area', placeholder: 'Area' },
            { value: '', type: 'text', name: 'floor', label: 'Floor', id: 'Floor', placeholder: 'Floor Number' },
            {
                required: true, value: '', type: 'dropDown', name: 'cartCheckDay', label: 'Weekly Notified On',
                id: 'Day', placeholder: 'Select Day', options: this.state.days
            },
            { value: '', type: 'text', name: 'room', label: 'Room', id: 'Room', placeholder: 'Room' },
            {
                required: true, value: '', type: 'number', name: 'noofDrawers', label: 'How many drawers you want to choose?',
                id: 'noofDrawers', placeholder: 'Enter No.of Drawers'
            },
        ]);
    }

    getCabinetFormFields = () => {
        return ([
            {
                required: true, value: '', type: 'autoComplete', name: 'companyId',
                label: 'Company Name', id: 'company', placeholder: 'Search company',
                searchApi: apiCalls.ActiveCompanies, searchField: 'name', call: 'GET'
            },
            {
                required: true, value: '', type: 'dropDown',
                name: 'clinicName', label: 'Facility', id: 'clinic', placeholder: 'Facility Name'
            },
            { required: true, value: '', type: 'text', name: 'cart', label: 'Supply Cabinet Name', id: 'Supply Cabinet Name', placeholder: 'Supply Cabinet Name' },
            { value: '', type: 'text', name: 'building', label: 'Building', id: 'Building', placeholder: 'Building' },
            { required: true, value: '', type: 'text', name: 'area', label: 'Area', id: 'Area', placeholder: 'Area' },
            { value: '', type: 'text', name: 'floor', label: 'Floor', id: 'Floor', placeholder: 'Floor Number' },
            {
                required: true, value: '', type: 'dropDown', name: 'cartCheckDay', label: 'Weekly Notified On',
                id: 'Day', placeholder: 'Select Day', options: this.state.days
            },
            { value: '', type: 'text', name: 'room', label: 'Room', id: 'Room', placeholder: 'Room' },
            {
                required: true, value: '', type: 'number', name: 'noofDrawers', label: 'How many shelves you want to choose?',
                id: 'noofDrawers', placeholder: 'Enter No.of Shelves'
            },
        ]);
    }

    getOperatingRoomFormFields = () => {
        return ([
            {
                required: true, value: '', type: 'autoComplete', name: 'companyId',
                label: 'Company Name', id: 'company', placeholder: 'Search company',
                searchApi: apiCalls.ActiveCompanies, searchField: 'name', call: 'GET'
            },
            {
                required: true, value: '', type: 'dropDown',
                name: 'clinicName', label: 'Facility', id: 'clinic', placeholder: 'Facility Name'
            },
            { required: true, value: '', type: 'text', name: 'cart', label: 'OR Suite Name', id: 'operatingRoom', placeholder: 'OR Suite Name' },
            { required: true, value: '', type: 'text', name: 'surgeryType', label: 'Surgery Type', id: 'surgery', placeholder: 'Surgery Type' },
            { value: '', type: 'text', name: 'building', label: 'Building', id: 'Building', placeholder: 'Building' },
            { required: true, value: '', type: 'text', name: 'area', label: 'Area', id: 'Area', placeholder: 'Area' },
            { value: '', type: 'text', name: 'floor', label: 'Floor', id: 'Floor', placeholder: 'Floor Number' },
            {
                required: true, value: '', type: 'dropDown', name: 'cartCheckDay', label: 'Weekly Notified On',
                id: 'Day', placeholder: 'Select Day', options: this.state.days
            },
            { value: '', type: 'text', name: 'room', label: 'Room', id: 'Room', placeholder: 'Room' },
            {
                required: true, value: '', type: 'number', name: 'noofDrawers', label: 'How many storage units you want to choose?',
                id: 'noofDrawers', placeholder: 'Enter No.of Storage Units'
            },
        ]);
    }

    getCompanyNamesValues = async () => {
        return fetchMethodRequest('GET', apiCalls.Companies)
            .then(async (response) => {
                if (response && response.companies && response.companies[0]) {
                    let modifiedClinicNames = [];
                    for (let clinic of response.companies) {
                        modifiedClinicNames.push({
                            label: clinic.name,
                            value: clinic.name,
                            id: clinic._id
                        })
                        await this.setState({
                            companies: modifiedClinicNames
                        });
                    }
                }
            });
    }

    render() {
        return (
            <div>
                <TableRender type='Storage Unit'
                    globalSearch={this.state.tabType == 'Cart Locations' ? 'Cart Name/Facility Name' :
                        this.state.tabType == 'Supply Cabinets' ? 'Supply Cabinet Name/Facility Name' :
                            'OR Suite/Surgery Type/Facility Name'}
                    tableFields={this.getTableFields}
                    formFields={this.getFormFields}
                    {...this.props}
                    tabType={this.state.tabType}
                    tabsList={this.state.tabsList}
                />
            </div>
        );
    }
}
