import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ProductsTable from './components/ProductsTable'


const ProductsTableList = (props) => (

    <Container>
        <ProductsTable {...props} />
    </Container>
);

ProductsTableList.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(ProductsTableList);
