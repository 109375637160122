import React from 'react';
import TableRender from '../../../DataTable/TableRender';
import RolePermissions from '../../../DataTable/Permissions';

import fetchMethodRequest from '../../../../../config/service'
import apiCalls from '../../../../../config/apiCalls';
import config from '../../../../../config/config';

export default class TicketsTable extends React.Component {
    constructor(props) {
        super(props);
        this.assigendToOptions = null;
        this.state = {
            ticketSubjectCategoryTypes: []
        };
    }
    componentDidMount = async () => {
        return fetchMethodRequest('GET', apiCalls.Settings)
            .then(async (response) => {
                if (response && response.settings && response.settings[0]) {
                    if (response.settings[0]) {
                        let ticketSubjectCategoryTypes = this.getticketTypeDrodownValues(response.settings[0]);
                        this.setState({
                            ticketSubjectCategoryTypes: ticketSubjectCategoryTypes,
                        });
                    }
                }
            });
    }

    getAssignedToOptions = () => {
        return fetchMethodRequest('GET', apiCalls.Employees)
            .then(async (response) => {
                if (response && response.employees && response.employees.length > 0) {
                    this.assigendToOptions = [{ label: 'Select', value: null }];
                    for (let employee of response.employees) {
                        if (employee) {
                            this.assigendToOptions.push({
                                label: employee.displayName,
                                value: employee._id
                            })
                        }
                    }
                }
            });
    }

    getticketTypeDrodownValues = (settingInfo) => {
        if (settingInfo && settingInfo.ticketSubjectcategory) {
            let modifiedticketSubjectCategoryTypes = [];
            for (let ticket of settingInfo.ticketSubjectcategory) {
                modifiedticketSubjectCategoryTypes.push({
                    label: ticket,
                    value: ticket
                })
            }
            return modifiedticketSubjectCategoryTypes;
        }
    }

    getTableFields = async () => {
        let statusTypes = [
            { label: 'All', value: null },
            { label: 'New', value: 'New' },
            { label: 'Assigned', value: 'Assigned' },
            { label: 'In Progress', value: 'In Progress' },
            { label: 'Answered', value: 'Answered' },
            { label: 'Pending', value: 'Pending' },
            { label: 'Reopened', value: 'Reopened' },
            { label: 'Not an Issue', value: 'Not an Issue' },
            { label: 'Closed', value: 'Closed' },
        ];
        let categoryTypes = [
            { label: 'All', value: null },
            { label: 'Payment', value: 'Payment' },
            { label: 'Support', value: 'Support' },
            { label: 'Technical', value: 'Technical' }];

        await this.getAssignedToOptions();

        let data = [
            { textAlign: 'left', width: 85, field: 'ticketId', header: 'Ticket ID', filter: true, sortable: true, },
            { textAlign: 'center', width: 100, field: 'subject', header: 'Subject', filter: true, sortable: true, },
            { textAlign: 'center', width: 100, field: 'emailId', header: 'EmailID', filter: true, sortable: true, },
            { textAlign: 'center', width: 100, field: 'userName', header: 'UserName', filter: true, sortable: true, },
            { textAlign: 'center', width: 85, field: 'category', header: 'Category', filterElement: categoryTypes, filter: true, sortable: true },
            { textAlign: 'left', width: 100, field: 'created', header: 'Created On', filter: true, sortable: true },
            { textAlign: 'center', width: 95, field: 'message', header: 'Message', filter: true, sortable: true },
            { textAlign: 'center', width: 105, field: 'assignedTo', header: 'Assigned To', filterElement: this.assigendToOptions, filter: true, sortable: true },
            { textAlign: 'center', width: 105, field: 'updated', header: 'Updated On', filter: true, sortable: true, placeholder: config.dateDayMonthFormat },
            { textAlign: 'center', width: 90, field: 'status', header: 'Status', filterElement: statusTypes, filter: true, sortable: true },
            { textAlign: 'center', width: 60, field: 'Actions', header: 'Actions', filter: false, sortable: false },

        ];

        let screenPermissions = RolePermissions.screenPermissions('Tickets');
        if (screenPermissions && screenPermissions != 'edit') {
            data.pop();
        }
        return data;
    };

    getFormFields = () => {
        let statusTypes = [
            { label: 'New', value: 'New' },
            { label: 'Assigned', value: 'Assigned' },
            { label: 'InProgress', value: 'InProgress' },
            { label: 'Pending', value: 'Pending' },
            { label: 'Answered', value: 'Answered' },
            { label: 'Reopened', value: 'Reopened' },
            { label: 'Not an issue', value: 'Not an issue' },
            { label: 'Closed', value: 'Closed' }
        ];
        return ([
            { required: true, value: '', type: 'input', name: 'ticketId', label: 'Ticket Id', id: 'ticketId', placeholder: 'Ticket Id', disable: true },
            { required: true, value: '', type: 'input', name: 'subject', label: 'Subject', id: 'subject', placeholder: 'Select', disable: true },
            { required: true, value: '', type: 'dropDown', name: 'category', label: 'Category', id: 'category', options: this.state.ticketSubjectCategoryTypes, placeholder: 'Select', disable: true },
            { required: true, value: '', type: 'input', name: 'emailId', label: 'Email Id', id: 'email', placeholder: 'Email', disable: true },
            { required: true, value: '', type: 'dropDown', name: 'status', label: 'Status', id: 'status', options: statusTypes, placeholder: 'Select' },
            {
                required: true, value: '', type: 'autoComplete', name: 'assignedTo', label: 'Assigned To', id: 'assignedTo',
                placeholder: 'Search User', searchApi: 'employees', searchField: 'displayName'
            },
            { value: '', name: 'closebutton', },

        ]);
    }

    render() {
        return (
            <div>
                <TableRender type='Tickets'
                    globalSearch='UserName/Email/Subject'
                    tableFields={this.getTableFields}
                    formFields={this.getFormFields} {...this.props}
                />
            </div>
        );
    }
}