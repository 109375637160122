import React from "react";
import RolePermissions from "../../../DataTable/Permissions";
import apiCalls from "../../../../../config/apiCalls";
import fetchMethodRequest from "../../../../../config/service";

// Table
import TableRender from "../../../DataTable/TableRender";

export default class StatesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      filterCriteria: { limit: 20, page: 1, criteria: [], sortfield: 'created', direction: 'desc' },
      states: [
        { label: "Alabama - AL", value: "Alabama - AL" },
        { label: "Alaska - AK", value: "Alaska - AK" },
        { label: "Arizona - AZ", value: "Arizona - AZ" },
        { label: "Arkansas - AR", value: "Arkansas - AR" },
        { label: "California - CA", value: "California - CA" },
        { label: "Colorado - CO", value: "Colorado - CO" },
        { label: "Connecticut - CT", value: "Connecticut - CT" },
        { label: "Delaware - DE", value: "Delaware - DE" },
        { label: "Florida - FL", value: "Florida - FL" },
        { label: "Georgia - GA", value: "Georgia - GA" },
        { label: "Hawaii - HI", value: "Hawaii - HI" },
        { label: "Idaho - ID", value: "Idaho - ID" },
        { label: "Illinois - IL", value: "Illinois - IL" },
        { label: "Indiana - IN", value: "Indiana - IN" },
        { label: "Iowa - IA", value: "Iowa - IA" },
        { label: "Kansas - KS", value: "Kansas - KS" },
        { label: "Kentucky - KY", value: "Kentucky - KY" },
        { label: "Louisiana - LA", value: "Louisiana - LA" },
        { label: "Maine - ME", value: "Maine - ME" },
        { label: "Maryland - MD", value: "Maryland - MD" },
        { label: "Massachusetts - MA", value: "Massachusetts - MA" },
        { label: "Michigan - MI", value: "Michigan - MI" },
        { label: "Minnesota - MN", value: "Minnesota - MN" },
        { label: "Mississippi - MS", value: "Mississippi - MS" },
        { label: "Missouri - MO", value: "Missouri - MO" },
        { label: "Montana - MT", value: "Montana - MT" },
        { label: "Nebraska - NE", value: "Nebraska - NE" },
        { label: "Nevada - NV", value: "Nevada - NV" },
        { label: "New Hampshire - NH", value: "New Hampshire - NH" },
        { label: "New Jersey - NJ", value: "New Jersey - NJ" },
        { label: "New Mexico - NM", value: "New Mexico - NM" },
        { label: "New York - NY", value: "New York - NY" },
        { label: "North Carolina - NC", value: "North Carolina - NC" },
        { label: "North Dakota - ND", value: "North Dakota - ND" },
        { label: "Ohio - OH", value: "Ohio - OH" },
        { label: "Oklahoma - OK", value: "Oklahoma - OK" },
        { label: "Oregon - OR", value: "Oregon - OR" },
        { label: "Pennsylvania - PA", value: "Pennsylvania - PA" },
        { label: "Rhode Island - RI", value: "Rhode Island - RI" },
        { label: "South Carolina - SC", value: "South Carolina - SC" },
        { label: "South Dakota - SD", value: "South Dakota - SD" },
        { label: "Tennessee - TN", value: "Tennessee - TN" },
        { label: "Texas - TX", value: "Texas - TX" },
        { label: "Utah - UT", value: "Utah - UT" },
        { label: "Vermont - VT", value: "Vermont - VT" },
        { label: "Virginia - VA", value: "Virginia - VA" },
        { label: "Washington - WA", value: "Washington - WA" },
        { label: "West Virginia - WV", value: "West Virginia - WV" },
        { label: "Wisconsin - WI", value: "Wisconsin - WI" },
        { label: "Wyoming - WY", value: "Wyoming - WY" },
      ]
    };
  }

  componentDidMount = async () => {
    this.getStatesFromServer();
  };

  //State Options filter from the server 
  getStatesFromServer = async () => {
    return fetchMethodRequest('GET', apiCalls.States)
      .then(async (response) => {
        let statedropDownval = this.state.states
        if (response && response.states && response.states.length > 0) {
          for (let stateval of response.states) {
            let valName = stateval.name
            statedropDownval.splice(statedropDownval.findIndex(v => v.label === valName), 1);
            this.setState({
              statedropDownval: statedropDownval
            })
          }
        } else {
          this.setState({
            statedropDownval: statedropDownval
          })
        }
      });
  };

  getTableFields = () => {
    let status = [
      { label: "All", value: null },
      { label: "suspend", value: "suspend" },
      { label: "Active", value: "Active" },
      { label: "Blocked", value: "Blocked" },
    ];

    let data = [
      {
        textAlign: "left",
        width: 135,
        field: "name",
        header: "State Name",
        filter: true,
        sortable: true,
        placeholder: "Search",
      },
      {
        textAlign: "left",
        width: 135,
        field: "createdByName",
        header: "Created By",
        filter: true,
        sortable: true,
        placeholder: "Search",
      },
      {
        textAlign: "left",
        width: 135,
        field: "created",
        header: "Created On",
        filter: true,
        sortable: true,
        placeholder: "Search",
      },
      {
        textAlign: "center",
        width: 90,
        field: "Actions",
        header: "Actions",
        filter: false,
        sortable: false,
      },
    ];
    let screenPermissions = RolePermissions.screenPermissions("Companies");
    if (screenPermissions && screenPermissions != "edit") {
      data.pop();
    }
    return data;
  };

  getFormFields = () => {
    return [
      {
        required: true,
        value: "",
        type: "dropDown",
        options: this.state.statedropDownval,
        name: "name",
        label: "State Name",
        id: "name",
        placeholder: "State Name",
      },
      {
        required: true,
        value: "",
        type: "input",
        name: "country",
        label: "Country",
        id: "country",
        placeholder: "country",
      },
      // {
      //   value: "",
      //   type: "dropDown",
      //   name: "requiredInventory",
      //   label: "Required Inventory",
      //   id: "requiredInventory",
      //   options: this.state.products,
      //   placeholder: "Select Required Inventory",
      //   isMultiSelect: true,
      //   required: true,
      // },
    ];
  };

  render() {
    return (
      <div>
        <TableRender
          type="Required Inventories"
          globalSearch="Company Name/Email"
          tableFields={this.getTableFields}
          formFields={this.getFormFields}
          //state={this.state.states}
          {...this.props}
        />
      </div>
    );
  }
}
