/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TrendingUpIcon from 'mdi-react/TrendingUpIcon';

class CountDisplay extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);
		this.state = {
			activeIndex: 0,
			itemsList: [
				{ displayName: 'Facilities', route: '/facilities', countName: 'clinicsCount', color: '#08699f' },
				{ displayName: 'Storage Unit', route: '/locations', countName: 'locationsCount', color: '#d65356' },
				{ displayName: 'Companies', route: '/companies', countName: 'companiesCount', color: '#23B7D9' },
				{ displayName: 'Employees', route: '/employees', countName: 'employeesCount', color: '#F29B9B' }
			]
		};
	}

	componentDidMount() {
		if (localStorage.getItem('loginCredentials')) {
			let user = JSON.parse(localStorage.getItem('loginCredentials'));
			if (user.role) {
				this.setState({ employeeRole: user.role });
			}
		}
	}

	handleClick = (index) => {
		this.setState({
			activeIndex: index,
		});
	};

	render() {
		const { dashboardData } = this.props;
		const { itemsList } = this.state;
		return (
			<div>
				{/* If user is Admin or superAdmin  */}
				{dashboardData ?
					<Row>
						{itemsList && itemsList.length > 0 ?
							itemsList.map((item, index) => {
								return <Col md={12} xl={3} lg={6} xs={12} key={index}>
									<Card>
										<CardBody className="dashboard__card-widget" style={{ backgroundColor: item.color }}>
											<div className="card__title">
												<h5 className="bold-text" style={{ color: '#ffff' }}>{item.displayName}</h5>
											</div>
											<div className="dashboard__total" style={{ color: '#ffff' }}>
												<TrendingUpIcon className="dashboard__trend-icon" style={{ color: '#ffff' }}/>
												<p className="dashboard__total-stat">
													<Link to={item.route} style={{ color: '#ffff' }}>
														{dashboardData[item.countName] ? dashboardData[item.countName] : 0}
													</Link>
												</p>
											</div>
										</CardBody>
									</Card>
								</Col>
							})
							: null}
					</Row>
					: null}
			</div>
		);
	}
}

export default withTranslation('common')(CountDisplay);

