import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import config from '../../../config/config';
import classNames from 'classnames';

// config file
export default class SessionModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const modalClass = classNames({
            'modal-dialog--colored': this.state.colored,
            'modal-dialog--header': this.state.header,
        });
        return (
            <div>
                <Modal isOpen={this.props.openSessionModal}
                    className={`modal-dialog-centered modal-dialog--primary  ${modalClass}`}>
                    <ModalBody className='deleteModalBody'>
                        <div style={{
                            background: config.templateColor, color: 'white', display: 'flex', justifyContent: 'center',
                            height: 45, alignItems: 'center', fontSize: 17, fontWeight: 'bold', borderRadius: 5
                        }}>
                            You're being timed out due to inactivity
                        </div>
                        <div className="text-center">
                            <h4 className="py-3">We’re Sorry</h4>
                        </div>
                        <div className="text-center">
                            <h4 className="pb-5">Your session has expired please login again.</h4>
                        </div>
                        <div className="confirm-modal">
                            <Button color='primary'><Link to='/'><span style={{ color: 'white' }}>Log In</span></Link></Button>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}