
const configMessages = {
    invalidEmail: 'Please enter valid email address',
    email: 'Please enter email',
    fillField: 'Please fill above field',
    aadharNumber: 'Please enter valid Aadhar number',
    panCardNumber: 'Please enter valid Pan Card Number',
    phoneNumber: 'please enter valid phone number',
    InvalidDate: 'Invalid Date',
    noRecords: 'No Records Found',
    toDateBeforeFromDate: 'To date must be after from Date',
    fromAndToRangeMustBeDifferent: 'From range and To range must be different',
    noOfDaysValidation: 'No of days between From range and To range must be same',
    toRangeDateValidation: 'To Range must be after From Range dates',
    shiftErrMsg: 'Please Select Location First',
    shiftAddOnErrMsg: 'Please Select Primary Shift First',
    toDoctorErrMsg: 'Please Select From User First',
    noOptionsMessage: 'No Options Available',
    passwordMatchValidation: 'Password does not match',
    numberValidation: 'Please Enter valid number',
    sessionExpiredMessage: "Session expired please login again.",
    FacilitiesDeleteMsg: 'Are you sure want to delete this facility because it will delete all items in the storage unit?',
    DeleteMsg: 'Do you really want to Delete?'

};
export default configMessages;
