import React from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import fetch from '../../../config/service';
import PropTypes from 'prop-types';


export default class autoComplete extends React.Component {
    static propTypes = {
        onChange: PropTypes.func,
        meta: PropTypes.shape({
            touched: PropTypes.bool,
            error: PropTypes.string,
        })
    };
    constructor(props) {
        super(props);
        this.state = {
            filteredSuggestions: [],
            errorMessage: '',
            preSearch: undefined
        };
        this.getAllSuggestions('', 'cont');
    }
    getLocations = (values, facility) => {
        let locs = []
        for (let value of values) {
            if (value.clinicName == facility) {
                locs.push(value)
            }
        }
        return locs
    }

    // to get all suggestions in autocomplete
    getAllSuggestions = (event, type) => {
        this.setState({
            noData: false,
            errorMessage: ''
        });
        let filterCriteria = {
            limit: 30,
            page: 1,
            sortfield: "created",
            direction: "desc",
            criteria: []
        };
        if (this.props.searchApi == 'users') {
            filterCriteria['criteria'].push({
                "key": "status",
                "value": "Active",
                "type": "eq"
            });
        }
        if (event && event.query) {
            filterCriteria['criteria'].push({
                "key": this.props.searchField,
                "value": event.query,
                "type": "regexOr"
            });
            this.setState({
                preSearch:event.query
            })
        }else if(event && !event.query && this.state.preSearch && this.state.preSearch !=undefined ){

            filterCriteria['criteria'].push({
                "key": this.props.searchField,
                "value": this.state.preSearch,
                "type": "regexOr"
            });
        }

        if (this.props.groupType && this.props.filterFieldType == 'leaves') {
            filterCriteria['criteria'].push({
                key: 'group',
                value: this.props.groupType,
                type: "regexOr"
            });
        }
        let body;
        let apiUrl = this.props.searchApi;
        let url = `${apiUrl}?filter=${JSON.stringify(filterCriteria)}`;
        if ((this.props.screen == 'Storage Unit Items' || this.props.screen == 'DashInventory') && (this.props.id == 'productName' || this.props.id == 'location' || this.props.id == 'subLocations' || this.props.id == 'clinic')) {
            url = `${apiUrl}`
        }
        if ((this.props.screen == 'Storage Unit Items' || this.props.screen == 'DashInventory') && (this.props.id == 'productName')) {
            filterCriteria.limit = 'all'
            url = `${apiUrl}&filter=${JSON.stringify(filterCriteria)}`
        }
        if (this.props.screen == 'addOrder') {
            url = apiUrl
        }
        if (this.props.screen == 'Storage Unit Items') {
            body = { 'productName': this.props.productName ? this.props.productName.value ? this.props.productName.value : this.props.productName : null, company_id: this.props.companyId ? this.props.companyId : null }
        }
        if (this.props.screen == 'DashInventory') {
            if (this.props.input.name == 'description') {
                body = {
                    'productName': this.props.productName,
                    'company_id': this.props.companyId
                }
            }
        }
        if (this.props.screen == 'Recall Items' && this.props.input && this.props.input.name == 'lotId') {
            url = `${url}&companyId=${this.props.companyId}`;
        }
        return fetch(this.props.screen == 'Storage Unit Items' || this.props.screen == 'DashInventory' ? this.props.call : 'GET', url, body)
            .then((response) => {
                if (response) {
                    let dropdownData = [];
                    if (response[apiUrl] && response[apiUrl].length && response[apiUrl].length > 0 || response['companies'] ||
                        response['products'] || response['clinics'] || response['locations'] || response['distributors']) {
                        dropdownData = response[apiUrl];

                        if (this.props.id == 'productName' || this.props.id == 'description') {
                            dropdownData = response['products']
                        } else if (this.props.id == 'clinic') {
                            dropdownData = response['clinics']
                        } else if (this.props.screen == 'Storage Unit') {
                            dropdownData = response['companies']
                        } else if (this.props.id == 'location' && this.props.facilityName) {
                            dropdownData = this.getLocations(response['locations'], this.props.facilityName)
                        } else if (this.props.id == 'subLocations' && this.props.cartName) {
                            let locationsList = []
                            let list = [];
                            for (let locations of response['locations']) {
                                if (locations.cart == this.props.cartName) {
                                    locationsList.push(locations.cartParts)
                                }
                            }
                            locationsList.map((value) => {
                                value.map((item) => {
                                    list.push({ 'cartParts': item })
                                })
                            })
                            dropdownData = list;
                        } else if (this.props.screen == 'Product Directory' || this.props.id == 'company' ||
                            this.props.screen == 'Facilities' || this.props.input.name == 'companyId' ||
                            this.props.screen == 'Distributors' || this.props.screen == 'Categories') {
                            dropdownData = response['companies'];
                        } else if (this.props.screen == 'addOrder' && this.props.input.name == 'distributorEmail') {
                            dropdownData = response['distributors']
                        }
                    }

                    if (dropdownData && dropdownData.length == 0) {
                        this.setState({
                            filteredSuggestions: [],
                            noData: true
                        });
                    } else {
                        this.setSuggestions(dropdownData);
                    }
                }
            }).catch((err) => {
                return err;
            });
    }
    // set filtered sugeestions, removing duplicates
    setSuggestions = async (dropdownData) => {
        if (this.props.input && this.props.input.value) {
            if (this.props.input.value.length > 0) {
                let values = this.props.input.value;
                if (Array.isArray(values)) {
                    values.forEach(element => {
                        let field = '_id';
                        dropdownData = dropdownData.filter((item) => item[field] !== element[field]);
                    });
                }
            }
        }
        await this.setState({
            filteredSuggestions: dropdownData
        });
    }

    // on selected value from suggestions
    onSelectRecord(e) {
        this.setState({
            noData: false,
            errorMessage: ''
        });
        if (this.props.input) {
            const { name, onChange } = this.props.input;
            onChange(e.value);
            this.props.handleAutoCompleteData(e.value, name)
            if (e && e.value) {
                this.removeDuplicates(e.value, name);
            }
        }
    }

    // to remove duplicates
    removeDuplicates() {
        if (this.state.filteredSuggestions) {
            this.setSuggestions(this.state.filteredSuggestions);
        }
    }

    render() {
        const {
            placeholder, input, multiple
        } = this.props;
        const { touched, error } = this.props.meta;

        return (
            <div className="date-picker mb-3">
                <span className="p-fluid">
                    <AutoComplete style={{ background: 'white' }}
                        multiple={multiple}
                        value={(input && input.value) ? input.value : null}
                        suggestions={this.state.filteredSuggestions}
                        completeMethod={(e) => this.getAllSuggestions(e)}
                        size={20}
                        minLength={3}
                        placeholder={placeholder}
                        dropdown={true}
                        onChange={(e) => this.onSelectRecord(e)}
                        field={this.props.searchField}
                        disabled={this.props.disabled}
                    />
                </span>
                {touched && error && <span className="form__form-group-error">{error}</span>}
                {this.state.noData && <span className="form__form-group-error">{'no records found'}</span>}
            </div>
        )
    }
}