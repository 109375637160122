import React, { PureComponent } from 'react';
import {
   Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';

import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { load as loadAccount } from './../../../../redux/reducers/commonReducer';
import { loginUserInfo } from './../../../../redux/actions/authActions';
import RadioButton from '../../../../shared/components/form/RadioButton'
import Select from '../../../../shared/components/form/Select'

import renderCheckBoxField from '../../../../shared/components/form/CheckBox'
import { Link } from 'react-router-dom';
// file upload
import RenderFileInputField from '../../../Form/components/FileUpload';

// config
import fetch from '../../../../config/service';
import apiCalls from '../../../../config/apiCalls';
import config from '../../../../config/config';
import SessionModal from '../../../Cruds/Modals/SessionExpirationModal';
import CardHeader from 'reactstrap/lib/CardHeader';

const renderField = ({
   input, placeholder, type, defaultValue, isDisable, meta: { touched, error },
}) => (
      <div className="form__form-group-input-wrap">
         <input {...input} defaultValue={defaultValue} disabled={isDisable} placeholder={placeholder} type={type} />
         {touched && error && <span className="form__form-group-error">{error}</span>}
      </div>
   );


let count = 0;
let planCount = 0;
renderField.propTypes = {
   input: PropTypes.shape().isRequired,
   placeholder: PropTypes.string,
   type: PropTypes.string,
   meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.string,
   }),
};

renderField.defaultProps = {
   placeholder: '',
   meta: null,
   type: 'text',
   isDisable: false
};

class Settings extends React.Component {
   static propTypes = {
      t: PropTypes.func,
      handleSubmit: PropTypes.func.isRequired,
      reset: PropTypes.func.isRequired,
      pristine: PropTypes.bool.isRequired,
      submitting: PropTypes.bool.isRequired,
   };

   constructor(props) {
      super(props);
      this.state = {
         showPassword: false,
         openSessionModal: false,
         paymentFields: [],
         paymentTypes: [],
         free: true,
         paid: true
      };
   }

   componentDidMount() {
      this.getSettingsData();

   }
   setPaymentTypes = async (paymentTypes) => {
      await this.setState({
         paymentTypes: paymentTypes
      })
   }
   getValues = (e, index, label) => {

      let x = this.state.paymentTypes
      if (label == "Plan Name") {
         x[index]['label'] = e.target.value
      }
      if (label == "Amount") {
         x[index]['value'] = e.target.value
      }
      this.setState({
         paymentTypes: x
      })
   }
   setValues = () => {
      let x = this.state.paymentTypes
      x.push({ label: '', value: '' })
      this.setState({
         paymentTypes: x
      })
   }

   handleSubmit = (formValues) => {
      console.log(formValues)
      if (formValues) {
         // if(formValues.length>0){
         console.log(formValues)
         let values = {};
         let objects = []
         for (let vals in formValues) {
            if (vals.substring(0, vals.length - 1) == "plan" && formValues[vals] !== null) {
               for (let i in this.state.paymentTypes) {
                  if (formValues[vals] == this.state.paymentTypes[i].label) {
                     objects.push(this.state.paymentTypes[i])
                  }
               }
            }
         }
         console.log(objects)
         // paymentType.push({label:'gold',value:parseInt(formValues.gold)})
         // paymentType.push({label:'silver',value:parseInt(formValues.silver)})
         // paymentType.push({label:'platinum',value:parseInt(formValues.platinum)})
         values.paymentTypes = objects
         if (formValues['free'] && formValues['paid']) {
            objects.unshift({
               label: 'Free',
               value: 0
            })
         }
         values.paymentTypes = objects
         if (formValues['free'] && !formValues['paid']) {
            console.log('hi')
            this.setState({
               paymentFields: []
            })
         }
         values['enableMails'] = formValues['enableMails'] ? formValues['enableMails'] : null
         values['sendGridEmail'] = formValues['sendGridEmail'] ? formValues['sendGridEmail'] : null
         values['sendGridApiKey'] = formValues['sendGridApiKey'] ? formValues['sendGridApiKey'] : null
         values['disableMultipleLogin'] = formValues['disableMultipleLogin']
         values['paymentType'] = "paid"
         values['freePayment'] = formValues['free']
         values['paidPayment'] = formValues['paid']
         values['paymentAmount'] = formValues['paymentAmount'] ? parseInt(formValues['paymentAmount']) : null
         // }
         let Url
         Url = `${apiCalls.Settings}/${formValues._id}`;
         return fetch('PUT', Url, values)
            .then(async (response) => {
               let sessionexpired = localStorage.getItem("sessionexpired");
               if (sessionexpired == 'true') {
                  this.setState({
                     openSessionModal: true
                  })
                  localStorage.setItem('sessionexpired', false);
                  return;
               }
               if (response && response.respCode && response.respCode === 205) {
                  //  this.getSettingsData();
                  showToasterMessage(response.respMessage, 'success');

               } else if (response && response.errorMessage) {
                  showToasterMessage(response.errorMessage, 'error');
               }
            }).catch((err) => {
               return err;
            });
      } else {
         return;
      }
   };

   //   getPaymentType = async(values)=>{
   //       if(values==='free'){
   //           await this.setState({
   //               paymentType: 'free'
   //           })
   //       }
   //       if(values==='paid'){
   //         await this.setState({
   //             paymentType: 'paid'
   //         })
   //     }
   //   }

   deleteFields = async (index, item) => {
      //    console.log(i)
      //   let fields = this.state.paymentFields
      //   console.log(fields[index])
      //   fields.splice(index,1)
      //   console.log(fields)
      //   await this.setState({
      //       paymentFields:fields
      //   })
      //   this.props.change("paymentName"+index,null)
      //   this.props.change("amount"+index,null)

      //   count= count +1
      let fields = this.state.paymentFields
      console.log(item)
      console.log(fields)
      // fields.splice(index,1)
      for (let i in fields) {

         if (fields[i] == item) {
            console.log('hiiiii')
            console.log(fields[i])
            delete fields[i]
            this.props.change("plan" + i, null)
            this.props.change("amount" + i, null)
            count = count + 1
            planCount = planCount - 1
         }
      }
      console.log(fields)
      this.setState({
         paymentFields: fields
      })

   }

   // getValues = (e,index,label) =>{
   //    let x = this.state.paymentTypes
   //    x.push({})
   //    if(label == "Plan Name"){
   //       x[index]['label'] = e.target.value
   //    }
   //    if(label == "Amount"){
   //       x[index]['value'] = e.target.value
   //    }
   //    console.log(x)
   //    this.setState({
   //       paymentTypes: x
   //    })
   // }
   changePaid = () => {
      this.setState({
         paid: !this.state.paid
      })
   }

   changeFree = () => {
      this.setState({
         free: !this.state.free
      })
   }

   addFields = async () => {

      let { selectedControllerIndex, paymentFields } = this.state;
      let temp = paymentFields
      let temp1 = []
      let temp2 = { 'fields': [] }
      // let len = temp.length
      temp1.push(
         {
            "value": "",
            "name": `plan${count}`,
            "label": "Plan Name",
            "id": count,
            "placeholder": "Plan Name",
            "type": "text",
         }, {
         "value": "",
         "name": `amount${count}`,
         "label": "Amount",
         "id": count,
         "placeholder": "Amount",
         "type": "text",
      }
      )
      count = count + 1
      planCount = planCount + 1
      temp2.fields = temp1
      temp.push(temp2)
      this.setValues()
      await this.setState({
         paymentFields: temp
      })
      console.log(temp)
   }

   // get data from server based on Id
   getSettingsData = async () => {
      let Url = `${apiCalls.Settings}`;
      return fetch('GET', Url)
         .then(async (response) => {
            let sessionexpired = localStorage.getItem("sessionexpired");
            let responses;
            if (sessionexpired == 'true') {
               this.setState({
                  openSessionModal: true
               })
               localStorage.setItem('sessionexpired', false);
               return;
            }
            if (response && response.settings) {
               let res = response.settings[0]
               this.props.load(res);
               if (response.settings[0].paymentTypes) {
                  this.setPaymentTypes(response.settings[0].paymentTypes)
                  for (let i in response.settings[0].paymentTypes) {
                     console.log(response.settings[0].paymentTypes['label'])
                     if (response.settings[0].paymentTypes[i]['label'] == 'Free') {
                        console.log('hi')
                        response.settings[0].paymentTypes.splice(i, 1)
                     }
                     console.log(response.settings[0].paymentTypes)
                     if (response.settings[0].paymentTypes[i]['label'] != 'Free') {
                        let { paymentFields } = this.state;
                        let temp = paymentFields
                        let temp1 = []
                        let temp2 = { 'fields': [] }
                        // let len = temp.length
                        temp1.push(
                           {
                              "value": "",
                              "name": `plan${i}`,
                              "label": "Plan Name",
                              "id": i,
                              "placeholder": "Plan Name",
                              "type": "text",
                           }, {
                           "value": "",
                           "name": `amount${i}`,
                           "label": "Amount",
                           "id": i,
                           "placeholder": "Amount",
                           "type": "text",
                        }
                        )
                        count = count + 1
                        planCount = planCount + 1
                        temp2.fields = temp1
                        temp.push(temp2)
                        res[`plan${i}`] = response.settings[0].paymentTypes[i]['label']
                        res[`amount${i}`] = response.settings[0].paymentTypes[i]['value']

                        await this.setState({
                           paymentFields: temp
                        })
                        console.log(temp)
                     }
                  }
               }
               // if(!response.settings[0].paid){
               //    console.log('hiiiiii')
               // }
               this.setState({
                  paymentType: response.settings[0].paymentType,
               })
               if (response.settings[0].freePayment && response.settings[0].paidPayment) {
                  await this.setState({
                     free: true,
                     paid: true
                  })
                  res['free'] = true
                  res['paid'] = true

               } else if (response.settings[0].freePayment) {
                  await this.setState({
                     free: true,
                     paid: false
                  })
                  res['free'] = true
               } else if (response.settings[0].paidPayment) {
                  await this.setState({
                     free: false,
                     paid: true
                  })
                  res['paid'] = true
               }
               // this.props.load(res);
               let settingsInfo = { ...user, ...response.details };

               // Store login info in redux store
               // await this.props.updateLoginUserInfo(userInfo);
               // localStorage.setItem('loginCredentials', JSON.stringify(userInfo));
            } else if (response && response.errorMessage) {
            }
         }).catch((err) => {
            return err;
         });
   }

   render() {
      const { handleSubmit, reset, t } = this.props;
      let paymentTypes = [
         { label: 'Free', value: 'free' },
         { label: 'Paid', value: 'paid' }
      ]

      return (
         <Col md={12} lg={12}>
            <Card>
               <CardHeader style={{ backgroundColor: 'white', margin: 'auto' }}>
                  <h4>Settings</h4>
               </CardHeader>
               <CardBody>
                  <div className="card__title">
                     <h5 className="bold-text">Mail Settings</h5>
                  </div>
                  <form className="form" onSubmit={handleSubmit(this.handleSubmit)}>
                     <div className="form__half">
                        <div className="form__form-group">
                           <span className="form__form-group-label">Enable Mails</span>
                           <div className="form__form-group-field mb-2 mt-2">
                              <div className='mr-3'>
                                 <Field
                                    name="enableMails"
                                    component={RadioButton}
                                    // eslint-disable-next-line
                                    radioValue={true}
                                    label="True"
                                 />
                              </div>
                              <div >
                                 <Field
                                    name="enableMails"
                                    component={RadioButton}
                                    // eslint-disable-next-line
                                    radioValue={false}
                                    label="False"
                                 />
                              </div>
                           </div>
                        </div>
                        <div className="form__form-group  mb-3">
                           <span className="form__form-group-label">Send Grid EMail</span>
                           <div className="form__form-group-field">
                              <Field
                                 name="sendGridEmail"
                                 component={renderField}
                                 type="email"
                                 placeholder="Email"
                              />
                           </div>
                        </div>
                        {/*
                  <div className="form__form-group">
                     <span className="form__form-group-label">Payment Type</span>
                     <div className="form__form-group-field mb-2 mt-2">
                        <div className='mr-3'>
                           <Field
                              name="paymentType"
                              component={RadioButton}
                              // eslint-disable-next-line
                              radioValue={'free'}
                              label="Free"
                              getPaymentType = {this.getPaymentType}
                              />
                        </div>
                        <div >
                           <Field
                              name="paymentType"
                              component={RadioButton}
                              // eslint-disable-next-line
                              radioValue={'paid'}
                              label="Paid"
                              getPaymentType = {this.getPaymentType}
                              />
                        </div>
                     </div>
                  </div>
                  */}
                     </div>
                     <div className="form__half">
                        <div className="form__form-group">
                           <span className="form__form-group-label">Disable Multiple Login</span>
                           <div className="form__form-group-field mb-2 mt-2">
                              <div className='mr-3'>
                                 <Field
                                    name="disableMultipleLogin"
                                    component={RadioButton}
                                    // eslint-disable-next-line
                                    radioValue={true}
                                    label="True"
                                 />
                              </div>
                              <div >
                                 <Field
                                    name="disableMultipleLogin"
                                    component={RadioButton}
                                    // eslint-disable-next-line
                                    radioValue={false}
                                    label="False"
                                 />
                              </div>
                           </div>
                        </div>
                        <div className="form__form-group mb-3">
                           <span className="form__form-group-label">Send Grid API Key</span>
                           <div className="form__form-group-field">
                              <Field
                                 name="sendGridApiKey"
                                 component={renderField}
                                 type="text"
                                 placeholder="Grid Api Key"
                              />
                           </div>
                        </div>
                        {/* {this.state.paymentType==='paid'?
                  <div className="form__form-group mb-3">
                     <span className="form__form-group-label">Amount</span>
                     <div className="form__form-group-field">
                        <Field
                           name="paymentAmount"
                           component={renderField}
                           type="text"
                           placeholder="Amount"
                           />
                     </div>
                  </div>
                  :null} */}
                        {/* 
                  <div className="form__half">
                     <div className="form__form-group">
                        <span className="form__form-group-label">Enable Mails</span>
                        <div className="form__form-group-field mb-2 mt-2">
                           <div className='mr-3'>
                              <Field
                                 name="enableMails"
                                 component={RadioButton}
                                 // eslint-disable-next-line
                                 radioValue={true}
                                 label="True"
                                 />
                           </div>
                        </div>
                     </div>
                  </div>
                  */}
                        {/* 
                  <div className="form__half">
                     <div className="card__title">
                        <h5 className="bold-text">Mail Settings</h5>
                     </div>
                  </div>
                  */}
                        {/* 
                  <ButtonToolbar className="form__button-toolbar">
                     <Link to={'/Home'}>
                     <Button style={{ marginLeft: '50px' }} color='primary'
                     >Cancel</Button>
                     </Link>
                     <Button color="primary" type="submit">Submit</Button>
                  </ButtonToolbar>
                  */}
                     </div>
                     <div class='container p-0 pt-4 pb-4 d-none'>
                        <h5 style={{ fontSize: 13, fontWeight: 700, color: '#646777' }}>PAYMENT SETTINGS</h5>
                     </div>
                     <div className="form__full d-none" style={{ marginRight: 30 }}>
                        <div className="form__form-group">
                           <span className="form__form-group-label">Payment Type</span>
                           <div className="form__form-group-field mb-2 mt-2">
                              <div className="form__form-group">
                                 <div className="form__form-group-field">
                                    <Field
                                       name="free"
                                       component={renderCheckBoxField}
                                       label="Free"
                                       onChange={() => this.changeFree()}
                                       defaultChecked={this.state.free == 'free' ? true : false}
                                    />
                                    <div className="ml-2">
                                       <Field
                                          name="paid"
                                          component={renderCheckBoxField}
                                          label="Paid"
                                          onChange={() => this.changePaid()}
                                          defaultChecked={this.state.paid == 'paid' ? true : false}
                                       />
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        {/* {this.state.paymentType==='paid'?
                 <div className='row ml-1'>
                   <div className="form__form-group mb-3 pr-2 col-4 pl-0">
                     <span className="form__form-group-label">Platinum</span>
                     <div className="form__form-group-field">
                        <Field
                           name="platinum"
                           component={renderField}
                           type="text"
                           placeholder="Amount in dollars"
                           />
                     </div>
                  </div>
                  <div className="form__form-group mb-2 pl-2 col-4">
                     <span className="form__form-group-label">Gold</span>
                     <div className="form__form-group-field">
                        <Field
                           name="gold"
                           component={renderField}
                           type="text"
                           placeholder="Amount in dollars"
                           />
                     </div>
                  </div>
                  
                  <div className="form__form-group mb-2 pr-2 pl-0 col-4">
                     <span className="form__form-group-label">Silver</span>
                     <div className="form__form-group-field">
                        <Field
                           name="silver"
                           component={renderField}
                           type="text"
                           placeholder="Amount in dollars"
                           />
                     </div>
                  </div> */}


                        {/* </div>
                  :null} */}
                        {this.state.paid == true ?
                           <div>
                              <div className="mt-2 mb-3">
                                 <span style={{ fontSize: 15 }}>Create Plans</span> <FontAwesomeIcon
                                    icon="plus"
                                    className="pl-1"
                                    size="lg"
                                    data-toggle="tool-tip"
                                    onClick={() => this.addFields()}
                                 />
                              </div>
                              <div className="row">

                                 {
                                    this.state.paymentFields && this.state.paymentFields.length > 0 ? this.state.paymentFields.map((item, i) => {
                                       return <div className="col-6 mb-4">
                                          <div style={{ overflow: 'hidden' }}>
                                             <div style={{ float: 'left' }}>Plan Type {i + 1}</div>
                                             <div style={{ float: 'right' }}><FontAwesomeIcon
                                                className='genderIconAlignment'
                                                color='white'
                                                icon='trash-alt'
                                                data-toggle="tool-tip"
                                                title="Delete"
                                                style={{ color: 'rgba(255, 0, 0, 0.55)', width: '13', marginLeft: 10, cursor: 'pointer' }}
                                                onClick={() => this.deleteFields(i, item)}
                                             /></div>
                                          </div>
                                          <div className="card-body px-3 py-2" style={{ border: "1px solid rgba(0,0,0,.125)" }}>
                                             {item.fields ? item.fields.map((item1, index) => {
                                                return <div className="form__form-group mb-2 pr-2 pl-0">
                                                   <span className="form__form-group-label">{item1.label}</span>
                                                   <div className="form__form-group-field">
                                                      <Field
                                                         name={item1.name}
                                                         component={renderField}
                                                         type="text"
                                                         defaultValue={item.defaultValue}
                                                         onChange={(e) => this.getValues(e, item1.id, item1.label)}
                                                         placeholder={item1.placeholder}
                                                      />
                                                   </div>
                                                </div>
                                             }) : null}


                                          </div>

                                       </div>
                                    }) : null
                                 }
                              </div>

                           </div>
                           : null}


                        {/*
                  <div className="form__form-group">
                     <span className="form__form-group-label">Payment Type</span>
                     <div className="form__form-group-field mb-2 mt-2">
                        <div className='mr-3'>
                           <Field
                              name="paymentType"
                              component={RadioButton}
                              // eslint-disable-next-line
                              radioValue={'free'}
                              label="Free"
                              getPaymentType = {this.getPaymentType}
                              />
                        </div>
                        <div >
                           <Field
                              name="paymentType"
                              component={RadioButton}
                              // eslint-disable-next-line
                              radioValue={'paid'}
                              label="Paid"
                              getPaymentType = {this.getPaymentType}
                              />
                        </div>
                     </div>
                  </div>
                  */}
                        <ButtonToolbar className="form__button-toolbar mt-4">
                           <Link to={'/Home'}>
                              <Button style={{ marginLeft: '50px' }} color='primary'
                              >Cancel</Button>
                           </Link>
                           <Button color="primary" type="submit">Submit</Button>
                        </ButtonToolbar>
                     </div>
                     <div className="form__half">
                        {/* {this.state.paymentType==='paid'?
                  <div className="form__form-group mb-2">
                     <span className="form__form-group-label">Gold Plan</span>
                     <div className="form__form-group-field">
                        <Field
                           name="gold_paymentAmount"
                           component={renderField}
                           type="text"
                           placeholder="Amount in dollars"
                           />
                     </div>
                  </div>
                  :null}
                  {this.state.paymentType==='paid'?
                  <div className="form__form-group mb-3">
                     <span className="form__form-group-label">Platinum Plan</span>
                     <div className="form__form-group-field">
                        <Field
                           name="platinum_paymentAmount"
                           component={renderField}
                           type="text"
                           placeholder="Amount in dollars"
                           />
                     </div>
                  </div>
                  :null} */}
                        {/* {this.state.paymentType==='paid'?
                  <div className="form__form-group mb-3">
                     <span className="form__form-group-label">Amount</span>
                     <div className="form__form-group-field">
                        <Field
                           name="paymentAmount"
                           component={renderField}
                           type="text"
                           placeholder="Amount"
                           />
                     </div>
                  </div>
                  :null} */}
                        {/* 
                  <div className="form__half">
                     <div className="form__form-group">
                        <span className="form__form-group-label">Enable Mails</span>
                        <div className="form__form-group-field mb-2 mt-2">
                           <div className='mr-3'>
                              <Field
                                 name="enableMails"
                                 component={RadioButton}
                                 // eslint-disable-next-line
                                 radioValue={true}
                                 label="True"
                                 />
                           </div>
                        </div>
                     </div>
                  </div>
                  */}
                        {/* 
                  <div className="form__half">
                     <div className="card__title">
                        <h5 className="bold-text">Mail Settings</h5>
                     </div>
                  </div>
                  */}



                     </div>
                  </form>
               </CardBody>
            </Card>
            {/* Session Expiration Modal */}
            <SessionModal openSessionModal={this.state.openSessionModal} />
         </Col >
      );
   }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
Settings = reduxForm({
   form: "Settings-Form", // a unique identifier for this form
   enableReinitialize: true,
})(Settings);

// You have to connect() to any reducers that you wish to connect to yourself
Settings = connect(
   state => ({
      initialValues: state.commonData.data // pull initial values from account reducer
   }),
   { load: loadAccount } // bind account loading action creator
)(Settings);


const mapDispatchToProps = (dispatch) => {
   return {
      updateLoginUserInfo: (userInfo) => dispatch(loginUserInfo(userInfo))
   }
}

export default connect(null, mapDispatchToProps)(Settings);