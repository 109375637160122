
import React from 'react';
import RolePermissions from '../../../DataTable/Permissions';

// Table 
import TableRender from '../../../DataTable/TableRender';

// config file
import apiCalls from '../../../../../config/apiCalls';
import fetchMethodRequest from '../../../../../config/service'

export default class Recalls extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clinics: [],
            products: [],
            locations: []
        };
    }
    componentDidMount = () => {
        if (this.props.match.path == '/recall/:cart') {
            window.history.pushState(null, null, window.location.pathname);
            window.addEventListener('popstate', this.onBackButtonEvent);
        }
    }

    onBackButtonEvent = () => {
        if (this.props.match.path == '/recall/:cart') {

            if (window.location.pathname != 'inmedtory/home') {
                window.location.pathname = 'inmedtory/home'
            }
        }
        else {
            return window
        }
    }

    getTableFields = () => {
        let data = [
            { textAlign: 'left', width: 135, field: 'productName', header: 'Product Name', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 135, field: 'description', header: 'Description', filter: true, sortable: true, placeholder: 'Search' },

            { textAlign: 'left', width: 135, field: 'lotId', header: 'Lot Id', filter: true, sortable: true, placeholder: 'Search' },

            { textAlign: 'left', width: 135, field: 'clinicName', header: 'Facility', filter: true, sortable: true, placeholder: 'Search' },

            { textAlign: 'left', width: 135, field: 'productsInCarts', header: 'Storage Unit', filter: true, sortable: true, placeholder: 'Search' },

            // { textAlign: 'left', width: 135, field: 'qrcode', header: 'Qr Code', filter: true, sortable: true, placeholder: 'Search' },

            { textAlign: 'center', width: 90, field: 'Actions', header: 'Actions', filter: false, sortable: false },
        ];
        let screenPermissions = RolePermissions.screenPermissions('Recall Items');
        if (screenPermissions && screenPermissions != 'edit') {
            data.pop();
        }
        return data;
    };

    getFormFields = () => {
        return ([
            {
                required: true, value: '', type: 'autoComplete', name: 'companyId',
                label: 'Company Name', id: 'company', placeholder: 'Search company',
                searchApi: apiCalls.ActiveCompanies, searchField: 'name', disabled: true
            },
            {
                required: true, value: '', type: 'autoComplete', name: 'lotId',
                label: 'Lot Id', id: 'Lot Id', searchApi: apiCalls.Quantities, searchField: 'lotId', placeholder: 'Search Lot Id',
            },
            {
                value: '', type: 'text', name: 'productName',
                label: 'Product', id: 'products', placeholder: 'Select Product', disable: true
            },
            {
                required: true, value: '', type: 'textarea', name: 'reason',
                label: 'Reason', id: 'reason', placeholder: 'Type the Reason'
            }
        ]);
    }

    render() {
        return (
            <div>
                <TableRender type='Recall Items'
                    globalSearch='Lot Id/Facility'
                    tableFields={this.getTableFields}
                    formFields={this.getFormFields} {...this.props}
                />
            </div>
        );
    }
}