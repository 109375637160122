import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CategoriesTable from './components/CategoriesTable'


const CategoriesTableList = (props) => (

    <Container>
        <CategoriesTable {...props} />
    </Container>
);

CategoriesTableList.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(CategoriesTableList);