import React from 'react';
import RolePermissions from '../../../DataTable/Permissions';

// Table 
import TableRender from '../../../DataTable/TableRender';

// config file
import apiCalls from '../../../../../config/apiCalls';
import fetchMethodRequest from '../../../../../config/service'

export default class CompaniesTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clinics: [],
            products: [],
            locations: [],
            inventoryTypes: []
        };
    }
    componentDidMount = () => {
        this.getClinicDropDownValues();
        this.getInventoryDropDownValues();
    }

    getInventoryDropDownValues = () => {
        return fetchMethodRequest('GET', apiCalls.Settings)
            .then(async (response) => {
                if (response && response.settings && response.settings[0]) {
                    this.setState({
                        inventoryTypes: response.settings[0].inventoryTypes
                    })
                }
            });
    }

    getTableFields = () => {
        let data = [
            { textAlign: 'left', width: 135, field: 'productName', header: 'Product Name', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 135, field: 'description', header: 'Description', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 105, field: 'volume', header: 'Volume', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 135, field: 'expirationDate', header: 'Expiration Date', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 105, field: 'clinicName', header: 'Facilities', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 135, field: 'cartName', header: 'Storage Unit', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 125, field: 'cartSubLocation', header: 'Sub Location', filter: true, sortable: true, placeholder: 'Search' },
            { textAling: 'center', width: 90, field: 'qrcode', header: 'QR Code' },
            { textAlign: 'center', width: 115, field: 'lotId', header: 'Lot Id', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 90, field: 'Actions', header: 'Actions', filter: false, sortable: false },
        ];
        let screenPermissions = RolePermissions.screenPermissions('Storage Unit Items');
        if (screenPermissions && screenPermissions != 'edit') {
            data.pop();
        }
        return data;
    };

    getFormFields = () => {
        return ([
            {
                required: true, value: '', type: 'autoComplete', name: 'companyId',
                label: 'Company Name', id: 'company', placeholder: 'Search company',
                searchApi: apiCalls.ActiveCompanies, searchField: 'name', call: 'GET',
                disabled: true
            },
        ]);
    }

    getClinicDropDownValues = () => {
        return fetchMethodRequest('GET', apiCalls.Clinics)
            .then(async (response) => {
                if (response && response.clinics && response.clinics.length > 0) {
                    let modifiedClinicNames = [];
                    for (let clinic of response.clinics) {
                        modifiedClinicNames.push({
                            label: clinic.name,
                            value: clinic._id
                        })
                    }
                    this.setState({
                        clinics: modifiedClinicNames
                    })
                }
            });
    }
    render() {
        return (
            <div>
                <TableRender type='Storage Unit Items'
                    globalSearch='Product Name/Description/Facilities/Storage Unit/Sub Locations/Lot Id'
                    tableFields={this.getTableFields}
                    formFields={this.getFormFields} {...this.props}
                    addType={'Quantity'}
                    inventoryTypes={this.state.inventoryTypes}
                />
            </div>
        );
    }
}