import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
  ButtonToolbar,
  ButtonGroup,
  Table
} from "reactstrap";
import classNames from "classnames";

import { load as loadAccount } from "./../../redux/reducers/commonReducer";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddProductModal from "../Cruds/Modals/AddProductModal";
import AddInventoryModal from "../Cruds/Modals/RequiredInventoryModal";
import PropTypes from "prop-types";
import { InputText } from 'primereact/inputtext';
// import { MultiSelect } from 'react-multi-select-component';
//import { MultiSelect } from '../../shared/components/form/MultiSelect';
//components
import AutoComplete from "./components/AutoComplete";
import CalendarBlankIcon from "mdi-react/CalendarBlankIcon";
import { Dropdown } from "primereact/dropdown";
import Select from "../../shared/components/form/Select";
import RadioButton from "../../shared/components/form/RadioButton";
import DefaultInput from "../../shared/components/form/DefaultInput";
import DefaultTextArea from "../../shared/components/form/DefaultTextArea";
import renderColorPicker from "../../shared/components/form/ChromeColorPicker";
import DatePicker from "../../shared/components/form/DatePicker";
import RenderFileInputField from "../Form/components/FileUpload";
import { faTimes, faStarOfLife } from '@fortawesome/free-solid-svg-icons';
import DeleteRowModal from "../Cruds/Modals/DeleteRowModal";
import config from "../../config/config";
import configMessages from "../../config/configMessages";
import apiCalls from "../../config/apiCalls";
import fetchMethodRequest from "../../config/service";

// Toaster message
import showToasterMessage from "../UI/ToasterMessage/toasterMessage";

import dateFormats from "../UI/FormatDate/formatDate";
import validate from "../Validations/validate";

// Multi select DropdownSelect
import MultiSelectDropDown from "./components/MultiSelect";

// Loader
import Loader from "../App/Loader";

// Calendar
import AddCategoryModal from "../Cruds/Modals/AddCategoryModal";
import TicketCommentsInfo from "../Cruds/List/Tickets/components/TicketCommentsInfo";
import SessionModal from "../Cruds/Modals/SessionExpirationModal";
let statusOptions = [];
const required = (value) =>
  value || typeof value === "string" ? undefined : configMessages.fillField;
const lessThan = (otherField) => (value, previousValue, allValues) =>
  value < allValues[otherField] ? value : previousValue;
const greaterThan = (otherField) => (value, previousValue, allValues) =>
  value > allValues[otherField] ? value : previousValue;
const normalizeCharacters = (value) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^a-z A-z 0-9]/g, "");
  return `${onlyNums}`;
};
const normalizestate = (value) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^a-z A-z]/, "");
  return `${onlyNums}`;
};
const greaterThan0 = (value) => (value > 0 ? value : null);

const lessThanEqual = (otherField) => (value, previousValue, allValues) =>
  value <= allValues[otherField] && value > 0 ? value : previousValue;
const normalizenumber = (value) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, "");
  return `${onlyNums}`;
};

// Format Phone num
const normalizePhone = (value) => {
  if (!value) {
    return value;
  }
  let onlyNums = value.replace(/[^\d]/g, "");
  if (onlyNums.length == 1) {
    onlyNums = "1-" + onlyNums;
  }
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 7) {
    return `${onlyNums.slice(0, 1)}-${onlyNums.slice(1, 4)}-${onlyNums.slice(
      4
    )}`;
  }

  return `${onlyNums.slice(0, 1)}-${onlyNums.slice(1, 4)}-${onlyNums.slice(
    4,
    7
  )}-${onlyNums.slice(7, 11)}`;
};

class FormModal extends React.Component {
  static propTypes = {
    t: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      formFields: [],
      formValues: {},
      DOB: "",
      selected: [],
      colored: false,
      header: true,
      isLoading: false,
      checked: false,
      inventoryDetails: {},
      inventoryHistory: [],
      inventoryComments: [],
      inventoryAttachments: [],
      savedComId: "",
      locationImage: null,
      iscustomCliniclocation: false,
      isCustomSublocation: false,
      categoryNameOptions: [],
      categoryType: "Medicine",
      distributorsNameOptions: [],
      isCustomSublocation: false,
      isCustomCategory: false,
      isAutoReOrder: false,
      isManualReOrder: false,
      openCategoryModal: false,
      isSubLoction: false,
      isTypeMedicine: true,
      showCategoryType: false,
      subLocations: [],
      stateProductName: [],
      openInventoryModal: false,
      roleType: [{ label: "Pharmasist", value: "pharmasist" }],
      isCompanySelect: false,
      isNewCustomCategory: false,
      selectedProductClinicName: null,
      isRolePharmasist: false,
      isCommentsUpdated: false,
      status: "",
      // productTablefields: [{}],
      isCustomVolumeDes: false,
      days: [
        { label: "Sunday", value: "sunday" },
        { label: "Monday", value: "monday" },
        { label: "Tuesday", value: "tuesday" },
        { label: "Wednesday", value: "wednesday" },
        { label: "Thursday", value: "thursday" },
        { label: "Friday", value: "friday" },
        { label: "Saturday", value: "saturday" },
      ],
      clinics: {},
      formModalType: null,
      locationType: null,
      openSessionModal: false,
    };
  }

  componentDidMount = async () => {
    this.props.load({});

    if (this.props.onRef) {
      this.props.onRef(this);
    }

    if (this.props.companyName) {
      this.setCompanyName(this.props.companyName);
    }
    if (this.props.type == "Storage Unit Items") {
      this.setState({
        companyId: this.props.companyId,
      });
      this.props.change("companyId", this.props.companyName);
      this.props.change("addedInventory", 1);
      this.getCompanyValues();
    }
    if (this.props.type == "Product Directory") {
      this.getCategoryDropDownValues(this.props.companyId);
    }

    if (this.props.type == "addOrder") {
      await this.setState({
        orderProduct: this.props.orderProductName,
        orderDescription: this.props.orderDescription,
        orderConcentration: this.props.orderConcentration,
      });
      this.props.change("productName", this.props.orderProductName);
      this.props.change("description", this.state.orderDescription);
      this.props.change("concentration", this.state.orderConcentration);
      this.props.change("required", this.props.orderNeeded);
    }
    if (this.props.type == "Storage Unit") {
      this.getFacilityValues();
      this.getFacilityDropDownValues();
      if (this.props.formType == "add") {
        this.props.change("noofDrawers", 5);
      }
    }
    if (this.props.id == "Checks") {
      this.setState({
        cartName: this.props.cart,
      });
      this.getTitleData(this.props.title1, this.props.cart);
      this.getLocationSubParts(this.props.inventoryCartName);
    }
    this.getFormFields();
    this.getFormModalType();
    this.getDistributorNames();
    if (this.props.type == "Leaves") {
      this.setState({
        minDate: new Date(),
      });
    }
    if (this.props.type == "Facility Staff") {
      this.getClinicsForFacilityStaff();
    }
    if (
      (this.props.type == "Storage Unit Items" || this.props.id == "Checks") &&
      this.props.formType == "edit"
    ) {
      this.getCountData();
    }
  };

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }

  getFormModalType = () => {
    let formModalType = this.props.type;
    if (formModalType == "Facilities") {
      formModalType = "Facility";
    } else if (formModalType == "Storage Unit") {
      if (this.props.tabType == "Cart Locations") {
        formModalType = "Cart Location";
      } else if (this.props.tabType == "Supply Cabinets") {
        formModalType = "Supply Cabinet";
      } else if (this.props.tabType == "OR Suites") {
        formModalType = "OR Suite";
      }
    } else if (formModalType == "Categories") {
      formModalType = "Category";
    } else if (formModalType == "Help") {
      formModalType = "Ticket";
    } else if (formModalType == "addOrder") {
      formModalType = "Order";
    } else if (formModalType == "Companies") {
      formModalType = "Company";
    } else if (formModalType == "Required Inventories") {
      formModalType = "Required Inventory";
    } else if (formModalType == "Recommended Inventories") {
      formModalType = "Recommended Inventory";
    } else {
      if (
        typeof formModalType == "string" &&
        (formModalType != "DashInventory" &&
          formModalType != "Facility Staff" &&
          formModalType != "Product Directory")
      ) {
        formModalType = formModalType.replace(/.$/, "");
      }
    }
    this.setState({
      formModalType,
    });
  };
  containsWhitespace(str) {
    return /\s/.test(str);
  }
  getFormFields = async () => {
    if (this.props.formFields) {
      let formFields = await this.props.formFields();
      if (this.props.type == "Storage Unit" && this.props.formType == "edit") {
        formFields = formFields.filter((field) => {
          return field.name != "cartSublocation";
        });
      }
      await this.setState({
        formFields: formFields,
      });
    }
  };

  setCompanyName = async (value) => {
    await this.setState({
      company: value,
    });
    this.props.change("companyId", this.state.company);
  };

  //Get From Fields data on Edit
  getRowData = async (selectedRowInfo) => {
    let rowData = selectedRowInfo;
    if (
      this.props.type == "Storage Unit Items" ||
      this.props.type == "Product Directory" ||
      this.props.type == "Distributors" ||
      this.props.type == "Categories" ||
      this.props.type == "Storage Unit" ||
      this.props.type == "Facilities"
    ) {
      await this.getCompanyValues();
    }
    if (this.props.type == "Product Directory") {
      await this.getCategoryNames();
      this.setState({
        isCustomCategory: true,
        showCategoryType: true,
      });
    }
    if (rowData) {
      this.setState({
        rowData: rowData,
        locationImage: rowData.photo ? rowData.photo : null,
      });

      let data33 = this.containsWhitespace(selectedRowInfo.companyId)
      if (this.props.type === "Product Directory" && selectedRowInfo.companyId && selectedRowInfo.companyId.length === 24 && !data33)
        this.setState({
          savedComId: selectedRowInfo.companyId
        })
      if (this.props.type == "Companies") {
        if (rowData.status) {
          if (rowData.status == "Active") {
            statusOptions = [{ label: "Blocked", value: "Blocked" }];
          } else if (rowData.status == "Blocked") {
            statusOptions = [{ label: "Active", value: "Active" }];
          } else if (rowData.status == "suspend") {
            statusOptions = [
              { label: "Active", value: "Active" },
              { label: "Blocked", value: "Blocked" },
            ];
          }
        }

      }
      if (this.props.type == "Storage Unit") {
        let body = {
          label: rowData.companyName,
          _id: rowData.company_id,
        };
        this.getFacilityValues(body);
        rowData.clinics = rowData.clinicName;
        if (rowData.company_id) {
          if (this.state.companies) {
            for (let values of this.state.companies) {
              if (values.value == rowData.company_id) {
                rowData.companyId = values.label;
              }
            }
          }
        }
      }
      if (this.props.type == "Distributors") {
        if (rowData.companyId) {
          if (this.state.companies) {
            for (let values of this.state.companies) {
              if (values.value == rowData.companyId) {
                rowData.companyId = values.label;
              }
            }
          }
        }
      }
      if (this.props.type == "Categories") {
        if (rowData.companyId) {
          if (this.state.companies) {
            for (let values of this.state.companies) {
              if (values.value == rowData.companyId) {
                rowData.companyId = values.label;
              }
            }
          }
        }
      }
      if (this.props.type == "Facilities") {
        if (rowData.company_id) {
          if (this.state.companies) {
            for (let values of this.state.companies) {
              if (values.value == rowData.company_id) {
                rowData.companyId = values.label;
              }
            }
          }
        }
      }

      if (this.props.type == "Tickets") {
        this.ticketCommentsRef.getselectedRowCommentsData(rowData);
      }
      if (this.props.type == "Required Inventories") {
        const sorted = rowData.requiredInventory.sort((a, b) => {
          let fa = a.productName.toLowerCase(),
            fb = b.productName.toLowerCase();
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
        await this.setState({
          productTablefields: sorted,
          allOriginalFields: sorted
        })
      }
      if (this.props.type == "Recommended Inventories") {
        const sortedData = rowData.recommendedInventory.sort((a, b) => {
          let fa = a.productName.toLowerCase(),
            fb = b.productName.toLowerCase();
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
        let fileterdData = sortedData
        if (this.state.categoryType && this.state.categoryType != '') {
          fileterdData = sortedData.filter((obj) => obj.categoryType == this.state.categoryType)
        }
        await this.setState({
          productTablefields: fileterdData,
          allOriginalFields: sortedData
        })
      }

      if (this.props.type == "Employees") {
        if (
          rowData.pharmasistCompanies &&
          rowData.pharmasistCompanies.length > 0
        ) {
          this.setState({
            isRolePharmasist: true,
          });
          for (let company of rowData.pharmasistCompanies) {
            delete company._id;
          }
        }
      }
      if (rowData.userName) {
        rowData.user = rowData.userName;
      }
      if (rowData.categoryId) {
        if (rowData.categoryId) {
          this.setState({
            catId: rowData.categoryId._id,
          });
        }
        rowData.categoryId = rowData.categoryId.categoryName;
      }
      if (this.props.type == "Product Directory") {
        if (rowData.companyId) {

          if (this.state.companies) {
            for (let values of this.state.companies) {
              if (values.value == rowData.companyId) {
                rowData.companyId = values.label;
              }
            }
          }
          if (rowData.companyId === this.state.savedComId) {
            rowData.companyId = ""
          }
        }
        if (rowData.clinicId) {
          this.setState({
            clinic_Id: rowData.clinicId._id,
          });
          rowData.clinicId = rowData.clinicId.name
            ? rowData.clinicId.name
            : rowData.clinicId;
        }
      }
      if (this.props.type == "Storage Unit Items") {
        if (this.props.id == "Checks") {
          rowData.cartSubLocation = this.props.cartSubLocation;
          await this.setState({
            subLoc: rowData.cartSubLocation,
          });
          rowData.expirationDate = dateFormats.formatDate(
            rowData.expirationDate,
            config.dateDBFormat
          );
          rowData.count2 = rowData.count1;
          rowData.clinicId = rowData.clinicId.name
            ? rowData.clinicId.name
            : rowData.clinicId;
          rowData.locationId = rowData.locationId.cart
            ? rowData.locationId.cart
            : rowData.locationId;
        }
        if (rowData.batteryExists) {
          await this.setState({
            isBattery: true,
          });
        } else if (!rowData.batteryExists) {
          await this.setState({
            isMedicine: true,
            isNonMedicine: true,
          });
        }
        if (rowData.productId._id) {
          this.setState({
            productId: rowData.productId._id
              ? rowData.productId._id
              : rowData.productId,
          });
        }
        if (rowData && rowData.locationId && rowData.locationId._id) {
          this.setState({
            inventoryCartId:
              rowData.locationId && rowData.locationId._id
                ? rowData.locationId._id
                : rowData.locationId,
          });
        }
        this.getCountData();
        await this.getUniProductName(rowData.title1);
        if (rowData.clinicName) {
          this.setState({
            clinicName: rowData.clinicName,
          });
        }
        if (rowData.cartName && this.props.id != "Checks") {
          this.setState({
            cartName: rowData.locationId.cart,
          });
        }
        rowData.cartName = this.state.cartName;
        if (rowData.companyId) {
          if (this.state.companies) {
            for (let values of this.state.companies) {
              if (values.value == rowData.companyId) {
                rowData.companyId = values.label;
              }
            }
          }
        }
        if (rowData.batteryExists) {
          await this.setState({
            isBattery: true,
          });
        } else if (!rowData.batteryExists) {
          await this.setState({
            isMedicine: true,
            isNonMedicine: true,
          });
        }

        if (rowData.description) {
          this.props.change("description", rowData.description);
          rowData.description = rowData.description;
        }
      }

      if (this.props.type == "Product Directory") {
        rowData.categoryField = "selectCategory";
        if (rowData.productId) {
          rowData.productId = rowData.productId.productName
            ? rowData.productId.productName
            : rowData.productId;
        }
        if (rowData.locationId) {
          rowData.locationId = rowData.locationId.cart
            ? rowData.locationId.cart
            : rowData.locationId;
        }
        if (rowData.distributorId) {
          if (rowData.distributorId) {
            this.setState({
              distributor_Id: rowData.distributorId._id,
            });
          }
          rowData.distributorId = rowData.distributorId.displayName
            ? rowData.distributorId.displayName
            : rowData.distributorId;
        }
        if (rowData.reorder && rowData.reorder == "auto") {
          this.setState({
            isAutoReOrder: true,
          });
        }
        if (rowData.reorder && rowData.reorder == "manual") {
          this.setState({
            isManualReOrder: true,
          });
        }
      }

      if (rowData.cartLocation) {
        rowData.building = rowData.cartLocation.building
          ? rowData.cartLocation.building
          : null;
        rowData.area = rowData.cartLocation.area
          ? rowData.cartLocation.area
          : null;
        rowData.floor = rowData.cartLocation.floor
          ? rowData.cartLocation.floor
          : null;
        rowData.facility = rowData.cartLocation.facility
          ? rowData.cartLocation.facility
          : null;
        rowData.room = rowData.cartLocation.room
          ? rowData.cartLocation.room
          : null;
      }
      if (rowData.address && this.props.type != "Companies") {
        rowData.street = rowData.address.street ? rowData.address.street : null;
        rowData.city = rowData.address.city ? rowData.address.city : null;
        rowData.state = rowData.address.state ? rowData.address.state : null;
        rowData.zip = rowData.address.zip ? rowData.address.zip : null;
      }

      if (rowData.reordering) {
        rowData.autoMin = rowData.reordering.autoMin
          ? rowData.reordering.autoMin
          : null;
        rowData.autoMax = rowData.reordering.autoMax
          ? rowData.reordering.autoMax
          : null;
        rowData.manualMax = rowData.reordering.manualMax
          ? rowData.reordering.manualMax
          : null;
      }
    }

    await this.getFormFields(rowData);
    let keys = Object.keys(rowData);
    let formData = [];
    if (this.state.formFields) {
      for (let item of this.state.formFields) {
        if (keys.indexOf(item.name) !== -1) {
          if (
            item.name == "startDate" ||
            item.name == "endDate" ||
            item.name == "dateOfBirth" ||
            item.name == "joinDate" ||
            item.name == "fromDate" ||
            item.name == "toDate"
          ) {
            item.value = dateFormats.formatDate(
              rowData[item.name],
              config.dateDayMonthFormat
            );
          } else {
            item.value = rowData[item.name];
          }
        } else {
          item.value = " ";
        }
        formData.push(item);
      }
    }
    // to load intail values of form
    if (this.props.load) {
      this.props.load(rowData);
    }

    this.setState({
      isLoading: false,
      editRowDataID: rowData._id,
      formFields: formData,
    });
  };

  // to empty form
  clearFormFields = async () => {
    if (this.props.load) {
      this.props.load({});
    }
    if (this.state.formFields) {
      let formData = [...this.state.formFields];
      if (formData) {
        formData.forEach((item) => {
          item.value = "";
          item.invalid = false;
          item.message = "";
        });
        await this.setState({
          formFields: formData,
        });
        this.getFormFields();
      }
    }
  };




  closeFormModal = async () => {
    this.props.reset()
    this.clearFormFields();

    this.props.closeFormModal();
    if (this.state.isCommentsUpdated) {
      await this.props.getDataFromServer();
    }
  };

  closeCategoryModal = () => {
    if (this.props.formType == "edit") {
      this.props.load(this.state.rowData);
    }
    this.props.change("categoryField", "selectCategory");
    this.setState({
      isCustomCategory: true,
      openCategoryModal: false,
      showCategoryType: true,
    });
  };
  closeInventoryModal = () => {
    if (this.props.formType == "edit") {
      // this.props.load(this.state.rowData);
    }
    //this.props.change("categoryField", "selectCategory");
    this.setState({
      //isCustomCategory: true,
      openInventoryModal: false,
      //showCategoryType: true,
    });
  };

  closeQrModal = () => {
    this.setState({
      openQrModal: false,
    });
  };

  //handle fields data
  handleData = async (value, name, type, searchName, otherField) => {
    let formValues = this.state.formValues;
    let formFields = [...this.state.formFields];
    // for auto complete values
    if (type && type == "autoComplete") {
      if (value._id) {
        formValues[name] = value._id;
      }
      if (otherField && searchName && value[searchName]) {
        formValues[otherField] = value[searchName];
      }
    }
    formFields.forEach(async (item) => {
      if (item.name == name) {
        item.value = value;
        item.invalid = false;
        item.message = "";
        if (item.name == "dateOfBirth") {
          this.setState({ DOB: item.value });
        }
      }
    });
    formValues[name] = value;
    await this.setState({
      formFields: formFields,
      formValues: formValues,
    });

    if (name == "reason" && type == "changeStatus") {
      this.saveDataToServer(formValues.reason, type);
    } else if (name == "status" && type == "changeStatus") {
      this.saveDataToServer(formValues.status, type);
    }
  };

  // form Submit
  submit = async (values) => {
    if (this.props.type == "Required Inventories" || this.props.type === "Recommended Inventories") {
      await this.onFilterChange()
    }
    if (this.props.type == "Product Directory" && this.props.formType == "edit" && values.companyId === "") {
      delete values.companyId
    }
    if (this.props.type == "addOrder") {
      values.distributorEmail =
        values.distributorEmail && values.distributorEmail.email
          ? values.distributorEmail.email
          : values.distributorEmail;
      values.type = this.props.orderFrom;
      values.clinics = this.props.orderClinic
        ? this.props.orderClinic
        : this.props.orderClinicId;
      values.cartName = this.props.orderCart;
      values.concentration = this.state.isCustomVolume
        ? values.customVolume
        : values.concentration;
      values.companyId = this.props.companyId;
      await this.getCompanyValues();
      if (this.state.companies) {
        for (let option of this.state.companies) {
          if (
            option.label ==
            (values.companyId && values.companyId.name
              ? values.companyId.name
              : values.companyId)
          ) {
            values.company_id = option.value;
          }
        }
      }
    }
    if (this.props.type == "Recall Items") {
      await this.getCompanyValues();
      if (this.state.companies) {
        for (let option of this.state.companies) {
          if (
            option.label ==
            (values.companyId.name ? values.companyId.name : values.companyId)
          ) {
            values.company_id = option.value;
          }
        }
      }
    }
    if (this.props.type == "Facilities") {
      await this.getCompanyValues();
      values = this.formatClinicAddressData(values);
      if (this.state.companies) {
        for (let option of this.state.companies) {
          if (
            option.label ==
            (values.companyId.name ? values.companyId.name : values.companyId)
          ) {
            values.company_id = option.value;
          }
        }
      }
    }
    // if (this.props.type == "Companies") {
    //   values = this.formatCompaniesData(values);
    // }
    if (this.props.type == "Categories") {
      if (this.props.formType == "add") {
        await this.getCompanyValues();
      }
      if (this.state.companies) {
        for (let option of this.state.companies) {
          if (
            option.label ==
            (values.companyId.name ? values.companyId.name : values.companyId)
          ) {
            values.company_id = option.value;
          }
        }
      }
    }

    if (this.props.type == "Distributors") {
      await this.getCompanyValues();
      if (this.state.companies) {
        for (let option of this.state.companies) {
          if (
            option.label ==
            (values.companyId.name ? values.companyId.name : values.companyId)
          ) {
            values.company_id = option.value;
          }
        }
      }
    }

    if (this.props.type == "Storage Unit" && values) {
      await this.getCompanyValues();
      values = this.formatLocationsData(values);
      if (this.state.facilities) {
        for (let option of this.state.facilities) {
          if (option.label == values.clinicName) {
            values.clinicId = option.id;
          }
        }
      }
      if (this.state.companies) {
        for (let option of this.state.companies) {
          if (
            option.label ==
            (values.companyId.name ? values.companyId.name : values.companyId)
          ) {
            values.company_id = option.value;
          }
        }
      }
      values.clinics = values.clinicId;
    }
    if (this.props.type == "Product Directory") {
      values.reorder = this.state.reorder;
      if (values.reorder) {
        values = this.formatReorderData(values);
      }

      await this.getCompanyValues();
      if (this.state.companies && values.companyId) {
        for (let option of this.state.companies) {
          if (
            option.label ==
            (values.companyId.name ? values.companyId.name : values.companyId)
          ) {
            values.company_id = option.value;
            values.companyId = option.value;
          }
        }
      }
    }
    if (this.props.id == "Checks") {
      values.oldSubLocation = this.state.subLoc;
      values.newSubLocation = values.cartSubLocation.cartParts;
      values.cart = values.cartName;
    }

    if (this.props.type == "Storage Unit Items") {
      values.locationType = this.state.locationType
        ? this.state.locationType
        : values.locationType
          ? values.locationType
          : null;
      values.cartName = this.state.cartName
        ? this.state.cartName
        : values.cartName;
      values.volume = this.state.isCustomVolumeDes
        ? values.customVolumeDes
        : values.volume;
      values.productName =
        values.productName && values.productName.title
          ? values.productName.title
          : values.productName;
      values.productId = this.state.productId;
      values.cartSubLocation =
        values.cartSubLocation && values.cartSubLocation.cartParts
          ? values.cartSubLocation.cartParts
          : values.cartSubLocation;
      values.locationId =
        values.locationId && values.locationId._id
          ? values.locationId._id
          : values.locationId;
      values.description =
        values.description && values.description.description
          ? values.description.description
          : values.description;
      values.clinicId = values.clinicId && values.clinicId._id;
      if (this.props.formType == "add") {
        values.expirationDate1 = values["expirationDate"]
          ? values["expirationDate"].slice(6, 10) +
          "-" +
          values["expirationDate"].slice(0, 2) +
          "-" +
          values["expirationDate"].slice(3, 5)
          : null;
      }
      if (!values.companyId._id) {
        for (let value of this.state.companies) {
          if (value.label == values.companyId) {
            values.company_id = value.value;
          }
        }
      } else {
        values.company_id = values.companyId._id
          ? values.companyId._id
          : values.companyId;
      }
    }

    if (this.props.type == "Facility Staff") {
      if (values.noofDrawers) {
        values.noofDrawers = parseInt(values.noofDrawers);
      }
      if (values.clinic) {
        let multiSelectclinics =
          this.props.clinics && this.props.clinics.multiSelectclinics
            ? this.props.clinics.multiSelectclinics
            : [];
        if (typeof values.clinic == "string") {
          values.clinic = [JSON.parse(values.clinic)];
        } else if (
          multiSelectclinics &&
          typeof multiSelectclinics == "object" &&
          multiSelectclinics.length > 0 &&
          typeof values.clinic == "object" &&
          values.clinic.length > 0 &&
          multiSelectclinics.length == values.clinic.length
        ) {
          values.clinicType = "all";
        }
      }
    }
    if (values && Object.keys(values).length > 0) {
      this.saveDataToServer(values);
    } else {
      return;
    }
  };

  //send data to server
  saveDataToServer = (formValues, type) => {
    let validationExists;
    this.setState({
      isLoading: true,
    });
    if (type == "AddProduct") {
      formValues.companyId = formValues.company_id;
    }
    if (this.props.formType == "edit") {
      if (this.props.type == "Companies") {

        if (formValues.companyId) {
          delete formValues.companyId;
        }

      }
      if (
        this.props.tabType == "Placed Orders" ||
        this.props.tabType == "Orders List"
      ) {
        if (formValues._id) {
          formValues.orderId = formValues._id;
          delete formValues._id;
        }
        if (formValues.required) {
          formValues.required = parseInt(formValues.required);
        }
      }
      if (this.props.tabType == "Placed Orders") {
        if (formValues.required1) {
          formValues.required = formValues.required1;
        } else if (!formValues.required1) {
          delete formValues.required;
        }
        if (this.state.placedProduct) {
          formValues.productName = this.state.placedProduct;
        }
      }
      if (this.props.type == "Storage Unit Items") {
        formValues.companyId = formValues.company_id;
        formValues.cartName = this.state.cartName;
        formValues.expirationDate1 = formValues.expirationDate.slice(0, 10);
      }
      if (this.props.type == "Product Directory") {
        formValues.categoryId = this.state.catId;
        if (formValues.reorder) {
          formValues = this.formatReorderData(formValues);
        }
        if (formValues.distributorId) {
          formValues.distributorId = this.state.distributor_Id;
        }
        if (formValues.clinicId) {
          formValues.clinicId = this.state.clinic_Id;
        }
        if (formValues.productId) {
          delete formValues.productId;
        }
      }
    }
    if (this.props.type == "Required Inventories" || this.props.type == "Recommended Inventories") {
      if (this.props.type === "Recommended Inventories") {
        formValues.recommendedInventory = {}
        formValues.recommendedInventory = this.state.productTablefields;
      } else {
        formValues.requiredInventory = {}
        formValues.requiredInventory = this.state.productTablefields;
      }
    }
    if (!validationExists) {
      if (formValues) {
        let method, apiUrl;
        if (this.props.formType == "edit") {
          delete formValues.password;
          method = "PUT";
          apiUrl = `${this.props.apiUrl}/${this.state.editRowDataID}`;
          if (this.props.id == "Checks") {
            apiUrl = this.props.apiUrl + `?companyId=${this.props.companyId}`;
          }
          if (this.props.tabType == "Placed Orders") {
            apiUrl = `orders/updateOrder?company_id=${this.props.companyId}`;
          }
          if (this.props.tabType == "Orders List") {
            apiUrl = `orders/updateOrderRequired?companyId=${this.props.companyId
              }`;
          }

          if (
            this.props.type == "Storage Unit Items" &&
            this.props.previousData
          )
            formValues = { ...this.props.previousData, ...formValues };
          if (this.props.id == "Checks") {
            if (
              formValues &&
              formValues["clinicId"] &&
              formValues["clinicId"]["_id"]
            ) {
              formValues["newClinicId"] = formValues["clinicId"]["_id"];
            } else if (formValues && formValues["clinicId"]) {
              formValues["newClinicId"] = formValues["clinicId"];
            }
            if (formValues && formValues["cartName"]) {
              formValues["newCartName"] = formValues["cartName"];
            }
            if (formValues && formValues["oldCartName"]) {
              formValues["oldCartName"] = formValues["oldCartName"];
            }
            formValues["newLocationId"] = formValues["locationId"];
            formValues["newLocationType"] = formValues["locationType"];
            delete formValues["locationId"];
            delete formValues["locationType"];
          }

          formValues["clinicName"] = this.state.clinicName;
          if (this.props.type !== "Product Directory") {
            formValues["productName"] = {
              title: formValues["title1"],
            };
          } else {
            formValues["productName"] = formValues["productName"]
          }
          if (
            formValues &&
            formValues["clinicId"] &&
            formValues["clinicId"]["_id"]
          ) {
            formValues["clinicId"] = formValues["clinicId"]["_id"];
          } else if (formValues && formValues["clinicId"]) {
            formValues["clinicId"] = formValues["clinicId"];
          }
          formValues["expirationDateChanged"] = false;
        } else {
          method = "POST";
          apiUrl = this.props.apiUrl;
          if (this.props.type == "Storage Unit") {
            apiUrl = "/locations/cartCreate";
          } else if (type == "AddProduct") {
            apiUrl = apiCalls.Products;
          } else if (this.props.type == "Companies") {
            apiUrl = apiCalls.CompaniesCreation;
          }

          if (this.props.type == "addOrder") {
            formValues["company_id"] = formValues.companyId;
            apiUrl = `${apiUrl}?company_id=${formValues.companyId}`;
          }
          if (this.props.type == "Storage Unit Items") {
            apiUrl = `${apiUrl}?company_id=${formValues.company_id}`;
          }
        }

        if (this.props.tabType == "Cart Locations") {
          apiUrl = `${apiUrl}?storageType=crashcart`;
        } else if (this.props.tabType == "Supply Cabinets") {
          apiUrl = `${apiUrl}?storageType=storage`;
        } else if (this.props.tabType == "OR Suites") {
          apiUrl = `${apiUrl}?storageType=operatingRoom`;
        }
        return fetchMethodRequest(method, apiUrl, { ...formValues })
          .then(async (response) => {
            if (response && response.respCode) {
              if (this.props.id == "Checks" || this.props.type == "addOrder") {
                showToasterMessage(response.respMessage, "success");
                this.props.closeFormModal("save");
              }
              if (this.props.modalType != "addCategoryFromProducts") {
                await this.props.getDataFromServer(undefined, "load");
              }
              showToasterMessage(response.respMessage, "success");
              if (response.quantityId) {
                this.props.closeFormModal("save", response.quantityId);
              } else if (this.props.modalType == "addProductFromInventory") {
                this.setState({
                  isProductsModalOpen: false,
                });
              }
            } else if (response && response.errorMessage) {
              if (localStorage.getItem("sessionexpired") == "true") {
                this.setState({
                  openSessionModal: true,
                });
                localStorage.setItem("sessionexpired", false);
                return;
              }
              showToasterMessage(response.errorMessage, "error");
            }
            if (type != "AddCategory" && type != "AddProduct") {
              this.props.closeFormModal();
              this.clearFormFields();
              this.props.reset();
            }
            this.setState({
              isLoading: false,
            });
          })
          .catch((err) => {
            return err;
          });
      } else {
        return;
      }
    }
  };

  // Modify Cart Location address
  formatLocationsData = (values) => {
    if (values) {
      values.cartLocation = {};
      if (values.building) {
        values.cartLocation.building = values.building;
      }
      if (values.area) {
        values.cartLocation.area = values.area;
      }
      if (values.floor) {
        values.cartLocation.floor = values.floor;
      }
      if (values.facility) {
        values.cartLocation.facility = values.facility;
      }
      if (values.room) {
        values.cartLocation.room = values.room;
      }
      return values;
    }
  };

  getTitleData = (name, cart) => {
    this.setState({
      isLoading: true,
    });
    return fetchMethodRequest(
      "GET",
      `quantities?companyId=${this.props.companyId}&load=true`
    ).then(async (response) => {
      if (response && response.quantities) {
        for (let option of response.quantities) {
          if (option.title1 == name && option.cartName == cart) {
            this.getRowData(option);
          }
        }
      }
    });
  };

  formatReorderData = (values) => {
    if (values) {
      values.reordering = {};
      if (values.autoMin) {
        values.reordering.autoMin = values.autoMin;
      }
      if (values.autoMax) {
        values.reordering.autoMax = values.autoMax;
      }
      if (values.manualMax) {
        values.reordering.manualMax = values.manualMax;
      }
      return values;
    }
  };

  formatCompaniesData = (values) => {
    if (values) {
      let id = values.state._id
      values.state = values.state.name
      values.stateId = id
      return values;

      // this.props.change("State", values.state.name);
    }
  };

  getProductName = async (name) => {
    this.props.change("productName", name.productName);
  };

  handleCartSublocations = async (event) => {
    if (this.props.type == "Storage Unit") {
      if (event) {
        if (event == "custom") {
          this.setState({
            isCustomSublocation: true,
          });
        } else if (event == "standard") {
          this.setState({
            isCustomSublocation: false,
          });
        }
      }
    }
  };
  openInventoryModal = async (item, type, index) => {
    if (type == "edit" && this.inventoryModalRef) {
      await this.setState({
        // rowData: item,
        openInventoryModal: true,
        inventorytype: type
      });
      this.inventoryModalRef.getRowData(this.state.productTablefields, index);
    } else {
      await this.setState({
        //isCustomCategory: isCustomCategory,
        inventorytype: type,
        openInventoryModal: true,

      });
    }

  }

  handleCategoryNames = async (event) => {
    if (this.props.type == "Product Directory") {
      let openCategoryModal = false,
        isCustomCategory = false;
      if (event) {
        if (event == "selectCategory") {
          await this.getCategoryNames();
          isCustomCategory = true;
        } else if (event == "addNewCategory") {
          openCategoryModal = true;
        }
      }
      this.setState({
        isCustomCategory: isCustomCategory,
        openCategoryModal: openCategoryModal,
      });
    }
  };

  handleReOrdering = async (event) => {
    if (this.props.type == "Product Directory") {
      if (event) {
        let isManualReOrder = false,
          isAutoReOrder = false;
        if (event == "auto") {
          this.props.change("autoMin", 1);
          this.props.change("autoMax", 10);
          await this.getCategoryNames();
          isAutoReOrder = true;
          this.setState({
            reorder: "auto",
          });
        } else if (event == "manual") {
          isManualReOrder = true;
          this.setState({
            reorder: "manual",
          });
        }
        this.setState({
          isAutoReOrder: isAutoReOrder,
          isManualReOrder: isManualReOrder,
        });
      }
    }
  };

  getDistributorNames = async () => {
    return fetchMethodRequest("GET", apiCalls.Distributors).then(
      async (response) => {
        if (response && response.distributors && response.distributors[0]) {
          if (response.distributors[0].displayName) {
            let distributors = [];
            for (let option of response.distributors) {
              distributors.push({
                label: option.displayName,
                value: option._id,
              });
            }
            this.setState({
              distributorsNameOptions: distributors,
            });
          }
        }
      }
    );
  };

  getCategoryNames = async () => {
    return fetchMethodRequest("GET", apiCalls.Categories).then(
      async (response) => {
        if (response && response.categories && response.categories[0]) {
          if (response.categories[0].categoryName) {
            let categories = [];

            for (let option of response.categories) {
              categories.push({
                label: option.categoryName,
                value: option._id,
                categoryType: option.categoryType,
              });
            }
            this.setState({
              categoryNameOptions: categories,
            });
          }
        }
      }
    );
  };

  getCategoryDropDownValues = (value) => {
    return fetchMethodRequest(
      "GET",
      `${apiCalls.Categories}?companyId=${value}`
    ).then(async (response) => {
      if (response && response.categories && response.categories[0]) {
        if (response.categories[0].categoryName) {
          let categories = [];

          for (let option of response.categories) {
            categories.push({
              label: option.categoryName,
              value: option._id,
              categoryType: option.categoryType,
            });
          }
          this.setState({
            categoryDropDown: categories,
          });
        }
      }
    });
  };

  formatClinicAddressData = (values) => {
    if (values) {
      values.address = {};
      if (values.street) {
        values.address.street = values.street;
      }
      if (values.city) {
        values.address.city = values.city;
      }
      if (values.state) {
        values.address.state = values.state;
      }
      if (values.zip) {
        values.address.zip = values.zip;
      }
      return values;
    }
  };

  getUniProductName = async (value) => {
    await this.setState({
      uniProductName: value,
      isCustom: false,
      noDescription: false,
      selectedOption: false,
      showRequired: false,
      isMedicine: false,
      isNonMedicine: false,
    });
  };

  onCheckType = (value) => {
    if (value.value == "weekly") {
      this.setState({
        isWeekly: true,
      });
    } else {
      this.setState({
        isWeekly: false,
      });
    }
  };

  getCountData = () => {
    if (
      this.props.type == "Storage Unit Items" ||
      this.props.type == "DashInventory"
    ) {
      if (
        this.state.inventoryCartId &&
        this.state.productId &&
        this.state.cartSubLocation
      ) {
        let body = {
          locationId: this.state.inventoryCartId,
          productId: this.state.productId,
          cartSubLocation: this.state.cartSubLocation,
        };
        return fetchMethodRequest(
          "POST",
          "quantities/currentInventoryCount",
          body
        ).then(async (response) => {
          if (response && (response.count || response.count == 0)) {
            this.props.change("count2", response.count);
          }
        });
      }
    }
  };

  //changing value of roleType
  handleAutoCompleteData = async (value, name) => {
    if (this.props.type == "Companies") {
      let stateProductName = []
      let stateProductNameValue = []
      for (let option of value.requiredInventory) {
        stateProductName.push({
          label: option.productId.productName,
          value: option.productId,
        });

      }
      await this.setState({
        stateProductName: stateProductName,

      });



    };


    if (this.props.type == "Recall Items") {
      if (name == "lotId") {
        this.props.change("productName", value.productName);
        this.props.change("lotId", value.lotId);
        this.props.change("productId", value.productId._id);
        this.props.change("locationId", value.locationId._id);
      }
    }
    if (
      (this.props.type == "Storage Unit Items" ||
        this.props.type == "DashInventory") &&
      name == "description"
    ) {
      this.props.change("limit", value["required"] ? value["required"] : 0);
      this.props.change("volume", value["dosage"] ? value["dosage"] : "");
      if (value["categoryType"]) {
        if (value["categoryType"] == "Medicine" && !value.batteryExists) {
          this.setState({
            isMedicine: true,
            isNonMedicine: false,
            isBattery: false,
          });
        } else if (
          value["categoryType"] == "Non-Medicine" &&
          !value.batteryExists
        ) {
          this.setState({
            isMedicine: false,
            isNonMedicine: true,
            isBattery: false,
          });
        } else if (value.batteryExists) {
          this.setState({
            isMedicine: false,
            isNonMedicine: false,
            isBattery: true,
          });
        }
      }
      await this.setState({
        productId: value["_id"],
      });
      // this.getCountData()
    }

    if (this.props.type == "Storage Unit Items") {
      if (name == "companyId") {
        this.setState({
          companyId: value._id,
        });
      }
      if (name == "productName") {
        this.props.change("description", "");
        this.getUniProductName(value.title1);
      }
      if (name == "clinicId") {
        this.props.change("cartSubLocation", "");
        this.props.change("locationId", "");
        this.setState({
          clinicName: value.name,
        });
      }
      if (name == "locationId") {
        await this.setState({
          cartName: value.cart,
          inventoryCartId: value._id,
          locationType: value.storageType,
        });
        // this.getCountData();
        this.props.change("cartSubLocation", "");
      }
      if (name == "cartSubLocation") {
        await this.setState({
          cartSubLocation: value.cartParts,
        });
        this.getCountData();
      }
    }
    if (this.props.type == "Product Directory") {
      if (value && value._id) {
        this.getCategoryDropDownValues(value._id);
      }
    }

    if (this.props.type == "Tickets" && name == "assignedTo") {
      if (value && value._id) {
        this.sendTicketStatusOrAssignedToServer(name, value._id);
      }
    }
    if (this.props.type == "Storage Unit") {
      this.getFacilityValues(value);
    }
  };

  checkLocationProductsLimit = (value) => {
    let limit = 0;
    if (
      value &&
      value.products &&
      value.products.length > 0 &&
      this.state.productId
    ) {
      for (let singleProduct of value.products) {
        if (
          singleProduct &&
          singleProduct.productId &&
          singleProduct.productId == this.state.productId
        ) {
          limit = singleProduct.limit;
        }
      }
    }
    this.props.change("limit", limit);
  };

  sendTicketStatusOrAssignedToServer = (name, value) => {
    this.setState({
      isLoading: true,
    });
    let userBody = {
      [name]: value,
    };
    let selectedTicketId =
      this.state.rowData && this.state.rowData._id
        ? this.state.rowData._id
        : null;
    return fetchMethodRequest(
      "PUT",
      `${apiCalls.Tickets}/${selectedTicketId}`,
      userBody
    )
      .then(async (response) => {
        if (response && response.respCode && response.respCode === 205) {
          // display message
          showToasterMessage(response.respMessage, "success");
          await this.props.getDataFromServer(undefined, "load");
        } else if (response && response.errorMessage) {
          // display message
          showToasterMessage(response.errorMessage, "error");
        }
        if (value == "Closed") {
          this.closeFormModal();
        }
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        return err;
      });
  };

  // Close delete modal
  closeDeleteModal = () => {
    this.setState({
      openDeleteModal: false,
    });
  };

  customVolumeInventory = () => {
    this.setState({
      isCustomVolumeDes: !this.state.isCustomVolumeDes,
    });
  };

  // set upload image in state
  getFileName = (file) => {
    this.setState({
      locationImage: file,
    });
  };

  getCompanyValues = async () => {
    return fetchMethodRequest("GET", apiCalls.ActiveCompanies).then(
      async (response) => {
        if (response && response.companies && response.companies[0]) {
          let companies = [];
          for (let option of response.companies) {
            companies.push({
              label: option.name,
              value: option._id,
            });
          }
          await this.setState({
            companies: companies,
          });
        }
      }
    );
  };

  getFacilityDropDownValues = async () => {
    return fetchMethodRequest("GET", apiCalls.Clinics).then(
      async (response) => {
        if (response && response.clinics && response.clinics[0]) {
          let clinics = [];
          for (let option of response.clinics) {
            clinics.push({
              label: option.name,
              value: option._id,
            });
          }
          await this.setState({
            clinics: clinics,
          });
        }
      }
    );
  };

  getFacilityValues = async (item) => {
    let companyId = item && item._id ? item._id : this.props.companyId;
    if (companyId) {
      return fetchMethodRequest("GET", `clinics?companyId=${companyId}`).then(
        async (response) => {
          if (response && response.clinics && response.clinics[0]) {
            let clinics = [];
            for (let option of response.clinics) {
              clinics.push({
                label: option.name,
                value: option.name,
                id: option._id,
              });
            }
            await this.setState({
              facilities: clinics,
            });
          }
        }
      );
    }
  };

  getCategoryProductType = (selectedProduct) => {
    if (selectedProduct && selectedProduct.productCategoryType) {
      let isTypeMedicine = this.state.isTypeMedicine;
      if (selectedProduct.productCategoryType == "Medicine") {
        isTypeMedicine = true;
      } else if (selectedProduct.productCategoryType == "Non-Medicine") {
        isTypeMedicine = false;
      }
      this.setState({
        isTypeMedicine: isTypeMedicine,
      });
    }
  };
  closeDeleteModal = async () => {
    await this.setState({
      openDeleteModal: false,
    });
  };

  seleteConfirmationFun = async (id, type, formType) => {
    await this.setState({
      openDeleteModal: true,
      deleteObj: { id: id, type: type, formType: formType }
    })
  }

  deleteSelectedRow = (str) => {
    const { id, type, formType } = this.state.deleteObj

    if (str === 'delete') {
      this.props.type === "Recommended Inventories" ? this.deleteproductAction(id, type, formType) : this.deleteOrderProduct(id)

    }
    this.closeDeleteModal()
  }

  openAddProductsModal = async () => {
    await this.setState({
      isProductsModalOpen: true,
    });
  };

  closeAddProductsModal = () => {
    this.setState({
      isProductsModalOpen: false,
    });
  };

  handleinventoryTable = async (values, type, index) => {
    let arr = [];
    let productTablefields = values;
    if (type === 'add') {
      arr = this.state.allOriginalFields ? [...this.state.allOriginalFields] : [];
      arr.push(values[values.length - 1]);
      productTablefields = arr.filter(obj => {
        return this.state.categoryType !== '' && this.state.categoryType === obj.categoryType;
      });
    }

    // Handling 'edit' operation
    if (type === 'edit') {
      productTablefields[index] = values[index];
      let i = -1;
      arr = this.state.allOriginalFields.map((obj, i2) => {
        if (this.state.categoryType !== '' && this.state.categoryType === obj.categoryType) {
          i++;
          if (i === index) {
            return values[index];
          }
          return values[i];
        } else if (this.state.categoryType === '' && index === i2) {
          return values[index];
        }
        return obj;
      });

      productTablefields = arr.filter(obj => {
        return this.state.categoryType !== '' && this.state.categoryType === obj.categoryType;
      });
    }

    // Update state with modified 'productTablefields' and 'allOriginalFields'
    this.setState({
      productTablefields: productTablefields,
      allOriginalFields: arr
    });
  };

  getLocationDropdownData = async (value) => {
    this.setState({
      clinicName: value.label,
    });
    return fetchMethodRequest("GET", apiCalls.CartLocations).then(
      async (response) => {
        let Locations = [];
        let i = value.label ? value.label : value;
        if (response && response.locations) {
          for (let option of response.locations) {
            if (
              option &&
              option.clinics &&
              option.clinics.name &&
              option.clinics.name == i
            ) {
              Locations.push({
                label: option.cart,
                value: option._id,
              });
            }
          }
        }
        this.setState({
          clinicsLocations: Locations,
        });
      }
    );
  };

  updateClinicName = (selectedClinic) => {
    if (selectedClinic && selectedClinic.value) {
      this.setState({
        clinic_Id: selectedClinic.value,
      });
    }
  };

  updateDistributorName = (selectedDistributor) => {
    if (selectedDistributor && selectedDistributor.value) {
      this.setState({
        distributor_Id: selectedDistributor.value,
      });
    }
  };

  updateRoleType = (selectedRole) => {
    let isRolePharmasist = false;
    if (selectedRole && selectedRole.value == "pharmasist") {
      isRolePharmasist = true;
    }
    this.setState({
      isRolePharmasist: isRolePharmasist,
    });
  };

  getCategoryTypes = (selectedCategory) => {
    if (selectedCategory.value) {
      this.setState({
        catId: selectedCategory.value,
      });
    }
    if (selectedCategory && selectedCategory.categoryType == "Medicine") {
      this.setState({
        showCategoryType: true,
        notMedicine: false,
      });
    } else if (selectedCategory.categoryType == "Non-Medicine") {
      this.setState({
        showCategoryType: false,
        notMedicine: true,
      });
    } else if (
      name == "category" &&
      selectedCategory &&
      selectedCategory.value
    ) {
      this.setState({
        selectedCategory: selectedCategory.value,
      });
    }
  };

  getLocationSubParts = (selectedLocation) => {

    let subLocations = [];
    if (selectedLocation && selectedLocation.cartParts) {
      for (let subLocation of selectedLocation.cartParts) {
        subLocations.push({
          label: subLocation,
          value: subLocation,
        });
      }
      this.setState({
        subLocations: subLocations,
        isSubLoction: true,
      });
    }
  };

  getIsLoading = (value) => {
    this.props.change("comment", "");
    this.setState({
      isLoading: value,
    });
  };

  getTicketStatus = (selectedStatus) => {
    if (
      this.props.type == "Tickets" &&
      selectedStatus &&
      selectedStatus.value
    ) {
      this.sendTicketStatusOrAssignedToServer("status", selectedStatus.value);
    }
  };

  onFilterChange = async (e, type) => {
    let productTablefields = this.state.allOriginalFields, newArray = []

    if (type === "dropDown" && e.value) {
      if (productTablefields !== undefined && productTablefields && productTablefields.length > 0 && e && e.value !== null) {
        newArray = productTablefields.filter(function (item) {
          if (item.categoryType) {
            let pN = item.categoryType
            return pN === e.value
          }
        });
        await this.setState({
          productTablefields: newArray,
          categoryType: e.value
        })
      } else {
        await this.setState({
          categoryType: e.value
        })
      }
    } else if (e && e.target && e.target.value && e.target.value.length > 0) {
      if (productTablefields !== undefined && productTablefields && productTablefields.length > 0 && e && e.target && e.target.value && e.target.value.length > 0) {
        newArray = productTablefields.filter(function (item) {
          if (item.productName) {
            let pN = item.productName.toLowerCase()
            return pN.includes(e.target.value)
          }
        });
        await this.setState({
          productTablefields: newArray
        })

      }
    } else {
      newArray = this.state.allOriginalFields
      await this.setState({
        productTablefields: newArray,
        // categoryType: ""
      })

    }
  }
  getClinicsForFacilityStaff = () => {
    if (
      this.props.routeInfo &&
      this.props.routeInfo.params &&
      this.props.routeInfo.params.company
    ) {
      let companyId = this.props.routeInfo.params.company;
      return fetchMethodRequest(
        "GET",
        `${apiCalls.Clinics}?companyId=${companyId}`
      ).then(async (response) => {
        let multiSelectclinics = [],
          singleSelectclinics = [];
        if (response && response.clinics && response.clinics.length > 0) {
          for (let clinics of response.clinics) {
            multiSelectclinics.push({
              label: clinics.name,
              value: {
                clinicId: clinics._id,
                name: clinics.name,
              },
            });
            singleSelectclinics.push({
              label: clinics.name,
              value: JSON.stringify({
                clinicId: clinics._id,
                name: clinics.name,
              }),
            });
          }
        }
        await this.setState({
          clinics: {
            multiSelectclinics: multiSelectclinics,
            singleSelectclinics: singleSelectclinics,
          },
        });
      });
    }
  };
  deleteproductAction = (id, type, formType) => {

    // if (type == "edit" || formType == "edit") {
    //   this.state.rowData.requiredInventory.splice(id, 1)
    //   let rowData = this.state.rowData
    //   this.setState({
    //     rowData: rowData
    //   })
    // } else {

    // this.state.productTablefields.splice(id, 1)
    // let productTablefields = this.state.productTablefields
    // this.setState({
    //   productTablefields: productTablefields,
    //   allOriginalFields: allOriginalFields
    // })
    // }

    let productTablefields = this.state.productTablefields;
    let data = productTablefields[id];
    productTablefields.splice(id, 1);
    let allOriginalFields = this.state.allOriginalFields.filter((obj) => JSON.stringify(obj) != JSON.stringify(data));
    this.setState({
      productTablefields: productTablefields,
      allOriginalFields: allOriginalFields
    });
  }

  render() {
    const { handleSubmit } = this.props;
    const { days } = this.state;
    //    const requiredInventory = this.state.rowData.requiredInventory
    const modalClass = classNames({
      "modal-dialog--colored": this.state.colored,
      "modal-dialog--header": this.state.header,
    });

    const checkTypes = [
      { label: "Daily", value: "daily" },
      { label: "Weekly", value: "weekly" },
      { label: "Monthly", value: "monthly" },
    ];
    return (
      <div style={{ display: "flex" }}>
        <Modal
          isOpen={this.props.openFormModal}
          className={`modal-dialog-centered modal-dialog--primary  ${modalClass}`}
          style={this.props.type == "Required Inventories" || this.props.type === "Recommended Inventories" ? {
            maxWidth: 1500
          } : {}}
        >
          <ModalHeader className="modal__header">
            <button
              className="lnr lnr-cross modal__close-btn"
              type="button"
              onClick={this.closeFormModal}
            />
            <p className="bold-text  modal__title">
              {this.props.formType && this.props.formType == "edit"
                ? this.props.type == "Tickets"
                  ? "Ticket Details"
                  : "Edit "
                : "Add "}
              {this.props.type && this.props.type != "Tickets"
                ? this.state.formModalType
                : ""}
            </p>
          </ModalHeader>
          <ModalBody className="modal__body mb-0">
            <Card className="m-0 p-0">
              <CardBody className="m-0 p-0">
                <Loader loader={this.state.isLoading} />
                <form
                  key={this.props.type}
                  onSubmit={handleSubmit(this.submit)}
                  autoComplete={"off"}
                >
                  {this.props.type != "Tickets" ? (
                    <div className="col-sm-12">
                      <ButtonToolbar className=" modal__footer mb-2 footerData">
                        {/* Cancel */}
                        <Button
                          className="mb-2"
                          color="primary"
                          type="button"
                          onClick={this.closeFormModal}
                        >
                          Cancel
                        </Button>

                        {/* Save */}
                        <Button color="primary" outline className="mb-2" type="submit">
                          {this.props.formType && this.props.formType == "add"
                            ? "Save"
                            : "Update"}
                        </Button>
                        {(this.props.type == "Storage Unit Items" ||
                          this.props.type == "DashInventory") &&
                          this.props.formType != "edit" ? (
                          <Button
                            color="primary"
                            type="button"
                            onClick={this.openAddProductsModal}
                          >
                            Add Product
                          </Button>
                        ) : null}
                      </ButtonToolbar>
                    </div>
                  ) : null}

                  <div className="row form">
                    {this.state.formFields
                      ? this.state.formFields.map((item, i) => {
                        return (
                          <div
                            key={this.props.type + i}
                            className={
                              item.type == "editor"
                                ? "col-sm-12"
                                : item.name == "status" ||
                                  (item.type == "autoComplete" &&
                                    item.name == "assignedTo") ||
                                  item.name == "closebutton"
                                  ? "col-sm-4"
                                  : "col-sm-6"
                            }
                          >
                            <div className="form__form-group">
                              <label className="form__form-group-label">
                                {item.label}
                              </label>
                              {item.type == "dropDown" &&
                                item.isMultiSelect ? (
                                <div className="form__form-group-field">
                                  <Field
                                    key={i}
                                    name={item.name}
                                    component={MultiSelectDropDown}
                                    id={item.id}
                                    validate={[required]}
                                    filterElement={this.props.type == "Companies" ? this.state.stateProductName : item.options}
                                  // filterElement={item.options}
                                  // selectAll={true}

                                  />
                                </div>
                              ) : item.type == "dropDown" ? (
                                <div className="form__form-group-field">
                                  <Field
                                    key={i}
                                    name={item.name}
                                    component={Select}
                                    options={
                                      item.name == "cartSubLocation"
                                        ? this.props.formType == "add"
                                          ? this.state.subLocations
                                          : this.state.subLocationCarts
                                        : this.props.type ==
                                          "Product Directory" &&
                                          item.name == "categoryId"
                                          ? this.state.categoryDropDown
                                          : this.state.isCompanySelect
                                            ? this.state.roleType
                                            : item.name == "clinicName"
                                              ? this.state.facilities
                                              : item.options

                                    }
                                    validate={item.required
                                      ? [required]
                                      : null
                                    }
                                    defaultValue={
                                      this.props.formType &&
                                        this.props.formType == "add"
                                        ? item.defaultValue
                                        : null
                                    }
                                    placeholder={item.placeholder}
                                    isDisable={
                                      this.props.type == "Storage Unit" &&
                                        this.props.formType == "edit" &&
                                        item.name == "companyName"
                                        ? true
                                        : this.props.type ==
                                          "Storage Unit Items" &&
                                          item.name == "cartSubLocation" &&
                                          this.props.formType == "add" &&
                                          !this.state.isSubLoction
                                          ? true
                                          : item.disable
                                            ? item.disable
                                            : false
                                    }
                                    getCategoryProductType={
                                      this.getCategoryProductType
                                    }
                                    updateClinicName={this.updateClinicName}
                                    updateDistributorName={
                                      this.updateDistributorName
                                    }
                                    updateRoleType={this.updateRoleType}
                                    getCategoryTypes={this.getCategoryTypes}
                                    getFacilityValues={this.getFacilityValues}
                                    getProductName={this.getProductName}
                                  />
                                </div>
                              ) : item.name == "closebutton" ? (
                                <div key={i} className="form__form-group">
                                  <div className="form__form-group-field">
                                    <Button
                                      className="mt-1"
                                      color="danger"
                                      size="sm"
                                      disabled={this.state.rowData.status == "Closed" ? true : false}
                                      onClick={() =>
                                        this.sendTicketStatusOrAssignedToServer(
                                          "status",
                                          "Closed"
                                        )
                                      }
                                    >
                                      Close Ticket
                                    </Button>
                                  </div>
                                </div>
                              ) : item.type == "profile" ? (
                                <div>
                                  <Field
                                    key={i}
                                    onRef={(ref) =>
                                      (this.profileUploadRef = ref)
                                    }
                                    name={item.name ? item.name : null}
                                    component={RenderFileInputField}
                                    label="Upload photo"
                                    type="profile"
                                    id={"photo"}
                                    acceptType="image/*"
                                    url={apiCalls.LocationImagePath}
                                    getFileName={this.getFileName}
                                  />
                                  <div
                                    className="col-md-2"
                                    style={{ padding: "20px" }}
                                  >
                                    <div style={{ justifyContent: "center" }}>
                                      {this.state.locationImage ? (
                                        <img
                                          src={`${config.imgUrl}location/${this.state.locationImage
                                            }`}
                                          className="detailsImgStyle"
                                        />
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              ) : item.type == "date" ? (
                                <div key={i} className="form__form-group">
                                  <div className="form__form-group-field">
                                    <Field
                                      key={i}
                                      name={item.name ? item.name : null}
                                      placeholder={
                                        item.placeholder
                                          ? item.placeholder
                                          : null
                                      }
                                      id={item.id ? item.id : null}
                                      component={DatePicker}
                                      minDate={
                                        this.state.minDate
                                          ? this.state.minDate
                                          : null
                                      }
                                      maxDate={
                                        this.state.maxDate
                                          ? this.state.maxDate
                                          : null
                                      }
                                      screen={this.props.type}
                                    />
                                    <div className="iconstyle form__form-group-icon">
                                      <CalendarBlankIcon />
                                    </div>
                                  </div>
                                  <div style={{ color: "#dc3545" }}>
                                    {item.message ? item.message : null}
                                  </div>
                                </div>
                              ) : item.type == "empty" ? (
                                <div> </div>
                              ) : item.type == "radio" ? (
                                <div className="mb-0">
                                  <div className="row">
                                    {item.options.map((option, ind) => {
                                      return (
                                        <div
                                          key={ind}
                                          className="form__form-group col-sm-6 mb-0"
                                        >
                                          <div className="form__form-group-field">
                                            <Field
                                              key={this.props.type + i + ind}
                                              name={
                                                item.name ? item.name : null
                                              }
                                              component={RadioButton}
                                              label={option.label}
                                              radioValue={option.value}
                                              disabled={
                                                item.name == "companyId"
                                                  ? true
                                                  : false
                                              }
                                              getCustomLocationList={
                                                this.props.type ==
                                                  "Storage Unit"
                                                  ? this
                                                    .handleCartSublocations
                                                  : null
                                              }
                                              getCustomCategoryNames={
                                                this.handleCategoryNames
                                              }
                                              defaultChecked={
                                                option.defaultChecked
                                                  ? option.defaultChecked
                                                  : null
                                              }
                                            />
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                  <div style={{ color: "#dc3545" }}>
                                    {item.message ? item.message : null}
                                  </div>
                                </div>
                              ) : item.type == "autoComplete" ? (
                                <Field
                                  key={i}
                                  companyId={this.props.companyId}
                                  name={item.name ? item.name : null}
                                  component={AutoComplete}
                                  // disabled={this.props.type == "Companies" && this.props.formType == "edit" ? true : false}
                                  call={item.call}
                                  type={item.type}
                                  filterField={item.filterField}
                                  filterValue={item.filterValue}
                                  filterType={item.isNotEq}
                                  multiple={item.isMultiple}
                                  placeholder={
                                    item.placeholder ? item.placeholder : ""
                                  }
                                  searchApi={item.searchApi}
                                  searchField={item.searchField}
                                  handleAutoCompleteData={(event) =>
                                    this.handleAutoCompleteData(
                                      event,
                                      item.name
                                    )
                                  }
                                  screen={this.props.type}
                                  allow={this.state.allowDuplicates}
                                  filterFieldType={
                                    item.filterFieldType
                                      ? item.filterFieldType
                                      : null
                                  }
                                  // disabled={
                                  //   item.disabled
                                  //     ? item.disabled
                                  //     : this.props.formType == "edit" &&
                                  //       item.name == "companyId" || item.name == "state"
                                  //       ? true
                                  //       : false
                                  // }
                                  disabled={

                                    this.props.formType == "edit" &&
                                      item.name == "state"
                                      ? true
                                      : false
                                  }
                                  formType={this.props.formType}
                                  validate={item.required
                                    ? [required]
                                    : null
                                  }
                                />
                              ) : item.type == "textarea" ? (
                                <Field
                                  key={i}
                                  name={item.name ? item.name : null}
                                  component={DefaultTextArea}
                                  type={item.type ? item.type : null}
                                  placeholder={
                                    item.placeholder ? item.placeholder : null
                                  }
                                  validate={
                                    !this.state.isTaskSet &&
                                      item.name == "otherTask"
                                      ? [required]
                                      : null
                                  }
                                />
                              ) : item.type == "color" ? (
                                <Field
                                  key={i}
                                  name={item.name ? item.name : null}
                                  component={renderColorPicker}
                                  color={
                                    this.props.formType == "edit"
                                      ? this.state.eventColor
                                      : ""
                                  }
                                  id={"color"}
                                />
                              ) : item.type == "buttonGroup" ? (
                                item.options && item.options.length > 0 ? (
                                  <ButtonToolbar className="mt-0">
                                    <ButtonGroup size="sm">
                                      {item.options.map((button) => {
                                        return (
                                          <Button
                                            style={{
                                              paddingTop: 3,
                                              paddingBottom: 3,
                                            }}
                                            color="primary"
                                            outline
                                            size="sm"
                                            active={
                                              this.state[
                                              `is${button.label}ReOrder`
                                              ]
                                            }
                                            onClick={() =>
                                              this.handleReOrdering(
                                                button.value
                                              )
                                            }
                                          >
                                            {button.label}
                                          </Button>
                                        );
                                      })}
                                    </ButtonGroup>
                                  </ButtonToolbar>
                                ) : null
                              ) : (
                                <Field
                                  key={i}
                                  name={item.name ? item.name : null}
                                  component={DefaultInput}
                                  isDisable={
                                    (this.props.formType &&
                                      this.props.formType == "edit") ||
                                      (this.props.type == "addOrder" &&
                                        this.props.formType == "Order") ||
                                      (this.props.type == "Recall Items" &&
                                        item.name == "productName")
                                      ? item.disable
                                      : false
                                  }
                                  type={item.type ? item.type : "text"}
                                  id={item.id ? item.id : null}
                                  placeholder={
                                    item.placeholder ? item.placeholder : null
                                  }
                                  validate={
                                    !this.state.isTaskSet &&
                                      item.name == "screenName"
                                      ? [required]
                                      : item.required ? [required] : null
                                  }
                                  maxLength={
                                    item.name == "phone" ||
                                      item.name == "contactPersonPhone"
                                      ? 15
                                      : null
                                  }
                                  normalize={
                                    item.name == "noofDrawers"
                                      ? greaterThan0
                                      : item.name == "phone" ||
                                        item.name == "contactPersonPhone"
                                        ? normalizePhone
                                        : item.name == "trialPeriod"
                                          ? normalizeCharacters
                                          : this.props.tabType ==
                                            "Placed Orders" &&
                                            item.name == "required1"
                                            ? lessThanEqual("required")
                                            : item.name == "city" ||
                                              item.name == "state" ||
                                              (this.props.type ==
                                                "Facility Staff" &&
                                                item.name == "lastName")
                                              ? normalizestate
                                              : item.name == "zip"
                                                ? normalizenumber
                                                : null
                                  }
                                />
                              )}
                            </div>
                          </div>
                        );
                      })
                      : null}
                    {this.props.type == "Companies" &&
                      this.props.formType == "edit" ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Status
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"status"}
                              component={Select}
                              placeholder={"Status"}
                              options={statusOptions}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {this.props.type == "Product Directory" &&
                      this.state.isAutoReOrder ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Auto Re-order at X minimum
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"autoMin"}
                              component={DefaultInput}
                              placeholder={"Auto Re-order at X minimum"}
                              type="number"
                              id="number"
                              normalize={lessThan("autoMax")}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {/* {this.props.type == "Companies" &&
                      this.props.formType == "add" ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Product Name
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"requiredInventory"}
                              component={MultiSelectDropDown}
                              placeholder={"Select"}
                              filterElement={this.props.type == "Companies" ? this.state.stateProductName : item.options}
                            // filterElement={item.options}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null} */}
                    {this.props.type == "Product Directory" &&
                      this.state.isAutoReOrder ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Auto Re-order to X maximum
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"autoMax"}
                              component={DefaultInput}
                              placeholder={"Auto Re-order to X maximum"}
                              type="number"
                              id="number"
                              normalize={greaterThan("autoMin")}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.props.type == "Product Directory" &&
                      this.state.isManualReOrder ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Manually Reorder to Maximum
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"manualMax"}
                              component={DefaultInput}
                              placeholder={"Enter"}
                              type="number"
                              id="number"
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.props.type == "Product Directory" &&
                      this.state.showCategoryType ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Inventory Type
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"inventoryType"}
                              component={Select}
                              options={this.props.inventoryTypes}
                              placeholder={"Select"}
                              getCategoryTypes={this.getCategoryTypes}
                              validate={required}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {this.props.type == "Product Directory" &&
                      this.state.showCategoryType ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Volume
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"dosage"}
                              component={DefaultInput}
                              placeholder={"Volume"}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.props.type == "Product Directory" &&
                      this.state.notMedicine ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Battery Exists
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"batteryExists"}
                              component={RadioButton}
                              label={"Yes"}
                              radioValue={true}
                            />
                            <Field
                              name={"batteryExists"}
                              component={RadioButton}
                              label={"No"}
                              radioValue={false}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {this.state.isRolePharmasist &&
                      this.props.type == "Employees" ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Companies
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"pharmasistCompanies"}
                              component={MultiSelectDropDown}
                              filterElement={this.props.companiesList}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {this.props.type == "Facility Staff" ? (
                      <div className="col-6">
                        {this.state.isRoleStaff ? (
                          <div className="form__form-group">
                            <label className="form__form-group-label">
                              Clinics
                            </label>
                            <div className="form__form-group-field">
                              <Field
                                name={"clinic"}
                                component={Select}
                                id={"clinic"}
                                options={
                                  this.state.clinics &&
                                    this.state.clinics.singleSelectclinics
                                    ? this.state.clinics.singleSelectclinics
                                    : []
                                }
                              />
                            </div>
                          </div>
                        )
                          : (
                            <div className="form__form-group">
                              <label className="form__form-group-label">
                                Clinics
                              </label>
                              <div className="form__form-group-field">
                                <Field
                                  name={"clinic"}
                                  component={MultiSelectDropDown}
                                  id={"clinic"}
                                  // validate={[required]}
                                  filterElement={
                                    this.state.clinics &&
                                      this.state.clinics.multiSelectclinics
                                      ? this.state.clinics.multiSelectclinics
                                      : null
                                  }
                                />
                              </div>
                            </div>
                          )}
                      </div>
                    ) : null}

                    {this.props.type == "Tickets" ? (
                      <div className="col-sm-12">
                        <TicketCommentsInfo
                          onRef={(ref) => (this.ticketCommentsRef = ref)}
                          selectedRowDataID={this.state.editRowDataID}
                          getIsLoading={this.getIsLoading}
                          isCommentsUpdated={() =>
                            this.setState({ isCommentsUpdated: true })
                          }
                        />
                      </div>
                    ) : null}
                    {this.props.type == "Storage Unit Items" &&
                      this.props.id != "Checks" ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Products
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"productName"}
                              component={AutoComplete}
                              id={"productName"}
                              placeholder="Product Directory"
                              searchApi={`products/productList?companyId=${this.state.companyId
                                ? this.state.companyId
                                : this.props.companyId
                                }`}
                              screen={this.props.type}
                              searchField={"title1"}
                              call={"GET"}
                              companyId={this.props.companyId}
                              handleAutoCompleteData={
                                this.handleAutoCompleteData
                              }
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.props.type == "Storage Unit Items" &&
                      this.props.id == "Checks" ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Product Name
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"title1"}
                              component={DefaultInput}
                              id={"products"}
                              isDisable={true}
                              placeholder="Product Name"
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.props.type == "Storage Unit Items" &&
                      this.state.uniProductName &&
                      this.props.id != "Checks" ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Description
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"description"}
                              component={AutoComplete}
                              id={"description"}
                              placeholder="Description"
                              searchApi={apiCalls.Descriptions}
                              productName={this.state.uniProductName}
                              screen={this.props.type}
                              searchField={"description"}
                              apiUrl={"products"}
                              call={"POST"}
                              companyId={this.state.companyId}
                              handleAutoCompleteData={
                                this.handleAutoCompleteData
                              }
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.props.type == "Storage Unit Items" &&
                      this.props.id == "Checks" ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Desription
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"description"}
                              component={DefaultInput}
                              id={"products"}
                              isDisable={true}
                              placeholder="Description"
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.props.type == "Storage Unit Items" &&
                      ((this.state.isMedicine || this.state.isNonMedicine) &&
                        !this.state.isBattery) ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Volume
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"volume"}
                              component={DefaultInput}
                              id={"volume"}
                              placeholder="Volume"
                              isDisable={true}
                            />
                            {this.props.id != "Checks" ? (
                              <FontAwesomeIcon
                                icon="plus"
                                size="lg"
                                className="categoryAddIcon"
                                data-toggle="tool-tip"
                                title="Add"
                                style={{
                                  backgroundColor: config.templateColor,
                                  marginTop: 5,
                                }}
                                onClick={this.customVolumeInventory}
                              />
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.state.isCustom &&
                      this.props.type == "Storage Unit Items" ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Custom Description
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"customDescription"}
                              component={DefaultInput}
                              placeholder={"Custom Description"}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.state.isCustomVolume &&
                      this.props.type == "addOrder" ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Custom Volume
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"customVolume"}
                              component={DefaultInput}
                              placeholder={"Custom Volume"}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {/* {(((this.state.isMedicine || this.state.isNonMedicine) && !this.state.isBattery) ||
                                            (this.state.showRequired)) && (this.props.type == 'Storage Unit Items') && (this.props.id != 'Checks') ?
                                            <div className='col-6'>
                                                <div className="form__form-group" >
                                                    <label className="form__form-group-label">Required Inventory</label>
                                                    <div className="form__form-group-field">
                                                        <Field name={'limit'}
                                                            component={DefaultInput}
                                                            placeholder={'Required Inventory'}
                                                            validate={required}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            : null}
                                        {(((this.state.isMedicine || this.state.isNonMedicine) && !this.state.isBattery) ||
                                            (this.state.showRequired)) && (this.props.type == 'Storage Unit Items') && (this.props.id == 'Checks') ?
                                            <div className='col-6'>
                                                <div className="form__form-group" >
                                                    <label className="form__form-group-label">Required Inventory</label>
                                                    <div className="form__form-group-field">
                                                        <Field name={'limit'}
                                                            component={DefaultInput}
                                                            placeholder={'Required Inventory'}
                                                            isDisable={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            : null} */}

                    {this.props.type == "Storage Unit Items" &&
                      this.state.isCustomVolumeDes ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Custom Volume
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"customVolumeDes"}
                              component={DefaultInput}
                              id={"volume"}
                              placeholder="Volume"
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.props.type == "Storage Unit Items" &&
                      this.props.id != "Checks" ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            LotId
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"lotId"}
                              validate={required}
                              component={DefaultInput}
                              id={"lotId"}
                              placeholder="Lot Id"
                              isDisable={
                                this.props.formType == "edit" ? true : false
                              }
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.props.type == "Storage Unit Items" &&
                      this.props.id != "Checks" ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Expiration Date
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"expirationDate"}
                              component={DatePicker}
                              placeholder={"Expiration Date"}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {this.props.type == "Storage Unit Items" ? (
                      //  && this.props.id != 'Checks'
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Facility
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"clinicId"}
                              component={AutoComplete}
                              id={"clinic"}
                              placeholder="Facilities"
                              searchApi={`clinics?companyId=${this.state.companyId
                                ? this.state.companyId
                                : this.props.companyId
                                }`}
                              screen={this.props.type}
                              searchField={"name"}
                              call={"GET"}
                              companyId={this.props.companyId}
                              handleAutoCompleteData={
                                this.handleAutoCompleteData
                              }
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {/* {this.props.type == 'Storage Unit Items' && this.props.id == 'Checks' ?

                                            <div className='col-6'>
                                                <div className="form__form-group" >
                                                    <label className="form__form-group-label">Facility</label>
                                                    <div className="form__form-group-field">
                                                        <Field name={'clinicId'}
                                                            component={DefaultInput}
                                                            isDisable={true}
                                                            id={'clinic'}
                                                            type='Storage Unit Items'
                                                            placeholder='Facility'
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                            : null} */}

                    {this.props.type == "Storage Unit Items" &&
                      this.state.clinicName ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Storage Unit
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"locationId"}
                              component={AutoComplete}
                              id={"location"}
                              placeholder="Search Storage Unit"
                              searchApi={`locations?companyId=${this.state.companyId
                                ? this.state.companyId
                                : this.props.companyId
                                }`}
                              screen={this.props.type}
                              searchField={"cart"}
                              call={"GET"}
                              companyId={this.props.companyId}
                              facilityName={this.state.clinicName}
                              disabled={this.state.clinicName ? false : true}
                              handleAutoCompleteData={
                                this.handleAutoCompleteData
                              }
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {/* {this.props.type == 'Storage Unit Items' && this.props.id == 'Checks' ?
                                            <div className='col-6'>
                                                <div className="form__form-group" >
                                                    <label className="form__form-group-label">Storage Unit</label>
                                                    <div className="form__form-group-field">
                                                        <Field name={'locationId'}
                                                            component={DefaultInput}
                                                            id={'Cart Locations'}
                                                            placeholder='Search Storage Unit'
                                                            validate={required}
                                                            searchApi={apiCalls.CartLocations}
                                                            clinicName={this.state.clinicName}
                                                            screen={this.props.type}
                                                            searchField={'cart'}
                                                            apiUrl={'locations'}
                                                            call={'GET'}
                                                            isDisable={true}
                                                            handleAutoCompleteData={this.handleAutoCompleteData}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            : null} */}
                    {this.props.type == "Storage Unit Items" &&
                      this.state.cartName ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Cart SubLocations
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"cartSubLocation"}
                              component={AutoComplete}
                              id={"subLocations"}
                              type="Storage Unit Items"
                              placeholder="Search Cart Sub Location"
                              validate={required}
                              searchApi={`locations?companyId=${this.state.companyId
                                ? this.state.companyId
                                : this.props.companyId
                                }`}
                              cartName={this.state.cartName}
                              screen={this.props.type}
                              searchField={"cartParts"}
                              apiUrl={"locations"}
                              call={"GET"}
                              handleAutoCompleteData={
                                this.handleAutoCompleteData
                              }
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.props.type == "Storage Unit Items" ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Current Inventory
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"count2"}
                              component={DefaultInput}
                              placeholder="Current Inventory"
                              isDisable={true}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.props.type == "Storage Unit Items" &&
                      this.props.formType != "edit" ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Inventory Being Added
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"addedInventory"}
                              component={DefaultInput}
                              placeholder="Inventory"
                            // validate={required}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.props.type == "Storage Unit Items" &&
                      this.state.isBattery ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Frequency
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"checkType"}
                              component={Select}
                              id={"clinic"}
                              options={checkTypes}
                              onCheckType={this.onCheckType}
                              placeholder="Check Type"
                              validate={required}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.props.type == "Storage Unit Items" &&
                      this.state.isWeekly ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Battery Check Day
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"batteryCheckDay"}
                              component={Select}
                              id={"clinic"}
                              options={days}
                              placeholder="Check Type"
                              validate={required}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.props.type == "Required Inventories" || this.props.type === "Recommended Inventories" ?
                      <div style={{ width: "100%" }}>
                        {/* <div>
                          <div className='row ml-2'>
                            <div className='serachAlignment' >
                              <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                  <i className="pi pi-search" ></i>
                                </span>
                                <InputText
                                  type="search"
                                  name='globalSearch'
                                  onChange={(e) => this.onFilterChange(e)}
                                  placeholder={'Product Name'}
                                  size="30" />
                              </div>
                            </div>
                          </div>
                        </div> */}
                        <div>
                          <div className='row ml-2'>
                            <div className='serachAlignment' >
                              <b style={{ marginTop: "0.4rem", marginRight: "0.4rem" }}>Category Type</b>
                              <div className="p-inputgroup">
                                <Dropdown
                                  style={{ minWidth: "100px", height: "100%", lineHeight: 1.25, textAlign: 'center' }}
                                  appendTo={document.body}
                                  name={"categoryType"}
                                  value={this.state.categoryType}
                                  options={[{ label: " ", value: null }, { label: "Medicine", value: "Medicine" }, { label: "Non-Medicine", value: "Non-Medicine" }]}
                                  onChange={(e) => this.onFilterChange(e, "dropDown")}
                                />
                              </div>
                              <div>
                                {/* <FontAwesomeIcon
                                  icon={faTimes}
                                  size="lg"
                                  className="categoryAddIcon mt-1"
                                  data-toggle="tool-tip"
                                  title="Click to remove Filter"
                                  style={{
                                    color: "red"
                                  }}
                                  onClick={() => this.onFilterChange()}
                                /> */}

                                {/* <Button color="danger"
                                  size="sm"
                                  style={{ backgroundColor: "#ef4444", color: "white", marginTop: "0.2rem", marginLeft: "0.3rem", border: "none", outline: "none", padding: "0.4rem 1rem", borderRadius: "5px", fontWeight: "bold" }}
                                  className="px-3"
                                  onClick={() => { this.onFilterChange() }}>
                                  Clear
                                </Button> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={{ width: "100%" }}>
                          <div className="col-12 modalTbody pl-0 pr-0" >
                            <Table>
                              <thead>
                                <tr>
                                  <th>S.No</th>
                                  <th>Product Name</th>
                                  <th>Description</th>
                                  <th style={{ textAlign: 'center' }}>Dosage</th>
                                  <th style={{ textAlign: 'center' }}>Required</th>
                                  <th>Inventory Type</th>
                                  <th>Location Type</th>
                                  <th>Category Name</th>
                                  <th>Category Type</th>
                                  <th style={{ textAlign: "center" }}>Actions</th>
                                </tr>
                              </thead>
                              <tbody style={{ maxHeight: 80 }}>
                                {this.state.productTablefields && this.state.productTablefields.length > 0 ?
                                  this.state.productTablefields.map((item, i) => {
                                    return <tr>
                                      <td>{item.productName ? i + 1 : ''}</td>
                                      <td className="word_align">{item.productName || '-'}</td>
                                      <td className="word_align">{item.description || '-'}</td>
                                      <td className="text-center">{item.dosage || '-'}</td>
                                      <td className="text-center">{item.required || '-'}</td>
                                      <td>{item.inventoryType || '-'}</td>
                                      <td className="textCapitalize">{item.locationType || '-'}</td>
                                      <td>{item.categoryName || '-'}</td>
                                      <td>{item.categoryType || '-'}</td>

                                      {this.props.formType == "add" ?
                                        <td>
                                          {item.productName ?
                                            <div>
                                              <FontAwesomeIcon
                                                className='genderIconAlignment'
                                                color='white'
                                                icon='trash-alt'
                                                data-toggle="tool-tip"
                                                title="Delete"
                                                style={{ color: '#bf1725', width: '13', marginLeft: 10, cursor: 'pointer' }}
                                                onClick={() => { this.seleteConfirmationFun(i, this.props.type) }} />
                                              <FontAwesomeIcon
                                                className='genderIconAlignment'
                                                color='white'
                                                icon='edit'
                                                data-toggle="tool-tip"
                                                title="Edit"
                                                style={{ color: '#285a83', width: '13', marginLeft: 10, cursor: 'pointer' }}
                                                onClick={() => this.openInventoryModal(item, "edit", i)} /></div>
                                            : ""}

                                        </td> :
                                        <td>
                                          <div className="d-flex justify-content-between">
                                            <FontAwesomeIcon
                                              className='genderIconAlignment'
                                              color='white'
                                              icon='trash-alt'
                                              data-toggle="tool-tip"
                                              title="Delete"
                                              style={{ color: '#bf1725', width: '13', marginLeft: 10, cursor: 'pointer' }}
                                              onClick={() => { this.seleteConfirmationFun(i, this.props.type) }} />
                                            <FontAwesomeIcon
                                              className='genderIconAlignment'
                                              color='white'
                                              icon='edit'
                                              data-toggle="tool-tip"
                                              title="Edit"
                                              style={{ color: '#285a83', width: '13', marginLeft: 10, cursor: 'pointer' }}
                                              onClick={() => this.openInventoryModal(item, "edit", i)} /></div>
                                        </td>}
                                    </tr>


                                  }) : ""}
                              </tbody>

                            </Table>
                          </div>
                          <div className="col-12">
                            <Button color="primary"
                              size="sm"
                              className="p-1"
                              onClick={() => { this.openInventoryModal(this.state.productTablefields, "add") }}>
                              <FontAwesomeIcon
                                icon='plus'
                                className='pl-1' size='lg'
                                data-toggle="tool-tip" title="Add Product"
                                onClick={() => { this.openInventoryModal(this.state.productTablefields, "add") }}
                              />
                            </Button>
                          </div>
                        </div>
                      </div>
                      : null}

                  </div>
                </form>
              </CardBody>
            </Card>
          </ModalBody>
        </Modal>
        <DeleteRowModal
          openDeleteModal={this.state.openDeleteModal}
          closeDeleteModal={this.closeDeleteModal}
          deleteSelectedRow={this.deleteSelectedRow}
          message={
            this.props.type === "Facilities"
              ? configMessages.FacilitiesDeleteMsg
              : configMessages.DeleteMsg
          }
          type={this.props.type}
        />

        {/* Add Category Modal in Products */}
        {
          this.props.type == "Product Directory" ? (
            <AddCategoryModal
              isOpenCategoryModal={this.state.openCategoryModal}
              closeCategoryModal={this.closeCategoryModal}
            />
          ) : null
        }
        {
          this.props.type == "Required Inventories" || this.props.type == "Recommended Inventories" ? (
            <AddInventoryModal
              onRef={(ref) => (this.inventoryModalRef = ref)}
              handleinventoryTable={this.handleinventoryTable}
              selectedRowDataID={this.state.rowData}
              isOpenInventoryModal={this.state.openInventoryModal}
              closeInventoryModal={this.closeInventoryModal}
              clearFormFields={this.clearFormFields}
              type={this.state.inventorytype}
              formtype={this.props.type == "Required Inventories" ? "Required Inventory" : "Recommended Inventory"}
              productTablefields={this.state.productTablefields ? this.state.productTablefields : []}

            />
          ) : null
        }
        {
          this.props.type == "Storage Unit Items" && this.state.companyId ? (
            <AddProductModal
              onRef={(ref) => (this.addProductModalRef = ref)}
              companyId={this.state.companyId}
              isProductsModalOpen={this.state.isProductsModalOpen}
              closeAddProductsModal={this.closeAddProductsModal}
              saveDataToServer={this.saveDataToServer}
              categoryDropDownValues={this.categoryDropDownValues}
              type={this.props.type}
              inventoryTypes={this.props.inventoryTypes}
            />
          ) : null
        }

        {/* Session Expiration Modal */}
        <SessionModal openSessionModal={this.state.openSessionModal} />
      </div >
    );
  }
}

FormModal = reduxForm({
  form: "Common Form", // a unique identifier for this form
  validate,
  enableReinitialize: true,
})(FormModal);

// You have to connect() to any reducers that you wish to connect to yourself
FormModal = connect(
  (state) => ({
    initialValues: state.commonData.data, // pull initial values from account reducer
  }),

  { load: loadAccount } // bind account loading action creator
)(FormModal);

export default FormModal;
