import React from 'react';
import { Card, CardBody, Col, Container, Row, Table } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Circle from 'mdi-react/CircleIcon'
import CountDisplay from './components/CountDisplay';
import fetch from '../../config/service';
import { Redirect } from 'react-router-dom';

import Loader from '../App/Loader';

class Dashboard extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            dashboardData: {},
            isLoading: false,
            expirationsList: [],
            data: []
        };
    }

    componentDidMount = async () => {
        let user = JSON.parse(localStorage.getItem('loginCredentials'));
        if (user) {
            this.setState({
                loginData: user,
                role: user.role,
                path: window.location.pathname.slice(window.location.pathname.lastIndexOf('/') + 1, window.location.pathname.length)

            })
        }
        this.getCountData();
        await this.getExpirationsList();
        this.getExpirationsList1();
    }

    // Get dashboard counts
    getCountData() {
        this.setState({ isLoading: true });
        fetch('GET', 'dashboard/counts')
            .then(async (response) => {
                if (response && response.details) {
                    this.setState({
                        dashboardData: response.details
                    });
                }
                this.setState({ isLoading: false });
            }).catch((err) => {
                return err;
            });
    }

    // Get Last 30 days expirations list
    getExpirationsList1 = async () => {
        this.setState({ isLoading: true });
        fetch('GET', 'companies/clinicsCount')
            .then(async (response) => {
                let arr = []
                let names = []
                let names2 = []
                for (let value of response.clinics) {
                    for (let value1 of response.carts) {
                        if (value._id == value1._id) {
                            arr.push({
                                'company': value._id, 'clinics': value.count, 'carts': value1.count,
                                Id: value.companyId
                            })
                        }
                    }
                }
                for (let value of arr) {
                    names.push(value.company)
                }

                for (let val of response.carts) {
                    if (names.indexOf(val._id) == -1) {
                        arr.push({ 'company': val._id, 'clinics': 0, 'carts': val.count, Id: val.companyId })
                    }
                }
                for (let value of arr) {
                    names2.push(value.company)
                }
                for (let val of this.state.expirationsList) {
                    if (names2.indexOf(val.name) == -1) {
                        arr.push({ 'company': val.name, 'clinics': 0, 'carts': 0 })
                    }
                }
                for (let value of this.state.expirationsList) {
                    for (let value1 of arr) {
                        if (value.name == value1.company) {
                            value1.status = value.status
                            value1.Id = value._id
                        }
                    }
                }
                await this.setState({
                    data: arr,
                    isLoading: false,
                });
            }).catch((err) => {
                return err;
            });

    }
    getExpirationsList = () => {
        this.setState({ isLoading: true });
        fetch('GET', 'companies')
            .then(async (response) => {
                if (response && response.companies) {
                    this.setState({
                        expirationsList: response.companies
                    });
                }
                this.setState({
                    isLoading: false,
                });
            }).catch((err) => {
                return err;
            });
    }

    Redirect = (name, _id) => {
        this.setState({
            clinicRedirect: true,
            clinicCompanyName: name,
            clinicCompanyId: _id
        })
    }

    RedirectCart = (name, _id) => {
        this.setState({
            cartRedirect: true,
            cartCompanyName: name,
            cartCompanyId: _id
        })
    }

    render() {
        return (
            <Container className="dashboard" >
                <Loader loader={this.state.isLoading} />
                <Row>
                    <Col md={12}>
                        <h3 className="page-title" style={{ marginBottom: 20 }}>Home</h3>
                    </Col>
                </Row>

                {/* Counts */}
                <CountDisplay
                    dashboardData={this.state.dashboardData} />

                {/* Expirations Table */}
                <Card >
                    <CardBody >
                        <span style={{ marginBottom: 20, fontSize: 14, fontWeight: 500, color: '#00324d' }}>COMPANIES</span>
                        <span style={{ fontSize: 14, fontWeight: 500, color: '#00324d', float: 'right' }}>
                            <span className='pr-4'><Circle color='#006699' size={15} />&nbsp;Active</span>
                            <span className='pr-4'><Circle color='#6c757d' size={15} />&nbsp;Pending</span>
                            <span className='pr-4'><Circle color='#ff0000' size={15} />&nbsp;Blocked</span>
                            <span className='pr-4'><Circle color='#e6e600' size={15} />&nbsp;Suspended</span>
                        </span>

                        <Row style={{ marginTop: 20 }}>
                            {this.state.data.map((project, index) => {
                                console.log(project);
                                return (
                                    <Col md={12} xl={3} lg={6} xs={12} key={index}>
                                        <Card >
                                            <CardBody style={project.status == 'Active' ? { background: '#006699' } : project.status == 'Blocked' ?
                                                { background: '#ff0000' } : project.status == 'suspend' ?
                                                    { background: '#e6e600' } : { background: 'grey' }}
                                                key={project.Id}
                                                className="dashboard__card-widget">
                                                <Row className="dashboard__total1">
                                                    <p className="dashboard__total-stat">
                                                        <span style={{ color: 'white' }}>{project.company}</span>
                                                    </p>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} style={{ color: 'white', marginTop: 30 }}>
                                                        <Row style={{ cursor: 'pointer' }}
                                                            onClick={() => this.Redirect(project.company, project.Id)}>
                                                            Facilities: {project.clinics}
                                                        </Row>
                                                        <Row style={{ cursor: 'pointer' }}
                                                            onClick={() => this.RedirectCart(project.company, project.Id)}>
                                                            Carts: {project.carts}
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                )
                            })}
                        </Row>
                    </CardBody >
                </Card >
                {
                    this.state.clinicRedirect ?
                        <Redirect to={`/facility/${this.state.clinicCompanyId}/${this.state.clinicCompanyName}/${this.state.path}`} />
                        : null
                }
                {
                    this.state.cartRedirect ?
                        <Redirect to={`/carts/${this.state.cartCompanyId}/${this.state.cartCompanyName}/${this.state.path}`} />
                        : null
                }
            </Container >
        );
    }
}

export default withTranslation('common')(Dashboard);