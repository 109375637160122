
const config = {
  // Test
  // apiUrl: 'https://ccapi.dosystemsinc.com/api/',
  // imgUrl: 'https://ccapi.dosystemsinc.com/',

  // Live
  apiUrl: 'https://api.inmedtory.com/api/',
  imgUrl: 'https://api.inmedtory.com/',

  serverErrMessage: 'Could Not reach server',

  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },

  //regex
  borderValidation: false,
  messages: true,
  emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  passwordRegex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$/,
  aadharcardNumberRegex: /^([0-9]){12}$/,
  pancardNumberRegex: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
  phoneNumberRegex: /^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
  numberRegex: /^([0-9]){1,}$/,
  // server response codes
  updateResCode: 205,
  deleteResCode: 206,

  datePlaceholder: '--/--/----',
  dateFormat: 'MM/DD/YYYY',
  dateTabularFormat: 'MMM DD YYYY',
  dateDisplayModalFormat: 'DD MMM YYYY',
  dateDBFormat: 'MM-DD-YYYY',
  dateDayMonthFormat: 'DD-MM-YYYY',
  dateYearMonthFormat: 'YYYY-MM-DD',
  dayYearDateFormat: 'YYYY-MM-DD',
  basicDateFromat: 'MM/DD/YYYY HH:mm A',
  descDateFromat: 'MMM DD YYYY HH:mm A',

  timeFormat: 'HH:mm',
  syncTimeFormat: 'hh:mm A, MM-DD-YYYY',
  lastModifiedDateFormat: 'MM/DD/YYYY HH:mm',
  dateTimeFormat: 'MM/DD/YYYY hh:mm',
  fullDateFormat: 'YYYY-MM-DD HH:mm:ss',
  dbDateFormat: 'YYYY-MM-DD[T]HH:mm:ss.SSS',
  dbOnlyDateFormat: 'YYYY-MM-DD[T]00:00:00Z',
  ESTTimezone: "America/New_York",

  noView: 'noView',
  edit: 'edit',
  view: 'view',

  templateColor: '#3475aa',
  greenColorCode: '#7fd07f',
  redRuleColor: '#f55b5b',
  amberColorCode: '#FFBF00',
  savedIndicatorColor: 'orange',
  publishedIndicatorColor: 'green',
  holidayIndicatorColor: '#8c34eb'
};
export default config;
