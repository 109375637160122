import React from 'react';
import FormModal from '../../Form/FormModal';
import apiCalls from '../../../config/apiCalls';
import fetchMethodRequest from '../../../config/service';

// config file
export default class AddCalendarEventModal1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            useTypes: [],
            categoryTypes: []
        };
    }


    componentDidMount = () => {
        if (this.props.orderClinicName) {
            return fetchMethodRequest('GET', `clinics?companyId=${this.props.companyId}`)
                .then(async (response) => {
                    if (response && response.clinics) {
                        if (response.clinics) {
                            for (let clinic of response.clinics) {
                                if (clinic.name == this.props.orderClinicName) {
                                    this.setState({
                                        orderClinicId: clinic._id
                                    })
                                }
                            }

                        }
                    }
                });
        }

    }


    getFormFields = async () => {
        return ([
            {
                required: true, value: '', type: 'autoComplete', name: 'companyId',
                label: 'Company Name', id: 'company', placeholder: 'Search company',
                searchApi: apiCalls.ActiveCompanies, searchField: 'name', disabled: true
            },
            {
                required: true, value: '', type: 'DefaultInput', name: 'productName', label: 'Product Name', disable: true
            },
            {
                value: '', type: 'text', name: 'description', label: 'Description',
                id: 'required', placeholder: 'Description', disable: true
            },
            {
                value: '', type: 'text', name: 'concentration', label: 'Volume',
                id: 'required', placeholder: 'Volume', disable: true
            },
            {
                value: '', type: 'autoComplete', searchApi: `distributors?companyId=${this.props.companyId}`, searchField: 'email',
                name: 'distributorEmail', label: 'POC Email', id: 'email', placeholder: 'Search POC Email'
            },
            {
                required: true, value: '', type: 'text', name: 'required', label: 'Need To Order',
                id: 'required', placeholder: 'Need To Order'
            },


        ]);
    }

    render() {
        return (
            <FormModal
                openFormModal={this.props.openOrderModal}
                closeFormModal={this.props.closeOrderModal}
                type={'addOrder'}
                modalType={'takeOrder'}
                formType={'Order'}
                formFields={this.getFormFields}
                orderProductName={this.props.product}
                orderFrom={this.props.orderFrom}
                orderCart={this.props.orderCart}
                orderClinic={this.props.orderClinic}
                orderClinicId={this.state.orderClinicId}
                orderDescription={this.props.orderDescription}
                orderConcentration={this.props.orderConcentration}
                orderNeeded={this.props.orderNeeded}
                apiUrl='orders'
                companyName={this.props.companyName}
                companyId={this.props.companyId}
            />
        );
    }
}