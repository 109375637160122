import React from 'react';
import TableRender from '../../../DataTable/TableRender';
import config from '../../../../../config/config';
import RolePermissions from '../../../DataTable/Permissions';

export default class RolesTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    getTableFields = () => {
        let data = [
            { textAlign: 'center', width: 190, field: 'staffRole', header: 'Role', filter: true, sortable: true, },
            { textAlign: 'center', width: 130, field: 'created', type: 'date', header: 'Created On', filter: true, sortable: true, placeholder: config.dateDayMonthFormat },
            { textAlign: 'center', width: 110, field: 'createdBy', header: 'Created By', filter: true, sortable: true },
            { textAlign: 'center', width: 100, field: 'updated', type: 'date', header: 'Updated On', filter: true, sortable: true, placeholder: config.dateDayMonthFormat },
            { textAlign: 'center', width: 130, field: 'updatedBy', header: 'Updated By', filter: true, sortable: true },
            { textAlign: 'left', width: 90, field: 'Actions', header: 'Actions', filter: false, sortable: false },
        ];
        let screenPermissions = RolePermissions.screenPermissions('Facility Staff Roles');
        if (screenPermissions && screenPermissions != 'edit') {
            data.pop();
        }
        return data;
    };

    getFormFields = () => {
        return ([
            { required: true, value: '', type: 'text', name: 'staffRole', label: 'Role', id: 'role', placeholder: 'Role' },
        ]);
    }

    render() {
        return (
            <div>
                <TableRender type='Facility Staff Roles'
                    tableFields={this.getTableFields}
                    formFields={this.getFormFields}
                    globalSearch={'Role'}
                    {...this.props}
                />
            </div>
        );
    }
}