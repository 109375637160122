import React from 'react';
import TableRender from '../../../DataTable/TableRender';
import RolePermissions from "../../../DataTable/Permissions";

export default class WeeklyChecks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    componentDidMount = () => {
        if (this.props.match.path == '/checks/:cart') {
            window.history.pushState(null, null, window.location.pathname);
            window.addEventListener('popstate', this.onBackButtonEvent);
        }
    }
    onBackButtonEvent = () => {
        if (this.props.match.path == '/checks/:cart') {
            if (window.location.pathname != 'inmedtory/home') {
                window.location.pathname = 'inmedtory/home'
            }
        } else {
            return window
        }
    }

    getTableFields = () => {
        let statusTypes = [
            { label: 'All', value: null },
            { label: 'checked', value: 'checked' },
            { label: 'Pending', value: 'Pending' }
        ];

        let data = [
            { textAlign: 'left', width: 135, field: 'checkType', header: 'Frequency', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 135, field: 'name', header: 'Check Type', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 135, field: 'clinicName', header: 'Facility', filter: true, sortable: true, placeholder: 'Search' },

            { textAlign: 'left', width: 135, field: 'cart', header: 'Storage Unit', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 135, field: 'address', header: 'Storage Unit Address', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 135, field: 'created', header: 'Date', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 105, field: 'status', header: 'Status ', filterElement: statusTypes, filter: true, sortable: false },

            { textAlign: 'center', width: 90, field: 'Actions', header: 'Actions', filter: false, sortable: false },
        ];
        let screenPermissions = RolePermissions.screenPermissions('Storage Unit Checks');
        if (screenPermissions && screenPermissions != 'edit') {
            data.pop();
        }
        return data;
    };


    getFormFields = async () => {
        let options = [{ label: 'Yes', value: true }, { label: 'No', value: false }]
        return ([
            {
                required: true, value: '', type: 'radio', name: 'isChecked', label: 'Storage Unit Checked',
                id: 'checked', options: options
            },
        ]);
    }
    render() {
        return (
            <div>
                <TableRender type='Storage Unit Checks'
                    globalSearch='Check Type/Facility/Storage Unit/Status'
                    tableFields={this.getTableFields}
                    formFields={this.getFormFields}
                    {...this.props}
                />
            </div>
        );
    }
}