import React from 'react';

export default class DefaultInput extends React.Component {
    constructor(props) {
        super(props);

    }

    onChange = (event) => {
        if (event && event.target) {
            const { onChange, name } = this.props.input;
            if (this.props.type == 'number') {
                if (event.target.value) {
                    let num = event.target.value;
                    if (name == 'noofDrawers' && num > 10) {
                        num = 10;
                    } else if (num <= 0) {
                        num = 0;
                    }
                    onChange(num);
                }
            } else {
                if (this.props.handleComments) {
                    this.props.handleComments(event.target.value)
                }
                onChange(event.target.value);
            }
        }
    }

    // render() {
    //     let disable;
    //     console.log("HI", this.props)

    //     const { input, placeholder, type, isDisable, screen, meta: { touched, error } } = this.props;
    //     return (
    //         <div className="form__form-group-input-wrap">
    //             <div className="form__form-group-field">
    //                 <input
    //                     style={isDisable ? { background: 'lightgray', border: "none" } :
    //                         { background: 'white' }}
    //                     {...input}
    //                     disabled={disable}
    //                     placeholder={placeholder}
    //                     type={type}
    //                     // value={this.props.value}
    //                     onChange={(e) => this.onChange(e)}
    //                     maxLength={this.props.maxLength ? this.props.maxLength : null} />
    //             </div>
    //             <div style={{ height: 18 }}>
    //                 {touched && error && <span className="form__form-group-error">{error}</span>}
    //             </div>
    //         </div>
    //     );
    // }
    render() {
        const {
            input,
            placeholder,
            type,
            isDisable,
            meta: { touched, error },
            validate,
            screen,
        } = this.props;
        let disable;
        if (screen == "Required Inventory") {
            let name = input.name;
            if (
                name == "description" ||
                name == "categoryType" ||
                name == "categoryName" ||
                name == "dosage" ||
                name == "inventoryType"
            ) {
                disable = true;
            }
        } else {
            disable = isDisable;
        }
        return (
            <div className="form__form-group-input-wrap">
                <div className="form__form-group-field">
                    <input
                        style={
                            isDisable
                                ? { background: "lightgray", border: "none" }
                                : { background: "white" }
                        }
                        {...input}
                        disabled={disable}
                        placeholder={placeholder}
                        type={type}
                        onChange={(e) => this.onChange(e)}
                        maxLength={this.props.maxLength ? this.props.maxLength : null}
                    />
                </div>
                {touched && error && (
                    <span className="form__form-group-error">{error}</span>
                )}
            </div>
        );
    }
}