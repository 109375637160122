import React, { PureComponent } from 'react';
import {
    Card, CardBody, Col, Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import AllInventoryTable from './AllInventory';
import InventoryTransfersTable from './InventoryTransfers'
import ReordersTable from './Reorders'
export default class Reports extends PureComponent {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    constructor() {
        super();
        this.state = {
            activetab: '1',
        };
    }
    toggle = (tab) => {
        const activetab = this.state.activetab;
        if (activetab !== tab) {
            this.setState({ activetab: tab })
        }
    }
    render() {
        const { t } = this.props;
        const activeTab = this.state.activetab;
        return (
            <Col md={12}>
                <Card>
                    <CardBody>
                        <div className='tabs'>
                            <div className='tabs__wrap'>
                                <Nav tabs>
                                    <NavItem >
                                        <NavLink className={classnames({ active: activeTab === '1' })}
                                            onClick={() => {
                                                this.toggle('1');
                                            }}
                                        >All Inventory </NavLink>

                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={classnames({ active: activeTab === '2' })}
                                            onClick={() => {
                                                this.toggle('2');
                                            }}
                                        >Inventory Transfers</NavLink>

                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={classnames({ active: activeTab === '3' })}
                                            onClick={() => {
                                                this.toggle('3');
                                            }}
                                        >Reorders</NavLink>

                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId='1'><AllInventoryTable type='All Inventory' /></TabPane>
                                    <TabPane tabId='2'><InventoryTransfersTable type='Inventory Transfers' /></TabPane>
                                    <TabPane tabId='3'><ReordersTable type='Re-Orders' /></TabPane>
                                </TabContent>
                            </div>

                        </div>
                    </CardBody>
                </Card>
            </Col>
        )
    }
}