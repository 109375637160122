import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';

import fetch from "../../../../config/service";

class SidebarContent extends Component {
  static propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      menuList: {}
    };
  }

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  componentDidMount = async () => {
    await this.getMenuListFromServer();

    let rolePermissions = localStorage.getItem("rolePermissions");
    rolePermissions = JSON.parse(rolePermissions);

    if (rolePermissions) {
      let neWmenuList = [];
      let keys = Object.keys(rolePermissions);
      if (this.state.menuList) {
        this.state.menuList.forEach((item) => {
          keys.forEach(element => {
            if (item.title == element) {
              if (rolePermissions[element] != "noView") {
                neWmenuList.push(item);
              }
            }
          });
        });
      }
      this.setState({ menuList: neWmenuList });
    }
  };

  getMenuListFromServer = async () => {
    let filterCriteria = {}, url;
    filterCriteria.sortfield = 'sequenceNo';
    filterCriteria.direction = 'asc';
    url = `menus?filter=${JSON.stringify(filterCriteria)}`;
    return fetch('GET', url)
      .then(async (res) => {
        if (res) {
          await this.setState({ menuList: res.menus })
        }
      })
  }

  render() {
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          {this.state.menuList &&
            this.state.menuList.length &&
            this.state.menuList.length > 0
            ? this.state.menuList.map((item, index) => {
              if (item.subMenus && item.subMenus.length > 0) {
                return (
                  <SidebarCategory
                    key={index}
                    title={item.displayTitle == 'Settings' ? 'Settings Menu' : item.displayTitle}
                    icon={item.icon}>
                    {item.subMenus.map((sitem, sindex) => {
                      return (
                        <SidebarLink key={sindex} title={sitem.displayTitle} icon={sitem.icon} onClick={this.hideSidebar} route={sitem.route} />
                      )
                    })}
                  </SidebarCategory>
                )
              }
              else {
                return (
                  <SidebarLink
                    key={index}
                    icon={item.icon}
                    title={item.displayTitle}
                    route={item.route}
                    onClick={this.hideSidebar}
                  />
                );
              }
            })
            : null}
        </ul>
      </div>
    );
  }
}

export default SidebarContent;

