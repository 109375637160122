import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import DistributersTable from './components/DistributersTable'

const DistributersTableList = (props) => (

    <Container>
        <DistributersTable {...props} />
    </Container>
);

DistributersTableList.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(DistributersTableList);

