import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CartChecks from './components/CartChecks'


const BasicTables = (props) => (
    <Container>
        <CartChecks {...props} />
    </Container>
);

BasicTables.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(BasicTables);

