import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SidebarLink = ({
  title, icon, newLink, route, onClick, type
}) => (
    <NavLink
      to={route}
      onClick={onClick}
      activeClassName="sidebar__link-active"
      activeStyle={{ backgroundColor: '#4c89bb' }}
    >
      <li className="sidebar__link">
        {icon ?
          title == 'Battery Checks' || title == 'Storage Unit Checks' || title == 'Recall Items' ||
            title == 'Payments' ||
            title == 'Bills' ||
            title == 'Cart Payments' ?
            <FontAwesomeIcon icon={icon} className='sidebar__link-fontawsome-icon' />
            :
            <span className={`sidebar__link-icon lnr lnr-${icon}`} />
          : ''}
        <p className={type == 'submenu' ? 'sidebar__link-title pl-2' : "sidebar__link-title"}>
          {title}
          {newLink ? <Badge className="sidebar__link-badge"><span>New</span></Badge> : ''}
        </p>
      </li>
    </NavLink>
  );

SidebarLink.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  newLink: PropTypes.bool,
  route: PropTypes.string,
  onClick: PropTypes.func,
};

SidebarLink.defaultProps = {
  icon: '',
  newLink: false,
  route: '/',
  onClick: () => { },
};

export default SidebarLink;
