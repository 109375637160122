// import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button, Col, Row, UncontrolledTooltip, Card, CardBody, CardHeader } from 'reactstrap';
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom';

import Icon from 'mdi-react/ArrowLeftIcon';

import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Paginator } from 'primereact/paginator';
import { CSVLink } from "react-csv";

//Modals
import FormModal from '../../Form/FormModal';
import DeleteRowModal from '../Modals/DeleteRowModal';
import ViewModal from '../Modals/viewModal';
import BulkUploadModal from '../Modals/BulkUploadModal';
import RolesModal from '../Modals/RolesModal';
import ConfirmationModal from '../Modals/ConfirmationModal';
import RejectModal from '../Modals/RejectModal';
import QrModal from '../Modals/QrModal';
import OrderModal from '../Modals/OrderModal';

// fetch method for server call
import fetch from '../../../config/service';
import config from '../../../config/config';
import configImages from '../../../config/configImages';
import configMessages from '../../../config/configMessages';
import apiCall from '../../../config/apiCalls';

// Permissions
import RolePermissions from '../DataTable/Permissions';

// Toaster message
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';

// Date Formate
import dateFormats from '../../UI/FormatDate/formatDate';
import PaymentTypes from '../List/Payment/components/PaymentTypes';
import TabTypes from './TabTypes';
import SessionModal from '../Modals/SessionExpirationModal';
export default class DataTables extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            deleteRowDataId: {},
            tableFields: [{}],
            first: 0,
            rows: 20,
            InventoryRedirect: false,
            sortified: false,
            direction: 'asc',
            //filterCriteria: { limit: 20, page: 1, criteria: [], sortfield: 'created', direction: 'desc' },
            filterCriteria1: { limit: 20, page: 1, criteria: [], sortfield: 'created', direction: 'desc' },
            selectedRows: '',
            selectedRowsId: '',
            totalRecords: [],
            exportData: [],
            isOpenFormModal: false,
            openBulkUploadModal: false,
            openViewModal: false,
            isLoading: false,
            isOpenRolesModal: false,
            isRequirdInventoryModal: false,
            isOpenConfirmationModal: false,
            selectedScreenPermission: '',
            showLeaveOptions: false,
            loginRole: 'Admin',
            CheckListRedirect: false,
            startDate: '',
            endDate: '',
            apiUrl: '',
            totalRecordsLength: 0,
            tabType: this.props.tabType,
            doctorStatus: '',
            openQrModal: false,
            openOrderModal: false,
            openSessionModal: false
        };
    }

    componentDidMount = async () => {
        // console.log(this.props)
        if (this.props.match) {
            if (this.props.match.params) {
                if (this.props.match.params.name) {
                    this.setState({
                        companyName: this.props.match.params.name
                    })
                }
                if (this.props.match.params.path) {
                    this.setState({
                        path: this.props.match.params.path
                    })
                }
                if (this.props.match.params.company) {
                    this.setState({
                        companyId: this.props.match.params.company
                    })
                }
            }
        }
        this.getRolePermissions();
        this.props.onRef(this);
        if (this.props.getTableFields) {
            let tableFields = await this.props.getTableFields();
            // tableFields.unshift({ 'selectionMode': 'multiple' })
            this.setState({
                tableFields: tableFields
            })
        }
        this.setState({
            ActivePath: window.location.pathname
        })
        if (this.props.tabType == 'Cart Locations') {
            this.getDataFromServer(this.state.filterCriteria, this.props.tabType);
        } else {
            this.getDataFromServer(this.state.filterCriteria);
        }
    }

    componentWillUnmount() {
        this.props.onRef(null);
    }

    //get url based on type
    getApiUrl = () => {
        let apiUrl = '';
        if (this.props.type === 'Employees') {
            apiUrl = apiCall.Employees;
        } else if (this.props.type === 'Distributors') {
            apiUrl = apiCall.Distributors;
        } else if (this.props.type === 'Companies') {
            apiUrl = apiCall.Companies;
        } else if (this.props.type === 'Storage Unit') {
            apiUrl = apiCall.Locations;
        } else if (this.props.type === 'Categories') {
            apiUrl = apiCall.Categories;
        } else if (this.props.type === 'Product Directory') {
            apiUrl = apiCall.Products;
        } else if (this.props.type === 'Facility Staff Roles') {
            apiUrl = apiCall.StaffRoles;
        } else if (this.props.type === 'Facilities') {
            apiUrl = apiCall.Clinics;
        } else if (this.props.type === 'Employee Roles') {
            apiUrl = apiCall.AdminRoles;
        } else if (this.props.type === 'Facility Staff') {
            apiUrl = apiCall.ClinicStaff;
        } else if (this.props.type == 'Payment') {
            apiUrl = this.state.tabType == 'Payment' ? apiCall.Payments : apiCall.History;
        } else if (this.props.name === 'Active Companies') {
            apiUrl = apiCall.ActiveCompanies;
        } else if (this.props.type === 'Storage Unit Items') {
            apiUrl = apiCall.Quantities;
        } else if (this.props.type === 'Expiration Dates') {
            apiUrl = apiCall.ExpiredItems;
        } else if (this.props.type === 'Tickets') {
            apiUrl = apiCall.Tickets;
        } else if (this.props.type === 'Missing Items') {
            apiUrl = apiCall.MissingItems;
        } else if (this.props.type === 'Recall Items') {
            apiUrl = apiCall.RecallItems;
        } else if (this.props.type === 'Ticket Subjects') {
            apiUrl = apiCall.TicketSubjects;
        } else if (this.props.type === 'Track Items') {
            apiUrl = apiCall.Track;
        } else if (this.props.type === 'Storage Unit Checks') {
            apiUrl = apiCall.WeeklyChecks;
        } else if (this.props.type === 'Battery Checks') {
            apiUrl = apiCall.BatteryChecks;
        } else if (this.props.type === 'Track Items') {
            apiUrl = apiCall.Track;
        } else if (this.props.type === 'Activities') {
            apiUrl = apiCall.Activity;
        } else if (this.props.type == 'Orders') {
            apiUrl = this.props.apiUrl;
        } else if (this.props.type == 'Required Inventories') {
            apiUrl = apiCall.States;
        } else if (this.props.type == 'Recommended Inventories') {
            apiUrl = apiCall.RecommendedInventories;
        } else if (this.props.type == 'Auto Recall') {
            apiUrl = apiCall.AutoRecalls;
        } else if (this.props.type == 'Cart Payments') {
            apiUrl = apiCall.CartPayments;
        } else if (this.props.type == 'Bills') {
            apiUrl = apiCall.Bills;
        }

        this.setState({
            apiUrl: apiUrl
        })
        return apiUrl;
    }

    //get table list data from server with filters if any
    getDataFromServer = async (filterCriteria, type) => {
        //console.log(filterCriteria, type)
        let productCount = 0, orderCount = 0, apiUrl, missingCount = 0;
        let url = this.getApiUrl();
        if (url) {
            let totalRecords = [...this.state.totalRecords];
            if (type != 'sort' && type != 'pagination') {
                totalRecords = [];
            }
            this.setState({
                isLoading: true,
                selectedRows: '',
                totalRecords
            });
            if (this.props.type === "Storage Unit Items") {
                let obj = { "key": "lotId", "value": "", "type": "regexOr" }

                if (filterCriteria && filterCriteria.criteria.length > 0) {
                    let index = filterCriteria["criteria"].findIndex(obj => obj.key === "lotId")
                    if (index !== -1) {
                        filterCriteria["criteria"].splice(index, 1)
                    }
                }
                if (filterCriteria && filterCriteria.criteria) {
                    filterCriteria["criteria"].push(obj)
                } else {
                    filterCriteria = this.state.filterCriteria1
                    filterCriteria["criteria"].push(obj)
                }
            }
            if (!filterCriteria) {
                // console.log("sdff", this.props.type)
                filterCriteria = {
                    'limit': this.props.type == 'Missing Items' || this.props.type == 'Expiration Dates' ? 'all' : 20,
                    'page': 1,
                    'criteria':  [],
                    'direction': 'desc',
                    sortfield: this.props.type == "Auto Recall" ? 'recallDate' : 'created'
                };
                if (this.props.type == 'Required Inventories') {
                    filterCriteria.sortfield = "name"
                    filterCriteria.direction = "asc"
                }
            } else {
                if (type === 'refresh' && filterCriteria) {
                    delete filterCriteria.globalSearch;
                    filterCriteria['criteria'] = this.props.type === 'Recommended Inventories' ? [{ key: "companyId", value: null, type: "eq" }] : [],
                        filterCriteria['direction'] = 'desc';
                    filterCriteria['sortfield'] = this.props.type == "Auto Recall" ? 'recallDate' : 'created';
                }
                // if (this.props.type === "Auto Recall") {
                //     delete filterCriteria.globalSearch;
                //     filterCriteria['criteria'] = [];
                //     filterCriteria['direction'] = 'desc';
                //     filterCriteria['sortfield'] = 'recallDate';
                // }

                if (filterCriteria && filterCriteria['criteria'] && filterCriteria['criteria'].length > 0) {
                    delete filterCriteria['sortfield'];
                    delete filterCriteria['direction'];
                }
            }


            if (this.props.type == 'Storage Unit Checks') {
                apiUrl = `${url}&filter=${JSON.stringify(filterCriteria)}`;
            } else {
                apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;
            }
            if (type == 'search' && this.props.type == 'Missing Items') {
                apiUrl = `${apiUrl}&search=search`;
            }
            if (type == 'refresh' || type == 'sort' || type == 'search' || type == 'pagination' || type == 'load') {
                type = this.state.tabType
            }

            if (type == 'Cart Locations') {
                apiUrl = `${apiUrl}&storageType=crashcart`;
            } else if (type == 'Supply Cabinets') {
                apiUrl = `${apiUrl}&storageType=storage`;
            } else if (this.props.tabType == 'OR Suites') {
                apiUrl = `${apiUrl}&storageType=operatingRoom`;
            }
            if (this.props.type == 'Orders') {
                apiUrl = `${url}&company_id=${this.props.companyId}`;
            }

            if (this.props.name === 'Active Companies') {
                url = 'companies'
            }
            if (this.props.match && this.props.match.params && this.props.match.params.company) {
                if (this.props.type == 'Expiration Dates' || this.props.type == 'Employees' || this.props.type == 'Facility Staff Roles' ||
                    this.props.type == 'Activities' || this.props.type == 'Categories' || this.props.type == 'Recall Items' ||
                    this.props.type == 'Distributors' || this.props.type == 'Product Directory') {
                    apiUrl = `${apiUrl}&companyId=${this.props.match.params.company}`;
                }
                if (this.props.type == 'Track Items') {
                    apiUrl = `${apiUrl}&companyId=${this.props.match.params.company}`;
                    url = 'activities'
                }
                if (this.props.type == 'Storage Unit Checks') {
                    apiUrl = `${apiUrl}&companyId=${this.props.match.params.company}`;
                    url = 'checks'
                }
                if (this.props.type == 'Battery Checks') {
                    apiUrl = `${apiUrl}&companyId=${this.props.match.params.company}`;
                    url = 'checks'
                }
                if (this.props.type == 'Storage Unit Items') {
                    apiUrl = `${apiUrl}&companyId=${this.props.match.params.company}`;
                    url = 'quantities'
                }
                if (this.props.type == 'Facility Staff') {
                    apiUrl = `${apiUrl}&companyId=${this.props.match.params.company}`;
                    url = 'users'
                }
                if (this.props.type == 'Facilities' || this.props.type == 'Storage Unit') {
                    apiUrl = `${apiUrl}&companyId=${this.props.match.params.company}`;
                }
            }

            return fetch('GET', apiUrl)
                .then(async (response) => {
                    let AllEmployeesData = [], totalRecordsLength = this.state.totalRecordsLength;
                    let sessionexpired = localStorage.getItem("sessionexpired");
                    if (sessionexpired == 'true') {
                        this.setState({
                            isLoading: false,
                            openSessionModal: true
                        })
                        localStorage.setItem('sessionexpired', false);
                        return;
                    }
                    if (this.props.type == 'Missing Items') {
                        url = 'missingItems'
                    }
                    if (this.props.type == 'Recommended Inventories') {
                        url = 'recommendedInventorys'
                    }
                    if (this.props.type == 'Auto Recall') {
                        url = 'autoRecalls'
                    }
                    if (this.props.type == 'Expiration Dates') {
                        url = 'quantities'
                    }
                    if (this.state.tabType == 'Orders List' || this.state.tabType == 'Placed Orders' || this.state.tabType == 'Fulfilled Orders') {
                        url = 'orders'
                    }

                    if (response && response[url] && response[url].length && response[url].length > 0) {
                        if (response.pagination) {
                            totalRecordsLength = response.pagination.totalCount ? response.pagination.totalCount : this.state.totalRecordsLength;
                        }
                        // if (this.props.type == 'Product Directory') {
                        //     let data = response[url]
                        //     data.sort((a, b) => (a.title.toUpperCase() > b.title.toUpperCase()) ? 1 : ((b.title.toUpperCase() > a.title.toUpperCase()) ? -1 : 0));
                        // }
                        response[url].forEach((employee, index) => {
                            if (this.props.type == 'Recall Items') {
                                if (employee['company_id'] == this.props.match.params.company) {
                                    AllEmployeesData.push(employee)
                                }
                            } else if (this.state.tabType == 'Placed Orders') {
                                totalRecordsLength = productCount;
                                if (!employee.fulilled) {
                                    productCount = productCount + 1
                                    AllEmployeesData.push(employee);
                                    totalRecordsLength = productCount;
                                }
                            } else if (this.state.tabType == 'Orders List') {
                                if (!employee.checked) {
                                    orderCount = orderCount + 1
                                    AllEmployeesData.push(employee);
                                    totalRecordsLength = orderCount;
                                }
                                totalRecordsLength = orderCount;
                            } else if (this.props.type != 'Missing Items') {
                                AllEmployeesData.push(employee);
                            }
                        })
                        if (this.props.type == 'Missing Items') {
                            for (let option of response['missingItems']) {
                                if (option.companyId == this.props.match.params.company) {
                                    missingCount = missingCount + 1
                                    AllEmployeesData.push(option)
                                }
                            }
                            totalRecordsLength = missingCount
                        }
                    } else {
                        if (response.pagination) {
                            totalRecordsLength = response.pagination.totalCount ? response.pagination.totalCount : 0;
                        }
                    }

                    await this.setState({
                        totalRecords: AllEmployeesData,
                        isLoading: false,
                        totalRecordsLength: totalRecordsLength
                    })
                    // console.log(this.state.totalRecords)
                    return AllEmployeesData;
                }).catch((err) => {
                    return err;
                });
        }
    }

    //Get all data of current screen with filters applied from server to export to CSV
    getDataToExport = async () => {
        this.setState({ isLoading: true })
        let filterCriteria = this.state.filterCriteria;
        if (filterCriteria && filterCriteria['limit']) delete filterCriteria['limit'];
        if (filterCriteria && filterCriteria['page']) delete filterCriteria['page'];

        let url = this.getApiUrl();
        let apiUrl;
        apiUrl = `${this.state.apiUrl}?filter = ${JSON.stringify(filterCriteria)}&&companyId=${this.state.companyId}&&type=exportToCsv`;
        return fetch('GET', apiUrl)
            .then(async (response) => {
                if (response && response[url]) {
                    let exportData = response[url];
                    exportData.forEach((item) => {
                        if (item.created) {
                            item.created = dateFormats.formatDate(item.created, config.dateDayMonthFormat);
                        }
                        if (item.updated) {
                            item.updated = dateFormats.formatDate(item.updated, config.dateDayMonthFormat);
                        }
                        if (item.fromDate) {
                            item.fromDate = dateFormats.formatDate(item.fromDate, config.dateDayMonthFormat);
                        }
                        if (item.toDate) {
                            item.toDate = dateFormats.formatDate(item.toDate, config.dateDayMonthFormat);
                        }
                        if (item.purchaseDate) {
                            item.purchaseDate = dateFormats.formatDate(item.purchaseDate, config.dateDayMonthFormat);
                        }
                        if (item.date) {
                            item.date = dateFormats.formatDate(item.date, config.dateDayMonthFormat);
                        }
                        if (item.startDate) {
                            item.startDate = dateFormats.formatDate(item.startDate, config.dateDayMonthFormat);
                        }
                        if (item.endDate) {
                            item.endDate = dateFormats.formatDate(item.endDate, config.dateDayMonthFormat);
                        }
                        if (item.joinDate) {
                            item.joinDate = dateFormats.formatDate(item.joinDate, config.dateDayMonthFormat);
                        }
                        for (let key in item) {
                            if (item[key] && item[key].name) {
                                item[key] = item[key].name;
                            }
                        }
                    });
                    this.setState({
                        exportData: exportData,
                        isLoading: false
                    }, () => {
                        // click the CSVLink component to trigger the CSV download
                        this.csvLinkRef.link.click();
                    });
                }
            }).catch((err) => {
                this.setState({
                    isLoading: false
                });
                return err;
            });
    }

    //open Form modal
    openFormModal = async (type, rowData, view) => {
        this.setState({
            isshowfield: false
        })
        if (this.props.type == 'Employee Roles' || this.props.type == 'Facility Staff Roles') {
            this.setState({
                isOpenRolesModal: true,
                formType: type,
                selectedRows: ''
            })
            if (type == 'edit') {
                this.roleModalRef.getRowData(rowData, type)
            }
            if (type == 'add') {
                let rowData, allRoles = this.state.totalRecords;
                if (allRoles && allRoles.length > 0) {
                    for (let roleInfo of allRoles) {
                        if (roleInfo.staffRole == "Super Admin" || roleInfo.role == "Super Admin") {
                            rowData = roleInfo;
                        }
                    }
                    this.roleModalRef.getRowData(rowData);
                } else {
                    let permissions = localStorage.getItem('rolePermissions');
                    if (permissions) {
                        this.roleModalRef.getRowData({
                            permissions: JSON.parse(permissions)
                        });
                    }
                }
            }
        } else {
            await this.setState({
                isOpenFormModal: true,
                formType: type,
                selectedRows: ''
            });

            if (type == 'edit') {
                if (this.state.isOpenFormModal && this.formModalRef) {
                    this.formModalRef.getRowData(rowData);
                }
            }
        }
    }

    //closeRolesModal
    closeRolesModal = () => {
        this.setState({
            isOpenRolesModal: false
        })
    }

    //close form modal
    closeFormModal = () => {
        this.setState({
            isOpenFormModal: false
        })
    }

    // delete selected row
    deleteSelectedRow = (type) => {
        let apiUrl = this.getApiUrl();
        let method, url, body = {}

        if ((this.props.type == 'Doctors') &&
            this.state.selectedRowsId && this.state.selectedRowsId.length && this.state.selectedRowsId.length > 0) {
            method = 'POST';
            url = `${apiUrl}/${apiCall.updateMultipleUsers}`;
            body['status'] = this.state.doctorStatus
            body[apiUrl] = [...this.state.selectedRowsId]
        } else {
            url = `${apiUrl}/${this.state.deleteRowDataId}`;
            if (this.state.tabType == 'Fulfilled Orders') {
                url = `orders/${this.state.deleteRowDataId}`
            }
            method = 'DELETE';
            body = ''
        }

        if (this.props.tabType == 'Cart Locations') {
            url = `${url}?storageType=crashcart`;
        } else if (this.props.tabType == 'Supply Cabinets') {
            url = `${url}?storageType=storage`;
        } else if (this.props.tabType == 'OR Suites') {
            url = `${url}?storageType=operatingRoom`;
        }

        if (this.props.type == 'Storage Unit Items' && type) {
            url = `${url}?type=${type}`
        }

        return fetch(method, url, body)
            .then(async (response) => {
                this.setState({
                    openDeleteModal: false
                });
                if (response && response.respCode) {
                    showToasterMessage(response.respMessage, 'success');
                    this.getDataFromServer();
                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                }
            }).catch((err) => {
                return err;
            });
    }

    //close delete modal
    closeDeleteModal = async () => {
        await this.setState({
            openDeleteModal: false
        })
    }

    // conformation for delete employee
    deleteConfirmAction = async (rowData, value) => {
        let selectedRowsId = [];
        let selectedRows = [...this.state.selectedRows]
        if (selectedRows && selectedRows.length && selectedRows.length > 0) {
            selectedRows.forEach((item, index) => {
                selectedRowsId.push(item._id)
            })
            await this.setState({ selectedRowsId: selectedRowsId })
            if (this.props.type == 'Doctors') {
                await this.setState({
                    doctorStatus: value.value
                })
                this.deleteSelectedRow();
            }
        }
        if ((this.props.type != 'Doctors' && selectedRows && selectedRows.length && selectedRows.length > 0) ||
            rowData) {
            this.setState({
                deleteRowDataId: rowData,
                openDeleteModal: true
            });
        }
    }

    //change dropdown elememt
    changeDropDownElement = (event) => {
        this.dt.filter(event.target.value, event.target.name, 'equals');
        this.setState({
            [event.target.name]: event.value
        });
    }
    openQrModal = async (code, Id) => {
        this.setState({
            qrcode: code,
            openQrModal: true,
            lotId: Id
        })
    }

    openViewModal = async (rowData, rowID) => {
        await this.setState({
            displayData: rowData
        })
        let keys = Object.keys(rowData);
        let formFields = this.props.formFields ?
            await this.props.formFields() : [];
        if (formFields) {
            if (this.props.type == 'Categories') {
                if (rowData['products']) {
                    let products = [];
                    for (let product of rowData['products']) {
                        if (product && product['productId'] && product['productId'].title) {
                            products.push(product['productId'].title);
                        }
                    }
                    formFields = [{
                        label: 'Products',
                        value: products && products.length > 0 ? products.join(', ') : null
                    }]
                }
            }
            if (this.props.type == 'Clinics') {
                for (let item of formFields) {
                    if (keys.indexOf(item.name) !== -1) {
                        if (item.name) {
                            item.value = rowData[item.name];
                        }
                    }
                }
            }
            if (this.props.type == 'Storage Unit') {
                if (rowData) {
                    let values;
                    for (let value1 of keys) {
                        if (value1 == '_id') {
                            this.setState({
                                locationId: rowData[value1],
                            })
                        }
                        if (value1 == 'cart') {
                            this.setState({
                                locationName: rowData[value1]
                            })
                        }
                        if (value1 == 'cartParts') {
                            let drawers = []
                            for (let value of rowData[value1]) {
                                drawers.push({
                                    label: value,
                                    value: value
                                })
                            }
                            this.setState({
                                drawers: drawers
                            })
                        }
                    }
                    let cart = rowData['cart'] ? rowData['cart'] : '-'
                    let companyName = rowData['companyName'] ? rowData['companyName'] : '-'
                    let clinicName = rowData['clinicName'] ? rowData['clinicName'] : '-'
                    let companyemail = rowData['companyemail'] ? rowData['companyemail'] : '-'
                    let companyId = rowData['companyId'] ? rowData['companyId'] : '-'
                    let area = rowData['cartLocation'] ? rowData['cartLocation']['area'] ? rowData['cartLocation']['area'] : '-' : '-'
                    let building = rowData['cartLocation'] ? rowData['cartLocation']['building'] ? rowData['cartLocation']['building'] : '-' : '-'
                    let floor = rowData['cartLocation'] ? rowData['cartLocation']['floor'] ? rowData['cartLocation']['floor'] : '-' : '-'
                    let room = rowData['cartLocation'] ? rowData['cartLocation']['room'] ? rowData['cartLocation']['room'] : '-' : '-'

                    formFields.push(
                        { label: 'Company Name', value: companyName },
                        { label: 'Area', value: area },
                        { label: 'Facility', value: clinicName },
                        { label: 'Building', value: building },
                        { label: 'Company Email', value: companyemail },
                        { label: 'Floor', value: floor },
                        { label: 'Company Id', value: companyId },
                        { label: 'Room', value: room },
                    )
                }
            } else if (this.props.type == 'Employees') {
                for (let item of formFields) {
                    if (keys.indexOf(item.name) !== -1) {
                        if (rowData[item.name]) {
                            if (item.name) {
                                item.value = rowData[item.name];
                            }
                        }
                    }
                }
            }
            await this.setState({
                openViewModal: true
            });
            if (rowData && rowData.storageType) {
                let type = rowData.storageType == 'operatingRoom' ? 'OR Suite' :
                    rowData.storageType == 'storage' ? 'Supply Cabinet' : 'Cart Location';
                formFields.push({
                    label: 'Storage Type',
                    value: type
                })
                this.viewModalRef.getRowData(formFields, type);
            } else {
                this.viewModalRef.getRowData(formFields);
            }
        }
    }

    closeViewModal = async () => {
        await this.setState({
            openViewModal: false
        });
    }
    closeQrModal = () => {
        this.setState({
            openQrModal: false
        })
    }
    //on changing pagination
    onPageChange = async (event) => {
        let filterCriteria = this.state.filterCriteria1;
        if (event && event.rows) {
            let currentPage = event.page + 1;
            filterCriteria['limit'] = event.rows;
            filterCriteria['page'] = currentPage;
            await this.setState({
                rows: event.rows,
                page: event.page,
                first: event.first
            })
        }
        this.getDataFromServer(filterCriteria, 'pagination')
    }

    //sorting fields
    sortChange = (event) => {
        this.setState({ selectedRows: '' })
        let sortCount = this.state.sortCount;
        if (event && event['sortField']) {
            sortCount = sortCount == 0 ? sortCount + 1 : 0;
            let sortField = event['sortField'];
            let filterCriteria = {
                direction: sortCount == 0 ? "desc" : 'asc',
                sortfield: sortField,
            }
            this.setState({
                sortCount: sortCount,
                isLoading: true
            });
            this.getDataFromServer(filterCriteria, 'sort');
        }
    }

    // on search get data from server
    onFilterChange = async (event, type) => {

        //console.log("@#$@@#$", event)
        this.setState({
            isLoading: true,
            selectedRows: ''
        })
        if (type == 'dropdownFilter') {
            await this.setState({
                [event.target.name]: event.target.value
            })
        }

        let fieldName = '', filterCriteria = this.state.filterCriteria1,
            selectedFilterValue, selectedFilter, selecterFilterType, formattedDate, isDateFilter = false;
        if (event) {
            //console.log("@#$@@#@#@#@$", event)
            if (event.filters && !type) {
                if (Object.keys(event.filters) && Object.keys(event.filters)[0]) {
                    fieldName = await Object.keys(event.filters)[0];
                }
                let field = event.filters;
                //  console.log("@#$@@#@#@#@$", field)
                selectedFilter = field[fieldName];
                // console.log("@#$@@#@#@#@$", selectedFilter)
                if (fieldName == 'dateOfBirth' || fieldName == 'fromDate' || fieldName == 'toDate' ||
                    fieldName == 'date' || fieldName == 'created' || fieldName == 'updated' || fieldName == 'expirationDate' ||
                    fieldName == 'startDate' || fieldName == 'joinDate' || fieldName == 'backDate' || fieldName == 'recallDate') {
                    isDateFilter = true;
                    selectedFilterValue = selectedFilter && selectedFilter.value && selectedFilter.value.length == 10 ?
                        selectedFilter.value : null;
                    let date = dateFormats.addDaysToDate(selectedFilter.value, 1);
                    formattedDate = dateFormats.formatDate(date, config.dateDBFormat);
                    selecterFilterType = 'lte'
                } else {
                    selecterFilterType = 'regexOr'

                    selectedFilterValue = selectedFilter && selectedFilter.value ? selectedFilter.value : null;
                }
            } else {
                fieldName = event.target.name;
                selectedFilterValue = event && event.target && event.target.value ? event.target.value : null;
            }
            if (type == 'dropdownFilter') {
                selecterFilterType = 'eq'
            }
            if ((selectedFilterValue && selectedFilterValue.length)) {
                if (fieldName == 'createdBy') {
                    fieldName = 'createdBy.name';
                }
                if (fieldName == 'cartLocation') {
                    (fieldName = 'cartLocation.area');
                }
                if (this.props.type == 'Track Items' && fieldName == 'locationId') {
                    fieldName = 'requestJson.json.body.cartName';
                }
                if (fieldName == 'Have') {
                    fieldName = 'count';
                }
                if (fieldName == 'globalSearch') {
                    //  console.log("dfsd 25", filterCriteria)
                    filterCriteria.globalSearch = {
                        value: selectedFilterValue,
                        type: 'user',
                        check: 'global'
                    }
                    // console.log("dfsd 25", filterCriteria)
                } else {
                    if (selecterFilterType == 'gte') {
                        let obj = filterCriteria.criteria.find(x => x.key == fieldName);
                        let index = filterCriteria.criteria.indexOf(obj);
                        if (index != -1) {
                            filterCriteria['criteria'].splice(index, 1, {
                                key: fieldName,
                                value: formattedDate,
                                type: 'lte'
                            });
                        } else {
                            filterCriteria['criteria'].push({
                                key: fieldName,
                                value: formattedDate,
                                type: 'lte'
                            });
                        }
                    } else {
                        if (fieldName == 'clinics') {
                            fieldName = 'clinics.name'
                        }
                        if (fieldName == 'pharmasistCompanies') {
                            fieldName = 'pharmasistCompanies.name'
                        }
                        if (fieldName == 'updated' && !(this.props.type == 'Help' || this.props.type == 'Facility Staff Roles' || this.props.type == 'Employee Roles')) {
                            fieldName = 'updated.name'
                        }
                        if (fieldName == 'updatedBy' && (this.props.type == 'Employee Roles' || this.props.type == 'Facility Staff Roles')) {
                            fieldName = 'updatedBy.name'
                        }
                        if (fieldName == 'needed') {
                            fieldName = 'needed';
                        }
                        if (fieldName == "paymentAmount") {
                            selectedFilterValue = parseInt(selectedFilterValue)
                            selecterFilterType = "eq"
                            // console.log(typeof selectedFilterValue)
                            // console.log(selectedFilterValue)
                        }
                        //console.log("@#$@@#@#@#@$", this.state.filterCriteria)
                        if (filterCriteria['criteria'].length == 0 && selecterFilterType != 'lte') {
                            filterCriteria['criteria'].push({
                                key: fieldName,
                                value: selectedFilterValue,
                                type: selecterFilterType
                            });
                        } else {
                            let obj = filterCriteria.criteria.find(x => x.key == fieldName);
                            let index = filterCriteria.criteria.indexOf(obj);

                            if (selecterFilterType == 'lte') {

                                if (selectedFilterValue.length == 10) {
                                    filterCriteria['criteria'].splice(0, 1, ({
                                        key: fieldName,
                                        value: selectedFilterValue.substring(6, 10) + '-' + selectedFilterValue.substring(0, 2) + '-' + selectedFilterValue.substring(3, 5) + 'T23:59:59Z',
                                        type: selecterFilterType
                                    }));
                                }
                            }
                            if (index != -1 && selecterFilterType != 'lte') {
                                filterCriteria['criteria'].splice(index, 1, {
                                    key: fieldName,
                                    value: selectedFilterValue,
                                    type: selecterFilterType
                                });
                            } else if (selecterFilterType != 'lte') {
                                filterCriteria['criteria'].push({
                                    key: fieldName,
                                    value: selectedFilterValue,
                                    type: selecterFilterType
                                });
                            }

                        }
                    }

                    this.setState({
                        filterCriteria: filterCriteria
                    });
                }

                this.getDataFromServer(filterCriteria, 'search')
            } else {
                if (selectedFilterValue == null && !isDateFilter) {
                    let obj = filterCriteria.criteria.find(x => x.key == fieldName);
                    let index = filterCriteria.criteria.indexOf(obj);
                    filterCriteria.criteria.splice(index, 1);
                    if (fieldName == 'globalSearch') {
                        filterCriteria.globalSearch = {}
                    }
                    this.setState({
                        filterCriteria: filterCriteria
                    });
                    this.getDataFromServer(filterCriteria, 'search')
                }
            }
            this.setState({
                isLoading: false
            })
        } else {
            this.setState({
                isLoading: false
            })
        }
    }

    sendEmail = (item
    ) => {
        let userBody = [];
        userBody.push(item);
        // console.log(userBody);
        return fetch('POST', `orders/sendEmail?company_id=${this.props.companyId}`, userBody)
            .then((response) => {
                if (response && response.respCode && response.respCode === 200) {
                    this.getDataFromServer(this.state.filterCriteria, 'refresh')

                    showToasterMessage(response.respMessage, 'success');
                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                }
                this.setState({
                    isLoading: false,
                    // tabType: 'Placed Orders'
                });
                this.clearInputFields();
            }).catch((err) => {
                return err;
            });
    }

    //open Bulk Modal
    bulkUploadMoadal = () => {
        this.setState({ openBulkUploadModal: true })
    }

    //close Bulk Modal
    closeBulkModal = async () => {
        await this.setState({
            openBulkUploadModal: false
        }, () => {
            this.bulkUploadMoadalRef.handleClickCount();
        })
        this.getDataFromServer(this.state.filterCriteria);
    }

    //select multiple rows to delete
    onSelectRowsUpdate = async (event) => {
        await this.setState({ selectedRows: event.value })
    }

    changeLeaveStatus = async (rowData, type, status) => {
        await this.setState({ formType: 'edit', setFormRef: true })
        if (type == 'reason') {
            if (this.formModalRef) {
                this.formModalRef.handleData(rowData, 'reason', 'changeStatus')
            }
            this.closeRejectModal();
        } else {
            let obj = {};
            obj.status = status;
            if (this.formModalRef) {
                this.formModalRef.getRowData(rowData);
                this.formModalRef.handleData(obj, 'status', 'changeStatus')
            }
        }
    }

    getSelectedItemInfo = (value, companyName, name, companyType) => {
        let path = window.location.pathname.slice(window.location.pathname.lastIndexOf('/') + 1, window.location.pathname.length);
        if (value) {
            this.setState({
                [path]: true,
                companyName: companyName,
                path: path,
                redirectCompanyId: value
            })
        }
    }

    //openRejectModal
    openRejectModal = async (rowData, status) => {
        this.setState({
            isOpenFormModal: false
        }, async () => {
            await this.setState({
                isOpenFormModal: false,
                setFormRef: true,
                leaveStatus: status,
                openRejectModal: true,
            });
            this.formModalRef.getRowData(rowData);
        })
    }
    getCompanyNames = () => {
        return fetch('GET', apiCall.Companies)
            .then(async (response) => {
                if (response && response.companies) {
                    let companies = []
                    for (let location of response.companies) {
                        companies.push({
                            name: location.name,
                            id: location._id
                        })

                    }
                    this.setState({
                        Companies: companies
                    })
                }

            });
    }

    closeRejectModal = () => {
        this.setState({
            openRejectModal: false
        });
    }

    //openConfirmationModal
    openConfirmationModal = async (rowData, status, type) => {
        this.setState({
            isOpenFormModal: false
        }, async () => {
            await this.setState({
                isOpenFormModal: false,
                openConfirmationModal: true,
                leaveStatus: status,
                item: rowData,
                confirmText: type
            })
        })

    }

    closeConfirmationModal = () => {
        this.setState({
            openConfirmationModal: false
        })
    }
    openOrderModal = async (product, type, Id, clinic, cart, orderDescription, needed, concentration) => {
        await this.setState({
            openOrderModal: true,
            orderProductName: product,
            orderFrom: type,
            orderCart: cart,
            orderClinic: Id,
            orderClinicName: clinic,
            orderDescription: orderDescription,
            orderConcentration: concentration,
            orderNeeded: needed
        })
    }

    closeOrderModal = async () => {
        await this.setState({
            openOrderModal: false
        })
    }

    //change table body values//
    changeFieldValues = (item, column) => {
        if (this.props.type == 'Track Items') {
            if (column.field == 'productName' && item.requestJson) {
                if (item.requestJson['json']) {

                    if (item.requestJson['json'].body) {
                        if (item.requestJson['json'].body.productName) {
                            if (item.requestJson['json'].body.productName.title && typeof (item.requestJson['json'].body.productName) == 'object') {
                                return (
                                    <span>
                                        <span id={`TooltipRight${item._id}`}>
                                            {item.requestJson['json'].body.productName.title}
                                        </span>

                                    </span>
                                )
                            }
                            else if (item.requestJson['json'].body.productName && typeof (item.requestJson['json'].body.productName) == 'string') {
                                return (
                                    <span>
                                        <span id={`TooltipRight${item._id}`}>
                                            {item.requestJson['json'].body.productName}
                                        </span>

                                    </span>
                                )
                            }
                            else {
                                return (
                                    <span>
                                        <span id={`TooltipRight${item._id}`}>
                                            -
                                        </span>

                                    </span>
                                )
                            }
                        }
                    }
                }
            }
            if (column.field == 'description' && item.requestJson) {
                if (item.requestJson['json']) {
                    if (item.requestJson['json'].body) {
                        if (item.requestJson['json'].body.description) {

                            return (
                                <span>
                                    <span id={`TooltipRight${item._id}`}>
                                        {item.requestJson['json'].body.description}
                                    </span>

                                </span>
                            )
                        }
                    }
                }
            }
            if (column.field == 'lotId' && item.requestJson) {
                if (item.requestJson['json']) {
                    if (item.requestJson['json'].body) {
                        if (item.requestJson['json'].body.lotId) {

                            return (
                                <span>
                                    <span id={`TooltipRight${item._id}`}>
                                        {item.requestJson['json'].body.lotId}
                                    </span>

                                </span>
                            )
                        }
                    }
                }
            }
            if (column.field == 'locationId' && item.locationId) {
                if (item.locationId.cart) {

                    return (
                        <span>
                            <span id={`TooltipRight${item._id}`}>
                                {item.locationId.cart}
                            </span>

                        </span>
                    )
                }
            }

        }
        if (this.props.type == 'Battery Checks' || this.props.type == 'Storage Unit Checks') {
            if (column.field == 'address') {
                let cartLocation = '';
                if (item['cartLocation']) {
                    cartLocation = item['cartLocation'].building ? item['cartLocation'].building : '';
                    cartLocation = `${cartLocation}${((item['cartLocation'].building && item['cartLocation'].area) ? ', ' : '') + (item['cartLocation'].area ? item['cartLocation'].area : '')}`;
                    cartLocation = `${cartLocation}${((item['cartLocation'].area && item['cartLocation'].floor) || ((item['cartLocation'].building && item['cartLocation'].floor)) ? ', ' : '') + (item['cartLocation'].floor ? item['cartLocation'].floor : '')}`;
                    cartLocation = `${cartLocation}${((item['cartLocation'].floor && item['cartLocation'].room) || ((item['cartLocation'].area && item['cartLocation'].room)) || ((item['cartLocation'].building && item['cartLocation'].room)) ? ', ' : '') + (item['cartLocation'].room ? item['cartLocation'].room : '')}`;

                    return (
                        <span>
                            <span id={`TooltipLeft${item._id}`}>
                                {cartLocation}
                            </span>
                            <UncontrolledTooltip placement="bottom"
                                target={`TooltipLeft${item._id}`}>
                                {'Building: ' + (item['cartLocation'].building ? item['cartLocation'].building : '-') + ', Area: ' +
                                    (item['cartLocation'].area ? item['cartLocation'].area : '-') + ', Floor: ' +
                                    (item['cartLocation'].floor ? item['cartLocation'].floor : '-') + ', Room: ' +
                                    (item['cartLocation'].room ? item['cartLocation'].room : '-')}
                            </UncontrolledTooltip>
                        </span>
                    )
                }
            }
        }
        if (this.props.type == 'Storage Unit') {
            if (column.field == 'cartParts') {
                return (
                    <span>
                        <span id={`TooltipRight${item._id}`}>
                            {item[column.field] && item[column.field].length > 0 ?
                                item[column.field].join(', ') : null}
                        </span>
                        <UncontrolledTooltip placement="bottom"
                            target={`TooltipRight${item._id}`}>
                            {item[column.field] && item[column.field].length > 0 ?
                                item[column.field].join(', ') : null}
                        </UncontrolledTooltip>
                    </span>
                )
            }
        }
        //conditon to show the payment screen data 
        if (this.props.type === "Payment") {
            let val;
            if (column.field === "numberOfCarts") {
                val = item.numberOfCarts;
                return <span>{val}</span>;
            } else if (column.field === "amount") {
                val = item.amount;
                return <span>{val}</span>;
            } else if (column.field === "billId" && item.billId === "Trial Period") {
                return <span style={{ color: "green" }}>{item.billId}</span>;
            }
        }

        if (column.field == 'cartLocation') {
            let cartLocation = '';
            if (item[column.field]) {
                cartLocation = item[column.field].building ? item[column.field].building : '';

                cartLocation = `${cartLocation}${((item[column.field].building && item[column.field].area) ? ',' : '') + (item[column.field].area ? item[column.field].area : '')}`;
                cartLocation = `${cartLocation}${((item[column.field].area && item[column.field].floor) || ((item[column.field].building && item[column.field].floor)) ? ',' : '') + (item[column.field].floor ? item[column.field].floor : '')}`;
                cartLocation = `${cartLocation}${((item[column.field].floor && item[column.field].room) || ((item[column.field].area && item[column.field].room)) || ((item[column.field].building && item[column.field].room)) ? ',' : '') + (item[column.field].room ? item[column.field].room : '')}`;

                return (
                    <span>
                        <span id={`TooltipLeft${item._id}`}>
                            {cartLocation}
                        </span>
                        <UncontrolledTooltip placement="bottom"
                            target={`TooltipLeft${item._id}`}>
                            {'Building: ' + (item[column.field].building ? item[column.field].building : '-') + ', Area: ' + (item[column.field].area ? item[column.field].area : '-') + ', Floor: ' + (item[column.field].floor ? item[column.field].floor : '-') + ', Room: ' + (item[column.field].room ? item[column.field].room : '-')}
                        </UncontrolledTooltip>
                    </span>
                )
            }
        }
        if (column.field == 'cartLocation') {
            if (column.field == 'address') {
                if (item[column.field]) {
                    return (
                        <span>
                            <span id={`TooltipLeft${item._id}`}>
                                {item[column.field]}
                            </span>
                            <UncontrolledTooltip placement="bottom"
                                target={`TooltipLeft${item._id}`}>
                                {item['cartLocation'] ?
                                    <div>
                                        <div>Building: {item['cartLocation'].building}, Floor: {item['cartLocation'].floor},<br />Area: {item['cartLocation'].area},Facility: {item['cartLocation'].facility},Room: {item['cartLocation'].room}</div>

                                    </div>
                                    : null}
                            </UncontrolledTooltip>
                        </span>
                    )
                }
            }
        }
        if (this.props.name == 'Active Companies') {
            if (column.field == 'name') {
                if (item[column.field]) {
                    return (
                        <span onClick={() => this.getSelectedItemInfo(item['_id'], item.name, window.location.pathname, 'itemCompany')}>
                            <span style={{ color: config.templateColor }} id={`TooltipLeft${item._id}`}>
                                {item[column.field]}
                            </span>

                        </span>
                    )
                }
            }
        }

        // change staff company field
        if (this.props.type == 'Employees') {
            if (column.field == 'pharmasistCompanies') {
                let companiesList = [], companyName;
                if (item[column.field] && item[column.field].length > 0) {
                    for (let companie of item[column.field]) {
                        if (companie && companie.name) {
                            companiesList.push(companie.name);
                        }
                        else {
                            companiesList.push(companie);
                        }
                    }
                } else {
                    companyName = item['companyName'];
                }
                return (
                    <span>
                        <span id={`TooltipLeft${item._id}`}>
                            {companiesList && companiesList.length > 0 ?
                                companiesList.join(',') : companyName}
                        </span>

                        <UncontrolledTooltip placement="top"
                            target={`TooltipLeft${item._id}`}>
                            {companiesList && companiesList.length > 0 ?
                                companiesList.join(', ') : companyName}
                        </UncontrolledTooltip>
                    </span>
                )
            }

        }
        if (this.props.type == 'Payment') {
            if (column.field == 'validateFrom') {

                let date = dateFormats.formatDate(item[column.field], config.dateDBFormat)
                return (
                    <span>
                        <span id={`TooltipRight${item._id}`}>
                            {date ? date : null}
                        </span>
                    </span>
                )
            }
            if (column.field == 'validateTo') {

                let date = dateFormats.formatDate(item[column.field], config.dateDBFormat)
                return (
                    <span>
                        <span id={`TooltipRight${item._id}`}>
                            {date ? date : null}
                        </span>
                    </span>
                )
            }
        }
        if (column.field == 'qrcode') {
            return <div className='text-center'>
                <span className='textEclipses' style={{ color: config.templateColor, textDecoration: 'underline' }}
                    onClick={() => this.openQrModal(item[column.field], item['lotId'])}>
                    view
                </span>
            </div >
        }
        //clinics for Quantity
        if (this.props.type == 'Storage Unit Items') {
            if (column.field == 'clinicId') {
                let clinic;
                if (item[column.field] && item[column.field].name) {
                    clinic = item[column.field].name;
                } else {
                    clinic = (item[column.field] ? item[column.field] : null);
                }
                return (
                    <span>
                        <span id={`TooltipRight${item._id}`}>
                            {clinic ? clinic : null}
                        </span>
                    </span>
                )
            }
            if (column.field == 'productId') {
                let product;
                if (item[column.field] && item[column.field].title) {
                    product = item[column.field].title;
                } else {
                    product = item[column.field];
                }
                return (
                    <span>
                        <span id={`TooltipRight${item._id}`}>
                            {product ? product : null}
                        </span>
                    </span>
                )
            }
            if (column.field == 'locationId') {
                let location;
                if (item[column.field] && item[column.field].cart) {
                    location = item[column.field].cart;
                } else {
                    location = item[column.field];
                }
                return (
                    <span>
                        <span id={`TooltipRight${item._id}`}>
                            {location ? location : null}
                        </span>
                    </span>
                )
            }
        }

        if (this.props.type == 'Tickets') {
            if (column.field == 'assignedTo') {
                return <span id={`${item._id}`}>
                    {item[column.field] && item[column.field].displayName ? item[column.field].displayName : null}
                </span>
            }
        }
        if (this.props.type == 'Facility Staff') {
            if (column.field == 'clinics') {
                let clinicsList = [];
                if (item[column.field] && item[column.field].length > 0 && item['role'] != "Super Admin") {
                    for (let clinic of item[column.field]) {
                        if (clinic && clinic.name) {
                            clinicsList.push(clinic.name);
                        } else {
                            clinicsList.push(clinic);
                        }
                    }
                } else if (
                    this.props.allClinicsName &&
                    this.props.allClinicsName.length
                ) {
                    for (let clinic of this.props.allClinicsName) {
                        if (clinic) {
                            clinicsList.push(clinic);
                        }
                    }
                }
                return (
                    <span>
                        <span id={`TooltipRight${item._id}`}>
                            {clinicsList && clinicsList.length > 0 ? clinicsList.join(', ') : null}
                        </span>

                        <UncontrolledTooltip placement="top"
                            target={`TooltipRight${item._id}`}>
                            {clinicsList && clinicsList.length > 0 ? clinicsList.join(', ') : null}
                        </UncontrolledTooltip>
                    </span>
                )
            }
        }

        if (this.props.type == 'Storage Unit' || this.props.type == 'Product Directory') {
            let clinic = item[column.field]

            if ((column.field == 'clinics' || column.field == 'clinicId')) {
                if (item[column.field] && item[column.field].name) {
                    clinic = item[column.field].name;

                } else {
                    if (item[column.field]) {
                        clinic = item[column.field];
                    }
                }
                return (
                    <span>
                        <span id={`TooltipRight${item._id}`}>
                            {clinic ? clinic : null}
                        </span>
                    </span>
                )
            }
            if (column.field == "required") {
                if (item[column.field] || item[column.field] == 0) {
                    return (
                        <span>
                            <span id={`TooltipLeft${item._id}`}>
                                {item[column.field] ? item[column.field] : 0}
                            </span>
                        </span>
                    );
                }
            }
            if (column.field == 'categoryId') {
                let category;
                if (item[column.field] && item[column.field].categoryName) {
                    category = item[column.field].categoryName;
                } else {
                    category = item[column.field];
                }
                return (
                    <span>
                        <span id={`TooltipRight${item._id}`}>
                            {category ? category : null}
                        </span>
                    </span>
                )
            }
        }

        if ((this.props.type == 'Clinics' && column.field == 'name') || (this.props.type == 'Storage Unit' && column.field == 'cart') ||
            (column.field == 'displayName' && this.props.type == 'Employees') ||
            (column.field == 'categoryName' && this.props.type == 'Categories')) {
            return <div >
                <span className='textElipses'
                    onClick={() => this.openViewModal(item)}>
                    {item[column.field]}
                </span>
            </div >
        }
        if (column.field === 'name' && this.props.type === 'Recommended Inventories') {
            return (
                <div>
                    <span
                        className='textElipses'
                        onClick={() => this.openViewModal(item)

                        }>
                        {item[column.field]}

                    </span>
                </div>
            );
        }



        if (column.field == 'employeeName') {
            return <div className='textElipses'>
                <span style={{ color: 'blue' }}
                    onClick={() => this.openViewModal(item)}>{item.employeeName}</span>
            </div >
        } else if (column.field == 'status') {
            if (item.status == 'Active' || item.status == 'Fixed' || item.status == 'InStock' ||
                item.status == 'Answered') {
                return <Badge color='success' pill style={dropdownOptionsStyle}>{item.status}</Badge>
            } else if (item.status == 'Pending' || item.status == 'Rejected') {
                return <Badge color='danger' pill style={dropdownOptionsStyle}>{item.status}</Badge>
            } else if (column.field == 'order') {
                return <Button className="ml-auto my-auto" color="primary" onClick={this.openOrderModal} outline>Order</Button>
            } else if (item.status == 'Inactive' || item.status == 'inProgress' || item.status == 'Cancelled' ||
                item.status == 'Reopen' || item.status == 'Assigned') {
                return <Badge color='warning' pill style={dropdownOptionsStyle}>{item.status}</Badge>
            } else if (item.status == 'Approved' || item.status == 'New' || item.status == 'Completed') {
                return <Badge color='primary' pill style={dropdownOptionsStyle}>{item.status}</Badge>
            } else if (item.status == 'Reject' || item.status == 'Closed' || item.status == 'Repair') {
                return <Badge color='danger' pill style={dropdownOptionsStyle}>{item.status}</Badge>
            } else {
                return <Badge color='info' pill style={dropdownOptionsStyle}>{item.status}</Badge>
            }
        } else if (column.field == 'role') {
            if (item.role == 'Admin') {
                return <Badge color='success' pill style={dropdownOptionsStyle}>{item.role}</Badge>
            } else if (item.role == "Super Admin") {
                return <Badge color='danger' pill style={dropdownOptionsStyle}>{item.role}</Badge>
            } else if (item.role == 'Support') {
                return <Badge color='secondary' pill style={dropdownOptionsStyle}>{item.role}</Badge>
            } else {
                return <Badge color='info' pill style={dropdownOptionsStyle}>{item.role}</Badge>
            }
        }
        else if (column.field == 'staffRole') {
            if (item.staffRole == 'Admin') {
                return <Badge color='success' pill style={dropdownOptionsStyle}>{item.staffRole}</Badge>
            } else if (item.staffRole == 'SuperAdmin') {
                return <Badge color='danger' pill style={dropdownOptionsStyle}>{item.staffRole}</Badge>
            } else if (item.staffRole == 'TeamLead') {
                return <Badge color='warning' pill style={dropdownOptionsStyle}>{item.staffRole}</Badge>
            } else if (item.staffRole == 'Developer') {
                return <Badge color='primary' pill style={dropdownOptionsStyle}>{item.staffRole}</Badge>
            } else if (item.staffRole == 'Trainee') {
                return <Badge color='info' pill style={dropdownOptionsStyle}>{item.staffRole}</Badge>
            } else if (item.staffRole == 'QA') {
                return <Badge color='secondary' pill style={dropdownOptionsStyle}>{item.staffRole}</Badge>
            } else if (item.staffRole == 'Support') {
                return <Badge color='secondary' pill style={dropdownOptionsStyle}>{item.staffRole}</Badge>
            } else {
                return <Badge color='info' pill style={dropdownOptionsStyle}>{item.staffRole}</Badge>
            }
        } else if (column.field == 'order') {
            return <Button className="ml-auto my-auto"
                color={item.isOrdered ? "danger" : "primary"}
                onClick={() => this.openOrderModal(item.productName, this.props.type, item.clinicId ? item.clinicId._id : null,
                    item.clinicName ? item.clinicName : null, item.cartName ? item.cartName : item.cart, item.description ? item.description : null, item.needed ? item.needed : null, item.volume)} outline>Add to Orders List</Button>
        } else if (column.field == 'sendEmail') {
            return <Button className="ml-auto my-auto" color="primary" onClick={() => this.sendEmail(item
            )} outline>Submit</Button>
        } else if (column.field == 'reSendEmail') {
            return <Button className="ml-auto my-auto" color="primary" onClick={() => this.ResendEmail(item)} outline>Re-Send Email</Button>
        } else if (column.field == 'Actions') {
            // if (this.props.type == 'Facility Staff' && item.role == "Super Admin") {
            //     //return null
            // } else {
            return (

                <div className='row'
                    style={{ justifyContent: 'center' }}>
                    {this.props.type == 'Tickets' ?
                        <div>
                            <Badge color='primary' style={{ backgroundColor: '#17a2b8' }} pill
                                onClick={() => this.openFormModal('edit', item)}>{'Details'}</Badge>
                        </div> :
                        <div>
                            {this.state.tabType != 'Fulfilled Orders' ?
                                <FontAwesomeIcon
                                    className="genderIconAlignment mr-4"
                                    color='white'
                                    icon='edit'
                                    data-toggle="tool-tip" title="Edit"
                                    style={{ color: '#024a88', width: '15', cursor: 'pointer' }}
                                    onClick={() => this.openFormModal('edit', item, 'editIcon')} /> : null}
                            {this.state.tabType != 'Placed Orders' && this.state.tabType != 'Orders List' ?
                                <FontAwesomeIcon
                                    className='genderIconAlignment'
                                    color='white'
                                    icon='trash-alt'
                                    data-toggle="tool-tip"
                                    title="Delete"
                                    style={{ color: '#bf1725', width: '13', marginLeft: 10, cursor: 'pointer' }}
                                    onClick={() => this.deleteConfirmAction(item._id, item)} />
                                : null}
                        </div>
                    }
                </div >
            )
            //}
        } else if (column.field == 'photo') {
            return <div style={{ textAlign: 'center' }}>
                {item.photo ?
                    <img src={`${config.imgUrl}location/${item.photo}`}
                        className='imgStyles' /> :
                    <img src={configImages.defaultImg}
                        className='imgStyles' />
                }
            </div>
        } else if (column.field == 'gender') {
            if (item.gender == 'Female') {
                return <div style={{ textAlign: 'center' }}>
                    <FontAwesomeIcon
                        className='genderIconAlignment'
                        icon='female'
                        color='white'
                        data-toggle="tool-tip"
                        title="Female"
                        style={{ backgroundColor: 'red', width: '15', }} />
                </div>
            } else if (item.gender == 'Male') {
                return <div style={{ textAlign: 'center' }}>
                    <FontAwesomeIcon className='genderIconAlignment'
                        icon='male'
                        color='white'
                        data-toggle="tool-tip"
                        title="Male"
                        style={{ backgroundColor: 'green', width: '15', }} />
                </div>
            }
        } else if (column.field == 'createdBy') {
            column.field == 'createdBy.name';
            if (item[column.field] && item[column.field].name) {
                return item[column.field].name;
            }
        } else if (column.field == 'updatedBy') {
            if (item[column.field] && item[column.field].name) {
                return item[column.field].name;
            }
        } else if (column.field == 'startDate' || column.field == 'endDate' || column.field == 'DOB' ||
            column.field == 'joinDate'
            || column.field == 'fromDate' || column.field == 'toDate' || column.field == 'date'
            || column.field == 'backDate' || column.field == 'joiningDate' ||
            column.field == 'date') {
            let date = dateFormats.formatDate(item[column.field], config.dateDayMonthFormat);
            return date;

        } else if (column.field == 'expirationDate' || column.field == 'created' || column.field == 'validateTo' || column.field == 'validateFrom' || column.field == 'updated' || column.field == 'recallDate') {
            let date = dateFormats.formatDate(item[column.field], config.dateDBFormat);
            return date;

        } else if (column.field == 'billIds') {
            return item[column.field] && item[column.field].length > 0 ? item[column.field].join(', ') : "";
        } else {
            if ((item[column.field] || (item[column.field] == 0 && column.field == 'existingQuantity')) && typeof item[column.field] !== 'object') {
                return item[column.field];
            }
        }
    }

    getRolePermissions = () => {
        let selectedScreenPermission = RolePermissions.screenPermissions(this.props.type);
        if (selectedScreenPermission) {
            this.setState({
                selectedScreenPermission: selectedScreenPermission
            })
        }
    }

    clickConfirm = () => {
        this.changeLeaveStatus(this.state.item, '', this.state.leaveStatus)
        this.closeConfirmationModal();
    }

    backButton = async () => {
        if (this.state.path) {
            await this.setState({
                back: true
            })
        }
    }

    rowClassName = (item) => {
        if (this.props.type == 'TimeSheet') {
            if (item.isHoliday === true || item.isWeekEnd === true) {
                return (
                    { 'p-highlightHoliday': (item.isHoliday === true || item.isWeekEnd === true) }
                )
            }
        }
    }

    handleDateInterval = (startDate, endDate) => {
        if (startDate) {
            this.setState({
                startDate: startDate
            })
        }
        if (endDate) {
            this.setState({
                endDate: endDate
            })
        }
    }

    ResendEmail = (item) => {
        let userBody = item;
        // {
        //     'productName': productName,
        //     'distributorEmail': distributorEmail,
        //     'required': required
        // }
        return fetch('POST', `orders/resendEmail?company_id=${this.props.companyId}`, userBody)
            .then((response) => {
                if (response && response.respCode && response.respCode === 200) {
                    showToasterMessage(response.respMessage, 'success');
                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                }
                this.setState({
                    isLoading: false,
                    tabType: 'Placed Orders'
                });
                this.clearInputFields();
            }).catch((err) => {
                return err;
            });

    }

    // Get info based on Selected Tab
    getSelectedTabTypeInfo = async (tabType) => {
        let filterCriteria = undefined;
        let tableFields = await this.props.getTableFields(tabType);
        await this.setState({
            tableFields: tableFields,
            tabType: tabType
        }, async () => {
            await this.getDataFromServer(filterCriteria, tabType);
        })
    }

    render() {
        //console.log(this.props.state)
        let header =
            this.props.type != 'Payment' ?
                <div className="row">
                    {this.props.type != 'Orders' ?
                        <div className='col-12'>
                            <div className='serachAlignment float-right' >
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-search" ></i>
                                    </span>
                                    <InputText
                                        type="search"
                                        name='globalSearch'
                                        onChange={(e) => this.onFilterChange(e)}
                                        placeholder={this.props.globalSearch ? this.props.globalSearch : 'search'}
                                        size="30" />
                                </div>
                            </div>
                        </div> : null}
                </div >
                : null
        return (
            <div>
                <Card>
                    <CardHeader className='cardHeader'>
                        <div className="row m-0">

                            {/* table name */}
                            <div className='col-6 pl-0 pt-2'>
                                <h4><b>{this.props.type == 'Storageunititems in companies' ?
                                    'Storage Unit Items in Companies' :
                                    this.props.type == "Inventorychecklist in companies" ?
                                        'Inventory CheckList in companies' :
                                        this.props.type == "Expirationdates in companies" ?
                                            'Expiration Dates in companies' :
                                            this.props.type == "Batterychecks in companies" ?
                                                'Battery Checks in companies' :
                                                this.props.type == "FacilityStaff in companies" ?
                                                    'Facility Staff in companies' :
                                                    this.props.type == "Productdirectory in companies" ?
                                                        'Product Directory in companies' :
                                                        this.props.type == "Expiration Dates"
                                                            ? "Expired Items" :
                                                            this.props.type == "Facilitystaffroles in companies"
                                                                ? "Facility Staff Roles in companies" :
                                                                this.props.type}</b></h4>
                            </div>
                            {this.props.type != 'Payment' ?
                                <span className='col-6 text-right'>
                                    <Row>
                                        {/* Export to csv */}
                                        <Button color="primary"
                                            className='p-1 ml-auto'
                                            size={'sm'} onClick={this.getDataToExport}>
                                            <FontAwesomeIcon
                                                icon='file'
                                                data-toggle="tool-tip" title="Export To CSV"
                                                className='pl-1' size='lg' />
                                        </Button>

                                        <CSVLink
                                            data={this.state.exportData}
                                            filename={`${this.props.type}.csv`}
                                            className="hidden text-white"
                                            ref={(r) => this.csvLinkRef = r}
                                            target="_blank" >
                                        </CSVLink>

                                        {this.props.sample ?
                                            <Button color="primary"
                                                size="sm"
                                                className="p-1"
                                                onClick={this.bulkUploadMoadal}>
                                                <FontAwesomeIcon
                                                    icon='upload'
                                                    className='pl-1' size='lg'
                                                    data-toggle="tool-tip" title="Bulk Upload"
                                                    onClick={this.bulkUploadMoadal} />
                                            </Button>
                                            : null}

                                        {/* Add button */}
                                        {this.props.type == 'Recommended Inventories' || this.state.selectedScreenPermission == 'edit' && (
                                            this.props.type != 'Tickets' && this.props.tabType != 'Placed Orders' && this.props.tabType != 'Fulfilled Orders' &&
                                            this.props.tabType != 'Orders List' && this.props.type != 'Storage Unit Checks' && this.props.type != 'Battery Checks' && this.props.type != 'Expiration Dates') ?
                                            <Button color="primary"
                                                size="sm"
                                                className="p-1"
                                                onClick={() => this.openFormModal('add')}>
                                                <FontAwesomeIcon
                                                    icon='plus'
                                                    className='pl-1' size='lg'
                                                    data-toggle="tool-tip" title="Add"
                                                    onClick={() => this.openFormModal('add')}
                                                />
                                            </Button>
                                            : null}
                                        {/* priority  in Issues*/}
                                        <Button
                                            className='ml-auto'
                                            color="secondary"
                                            size="sm"
                                            // className="p-1"
                                            onClick={() => this.getDataFromServer(this.state.filterCriteria, 'refresh')}>
                                            <FontAwesomeIcon
                                                icon='sync-alt'
                                                size='lg'
                                                data-toggle="tool-tip" title="Refresh"
                                                color={config.templateColor}
                                                className='refreshIcon pl-1' />
                                        </Button>
                                    </Row>
                                </span>
                                : null}
                            {/* //hiding the tabtype functionality for now */}
                            {/* <span className='col-12'>
                                    <Row style={{ justifyContent: 'flex-end' }}>
                                        {this.props.type == 'Payment' && this.props.tabType != 'Order and Invoice History' ?
                                            <Link to={'/PaymentForm'} >
                                                < Button
                                                    className='m-auto '
                                                    color="primary"
                                                    size="sm"
                                                    style={{ float: "right", marginRight: "50" }} >Pay
                                                </Button>
                                            </Link>
                                            : null}
                                    </Row>
                                </span>
                             */}
                        </div>
                    </CardHeader>
                    <CardBody className='tableCardBody'>
                        {this.state.companyName ?
                            <span>
                                <h4 style={{ display: 'flex', justifyContent: 'center', color: '#3475aa' }}><b>{this.state.companyName}</b></h4>

                            </span>
                            : null}
                        {this.state.companyName ?
                            <span>
                                <span style={{ fontSize: 17 }} title={'Back'} onClick={() => this.backButton()} className='mb-2'><Icon className='back' /></span>
                            </span>
                            : null}

                        {/* Payment Tabs Info */}
                        {this.props.type == 'Payment' ?
                            <Row className='pb-1'>
                                <Col sm={12}>
                                    <PaymentTypes
                                        getSelectedPaymentTypeInfo={this.getSelectedTabTypeInfo} />
                                </Col>
                            </Row>
                            : null}


                        {this.props.type == 'Storage Unit' || this.props.type == 'Orders' ?
                            <TabTypes
                                getSelectedTabInfo={this.getSelectedTabTypeInfo}
                                tabsList={this.props.tabsList}
                            />
                            : null}

                        {/* Data table */}
                        <DataTable
                            rowClassName={this.rowClassName}
                            ref={(el) => this.dt = el}
                            value={this.state.totalRecords}
                            header={header}
                            totalRecords={this.state.totalRecordsLength}
                            paginator={false}
                            lazy={true}
                            resizableColumns={true}
                            columnResizeMode="expand"
                            onSort={this.sortChange}
                            globalFilter={this.props.type != 'Payment' ? this.state.globalFilter : false}
                            onFilter={this.onFilterChange}
                            scrollable={true}
                            selection={this.props.type == 'Doctors' || this.props.type == 'SuggestRules' ? this.state.selectedRows : false}
                            onSelectionChange={e => this.onSelectRowsUpdate(e)}
                            scrollHeight='calc(100vh - 200px)'
                            style={{ marginTop: 0 }}
                            emptyMessage={this.props.type == 'SuggestRules' ? configMessages.noOptionsMessage : configMessages.noRecords}
                            sortMode="single"
                            selectionMode={this.props.type == 'SuggestRules' ? 'single' : 'multiple'}
                            metaKeySelection={false}
                            loading={this.state.isLoading}
                            style={this.state.totalRecords && this.state.totalRecords.length == 0 ?
                                { textAlign: 'center' }
                                : null}
                        >
                            {this.state.tableFields && this.state.tableFields.length ?
                                this.state.tableFields.map((item, i) => {
                                    return <Column key={item.field + i}
                                        style={{
                                            maxwidth: item.width,
                                            padding: 4,
                                        }}
                                        bodyStyle={{
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap', textAlign: item.textAlign ? item.textAlign : 'left'
                                        }}
                                        field={item.field}
                                        header={item.header}
                                        body={this.changeFieldValues}
                                        headerStyle={{
                                            padding: 4, fontWeight: 500, width: item.width, fontSize: 13,
                                            color: 'white', backgroundColor: config.templateColor
                                        }}
                                        filter={item.filter ? true : false}
                                        sortable={item.sortable ? true : false}
                                        filterPlaceholder={item.placeholder ? item.placeholder : 'search'}
                                        filterElement={item.filterElement ?
                                            <div style={{ height: 26 }}>
                                                <Dropdown style={{ minWidth: '10%', lineHeight: 1.1 }}
                                                    appendTo={document.body}
                                                    name={item.field}
                                                    value={this.state[item.field]}
                                                    options={item.filterElement}
                                                    onChange={(e) => this.onFilterChange(e, 'dropdownFilter')} />
                                            </div>

                                            : null}
                                        selectionMode={item.selectionMode}

                                    />
                                })
                                : null}
                        </DataTable>
                        {this.props.type != 'SuggestRules' ?
                            <Row className="paginationcss  mr-0">
                                <Col className="pr-0  col-12 col-md-9">
                                    <Paginator
                                        totalRecords={this.state.totalRecordsLength}
                                        first={this.state.first}
                                        rows={this.state.rows}
                                        rowsPerPageOptions={[10, 15, 20, 30, 50, 100]}
                                        onPageChange={this.onPageChange}
                                        style={{ padding: 10, backgroundColor: 'rgba(0,0,0,.03)', border: 'none' }}>
                                    </Paginator>
                                </Col>
                                <Col className="col-12 col-md-3 pt-3 text-center"
                                >
                                    <span style={{ fontSize: 13, fontFamily: "Open Sans,Helvetica Neue,sans-serif" }}>
                                        TOTAL RECORDS : {this.state.totalRecordsLength}</span>
                                </Col>
                            </Row>
                            : null}
                    </CardBody>
                </Card >
                {/* Add/Edit Modal */}
                {
                    this.state.isOpenFormModal || this.state.setFormRef ?
                        <FormModal
                            onRef={(ref) => (this.formModalRef = ref)}
                            openFormModal={this.state.isOpenFormModal}
                            closeFormModal={this.closeFormModal}
                            closeRejectModal={this.closeRejectModal}
                            type={this.props.type}
                            states={this.props.state}
                            formType={this.state.formType}
                            formFields={() => this.props.formFields ?
                                (this.props.type == 'Storage Unit Items' || this.props.type == 'Orders' ||
                                    this.props.type == 'Storage Unit') ? this.props.formFields(this.state.tabType) : this.props.formFields()
                                : null}
                            getDataFromServer={this.getDataFromServer}
                            apiUrl={this.state.apiUrl}
                            role={this.state.loginRole}
                            tabType={this.state.tabType}
                            companyName={this.state.companyName}
                            getDoctorPostingFields={this.props.getDoctorPostingFields}
                            categoryNames={this.props.categoryNames}
                            companiesList={this.props.companiesList}
                            companyId={this.state.companyId ? this.state.companyId : this.props.companyId ? this.props.companyId : null}
                            clinics={this.props.clinics}
                            clinicvalues={this.props.clinicvalues}
                            routeInfo={this.props.match}
                            addType={this.props.addType}
                            inventoryTypes={this.props.inventoryTypes}

                        />
                        : null
                }

                {/* Roles modal */}
                <RolesModal
                    onRef={(ref) => (this.roleModalRef = ref)}
                    openRolesModal={this.state.isOpenRolesModal}
                    closeRolesModal={this.closeRolesModal}
                    type={this.props.type}
                    formType={this.state.formType}
                    getDataFromServer={this.getDataFromServer}
                    apiUrl={this.state.apiUrl}
                />

                {/* Display Selected Row Info */}
                <ViewModal
                    type={this.props.type}
                    openViewModal={this.state.openViewModal}
                    rowData={this.state.rowData}
                    formFields={this.props.formFields}
                    onRef={(ref) => (this.viewModalRef = ref)}
                    closeViewModal={this.closeViewModal}
                    locationId={this.state.locationId}
                    locationName={this.state.locationName}
                    drawers={this.state.drawers}
                    displayData={this.state.displayData}
                />


                {/* Delete Confirmation */}
                <DeleteRowModal
                    openDeleteModal={this.state.openDeleteModal}
                    closeDeleteModal={this.closeDeleteModal}
                    deleteSelectedRow={this.deleteSelectedRow}
                    message={this.props.type === 'Facilities' ?
                        configMessages.FacilitiesDeleteMsg : configMessages.DeleteMsg}
                    type={this.props.type}
                />

                {/* Bulk Upload modal */}
                {
                    this.props.sample ?
                        <BulkUploadModal
                            onRef={(ref) => this.bulkUploadMoadalRef = ref}
                            bulkApi={this.props.bulkApi}
                            type={this.props.type}
                            sample={this.props.sample}
                            openBulkUploadModal={this.state.openBulkUploadModal}
                            closeBulkModal={this.closeBulkModal} />
                        : null
                }

                {/* Reject Modal */}
                <RejectModal
                    formType={this.state.formType}
                    onRef={(ref) => (this.rejectRef = ref)}
                    openRejectModal={this.state.openRejectModal}
                    closeRejectModal={this.closeRejectModal}
                    changeLeaveStatus={this.changeLeaveStatus}
                    leaveStatus={this.state.leaveStatus} />

                {/* Confirmation modal */}
                {
                    this.state.openConfirmationModal ?
                        <ConfirmationModal
                            formType={this.state.formType}
                            onRef={(ref) => (this.confirmRef = ref)}
                            openConfirmationModal={this.state.openConfirmationModal}
                            closeConfirmationModal={this.closeConfirmationModal}
                            confirm={this.clickConfirm}
                            text={this.state.confirmText}

                        />
                        : null
                }
                {
                    this.state.storageunititems ?
                        <Redirect to={`/${this.state.path}list/${this.state.redirectCompanyId}/${this.state.companyName}/${this.state.path}`} />
                        : null
                }
                {
                    this.state.inventorychecklist ?
                        <Redirect to={`/${this.state.path}list/${this.state.redirectCompanyId}/${this.state.companyName}/${this.state.path}`} />
                        : null
                }
                {
                    this.state.facilities ?
                        <Redirect to={`/facility/${this.state.redirectCompanyId}/${this.state.companyName}/${this.state.path}`} />
                        : null
                }
                {
                    this.state.locations ?
                        <Redirect to={`/carts/${this.state.redirectCompanyId}/${this.state.companyName}/${this.state.path}`} />
                        : null
                }
                {
                    this.state.expirationdates ?
                        <Redirect to={`/expirations/${this.state.redirectCompanyId}/${this.state.companyName}/${this.state.path}`} />
                        : null
                }
                {
                    this.state.missingItems ?
                        <Redirect to={`/missing/${this.state.redirectCompanyId}/${this.state.companyName}/${this.state.path}`} />
                        : null
                }
                {
                    this.state.recall ?
                        <Redirect to={`/recalls/${this.state.redirectCompanyId}/${this.state.companyName}/${this.state.path}`} />
                        : null
                }
                {
                    this.state.FacilityRedirect ?
                        <Redirect to={`/facility/${this.state.facilityCompany}/${this.state.companyName}/${this.state.path}`} />
                        : null
                }
                {
                    this.state.checks ?
                        <Redirect to={`/storageunitchecks/${this.state.redirectCompanyId}/${this.state.companyName}/${this.state.path}`} />
                        : null
                }
                {
                    this.state.batterychecks ?
                        <Redirect to={`/batterycheckitems/${this.state.redirectCompanyId}/${this.state.companyName}/${this.state.path}`} />
                        : null
                }
                {
                    this.state.CartLocationRedirect ?
                        <Redirect to={`/carts/${this.state.cartCompany}/${this.state.companyName}/${this.state.path}`} />
                        : null
                }
                {
                    this.state.track ?
                        <Redirect to={`/trackitems/${this.state.redirectCompanyId}/${this.state.companyName}/${this.state.path}`} />
                        : null
                }
                {
                    this.state.facilityStaff ?
                        <Redirect to={`/facilityStaffList/${this.state.redirectCompanyId}/${this.state.companyName}/${this.state.path}`} />
                        : null
                }
                {
                    this.state.orders ?
                        <Redirect to={`/orderlist/${this.state.redirectCompanyId}/${this.state.companyName}/${this.state.path}`} />
                        : null
                }
                {
                    this.state.facilitystaffroles ?
                        <Redirect to={`/facilityStaffRolesList/${this.state.redirectCompanyId}/${this.state.companyName}/${this.state.path}`} />
                        : null
                }
                {
                    this.state.activities ?
                        <Redirect to={`/activitylist/${this.state.redirectCompanyId}/${this.state.companyName}/${this.state.path}`} />
                        : null
                }
                {
                    this.state.categories ?
                        <Redirect to={`/categorieslist/${this.state.redirectCompanyId}/${this.state.companyName}/${this.state.path}`} />
                        : null
                }
                {/* {
                    this.state.productdirectory ?
                        <Redirect to={`/productslist/${this.state.redirectCompanyId}/${this.state.companyName}/${this.state.path}`} />
                        : null
                } */}
                {
                    this.state.distributors ?
                        <Redirect to={`/distributorslist/${this.state.redirectCompanyId}/${this.state.companyName}/${this.state.path}`} />
                        : null
                }
                {
                    this.state.back ?
                        <Redirect to={`/${this.state.path}`} />
                        : null
                }
                {
                    this.state.openOrderModal ?

                        <OrderModal
                            onRef={(ref) => (this.confirmRef = ref)}
                            openOrderModal={this.state.openOrderModal}
                            closeOrderModal={this.closeOrderModal}
                            product={this.state.orderProductName}
                            orderFrom={this.state.orderFrom}
                            orderClinic={this.state.orderClinic}
                            orderCart={this.state.orderCart}
                            orderClinicName={this.state.orderClinicName}
                            orderDescription={this.state.orderDescription}
                            orderConcentration={this.state.orderConcentration}
                            orderNeeded={this.state.orderNeeded}
                            companyId={this.state.companyId}
                            companyName={this.state.companyName}
                        />
                        : null
                }
                {
                    this.props.type == 'Storage Unit Items' || this.props.type == 'Recall Items' ?
                        <QrModal
                            openQrModal={this.state.openQrModal}
                            closeQrModal={this.closeQrModal}
                            selectedQtyQrCode={this.state.selectedQtyQrCode}
                            qrcode={this.state.qrcode}
                            lotId={this.state.lotId}
                        />
                        : null
                }

                {/* Session Expiration Modal */}
                <SessionModal openSessionModal={this.state.openSessionModal} />
            </div >
        );
    }
}
//setting fixed width for all badges
const dropdownOptionsStyle = {
    padding: 5,
    fontSize: 11,
    fontWeight: "500",
    color: "white",
    width: 80,
};
