import React, { PureComponent } from 'react';
import RolePermissions from '../../../DataTable/Permissions';

import PropTypes from 'prop-types';
import TableRender from '../../../DataTable/TableRender';
import apiCalls from '../../../../../config/apiCalls';



export default class Reports extends PureComponent {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    constructor() {
        super();
        this.state = {
            tabType: 'Payment',
            apiUrl: apiCalls.Payments,
            settingInfo: {},
        };
    }

    getTableFields = (tabType) => {
        let apiUrl = this.state.apiUrl;
        if (tabType == 'Payment') {
            apiUrl = apiCalls.Payments;
        } else if (tabType == 'Order and Invoice History') {
            apiUrl = apiCalls.History;
        }
        this.setState({
            tabType: tabType,
            apiUrl: apiUrl
        })
        let data = [];
        if (tabType == 'Payment') {
            data = this.getPaymentTableFields();
        } else if (tabType == 'Order and Invoice History') {
            data = this.getHistoyTableFields();
        } else {
            data = this.getPaymentTableFields();
        }
        return data;
    };
    getPaymentTableFields = () => {
        let data = [
            { textAlign: 'left', width: 100, field: 'companyName', header: 'Company Name', filter: false, sortable: true },
            { textAlign: 'center', width: 100, field: 'validateFrom', header: 'Invoice Date', filter: false, sortable: true },
            { textAlign: 'center', width: 100, field: 'billId', header: 'Invoice Id', filter: false, sortable: true },
            { textAlign: 'center', width: 100, field: 'validateTo', header: 'Trial Period', filter: false, sortable: true },
            { textAlign: 'center', width: 100, field: 'numberOfCarts', header: 'Number Of Carts', filter: false, sortable: true },
            { textAlign: 'center', width: 100, field: 'amount', header: 'Amount', filter: false, sortable: true },
            { textAlign: 'center', width: 100, field: 'status', header: 'Status', filter: false, sortable: true },
        ];
        return data;
    };


    getHistoyTableFields = () => {
        let data = [
            { textAlign: 'center', width: 100, field: 'paymentdate', header: 'Payment Date', filter: false, sortable: false },
            { textAlign: 'left', width: 100, field: 'invoiceid', header: 'Invoice Id', filter: false, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 100, field: 'paymentinstrument', header: 'Payment instrument', filter: false, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 100, field: 'transactiontype', header: 'Transaction type', filter: false, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 100, field: 'paymentmethod', header: 'Payment Method', filter: false, sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 100, field: 'transactionamount', header: 'Transaction Amount ', filter: false, sortable: true },
        ];
        // let screenPermissions = RolePermissions.screenPermissions('Payments');
        // if (screenPermissions && screenPermissions != 'edit') {
        //     data.pop();
        // }
        return data;
    };

    render() {
        return (
            <div>
                <TableRender type={'Payment'}
                    tableFields={this.getTableFields}
                    apiUrl={this.state.apiUrl}
                    tabType={this.state.tabType}
                    {...this.props}
                />
            </div>
        );
    }
}