import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Dropdown } from 'primereact/dropdown';
import fetchMethodRequest from '../../../config/service';
import apiCalls from '../../../config/apiCalls';

// File Upload
import FileUpload from '../../Form/components/FileUpload';

// config file
export default class BulkUploadModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: null
        };
        this.clickCount = 0;
    }

    componentDidMount = () => {
        if (this.props.onRef) {
            this.props.onRef(this);
        }
        if (this.props.type) {
            if (this.props.type.includes("Companies")) {
                this.setState({ type: this.props.type.slice(0, this.props.type.length - 9) })
            }
        }
        fetchMethodRequest('GET', apiCalls.Settings)
            .then(async (response) => {
                if (response && response.settings && response.settings[0]) {
                    let sampArr = []
                    if (response.settings[0]) {
                        for (let i = 0; i < response.settings[0].companies.length; i++) {
                            let obj = { "label": response.settings[0].companies[i], "value": response.settings[0].companies[i] }
                            sampArr.push(obj)
                        }
                    }
                    console.log("KKKKKKKKKKKKKKKKKKKK", sampArr)
                    this.setState({ Companies: sampArr })
                }
            });
    }

    componentWillUnmount = () => {
        if (this.props.onRef) {
            this.props.onRef(null);
        }
    }

    uploadToServer = () => {
        if (this.clickCount == 0) {
            this.clickCount++;
            this.fileUploadRef.UploadFileToServer();
        }
        this.setState({ value: null })
    }

    handleClickCount = () => {
        this.clickCount = 0;
    }
    onFilterChange = (e) => {
        console.log(":::::::::::::::::::::", e)

        console.log(":::::::::::::::::::::", e.target.value)
        this.setState({ value: e.target.value })
        console.log("thisssss", this.state.value)
    }
    closeBulkModal = () => {
        this.setState({ value: null })
        this.props.closeBulkModal()
    }
    render() {
        return (
            <Modal isOpen={this.props.openBulkUploadModal} className='bulkUploadModalHeight'>
                <ModalHeader style={{ fontWeight: '900', fontSize: 20 }}>Bulk Upload</ModalHeader>
                <ModalBody>
                    <div className='row m-2'>
                        <div className='col-5' style={{ paddingTop: 10, textAlign: 'left' }}>
                            Company
                        </div>
                        <div className='col-7' margin>
                            <Dropdown

                                appendTo={document.body}
                                name={"company"}
                                value={this.state.value}
                                options={this.state.Companies}
                                onChange={(e) => this.onFilterChange(e)}
                            />
                        </div>
                    </div>

                    {/* <div className='row'>
                        <div className='col-5'>
                            Sample Document
                        </div>
                        <div className='col-7'>
                            <a className='btn btn-primary outline' href={this.props.sample}>Download</a>
                        </div>
                    </div> */}
                    <div className='row'>
                        <div className='col-5'>
                            Please Attach File
                        </div>
                        <div className='col-7'>
                            <FileUpload type="bulkUpload"
                                onRef={(ref) => (this.fileUploadRef = ref)}
                                url={'quantities/bulkupload?type=' + this.props.bulkApi + "&company=" + this.state.value}
                                close={this.props.closeBulkModal}
                            />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter style={{ width: '370px' }}>
                    <Button color='primary' outline
                        onClick={this.closeBulkModal}>Close</Button>
                    <Button color='primary'
                        onClick={this.uploadToServer}>Upload - {this.state.type ? this.state.type : this.props.type}</Button>
                </ModalFooter>
            </Modal >
        );
    }
}