import React from 'react';
import RolePermissions from '../../../DataTable/Permissions';

// Table 
import TableRender from '../../../DataTable/TableRender';

//config file
import apiCalls from '../../../../../config/apiCalls';
import fetchMethodRequest from '../../../../../config/service';

export default class DoctorsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clinics: [],
            companiesList: []
        };
    }

    componentDidMount = async () => {
        await this.getRolesDrodownValues();
        await this.getCompaniesList();
        return fetchMethodRequest('GET', apiCalls.Clinics)
            .then(async (response) => {

                if (response && response.clinic) {
                    let modifiedclinicsNames = [];
                    for (let clinics of response.clinics) {
                        modifiedclinicsNames.push({
                            label: clinics.name,
                            value: clinics._id
                        })
                    }
                    this.setState({
                        clinics: modifiedclinicsNames
                    })
                }
            });

    }

    getCompaniesList = () => {
        return fetchMethodRequest('GET', apiCalls.Companies)
            .then(async (response) => {
                if (response && response.companies) {
                    let modifiedCompanyNames = [];
                    for (let company of response.companies) {
                        modifiedCompanyNames.push({
                            label: company.name,
                            value: {
                                companyId: company._id,
                                name: company.name
                            }
                        })
                    }
                    this.setState({
                        companiesList: modifiedCompanyNames
                    })
                }
            });
    }

    getTableFields = () => {
        let data = [
            { textAlign: 'left', width: 100, field: 'displayName', header: 'Name', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 125, field: 'email', header: 'Email', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 110, field: 'role', header: 'Permissioned Role', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 90, field: 'Actions', header: 'Actions', filter: false, sortable: false },
        ];

        let screenPermissions = RolePermissions.screenPermissions('Employees');
        if (screenPermissions && screenPermissions != 'edit') {
            data.pop();
        }
        return data;


    };
    getRolesDrodownValues = () => {
        return fetchMethodRequest('GET', apiCalls.AdminRoles)
            .then(async (response) => {
                if (response && response.roles && response.roles.length > 0) {
                    let modifiedRoleNames = [];
                    for (let role of response.roles) {

                        if (role.role != "Super Admin")
                            modifiedRoleNames.push({
                                label: role.role,
                                value: role.role
                            });
                    }
                    this.setState({
                        roles: modifiedRoleNames
                    })
                }
            });
    }
    getFormFields = () => {

        return ([
            { required: true, value: '', type: 'input', name: 'firstName', label: 'First Names', id: 'firstName', placeholder: 'First Name' },
            { required: true, value: '', type: 'input', name: 'lastName', label: 'Last Name', id: 'lastName', placeholder: 'Last Name' },
            { required: true, value: '', type: 'input', name: 'email', label: 'Email', id: 'email', placeholder: 'email@yopmail.com' },
            { required: false, value: '', type: 'text', name: 'phone', label: 'Phone Number', id: 'phone', placeholder: 'Phone Number' },
            {
                required: true, value: '', type: 'dropDown', name: 'role', label: 'Permissioned Role', id: 'role',
                options: this.state.roles, placeholder: 'Role'
            }
        ]);
    }

    render() {
        return (
            <div>
                <TableRender type='Employees'
                    globalSearch='Name/E-mail'
                    tableFields={this.getTableFields}
                    formFields={this.getFormFields} {...this.props}
                    companiesList={this.state.companiesList}
                />
            </div>
        );
    }
}