import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import FacilityStaffTable from './components/FacilityStaffTable'

const FacilityStaffTableList = (props) => (

    <Container>
        <FacilityStaffTable {...props} />
    </Container>
);

FacilityStaffTableList.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(FacilityStaffTableList);