import config from '../../config/config';
import configMessages from '../../config/configMessages';
import dateFormats from '../UI/FormatDate/formatDate';

const validate = (values, props) => {
  let fields = [];
  // get formFields from props and set validation  fields
  if (props.formFields) {
    fields = [];
    let formFields = props.formFields();
    if (formFields && formFields.length > 0) {
      formFields.forEach(formField => {
        if (formField.required) {
          fields.push(formField.name);
        }
      });
    }
    console.log("sdfs", formFields)
  }
  const errors = {};
  let checkRegExpFieldsArray = ['aadharcardNumber', 'companyID', 'productName', 'paymentAmount', 'pancardNumber', 'email', 'companyEmail', 'phoneNumber', 'password'];

  fields.forEach((field) => {
    if (!values[field]) {
      console.log("sdfs")
      errors[field] = configMessages.fillField;

    } else if (checkRegExpFieldsArray.includes(field)) {
      let regex = config[field + "Regex"];
      if (field == 'companyEmail') {
        regex = config['email' + "Regex"];
      }
      if (field == 'paymentAmount') {
        console.log("Hi")
        regex = config['number' + "Regex"];
      }
      if (values[field] && typeof (values[field]) == 'string' && regex.test(values[field].trim()) == false) {
        if (field == 'companyEmail') {
          errors[field] = configMessages['email'];
        } else if (field == 'paymentAmount') {
          console.log("Hi")
          errors[field] = configMessages['numberValidation'];
        } else {
          errors[field] = configMessages[field];
        }
      }

    }
  });

  let todayDate = dateFormats.formatDate(new Date(), config.dateDayMonthFormat);
  let compare;
  if (values.dateOfBirth) {
    compare = dateFormats.datesComparisionBefore(todayDate, values.dateOfBirth);
    if (compare) {
      errors.dateOfBirth = configMessages.InvalidDate;
    }
  }

  if (values.endDate && values.startDate) {
    compare = dateFormats.datesComparisionBefore(values.endDate, values.startDate);
    if (compare) {
      errors['endDate'] = configMessages.InvalidDate;
    } else {
      errors['endDate'] = '';
    }
  }
  if (values.toDate && values.fromDate) {
    compare = dateFormats.datesComparisionBefore(values.toDate, values.fromDate);
    if (compare) {
      errors['toDate'] = configMessages.InvalidDate;
    } else {
      errors['toDate'] = '';
    }
  }
  if (values.joinDate) {
    if (values.dateOfBirth) {
      let years = dateFormats.differenceBetweenDatesInYears(values.joinDate, values.dateOfBirth);
      if (years < 15) {
        errors.joinDate = configMessages.InvalidDate;
      } else {
        errors.joinDate = '';
      }
    }
  }
  return errors;
};




export default validate;

