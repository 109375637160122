import React from 'react';
import SendIcon from 'mdi-react/SendIcon';
import { Row, Col } from 'reactstrap';

import { Field } from 'redux-form';

const noProfileImage = require('../../../../../assets/img/profile/userProfile.png');

import DefaultInput from '../../../../../shared/components/form/DefaultInput';

import fetchMethodRequest from '../../../../../config/service';
import apiCalls from '../../../../../config/apiCalls';
import config from '../../../../../config/config';

import showToasterMessage from '../../../../UI/ToasterMessage/toasterMessage';

import dateFormats from '../../../../UI/FormatDate/formatDate';

export default class TicketCommentsInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            comments: '',
            selectedRowComments: []
        };
    }

    componentDidMount = () => {
        this.props.onRef(this);
    }

    componentWillUnmount = () => {
        this.props.onRef(null);
    }

    handleComments = (comments) => {
        if (comments) {
            this.setState({
                comments: comments
            })
        }
    }

    // Get Comments info for selected ticket
    getselectedRowCommentsData = (rowData) => {
        if (rowData && rowData.comments) {
            this.setState({
                selectedRowComments: rowData.comments
            })
        }
    }

    // send comment message to servr
    sendCommentsDataToServer = () => {
        if (this.state.comments) {
            this.props.getIsLoading(true);
            let userBody = {
                comments: [
                    {
                        message: this.state.comments
                    }
                ]
            }
            return fetchMethodRequest('POST', `${apiCalls.Ticketreply}?ticketId=${this.props.selectedRowDataID}&response=true`, userBody)
                .then(async (response) => {
                    if (response) {
                        if (response.respCode && response.respCode === 205) {
                            showToasterMessage(response.respMessage, 'success');
                            this.props.isCommentsUpdated();
                            if (response.details) {
                                this.getselectedRowCommentsData(response.details);
                            }
                        } else if (response && response.errorMessage) {
                            showToasterMessage(response.errorMessage, 'error');
                        }
                        this.props.getIsLoading(false);
                    }
                }).catch((err) => {
                    return err;
                });
        }

    }

    render() {
        const { selectedRowComments } = this.state;
        return (
            <div >
                <div style={{ maxHeight: 150, overflowY: 'auto', overflowX: 'hidden' }}>
                    {selectedRowComments && selectedRowComments.length > 0 ?
                        selectedRowComments.map((item, index) => {
                            return item.postedBy ?
                                item.postedBy.user ?
                                    <div className='row text-left' key={index}>
                                        <img src={noProfileImage} className='topbar__avatar-img mr-2' style={{ border: `2px solid ${config.templateColor}` }} />
                                        <div className="botmessagesText mt-1">
                                            <div className='row'>
                                                <div className="col-4" style={{ fontSize: 11 }}>
                                                    <small>{dateFormats.formatDate(item.created, config.descDateFromat)}</small>
                                                </div>
                                                <div className="col-8" style={{ fontSize: 14, textAlign: 'right' }}>
                                                    <small style={{ paddingRight: 5 }}>{item.postedBy.user.displayName ?
                                                        item.postedBy.user.displayName : null}</small>
                                                </div>
                                            </div>
                                            <div key={item._id} style={{ paddingBottom: 5 }}>{item.message}</div>

                                        </div>

                                    </div> :
                                    item.postedBy.employee ?
                                        <div className='row ' style={{ justifyContent: 'flex-end', marginRight: 10 }} key={index}>
                                            <div className='usermessagesText'>
                                                <Row>
                                                    <div className="col-4 pr-1" style={{ fontSize: 11 }}>
                                                        <small>{dateFormats.formatDate(item.created, config.descDateFromat)}</small>
                                                    </div>
                                                    <div className="col-8" style={{ fontSize: 14, textAlign: 'right' }}>
                                                        <small style={{ paddingRight: 5 }}>{item.postedBy.employee.displayName ?
                                                            item.postedBy.employee.displayName : null}</small>
                                                    </div>
                                                </Row>
                                                <div key={item._id} style={{ paddingBottom: 5 }}>{item.message}</div>
                                            </div>
                                            <img src={noProfileImage}
                                                className='topbar__avatar-img mr-2'
                                                style={{ border: `2px solid ${config.templateColor}` }} />

                                        </div>
                                        : null
                                : null

                        })
                        : null}
                </div>
                <div className="form__form-group" >
                    <label className="form__form-group-label"></label>
                    <div className="form__form-group-field">
                        <Field name={'comment'}
                            component={DefaultInput}
                            placeholder={'Enter Comments'}
                            handleComments={this.handleComments}
                        />
                        <div className={"iconstyle form__form-group-icon"}
                            style={{ cursor: 'pointer' }}
                            onClick={this.sendCommentsDataToServer}><SendIcon />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}