import React from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import * as PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import configMessage from '../../../../config/configMessages';
import CreditCardIcon from 'mdi-react/CreditCardIcon';
import RadioButton from '../../../../shared/components/form/RadioButton';
import { Link } from 'react-router-dom';
const required = value => (value || typeof value === 'string' ? undefined : configMessage.fillField)
const MasterCard = `${process.env.PUBLIC_URL}/img/for_store/cards/mc.svg`;
const VisaCard = `${process.env.PUBLIC_URL}/img/for_store/cards/visa.svg`;
const PayPal = `${process.env.PUBLIC_URL}/img/for_store/cards/paypal.svg`;

const renderField = ({
  input, placeholder, type, mask,
}) => (
    <MaskedInput {...input} placeholder={placeholder} type={type} mask={mask} />
  );

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  mask: PropTypes.arrayOf(PropTypes.any),
};

renderField.defaultProps = {
  placeholder: '',
  type: 'text',
  mask: [],
};

const PaymentForm = props => {

  const { handleSubmit } = props
  return (
    <form className="form payment__credit-cards" onSubmit={handleSubmit}>
      <div className="form__form-group">
        <span className="form__form-group-label">Choose payment method:</span>
        <div className="form__form-group-field">
          <div className="payment__credit-card">
            <Field
              name="card"
              component={RadioButton}
              // eslint-disable-next-line
              label={<div><img className="payment__credit-card_img" src={MasterCard} alt="mc" /><p className="payment__credit-name">MasterCard</p></div>}
              radioValue="russian_post"

            />
          </div>
          <div className="payment__credit-card">
            <Field
              name="card"
              component={RadioButton}
              // eslint-disable-next-line
              label={<div><img className="payment__credit-card_img" src={VisaCard} alt="visa" /><p className="payment__credit-name">Visa</p></div>}
              radioValue="dhl"
            />
          </div>
          <div className="payment__credit-card">
            <Field
              name="card"
              component={RadioButton}
              // eslint-disable-next-line
              label={<div><img className="payment__credit-card_img" src={PayPal} alt="paypal" /><p className="payment__credit-name">PayPal</p></div>}
              radioValue="mail_priority"
            />
          </div>
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Card number</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <CreditCardIcon />
          </div>
          <Field
            name="card_number"
            component={renderField}
            type="text"
            mask={[/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholder="xxxx-xxxx-xxxx-xxxx"
            validate={[required]}
          />
        </div>
      </div>
      <div className="form__form-group-date-cvc">
        <div className="form__form-group form__form-group-date">
          <span className="form__form-group-label">Expiration Date</span>
          <div className="form__form-group-field">
            <Field
              name="date"
              component={renderField}
              type="text"
              mask={[/[0-3]/, /\d/, '/', /[0-1]/, /\d/, '/', /\d/, /\d/]}
              placeholder="DD/MM/YY"
              validate={[required]}
            />
          </div>
        </div>
        <div className="form__form-group form__form-group-cvc">
          <span className="form__form-group-label">CVC</span>
          <div className="form__form-group-field">
            <Field
              name="cvc"
              component={renderField}
              type="text"
              mask={[/\d/, /\d/, /\d/]}
              validate={[required]}
            />
          </div>
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Cardholder name</span>
        <div className="form__form-group-field">
          <Field
            name="name"
            component="input"
            type="text"
            placeholder="Name and Surname"
            validate={[required]}
          />
        </div>
      </div>

      <ButtonToolbar className="form__button-toolbar m-auto">
        <Button color="primary" type="submit">Make Payment</Button>
        <Link to={'/Payment'}>
          <Button style={{ marginLeft: '10px' }} color='primary'
          >Back</Button>
        </Link>
      </ButtonToolbar>

    </form>
  )
};

PaymentForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'payment_form', // a unique identifier for this form
})(PaymentForm);
