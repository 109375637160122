import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CompanyTable from './components/Companies'


const CompanyTableList = (props) => (

    <Container>
        <CompanyTable {...props} />
    </Container>
);

CompanyTableList.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(CompanyTableList);

