import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Permissions from './components/permissions'

const PermissionsTable = (props) => (

    <Container>
        <Permissions {...props} />
    </Container>
);

PermissionsTable.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(PermissionsTable);

