import React from "react";
import { Button, Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import { load as loadAccount } from "../../../../redux/reducers/commonReducer";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import apiCalls from "../../../../config/apiCalls";
import OrderModal from "../../Modals/OrderModal";
import fetchMethodRequest from "../../../../config/service";
import AddInventoryModal from "../../Modals/AddInventoryModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TableRender from "../../DataTable/TableRender";
import DeleteRowModal from "../../Modals/DeleteRowModal";
import Icon from "mdi-react/ArrowLeftIcon";

import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
// Loader
import Loader from "../../../App/Loader";
import FormModal from "../../../Form/FormModal";
import config from "../../../../config/config";
import configMessages from "../../../../config/configMessages";

let Num = 0;
export default class ViewModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: {},
      label: "",
      status: "",
      ShiftType: null,
      isOpenSuggestModal: false,
      suggestGroupInfo: [],
      selectedDate: null,
      type: null,
      openOrderModal: false,
      showInfo: false,
      openInventoryModal: false,
      delete: false,
      openDeleteModal: false,
      type: "Inventory Checks",
      storageUnit: {},
      locationType: null,
      isLoading: true,
      previousData: {}
    };
  }

  componentDidMount = async () => {
    if (this.props.match.params) {
      await this.setState({
        companyName: this.props.match.params.name,
        companyId: this.props.match.params.company,
        backPath: this.props.match.params.path,
      });
    }
    await this.getData();
    await this.getClinicDropDownValues();
    if (this.props.location.pathname !== "/inventorychecklist") {
      let cartName = this.props.location.pathname.slice(
        20,
        this.props.location.pathname.length
      );
      return fetchMethodRequest("GET", apiCalls.Locations).then(
        async (response) => {
          if (response && response.locations) {
            for (let value of response.locations) {
              if (value.cart == cartName) {
                this.getDrawerDropdownValues(value._id, value.cart);
              }
            }
          }
        }
      );
    }
  };

  getClinicDropDownValues = async () => {
    let loginInfo = JSON.parse(localStorage.loginCredentials);
    if (loginInfo.clinics && loginInfo.clinics.length > 0 && loginInfo.clinics[0] != "") {
      let modifiedClinics = [];
      loginInfo.clinics.map((item, i) => {
        modifiedClinics.push({
          label: item.name,
          value: item.clinicId,
        });
      });

      await this.setState({
        clinics: modifiedClinics,
      });
    } else {
      return fetchMethodRequest("GET", apiCalls.Clinics).then(
        async (response) => {
          if (response && response.clinics && response.clinics[0]) {
            if (response.clinics[0].name) {
              let clinics = response.clinics;
              let modifiedClinicNames = [];
              for (let clinic of clinics) {
                modifiedClinicNames.push({
                  label: clinic.name,
                  value: clinic._id,
                });
              }
              await this.setState({
                clinics: modifiedClinicNames,
              });
            }
          }
        }
      );
    }
  };

  getRowData = async (rowData) => {
    if (this.props.type == "Storage Unit") {
      this.props.change("inventoryType", "EXTERNAL");
      await this.getDrawerDropdownValues();
    }
    this.setState({
      rowData: rowData,
    });
  };

  getData = async () => {
    return fetchMethodRequest(
      "GET",
      `${apiCalls.ItemsCount}?company_id=${this.state.companyId}`
    ).then(async (response) => {
      if (response) {
        this.setState({
          storageUnit: response.totalItems,
          isLoading: false,
        });
        if (response.errorMessage) {
          showToasterMessage(response.errorMessage, "error");
        }
      }
    });
  };

  openFormModal = (item, cart, cartSubLocation, project) => {
    let { clinics, storageUnit } = this.state;
    let oldLocationId,
      oldClinicId,
      oldCartName = project.cartName,
      oldLocationType = project.locationType,
      locations = [];
    if (clinics && clinics.length) {
      clinics.forEach((item, index) => {
        if (item && project && item.label == project.facilityName) {
          oldClinicId = item.value;
        }
      });
    }
    if (storageUnit && oldLocationType && storageUnit[oldLocationType]) {
      storageUnit[oldLocationType].forEach((item, index) => {
        if (item && project && item.facilityName == project.facilityName) {
          oldLocationId = item._id;
        }
      });
    }
    let previousData = {
      oldLocationId: oldLocationId,
      oldClinicId: oldClinicId,
      oldLocationType: oldLocationType,
      oldCartName: oldCartName
    };
    this.setState({
      productName: item,
      cart: cart,
      cartSubLocation: cartSubLocation,
      isOpenFormModal: true,
      previousData: previousData,
    });
  };

  closeDeleteModal = () => {
    this.setState({
      openDeleteModal: false,
    });
  };

  deleteConfirmAction = (item, cart, cartSubLocation, description) => {
    let body = {
      title1: item,
      cart: cart,
      SubLocation: cartSubLocation,
      description: description,
    };
    this.setState({
      openDeleteModal: true,
      body: body,
    });
  };

  deleteSelectedRow = (type) => {
    if (this.state.companyId) {
      return fetchMethodRequest(
        "POST",
        `${apiCalls.Delete}?company_id=${this.state.companyId}&type=${type}`,
        this.state.body
      )
        .then(async (response) => {
          this.setState({
            openDeleteModal: false,
          });
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
          this.getDrawerDropdownValues(
            this.state.id,
            this.state.cartNumber,
            this.state.facilityName
          );
        })
        .catch((err) => {
          return err;
        });
    }
  };

  makeFalse = () => {
    this.setState({
      delete: false,
    });
    this.getDrawerDropdownValues(
      this.state.id,
      this.state.cartNumber,
      this.state.facilityName
    );
  };

  closeFormModal = () => {
    this.setState({
      isOpenFormModal: false,
    });
    this.getDrawerDropdownValues(
      this.state.id,
      this.state.cartNumber,
      this.state.facilityName
    );
  };

  closeInventoryModal = (name) => {
    this.setState({
      openInventoryModal: false,
    });
    this.getDrawerDropdownValues(
      this.state.id,
      this.state.cartNumber,
      this.state.facilityName
    );
  };
  openInventoryModal = async (cart) => {
    await this.setState({
      openInventoryModal: true,
      inventoryCartName: cart,
    });
  };

  goBack = () => {
    this.setState({
      showInfo: false,
    });
    this.getData();
  };

  openOrderModal = async (
    product,
    subcart,
    description,
    needed,
    concentration
  ) => {
    await this.setState({
      openOrderModal: true,
      orderProductName: product,
      orderFrom: "Inventory Check List",
      orderCart: this.state.cartNumber,
      orderSubCart: subcart,
      orderDescription: description,
      orderConcentration: concentration,
      orderNeeded: needed,
    });
  };

  closeOrderModal = (type) => {
    if (type == "save") {
      this.getDrawerDropdownValues(
        this.state.id,
        this.state.cartNumber,
        this.state.facilityName
      );
    }
    this.setState({
      openOrderModal: false,
    });
  };
  backButton = async () => {
    if (this.state.backPath) {
      await this.setState({
        back: true,
      });
    }
  };

  getDrawerDropdownValues = (id, cartName, facility, locationType) => {
    if (id) {
      this.setState({
        isLoading: true,
        id: id,
        facilityName: facility,
        cartNumber: cartName,
        type: "Not",
      });
      if (
        locationType == "crashcart" ||
        locationType == "storage" ||
        locationType == "operatingRoom"
      ) {
        this.setState({
          locationType:
            locationType == "crashcart"
              ? "Cart Location"
              : locationType == "storage"
                ? "Supply Cabinet"
                : "OR Suite",
        });
      }
      return fetchMethodRequest(
        "GET",
        `${apiCalls.CartView}?locationId=${id}`
      ).then(async (response) => {
        if (response) {
          let locations = [];
          let arr = [];
          response.map((project) => {
            if (project && project.data && project.data.length) {
              project.data.map((item) => locations.push(item));
            }
          }

          );
          this.setState({
            info: locations,
            showInfo: true,
          });
          for (let item of this.state.info) {
            if (arr.indexOf(item._id.cartSubLocation) === -1) {
              arr.push(item._id.cartSubLocation);
            }
          }
          let modifiedLocations = arr.sort();
          for (let item of modifiedLocations) {
            if (item == "TOP OF CART") {
              let old_index = modifiedLocations.indexOf(item);
              modifiedLocations.splice(
                0,
                0,
                modifiedLocations.splice(old_index, 1)[0]
              );
            } else if (
              modifiedLocations.indexOf("TOP OF CART") == -1 &&
              item == "SIDE OF CART"
            ) {
              let old_index = modifiedLocations.indexOf(item);
              modifiedLocations.splice(
                0,
                0,
                modifiedLocations.splice(old_index, 1)[0]
              );
            } else if (
              modifiedLocations.indexOf("TOP OF CART") != -1 &&
              item == "SIDE OF CART"
            ) {
              let old_index = modifiedLocations.indexOf(item);
              modifiedLocations.splice(
                0,
                0,
                modifiedLocations.splice(old_index, 1)[0]
              );
            } else if (
              modifiedLocations.indexOf("TOP OF CART") == -1 &&
              modifiedLocations.indexOf("SIDE OF CART") == -1 &&
              item == "BACK OF CART"
            ) {
              let old_index = modifiedLocations.indexOf(item);
              modifiedLocations.splice(
                0,
                0,
                modifiedLocations.splice(old_index, 1)[0]
              );
            } else if (
              modifiedLocations.indexOf("SIDE OF CART") == -1 &&
              item == "BACK OF CART"
            ) {
              let old_index = modifiedLocations.indexOf(item);
              modifiedLocations.splice(
                0,
                0,
                modifiedLocations.splice(old_index, 1)[0]
              );
            } else if (
              modifiedLocations.indexOf("TOP OF CART") == -1 &&
              item == "BACK OF CART"
            ) {
              let old_index = modifiedLocations.indexOf(item);
              modifiedLocations.splice(
                0,
                0,
                modifiedLocations.splice(old_index, 1)[0]
              );
            } else if (
              modifiedLocations.indexOf("TOP OF CART") != -1 &&
              modifiedLocations.indexOf("SIDE OF CART") != -1 &&
              item == "BACK OF CART"
            ) {
              let old_index = modifiedLocations.indexOf(item);
              modifiedLocations.splice(
                0,
                0,
                modifiedLocations.splice(old_index, 1)[0]
              );
            }
          }
          this.setState({
            headerInfo: modifiedLocations,
            isLoading: false,
          });
        }
      });
    }
  };

  render() {
    return (
      <Container className="dashboard">
        <Loader loader={this.state.isLoading} />
        {!this.state.showInfo ? (
          <div>
            <Row>
              <Col lg={12} xl={12}>
                <Card className="inventoryCard">
                  <CardBody>
                    <div className="text-center topbar__center">
                      {this.state.companyName}
                    </div>
                    <div className="card__title mt-0">
                      <h5 className="bold-text">Inventory Check List</h5>
                    </div>
                    <span>
                      <span
                        style={{ fontSize: 17, paddingTop: 10 }}
                        title={"Back"}
                        onClick={() => this.backButton()}
                        className="mb-2"
                      >
                        <Icon className="back" />
                      </span>
                    </span>
                    {this.state.storageUnit &&
                      Object.keys(this.state.storageUnit).length > 0
                      ? Object.keys(this.state.storageUnit).map(
                        (item, index) => {
                          return (
                            <Table
                              className="table--bordered table--head-accent mb-4"
                              hover
                              key={index}
                            >
                              <thead className="inventory-check-thead">
                                <tr
                                  style={{ background: config.templateColor }}
                                >
                                  <th
                                    style={{
                                      width: 50 + "%",
                                      textAlign: "center",
                                      color: "whitesmoke",
                                      borderRight: "1px solid white",
                                    }}
                                  >
                                    {item == "crashcart"
                                      ? "Cart Location"
                                      : item == "storage"
                                        ? "Supply Cabinet"
                                        : "OR Suite"
                                    }
                                  </th>
                                  <th
                                    style={{
                                      width: 50 + "%",
                                      textAlign: "center",
                                      color: "whitesmoke",
                                    }}
                                  >
                                    No of Items
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="inventory-check-tbody">
                                {this.state.storageUnit[item] &&
                                  this.state.storageUnit[item].length > 0 ? (
                                  this.state.storageUnit[item].map(
                                    (location, index) => {
                                      return (
                                        <tr key={index}>
                                          <td
                                            className="inventoryCheckRowTextColor storageTypeCount_hover"
                                            style={{ width: 50 + "%" }}
                                            onClick={() =>
                                              this.getDrawerDropdownValues(
                                                location["_id"],
                                                location.cartName,
                                                location.facilityName,
                                                item
                                              )
                                            }
                                          >
                                            <span color="info">
                                              {location.cartName}
                                            </span>
                                          </td>
                                          <td
                                            className="inventoryCheckRowTextColor"
                                            style={{ width: 50 + "%" }}
                                          >
                                            {location.count}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )
                                ) : (
                                  <tr>
                                    <td className="text-center">
                                      No data found.
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          );
                        }
                      )
                      : null}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        ) : (
          <div>
            <Row>
              <Col lg={12} xl={12}>
                <Card className="inventoryCard">
                  <CardBody>
                    <div className="card__title">
                      <h5 className="bold-text">Inventory Check List</h5>
                    </div>
                    <div style={{ float: "right", marginRight: 10 }}>
                      <Button
                        className="ml-auto"
                        color="primary"
                        outline
                        onClick={() =>
                          this.openInventoryModal(this.state.cartNumber)
                        }
                      >
                        Add
                      </Button>
                    </div>
                    <span className="mb-2" onClick={() => this.goBack()}>
                      <Icon />
                    </span>
                    <br />
                    <div
                      className="checkListdiv"
                      style={this.state.company ? { paddingRight: 150 } : null}
                    >
                      <div style={{ fontSize: 14, paddingLeft: 40 }}>
                        <span
                          style={{
                            color: config.templateColor,
                            fontWeight: 550,
                          }}
                        >
                          {this.state.locationType}
                        </span>{" "}
                        : {this.state.cartNumber}
                        <br />
                        {this.state.facilityName ? (
                          <div>
                            <span
                              style={{
                                color: config.templateColor,
                                fontWeight: 550,
                              }}
                            >
                              Facility
                            </span>{" "}
                            : {this.state.facilityName}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {this.state.headerInfo
                      ? this.state.headerInfo.map((item, index) => (
                        <Table
                          className="table--bordered table--head-accent mb-4"
                          hover
                          key={index.toString()}
                        >
                          {/* <span className="checkListspan">{item}</span> */}
                          <thead className="inventory-check-thead">
                            <tr style={{ background: config.templateColor }}>
                              <th
                                className="inventoryCheckRow"
                                style={{ width: "12%" }}
                              >
                                Product
                              </th>
                              <th
                                className="inventoryCheckRow"
                                style={{ width: "12%" }}
                              >
                                Description
                              </th>
                              <th
                                className="inventoryCheckRow"
                                style={{ width: "10%" }}
                              >
                                Volume
                              </th>
                              <th
                                className="inventoryCheckRow"
                                style={{ width: "14%" }}
                              >
                                Required Inventory
                              </th>
                              <th
                                className="inventoryCheckRow"
                                style={{ width: "13%" }}
                              >
                                Current Inventory
                              </th>
                              <th
                                className="inventoryCheckRow"
                                style={{ width: "8%" }}
                              >
                                Need To Order
                              </th>
                              <th
                                className="inventoryCheckRow"
                                style={{ width: "15%" }}
                              >
                                Expired
                              </th>
                              <th
                                className="inventoryCheckRow"
                                style={{ width: "6%" }}
                              >
                                Actions
                              </th>
                              <th
                                className="inventoryCheckRow"
                                style={{ width: "10%" }}
                              >
                                Order
                              </th>
                            </tr>
                          </thead>
                          <tbody className="inventory-check-tbody">
                            {this.state.info && this.state.info.length > 0
                              ? this.state.info.map((project, i) => {
                                if (project &&
                                  project._id &&
                                  project._id.cartSubLocation &&
                                  project._id.cartSubLocation == item) {
                                  return (
                                    <tr key={i}>
                                      <td
                                        className="inventoryCheckRowTextColor"
                                        style={{ width: "12%" }}
                                      >
                                        {project.productName}
                                      </td>
                                      <td
                                        className="inventoryCheckRowTextColor"
                                        style={{ width: "12%" }}
                                      >
                                        {project.description}
                                      </td>
                                      <td
                                        className="inventoryCheckRowTextColor"
                                        style={{ width: "10%" }}
                                      >
                                        {project.volume}
                                      </td>

                                      <td
                                        className="inventoryCheckRowTextColor"
                                        style={{ width: "14%" }}
                                      >
                                        {project.limit}
                                      </td>
                                      <td
                                        className="inventoryCheckRowTextColor"
                                        style={{ width: "13%" }}
                                      >
                                        {project.count}
                                      </td>
                                      <td
                                        className="inventoryCheckRowTextColor"
                                        style={{ width: "8%" }}
                                      >
                                        {project.limit && project.count >= 0
                                          ? project.limit - project.count >=
                                            0
                                            ? project.limit - project.count
                                            : "-"
                                          : null}
                                      </td>

                                      <td
                                        className="inventoryCheckRowTextColor"
                                        style={{ width: "15%" }}
                                      >
                                        {project.expiringInFiveWeeks}
                                      </td>
                                      <td
                                        className="inventoryCheckRowTextColor"
                                        style={{ width: "6%" }}
                                      >
                                        {
                                          <div>
                                            {" "}
                                            <FontAwesomeIcon
                                              className="genderIconAlignment"
                                              color="white"
                                              icon="edit"
                                              data-toggle="tool-tip"
                                              title="Edit"
                                              style={{
                                                color: "#024a88",
                                                width: "15",
                                              }}
                                              onClick={() =>
                                                this.openFormModal(
                                                  project.title1,
                                                  this.state.cartNumber,
                                                  item,
                                                  project
                                                )
                                              }
                                            />
                                            <FontAwesomeIcon
                                              className="genderIconAlignment"
                                              color="white"
                                              icon="trash-alt"
                                              data-toggle="tool-tip"
                                              title="Delete"
                                              style={{
                                                color: "#bf1725",
                                                width: "13",
                                                marginLeft: 10,
                                              }}
                                              onClick={() =>
                                                this.deleteConfirmAction(
                                                  project.title1,
                                                  this.state.cartNumber,
                                                  item,
                                                  project.description
                                                )
                                              }
                                            />
                                          </div>
                                        }
                                      </td>

                                      <td
                                        className="inventoryCheckRowTextColor"
                                        style={{ width: "10%" }}
                                      >
                                        <Button
                                          className="add_to_orders_btn"
                                          color={
                                            project.isOrdered
                                              ? "danger"
                                              : "primary"
                                          }
                                          onClick={() =>
                                            this.openOrderModal(
                                              project.productName,
                                              item,
                                              project.description,
                                              project.limit - project.count,
                                              project.volume
                                            )
                                          }
                                          outline
                                        >
                                          <span style={{ fontSize: 12 }}>
                                            Add&nbsp;to
                                          </span>
                                          <span style={{ fontSize: 12 }}>
                                            {" "}
                                            Orders&nbsp;List
                                          </span>
                                        </Button>
                                      </td>
                                    </tr>
                                  );
                                }
                              })
                              : null}
                          </tbody>
                        </Table>
                      ))
                      : null}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}

        <DeleteRowModal
          openDeleteModal={this.state.openDeleteModal}
          closeDeleteModal={this.closeDeleteModal}
          deleteSelectedRow={this.deleteSelectedRow}
          type={"Storage Unit Items"}
          message={configMessages.DeleteMsg}
        />
        {this.state.openOrderModal ? (
          <OrderModal
            onRef={(ref) => (this.confirmRef = ref)}
            openOrderModal={this.state.openOrderModal}
            closeOrderModal={this.closeOrderModal}
            product={this.state.orderProductName}
            orderFrom={this.state.orderFrom}
            orderCart={this.state.orderCart}
            orderDescription={this.state.orderDescription}
            orderConcentration={this.state.orderConcentration}
            orderNeeded={
              this.state.orderNeeded >= 0 ? this.state.orderNeeded : 0
            }
            companyId={this.state.companyId}
            companyName={this.state.companyName}
          />
        ) : null}
        {this.state.back ? <Redirect to={`/${this.state.backPath}`} /> : null}
        {this.state.openInventoryModal ? (
          <AddInventoryModal
            onRef={(ref) => (this.confirmRef = ref)}
            openInventoryModal={this.state.openInventoryModal}
            closeInventoryModal={this.closeInventoryModal}
            companyId={this.state.companyId}
            companyName={this.state.companyName}
            cart={this.state.inventoryCartName}
            facility={this.state.facilityName}
            id={this.state.id}
            locationType={this.state.locationType}
          />
        ) : null}
        {this.state.isOpenFormModal ? (
          <FormModal
            onRef={(ref) => (this.formModalRef = ref)}
            openFormModal={this.state.isOpenFormModal}
            closeFormModal={this.closeFormModal}
            type={"Storage Unit Items"}
            formType={"edit"}
            id={"Checks"}
            title1={this.state.productName}
            cart={this.state.cart}
            apiUrl={"quantities/changeQuantity"}
            inventoryCartName={this.state.cartNumber}
            cartSubLocation={this.state.cartSubLocation}
            clinicvalues={this.state.clinics}
            companyId={this.state.companyId}
            previousData={this.state.previousData}
          />
        ) : null}
        {this.state.delete ? (
          <TableRender
            id={"checks"}
            title={this.state.productName}
            lotId={this.state.lotId}
            makeFalse={this.makeFalse}
          />
        ) : null}
      </Container>
    );
  }
}

ViewModal = reduxForm({
  form: "View Form", // a unique identifier for this form
})(ViewModal);

// You have to connect() to any reducers that you wish to connect to yourself
ViewModal = connect(
  (state) => ({
    initialValues: state.commonData.data, // pull initial values from account reducer
  }),

  { load: loadAccount } // bind account loading action creator
)(ViewModal);
