import config from './config';
import configMessages from './configMessages';

import showToasterMessage from '../containers/UI/ToasterMessage/toasterMessage';

let headers = config.headers;

// To get logged in user token
const getLoggedInuserToken = () => {
    let userData = localStorage.getItem('loginCredentials');
    userData = JSON.parse(userData);
    if (userData && userData.accessToken) {
        headers.Authorization = `Bearer ${userData.accessToken}`;
    }
    return headers;
}


const sendRequestToServer = async (method, url, body, headers, type) => {
    let reqHeaders = { ...headers };
    let reqInfo = await formatBody(method, url, body, reqHeaders, type);
    let requestInfo = {
        method: method,
        ...reqInfo
    };

    // console.log(method, body, `${config.apiUrl}${url}`);
    // console.log(reqHeaders);

    return fetch(`${config.apiUrl}${url}`, requestInfo)
        .then(res => res.json())
        .then(responseJson => {
            let sessionexpired = false;
            if (responseJson && responseJson['errorMessage'] == configMessages.sessionExpiredMessage) {
                sessionexpired = true;
            }
            localStorage.setItem('sessionexpired', sessionexpired);
            // console.log(responseJson);

            return responseJson;
        }).catch(err => {
            showToasterMessage(config.serverErrMessage, 'error');
            return 'serverError';
        });
}

const formatBody = (method, url, body, reqHeaders, type) => {
    let requestInfo = {};
    if (type && type == 'upload') { // only for image upload
        reqHeaders = {
            'Authorization': `${reqHeaders.Authorization}`
        };
        let formData = new FormData();
        if (body) {
            formData.append('file', body);
        }
        requestInfo['body'] = formData;
    } else {
        if (method === 'POST' || method === 'PUT') {
            requestInfo['body'] = JSON.stringify(body);
        }
    }
    requestInfo['headers'] = reqHeaders;
    return requestInfo;
}

const fetchMethodRequest = (method, url, body, type) => {
    if (url === 'auth/login') {
        return sendRequestToServer(method, url, body, headers);
    } else {
        let headers = getLoggedInuserToken();
        return sendRequestToServer(method, url, body, headers, type)
            .then(response => {
                if (response) {
                    if (response.errorCode && response.errorCode === '9001') { // Session expiry
                        return response;
                    } else {
                        return response;
                    }
                }
            }).catch((err) => {
                // console.log('error ' + err)
            });
    }
}

export default fetchMethodRequest;