import React from 'react';
import TableRender from '../../../DataTable/TableRender';
import RolePermissions from "../../../DataTable/Permissions";
import apiCalls from '../../../../../config/apiCalls';

export default class GroupsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    getTableFields = () => {
        let statusTypes = [
            { label: 'All', value: null },
            { label: 'Active', value: 'Active' },
            { label: 'Inactive', value: 'Inactive' },
            { label: 'Dismissed', value: 'Dismissed' },
        ];
        let data = [
            // { textAlign: 'center', width: 50, field: 'Sno', header: 'S.No', filter: false, sortable: false, },
            { textAlign: 'left', width: 100, field: 'companyName', header: 'Company', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 105, field: 'displayName', header: 'Point Of Contact Name', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 140, field: 'email', header: 'Point Of Contact Email', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 105, field: 'phone', header: 'Point Of Contact Phone', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 75, field: 'status', header: 'Status ', filterElement: statusTypes, filter: true, sortable: false },
            { textAlign: 'center', width: 60, field: 'Actions', header: 'Actions', filter: false, sortable: false },
        ];
        let screenPermissions = RolePermissions.screenPermissions('Distributors');
        if (screenPermissions && screenPermissions != 'edit') {
            data.pop();
        }
        return data;
    };

    getFormFields = () => {
        let statusTypes = [
            { label: 'Active', value: 'Active' },
            { label: 'Inactive', value: 'Inactive' },
            { label: 'Dismissed', value: 'Dismissed' },
        ];
        return ([
            {
                required: true, value: '', type: 'autoComplete', name: 'companyId',
                label: 'Company Name', id: 'company', placeholder: 'Search company',
                searchApi: apiCalls.ActiveCompanies, searchField: 'name', disabled: true
            },

            { required: true, value: '', name: 'firstName', label: 'POC First Name', id: 'firstname', placeholder: 'First Name', },
            { required: true, value: '', name: 'lastName', label: 'POC LastName', id: 'lastname', placeholder: 'Last Name' },
            { required: true, value: '', type: 'input', name: 'email', label: 'POC Email', id: 'email', placeholder: 'email@gmail.com', placeholder: 'email@gmail.com', disable: true },
            { required: true, value: '', type: 'input', name: 'phone', label: 'Phone', id: 'phone', placeholder: 'Phone Number' },
            {
                required: true, value: '', type: 'dropDown', name: 'status', label: 'Status', id: 'status',
                options: statusTypes, placeholder: 'Status'
            },
        ]);
    }

    render() {

        return (
            <div>
                <TableRender type='Distributors'
                    globalSearch='Point Of Contact Name/Email'
                    tableFields={this.getTableFields}
                    formFields={this.getFormFields} {...this.props}
                />
            </div>
        );
    }
}