import React from 'react';
import {
    Nav, NavItem, NavLink
} from 'reactstrap';

import classnames from 'classnames';
import fetchMethodRequest from '../../../../../config/service';
import apiCalls from '../../../../../config/apiCalls';

export default class PaymentTypes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0,
            //hiding the tabtype functionality for now
            // paymentTypes: [
            //     { name: 'Payment', value: 'Payment' },
            //     { name: 'Order and Invoice History', value: 'Order and Invoice History' }
            // ],
            currentShift: null
        };
    }

    // Handle Selected Group
    handlePaymentTypes = (tab, paymentTabType) => {
        const { activeTab } = this.state;
        if (activeTab !== tab) {
            this.setState({
                activeTab: tab,
                currentShift: paymentTabType
            });
        }
        this.props.getSelectedPaymentTypeInfo(paymentTabType);
    };

    render() {
        const { activeTab, paymentTypes } = this.state;
        return (
            <div>
                <div className="tabs tabs--justify tabs--bordered-top">
                    <div className="tabs__wrap">
                        <Nav tabs>
                            {paymentTypes && paymentTypes.length > 0 ?
                                paymentTypes.map((paymentTabType, index) => {
                                    return (
                                        <NavItem key={index}>
                                            <NavLink
                                                className={classnames({ active: activeTab === index })}
                                                onClick={() => {
                                                    this.handlePaymentTypes(index, paymentTabType.name);
                                                }}>{paymentTabType.value}</NavLink>
                                        </NavItem>
                                    )
                                })
                                : null}
                        </Nav>
                    </div>
                </div>
            </div>
        );
    }
}