import React, { PureComponent } from 'react';
import { Button, ButtonToolbar, Modal, ModalHeader, ModalBody, ModalFooter, } from 'reactstrap';

export default class QrModal extends PureComponent {
    constructor() {
        super();
        this.state = {
            modal: false,
            text: ''
        };
    }

    getEditorText = (e) => {
        this.setState({
            text: e.htmlValue
        })
    }

    sendCommentsToserver = () => {
        this.props.commentsData(this.state.text, 'comments')
    }

    render() {
        return (
            <div>
                <Modal
                    isOpen={this.props.openQrModal}
                    size='1000px'
                >
                    <ModalBody>
                        <div className="modal__body p-1">
                            <img id="qr-img" style={{ width: '30%', height: '30%' }}
                                src={this.props.qrcode}
                                alt="QR Code" />
                            <p>Lot Id : {this.props.lotId}</p>
                        </div>
                    </ModalBody>
                    <ModalFooter className="modal__footer mb-3">
                        <Button color='primary' outline
                            onClick={this.props.closeQrModal}>Cancel
                            </Button>

                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}
