import React from 'react';
import TableRender from '../../../DataTable/TableRender';
import RolePermissions from '../../../DataTable/Permissions';

export default class ActivitiesTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    getTableFields = () => {
        let data = [
            { textAlign: 'center', width: 80, field: 'context', header: 'Context', filter: true, sortable: true, placeholder: 'Search' },
            {
                textAlign: 'center', width: 80, field: 'contextType', header: 'Context Type', filter: true,
                sortable: true, placeholder: 'Search'
            },
            {
                textAlign: 'center', width: 100, field: 'email', header: 'Email', filter: true,
                sortable: true, placeholder: 'search'
            },
            { textAlign: 'center', width: 50, field: 'key', header: 'Key', filter: true, sortable: true },
            { textAlign: 'center', width: 100, field: 'created', header: 'Created', filter: true, sortable: true },

        ];
        let screenPermissions = RolePermissions.screenPermissions('Activities');
        if (screenPermissions && screenPermissions != 'edit') {
            data.pop();
        }
        return data;
    };

    render() {
        return (
            <div>
                <TableRender type='Activities'
                    globalSearch='Context/Context-Type'
                    tableFields={this.getTableFields}
                    {...this.props}
                />
            </div>
        );
    }
}