import React from 'react';
import TableRender from '../../../DataTable/TableRender';

export default class GroupsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    componentDidMount() {
        if (this.props.match.path == '/expirationdates/:locationId') {
            window.history.pushState(null, null, window.location.pathname);
            window.addEventListener('popstate', this.onBackButtonEvent);
        }
    }
    onBackButtonEvent = () => {
        if (this.props.match.path == '/expirationdates/:locationId') {

            if (window.location.pathname != 'inmedtory/home') {
                window.location.pathname = 'inmedtory/home'
            }
        }
        else {
            return window
        }
    }
    getTableFields = () => {
        let data = [
            { textAlign: 'left', width: 100, field: 'productName', header: 'Product Name', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 100, field: 'description', header: 'Description', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 105, field: 'expirationDate', header: 'Expiration Date', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 140, field: 'clinicName', header: 'Facility', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 105, field: 'cartName', header: 'Storage Unit', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 105, field: 'lotId', header: 'Lot Id', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 185, field: 'order', header: 'Order' },
        ];
        return data;
    };

    render() {

        return (
            <div>
                <TableRender type='Expiration Dates'
                    globalSearch='Product Name/Description/Facility/Location/LotId'
                    tableFields={this.getTableFields}
                    {...this.props}
                />
            </div>
        );
    }
}