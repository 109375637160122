
import React from 'react';
import RolePermissions from '../../../DataTable/Permissions';

// Table 
import TableRender from '../../../DataTable/TableRender';

// config file
import apiCalls from '../../../../../config/apiCalls';
import fetchMethodRequest from '../../../../../config/service'

export default class Recalls extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clinics: [],
            products: [],
            locations: []
        };
    }
    componentDidMount = () => {
        if (this.props.match.path == '/recall/:cart') {
            window.history.pushState(null, null, window.location.pathname);
            window.addEventListener('popstate', this.onBackButtonEvent);
        }
    }

    onBackButtonEvent = () => {
        if (this.props.match.path == '/recall/:cart') {

            if (window.location.pathname != 'inmedtory/home') {
                window.location.pathname = 'inmedtory/home'
            }
        }
        else {
            return window
        }
    }

    getTableFields = () => {
        let data = [
            { textAlign: 'left', width: 135, field: 'recallDate', header: 'Recall Date', filter: true, sortable: true, placeholder: 'MM-DD-YYYY' },
            { textAlign: 'left', width: 135, field: 'brandName', header: 'Brand Name', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 135, field: 'description', header: 'Description', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 135, field: 'product_type', header: 'Product Type', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 135, field: 'companyName', header: 'Company Name', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 135, field: 'reason', header: 'Reason', filter: true, sortable: true, placeholder: 'Search' },

            // { textAlign: 'left', width: 135, field: 'qrcode', header: 'Qr Code', filter: true, sortable: true, placeholder: 'Search' },
        ];
        let screenPermissions = RolePermissions.screenPermissions('Auto Recall');
        if (screenPermissions && screenPermissions != 'edit') {
            data.pop();
        }
        return data;
    };



    render() {
        return (
            <div>
                <TableRender type='Auto Recall'
                    globalSearch='Brand Name/Description'
                    tableFields={this.getTableFields}
                    {...this.props}
                />
            </div>
        );
    }
}