import {
  LOGIN_USER_INFO
} from '../actions/authActions';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN_USER_INFO:
      return action.loginuser;
    default:
      return state;
  }
}


