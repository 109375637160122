const apiCalls = {
  //Login//
  loginUser: "auth/login",
  loginCheck: "auth/loginCheck",
  loginChangePassword: "auth/loginChangePassword",
  SuperUsers: "employees",
  Products: "products",
  Settings: "settings",
  Categories: "categories",
  MenuList: "menus",
  ClinicStaff: "users",
  MissingItemsSidebar: "quantities/missingItems",
  ExpiredItems: "quantities/expiredQuantities",
  Descriptions: "products/descriptionList",
  AdminRoles: "roles",
  //Tables
  Employees: "employees",
  Recalls: "recalls",
  Distributors: "distributors",
  Companies: "companies",
  CompaniesCreation: "companies/adminCreate",
  ActiveCompanies: "companies/companiesList",
  Locations: "locations",
  WeeklyChecks: "checks?checkType=weekly",
  BatteryChecks: "checks?checkType=daily",
  Quantities: "quantities",
  TicketSubjects: "ticketSubjects",
  Tickets: "tickets",
  Activity: "activities",
  States: "states",
  Track: "activities/track",
  MissingItems: "quantities/missingItems",
  RecallItems: "recalls",
  MissingItemsDashboard: "dashboard/missingItems",
  BatteryCheckDashboard: "dashboard/batteryCheck",
  RecommendedInventories: "recommendedInventory",
  //Inventory Check List
  CartView: "locations/cartViewNew",
  Delete: "quantities/deleteQuantity",
  ItemsCount: "quantities/cartItemscount",
  // Uploads: 'uploads'

  LocationImagePath: "uploads?uploadWhileCreate=true&uploadPath=location",
  ProfileImagePath: "uploads?uploadWhileCreate=true&uploadPath=employee",
  StaffRoles: "staffRoles",
  Roles: "staffRoles",
  Clinics: "clinics",
  Drawers: "locations/cartView",
  //Payments
  Payments: "bills",
  History: "",
  Ticketreply: "tickets/createReplyTicketsForTicket",
  AutoRecalls: "autorecalls",
  Bills: "bills",
  CartPayments: "cartPayments",
};
export default apiCalls;