import React from 'react';
import RolePermissions from '../../../DataTable/Permissions';

// Table 
import TableRender from '../../../DataTable/TableRender';

//config file
import apiCalls from '../../../../../config/apiCalls';
import fetchMethodRequest from '../../../../../config/service';

export default class FacilityStaffTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            staffRoles: [
                { label: 'Admin', value: 'Admin' },
                { label: 'Staff', value: 'Staff' }
            ],
            clinics: [],
            allClinicsName: [],
        };
    }
    componentDidMount = () => {
        this.getRolesDropDown();
        return fetchMethodRequest("GET", apiCalls.Clinics + '?companyId=' + this.props.match.params.company).then(
            async (response) => {
                if (response && response.clinics && response.clinics.length > 0) {
                    let multiSelectclinics = [],
                        singleSelectclinics = [],
                        allClinics = [];
                    console.log(response.clinics)
                    for (let clinics of response.clinics) {

                        allClinics.push(clinics.name);
                        multiSelectclinics.push({
                            label: clinics.name,
                            value: {
                                clinicId: clinics._id,
                                name: clinics.name,
                            },
                        });
                        singleSelectclinics.push({
                            label: clinics.name,
                            value: JSON.stringify({
                                clinicId: clinics._id,
                                name: clinics.name,
                            }),
                        });
                    }
                    await this.setState({
                        clinics: {
                            multiSelectclinics: multiSelectclinics,
                            singleSelectclinics: singleSelectclinics,
                        },
                        allClinicsName: allClinics,
                    });
                }
            }
        );
    }

    getTableFields = () => {
        let data = [
            { textAlign: 'left', width: 100, field: 'displayName', header: 'Name', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 125, field: 'email', header: 'Email', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 105, field: 'clinics', header: 'Clinic', filter: true, sortable: true },
            { textAlign: 'center', width: 110, field: 'role', header: 'Permissioned Role', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 90, field: 'Actions', header: 'Actions', filter: false, sortable: false },
        ];
        let screenPermissions = RolePermissions.screenPermissions('Facility Staff');
        if (screenPermissions && screenPermissions != 'edit') {
            data.pop();
        }
        return data;
    };

    getFormFields = () => {
        return ([
            { required: true, value: '', type: 'text', name: 'firstName', label: 'First Name', id: 'firstName', placeholder: 'Enter First Name' },
            { required: true, value: '', type: 'text', name: 'lastName', label: 'Last Name', id: 'lastName', placeholder: 'Enter Last Name' },
            { required: true, value: '', type: 'text', name: 'email', label: 'Email', id: 'email', placeholder: 'email@gmail.com', },
            { required: true, value: '', type: 'text', name: 'phone', label: 'Phone Number', id: 'phone', placeholder: 'Phone number', },
            {
                required: true, value: '', type: 'dropDown', name: 'role', label: 'Permissioned Role', id: 'role',
                options: this.state.staffRoles, placeholder: 'Role'
            }

        ]);
    }

    getRolesDropDown = async () => {
        let companyId = this.props.match && this.props.match.params && this.props.match.params.company;
        return fetchMethodRequest('GET', `${apiCalls.StaffRoles}?companyId=${companyId}`)
            .then(async (response) => {
                if (response && response.staffRoles) {
                    let users = [...this.state.staffRoles]
                    for (let option of response.staffRoles) {
                        users.push({
                            label: option.staffRole,
                            value: option.staffRole
                        })
                    }
                    await this.setState({
                        staffRoles: users
                    })
                }

            })
    }

    render() {
        return (
            <div>
                <TableRender type='Facility Staff'
                    globalSearch='Name'
                    tableFields={this.getTableFields}
                    formFields={this.getFormFields}
                    {...this.props}
                    clinics={this.state.clinics}
                    allClinicsName={this.state.allClinicsName}
                />
            </div>
        );
    }
}