import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ButtonToolbar } from 'reactstrap';
import classNames from 'classnames';
import { Field, reduxForm } from 'redux-form';

import PropTypes from 'prop-types';
import { load as loadAccount } from '../../../redux/reducers/commonReducer';
import { connect } from 'react-redux';

import DefaultInput from '../../../shared/components/form/DefaultInput';

import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';

import configMessages from '../../../config/configMessages';
import fetch from '../../../config/service';

import Loader from '../../App/Loader';

// Validation
import validate from '../../Validations/validate';
const required = value => (value || typeof value === 'string' ? undefined : configMessages.fillField);

class RolesModal extends React.Component {
    static defaultProps = {
        name: 'permissions'
    }
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
    };
    constructor(props) {
        super(props);
        this.state = {
            defaultValue: true,
            colored: false,
            header: true,
            rowId: '',
            menuList: [],
            isLoading: false
        };
    }

    componentDidMount = () => {
        this.props.onRef(this);
    }

    componentWillUnmount = () => {
        this.props.onRef(null);
    }

    //submitting role and permissions
    submit = (values) => {
        if (values && Object.keys(values).length > 0 && values.role) {
            this.setState({ isLoading: true });

            let method, apiUrl;
            if (this.props.formType == 'edit') {
                method = 'PUT';
                apiUrl = `${this.props.apiUrl}/${this.state.rowId}`;
            } else {
                method = 'POST';
                apiUrl = this.props.apiUrl;
            }

            let permissions = {};
            let keys = Object.keys(values);
            this.state.menuList.forEach((item, index) => {
                keys.forEach((key) => {
                    if (item.label == key) {
                        permissions[item.label] = values[key]
                    }
                })
            })
            let body = {};
            body.permissions = { ...permissions }
            body.permissions.Facility = 'edit'
            body.permissions.Inventory = 'edit'
            body.permissions.Administration = 'edit'
            body.permissions.Home = 'view'
            if (this.props.type == 'Employee Roles') {
                body.role = values.role;
            } else {
                body.staffRole = values.role;
            }
            return fetch(method, apiUrl, body)
                .then(async (response) => {
                    if (response && response.respCode) {
                        this.props.getDataFromServer();
                        if (response.respMessage) {
                            showToasterMessage(response.respMessage, 'success');
                        }
                        this.closeRolesModal()
                    } else if (response && response.errorMessage) {
                        showToasterMessage(response.errorMessage, 'error');
                    }
                    this.setState({ isLoading: false });

                }).catch((err) => {
                    return err;
                });
        }
    }

    // Display Selected row data permissions in modal
    getRowData = async (rowData, type) => {
        if (rowData) {
            let data = {}, permissionsArray = []
            if (type == 'edit') {
                data.role = rowData.role ? rowData.role : rowData.staffRole ? rowData.staffRole : null;
            } else {
                data.role = null;
            }
            let permissions = rowData.permissions

            if (permissions) {
                let keys = Object.keys(permissions);
                let HomePos = (keys.indexOf('Home'))
                if ((HomePos || HomePos == 0) && (HomePos != -1)) {
                    keys.splice(HomePos, 1)
                }
                let AdminPos = (keys.indexOf('Administration'))
                if (AdminPos && AdminPos != -1) {
                    keys.splice(AdminPos, 1)
                }
                let InventoryPos = (keys.indexOf('Inventory'))
                if (InventoryPos && InventoryPos != -1) {
                    keys.splice(InventoryPos, 1)
                }
                let FacilityPos = (keys.indexOf('Facility'))
                if (FacilityPos && FacilityPos != -1) {
                    keys.splice(FacilityPos, 1)
                }

                keys.forEach((element) => {
                    if (element) {
                        data[element] = permissions[element];
                        let permissonObj = {
                            label: element,
                        }
                        if (type == 'edit') {
                            if (data[element] == 'edit') {
                                permissonObj.edit = false;
                            } else if (data[element] == 'view') {
                                permissonObj.view = false;
                            } else if (data[element] == 'noView') {
                                permissonObj.noView = false;
                            }
                        } else {
                            if (data[element] == 'edit' || data[element] == 'view') {
                                data[element] = 'noView';
                                permissonObj.noView = false;
                            }
                        }
                        permissionsArray.push(permissonObj);
                    }
                });
            }
            this.props.load(data);
            this.setState({
                rowId: rowData._id,
                defaultValue: false,
                menuList: permissionsArray
            })
        }
    }

    // Close Modal
    closeRolesModal = async () => {
        this.props.reset();
        this.props.closeRolesModal();
    }

    render() {
        const modalClass = classNames({
            'modal-dialog--colored': this.state.colored,
            'modal-dialog--header': this.state.header,
        });
        const { handleSubmit } = this.props;
        return (
            <div>
                <Modal
                    isOpen={this.props.openRolesModal}
                    className={`modal-dialog-centered modal-dialog--primary ${modalClass}`}
                >
                    <Loader loader={this.state.isLoading} />

                    <ModalHeader className="modal__header">
                        <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.closeRolesModal} />
                        <div className="bold-text modal__title">{this.props.formType && this.props.formType == 'edit' ?
                            'Edit ' : 'Add '}{this.props.type == 'Facility Staff Roles' ? 'Facility Staff Roles' : this.props.type}</div>
                    </ModalHeader>
                    <ModalBody className="modal__body" style={{ justifyContent: 'center' }}>
                        <form className="form form--horizontal"
                            onSubmit={handleSubmit(this.submit)}>
                            <div className="form__form-group">
                                <span className="form__form-group-label">Role</span>
                                <div className="form__form-group-field">
                                    <Field
                                        name={'role'}
                                        component={DefaultInput}
                                        type="text"
                                        id='Role'
                                        placeholder='Role'
                                        validate={[required]}
                                    />
                                </div>
                            </div>

                            <div className="form__form-group row">
                                <div className='col-sm-6' style={{ paddingLeft: '119px' }}>
                                    <span className='pr-4 pl-5'>Edit</span>
                                    <span className='pr-3 pl-2'>View</span>
                                    <span className='pr-2 '>No View</span>
                                </div>
                                <div className='col-sm-6' style={{ paddingLeft: '119px' }}>
                                    <span className='pr-4 pl-5'>Edit</span>
                                    <span className='pr-3 pl-2'>View</span>
                                    <span className='pr-2 '>No View</span>
                                </div>
                            </div>

                            {this.state.menuList ?
                                this.state.menuList.map((item, index) => {

                                    return <div key={index} className="form__form-group col-sm-6">
                                        <span className="form__form-group-label ">{item.label}</span>
                                        <div className="form__form-group-field ">
                                            <Field
                                                name={item.label}
                                                component={renderRadioButtonField}
                                                radioValue={!item.edit ? 'edit' : ''} />
                                            <Field
                                                name={item.label}
                                                component={renderRadioButtonField}
                                                radioValue={!item.view ? 'view' : ''}
                                                defaultChecked={this.state.defaultValue && index == 0 ? true : false}
                                            />
                                            <Field
                                                name={item.label}
                                                component={renderRadioButtonField}
                                                radioValue={!item.noView ? 'noView' : ''}
                                                defaultChecked={this.state.defaultValue && index > 0 ? true : false}
                                            />
                                        </div>
                                    </div>
                                }) : null
                            }
                            <div className='col-sm-12 '>
                                <ButtonToolbar className="modal__footer footerData mb-2">
                                    <Button color='primary' outline onClick={this.closeRolesModal}>Cancel</Button>
                                    {this.props.formType && this.props.formType == 'edit' ?
                                        <Button color='primary' type='submit' outline >Update</Button> :
                                        <Button color='primary' type='submit' outline>Save</Button>
                                    }
                                </ButtonToolbar>
                            </div>
                        </form>
                    </ModalBody>
                </Modal>
            </div >
        );
    }
}
RolesModal = reduxForm({
    form: "Roles Modal Form",
    validate,
    enableReinitialize: true, // a unique identifier for this form
})(RolesModal);
RolesModal = connect(
    state => ({
        initialValues: state.commonData.data // pull initial values from account reducer
    }),
    { load: loadAccount } // bind account loading action creator
)(RolesModal);
export default RolesModal;