import React, { PureComponent } from "react";
import { Button, Modal, ModalHeader, ModalBody, ButtonToolbar, Card, CardBody } from 'reactstrap';
import { connect } from "react-redux";
import classNames from 'classnames';
import { Field, reduxForm } from "redux-form";
// import configImages from "./../../../config/configImages";

import PropTypes from "prop-types";

import { load as loadAccount } from "./../../../redux/reducers/commonReducer";
// Validate input
import validate from "../../Form/validate";
import Select from "../../../shared/components/form/Select";
// Input field
import DefaultInput from "./../../../shared/components/form/DefaultInput";
import AutoComplete from "./../../../containers/Form/components/AutoComplete";


// Toaster message

// Loader
import Loader from "./../../App/Loader";
import apiCalls from "./../../../config/apiCalls";
import moment from "moment";
const required = (value) =>
    value || typeof value === "string" ? undefined : "Please Fill above field";

const normalizenumber = (value) => {
    if (!value) {
        return value;
    }
    const onlyNums = value.replace(/[^\d]/g, "");
    return `${onlyNums}`;
};


class InventoryForm extends PureComponent {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            locationType: [],
            colored: false,
            formValues: [],
            header: true,
            productArr: [],
            productBody: [],
            showError: false,
            formFields: [
                {
                    required: true,
                    value: "",
                    type: "autoComplete",
                    searchApi: apiCalls.Products,
                    searchField: "title1",
                    name: "productName",
                    label: "Product Name",
                    id: "productName",
                    placeholder: "Search Product Name",
                },
                {

                    value: "",
                    type: "text",
                    name: "description",
                    label: "Description",
                    id: "description",
                    placeholder: "Description",
                    disable: true,
                },
                {

                    value: "",
                    type: "text",
                    name: "categoryName",
                    label: "Category Name",
                    id: "categoryName",
                    placeholder: "Category Name",
                    disable: true,
                },
                {

                    value: "",
                    type: "text",
                    name: "dosage",
                    label: "Volume ",
                    id: "dosage",
                    placeholder: "Volume",
                    disable: true,
                },

                {

                    value: "",
                    type: "text",
                    name: "inventoryType",
                    label: "Select Inventory Type ",
                    id: "inventoryType",
                    placeholder: "Inventory Type",
                    disable: true,
                },
                {
                    required: true,
                    value: "",
                    type: "dropDown",
                    name: "locationType",
                    label: "Select Location Type",
                    id: "locationType",
                    placeholder: "Location Type",
                    //options: this.state.locationType,
                },
                {
                    required: true,
                    value: "",
                    type: "text",
                    name: "required",
                    label: "Required",
                    id: "required",
                    placeholder: "Required",
                },
            ],
        };

    }

    componentDidMount = async () => {
        if (this.props.onRef) {
            this.props.onRef(this);
        }
        this.props.load({})
        this.props.reset()
        let locationType = [
            { label: "Cart Location", value: "crashcart" },
            { label: "Supply Cabinet", value: "storage" },
            { label: "OR Suite", value: "operatingRoom" },
        ];
        this.setState({
            locationType: locationType,
        });
    }
    handleAutoCompleteData = (value) => {
        this.props.change("categoryName", value.categoryName);
        this.props.change("dosage", value.dosage);
        this.props.change("inventoryType", value.inventoryType);
        this.props.change("description", value.description);

    }
    submit = async (values) => {
        let { productArr, formValues } = this.state;
        let index
        if (this.props.type === "edit") {
            productArr = this.state.rowData
        } else {
            index = this.props.productTablefields.findIndex(obj => obj.locationType === values.locationType && obj.description === values.description && obj.productName === values.productName.productName)
            productArr = this.props.productTablefields
        }
        if (index > -1) {
            await this.setState({
                showError: true
            })
        } else {
            let body = {
                productName: values.productName.productName ? values.productName.productName : values.productName,
                categoryType: values.productName.categoryType ? values.productName.categoryType : values.categoryType,
                categoryName: values.categoryName,
                dosage: values.dosage,
                inventoryType: values.inventoryType,
                required: values.required,
                locationType: values.locationType,
                description: values.description,
                productId: values.productName && values.productName._id ? values.productName._id : this.state.rowData[this.state.updatedIndex].productId
            }
            await this.setState({
                formValues: body,
                showError: false
            })
            if (this.props.type === "edit") {
                productArr[this.state.updatedIndex] = body
            } else {
                productArr.push(body)
            }
            //removing the filter functionality for recommende inventories acreen
            this.props.handleinventoryTable(productArr, this.props.type, this.state.updatedIndex)
            this.closeInventoryModal()
        }
    };

    getRowData = async (item, index) => {
        // if (item && index) {
        await this.setState({
            updatedIndex: index,
            rowData: item
        })
        let rowData = item[index]
        this.props.change("categoryName", rowData.categoryName);
        this.props.change("dosage", rowData.dosage);
        this.props.change("inventoryType", rowData.inventoryType);
        this.props.change("productName", rowData.productName);
        this.props.change("locationType", rowData.locationType);
        this.props.change("required", rowData.required);
        this.props.change("description", rowData.description);
        this.props.change("categoryType", rowData.categoryType);
        this.setState({ showError: false });

        // } else {
        //     this.props.load({})
        // }
    }
    // Close Modal
    closeInventoryModal = async () => {
        // this.props.load({})
        this.emptyFormFields();
        this.props.closeInventoryModal();
    }

    emptyFormFields = async () => {
        this.props.change("categoryName", "");
        this.props.change("dosage", "");
        this.props.change("inventoryType", "");
        this.props.change("productName", "");
        this.props.change("locationType", "");
        this.props.change("required", "");
        this.props.change("description", "");
    }

    render() {

        const modalClass = classNames({
            'modal-dialog--colored': this.state.colored,
            'modal-dialog--header': this.state.header,
        });
        const { handleSubmit } = this.props;
        const { showPassword, formFields, productBody } = this.state;
        return (
            <Modal
                isOpen={this.props.isOpenInventoryModal}
                className={`modal-dialog-centered modal-dialog--primary ${modalClass}`}
            >
                <Loader loader={this.state.isLoading} />
                <ModalHeader className="modal__header">
                    <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.closeInventoryModal} />
                    <div className="bold-text modal__title">{this.props.type === "add" ? "Add" : "Edit"}  {" " + this.props.formtype}</div>
                </ModalHeader>
                <ModalBody className="modal__body" style={{ justifyContent: 'center' }}>
                    <Card className='m-0 p-0'>
                        <CardBody className='m-0 p-0'>
                            <form className="form" autoComplete="off" onSubmit={handleSubmit(this.submit)}>
                                <Loader loader={this.state.isLoading} />
                                <div>
                                    {this.state.showError ?
                                        <div className="justify-content-center text-center" style={{ color: "red" }}>Record with selected Product and Location Type is already exists</div>
                                        : null}
                                    <div className='col-sm-12'>
                                        <ButtonToolbar className=" modal__footer footerData">
                                            {/* Cancel */}
                                            < Button color='primary' type="button"
                                                onClick={this.closeInventoryModal}>Cancel</Button>
                                            {/* Save */}
                                            <Button
                                                color='primary'
                                                outline
                                                type="submit"> {this.props.type === "add" ? "Save" : "Update"}</Button>

                                        </ButtonToolbar>
                                    </div>
                                    <div className="row form" >
                                        {formFields.map((item, index) => {
                                            return (
                                                item.type == "autoComplete" ? (
                                                    <div className="form__form-group col-sm-6">
                                                        <span className="form__form-group-label">
                                                            {item.label}
                                                        </span>
                                                        <div className="form__form-group-field">

                                                            <Field
                                                                name={item.name}
                                                                component={AutoComplete}
                                                                id={item.id}
                                                                placeholder={"Search"}
                                                                searchApi={item.searchApi}
                                                                searchField={item.searchField}
                                                                call={"GET"}
                                                                handleAutoCompleteData={
                                                                    this.handleAutoCompleteData
                                                                }


                                                            />
                                                        </div>
                                                    </div>

                                                ) : item.type == "dropDown" ? (
                                                    <div className="form__form-group col-sm-6">
                                                        <span className="form__form-group-label">
                                                            {item.label}
                                                        </span>
                                                        <div className="form__form-group-field">
                                                            <Field
                                                                name={item.name}
                                                                component={Select}
                                                                options={this.state.locationType}
                                                                isDisable={item.disable}
                                                                placeholder={item.placeholder}
                                                                validate={required}


                                                            />

                                                        </div>
                                                    </div>

                                                ) : item.type == "text" ? (
                                                    <div className="form__form-group col-sm-6">
                                                        <span className="form__form-group-label">
                                                            {item.label}
                                                        </span>
                                                        <div className="form__form-group-field">
                                                            <Field
                                                                name={item.name}
                                                                component={DefaultInput}
                                                                type={item.type}
                                                                isDisable={item.disable}
                                                                placeholder={item.placeholder}
                                                                validate={item.required ? required : ""}
                                                                normalize={item.name === 'required' ? normalizenumber : undefined}
                                                                screen="Required Inventory"

                                                            />

                                                        </div>
                                                    </div>

                                                ) : null



                                            )
                                        })}


                                    </div>
                                </div>

                            </form>

                        </CardBody>
                    </Card>
                </ModalBody>
            </Modal >
        );
    }
}

InventoryForm = reduxForm({
    form: "inventory_form", // a unique identifier for this form
    validate,
    enableReinitialize: false,
})(InventoryForm);

// You have to connect() to any reducers that you wish to connect to yourself
InventoryForm = connect(
    (state) => ({
        initialValues: {}, // pull initial values from account reducer
    }),
    { load: loadAccount } // bind account loading action creator
)(InventoryForm);

export default InventoryForm;
