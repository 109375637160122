import React, { PureComponent } from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';

import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { load as loadAccount } from '../../../../redux/reducers/commonReducer';

import PropTypes from 'prop-types';

import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';

import renderCheckBoxField from '../../../../shared/components/form/CheckBox';

// Validate input
import validate from '../../../Validations/validate';

// Input field
import DefaultInput from '../../../../shared/components/form/DefaultInput';

// Config
import configMessage from '../../../../config/configMessages';
import fetchRequest from '../../../../config/service';
import apiCalls from '../../../../config/apiCalls';

// Toaster message
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';

// Loader
import Loader from '../../../App/Loader';

const required = value => (value || typeof value === 'string' ? undefined : configMessage.fillField)

class LogInForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      isLoginDisabled: false,
      isLoading: false,
      loginRememberInfo: null,
      captchaValue: null,
      isRobot: false,
      isLoginSuccess: false,
      isLoading: false
    };

    this.getLoginRememberInfo();
  }

  getLoginRememberInfo = async () => {
    localStorage.removeItem('loginCredentials');
    localStorage.removeItem('rolePermissions');
    localStorage.removeItem('loginBody');

    let loginRememberInfo = localStorage.getItem('loginRememberInfo');
    loginRememberInfo = loginRememberInfo ? JSON.parse(loginRememberInfo) : null;
    await this.props.load(loginRememberInfo);
    this.setState({
      loginRememberInfo: loginRememberInfo,
      remember_me: loginRememberInfo && loginRememberInfo.remember_me ? loginRememberInfo.remember_me : false
    })
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  }

  submit = (values) => {
    if (values.email && values.password) {

      this.setState({
        remember_me: values.remember_me,
        isLoginDisabled: true,
        isLoading: true
      })
      this.handleLoginUser(values)
    }
  }

  handleLoginUser = async (values) => {
    let userBody = {
      email: values.email,
      password: values.password,
      entityType: "employee",
      // loginSite: 'cc'
      loginSite: location.href
    };
    localStorage.setItem('loginBody', JSON.stringify(userBody));
    return fetchRequest('POST', apiCalls.loginUser, userBody)
      .then(async (response) => {
        if (response && response.respCode && response.respCode == 200) {
          this.setState({
            isLoading: true
          });

          let userDetails = response.details;
          if (userDetails && userDetails._id) {
            if (response.accessToken) {
              let tokenInfo = {
                accessToken: response.accessToken,
                refreshToken: response.refreshToken,
                tokenExpires: response.tokenExpires,
              };
              userDetails = { ...userDetails, ...tokenInfo };
              // save user credentials in storage
              localStorage.setItem('loginCredentials', JSON.stringify(userDetails));
            }
            if (userDetails && userDetails.rolePermissions) {
              let permissions = userDetails.rolePermissions;
              // save user permissions in storage
              localStorage.setItem('rolePermissions', JSON.stringify(permissions));
            }
          }
          showToasterMessage(response.respMessage, 'success');
          await this.setState({
            isLoginSuccess: true,
          }, () => {
            if (this.state.remember_me) {
              var data = {
                email: values.email,
                password: values.password,
                remember_me: this.state.remember_me
              }
              if (this.state.loginRememberInfo && this.state.loginRememberInfo.email &&
                this.state.loginRememberInfo.email != values.email) {
                localStorage.setItem('loginRememberInfo', JSON.stringify(data));
              } else if (!this.state.loginRememberInfo) {
                localStorage.setItem('loginRememberInfo', JSON.stringify(data));
              }
            } else {
              localStorage.removeItem('loginRememberInfo');
            };
          });
        } else if (response && response.errorMessage) {
          setTimeout(() => {
            this.setState({
              isLoginDisabled: false,
              isLoading: false
            }), 250
          });
          showToasterMessage(response.errorMessage, 'error');
        } else {
          this.setState({
            isLoginDisabled: false,
            isLoading: false
          });
        }

      }).catch((err) => {
        this.setState({
          isLoginDisabled: false,
          isLoading: false
        });
      });
  }

  onChange = (value) => {
    if (value == 'remember' && this.state.remember_me) {
      this.setState({
        remember_me: false
      })
    }
  }

  render() {
    const { handleSubmit } = this.props;
    const { showPassword } = this.state;

    return (
      <form className="form" onSubmit={handleSubmit(this.submit)}>
        <Loader loader={this.state.isLoading} />
        <div className="form__form-group">
          <span className="form__form-group-label">Username</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <Field
              name="email"
              component={DefaultInput}
              type="email"
              placeholder="Email"
              validate={required}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Password</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="password"
              component={DefaultInput}
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
              validate={required}
            />
            <button
              type="button"
              className={`form__form-group-button${showPassword ? ' active' : ''}`}
              onClick={e => this.showPassword(e)}
            ><EyeIcon />
            </button>
          </div>
          <div className="account__forgot-password">
            <Link to="/forgot_password">Forgot a password?</Link>
          </div>
        </div>
        <div className="form__form-group">
          <div className="form__form-group-field">
            <Field
              name="remember_me"
              component={renderCheckBoxField}
              label="Remember me"
              checked={this.state.remember_me}
              onChange={() => this.onChange('remember')}
            />
          </div>
        </div>
        <div className="account__btns mt-2">
          <Button className="ml-auto"
            color="primary"
            disabled={this.state.isLoginDisabled}>Sign In</Button>
        </div>

        {/* Navigate to Home */}
        {this.state.isLoginSuccess ? <Redirect to="/home" /> : null}
      </form >
    );
  }
}

LogInForm = reduxForm({
  form: 'log_in_form',
  validate,
  enableReinitialize: true,
})(LogInForm);

LogInForm = connect(
  state => ({
    initialValues: state.commonData.data
  }),
  { load: loadAccount }
)(LogInForm);

export default LogInForm;
