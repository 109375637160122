import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CartPaymentsTable from './components/CartPaymentsTable'


const CartPaymentsTableList = (props) => (

    <Container>
        <CartPaymentsTable {...props} />
    </Container>
);

CartPaymentsTableList.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(CartPaymentsTableList);
