
import React, { PureComponent } from 'react';
import RolePermissions from '../../../DataTable/Permissions';

import PropTypes from 'prop-types';
import fetchMethodRequest from '../../../../../config/service';
import TableRender from '../../../DataTable/TableRender';
import apiCalls from '../../../../../config/apiCalls';
import configImages from '../../../../../config/configImages'

export default class Reports extends PureComponent {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    constructor() {
        super();
        this.state = {
            tabType: 'Categories',
            apiUrl: apiCalls.Categories,
            settingInfo: {},
            useTypes: [],
            categoryTypes: [],
            inventoryTypes: []
        };
    }

    componentDidMount = async () => {
        let categoryTypes = await this.getCategoryDrodownValues();
        return fetchMethodRequest('GET', apiCalls.Settings)
            .then(async (response) => {
                if (response && response.settings && response.settings[0]) {
                    if (response.settings[0]) {
                        let useTypes = this.getUseTypeDrodownValues(response.settings[0]);
                        this.setState({
                            categoryTypes: categoryTypes,
                            useTypes: useTypes,
                            inventoryTypes: response.settings[0].inventoryTypes
                        });
                    }
                }
            });
    }

    getTableFields = () => {
        let data = [
            { textAlign: 'left', width: 105, field: 'title', header: 'Product Name', filter: true, sortable: false },
            { textAlign: 'left', width: 105, field: 'description', header: 'Description', filter: true, sortable: false },
            { textAlign: 'left', width: 135, field: 'categoryName', header: 'Category', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 85, field: 'dosage', header: 'Volume', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 105, field: 'required', header: 'Required Inventory', filter: true, sortable: false },
            { textAlign: 'left', width: 85, field: 'inventoryType', header: 'Type', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 90, field: 'Actions', header: 'Actions', filter: false, sortable: false },
        ];
        let screenPermissions = RolePermissions.screenPermissions('Product Directory');
        if (screenPermissions && screenPermissions != 'edit') {
            data.pop();
        }
        return data;
    };


    getFormFields = async () => {
        return ([
            {
                required: false, value: '', type: 'autoComplete', name: 'companyId',
                label: 'Company Name', id: 'company', placeholder: 'Search company',
                searchApi: apiCalls.ActiveCompanies, searchField: 'name', disabled: true
            },
            {
                required: true, value: '', type: 'text', name: 'productName', label: 'Product Name',
                id: 'productName', placeholder: 'Product Name'
            },
            {
                required: true, value: '', type: 'text', name: 'description', label: 'Description',
                id: 'description', placeholder: 'Description'
            },
            {
                required: true, value: '', type: 'dropDown', name: 'categoryId',
                label: 'Category', id: 'categoryId', placeholder: 'Select Category'
            },
            {
                required: false, value: '', type: 'number', name: 'required', label: 'Required',
                id: 'required', placeholder: 'Required'
            },
            // {
            //     required: false, value: '', type: 'buttonGroup', name: 'reorder',
            //     label: 'Re-Ordering', id: 'reordering', placeholder: 'Select',
            //     options: [{ value: 'auto', label: 'Auto' }, { value: 'manual', label: 'Manual' }],
            // },
            // {
            //     required: true, value: '', type: 'text', name: 'required',
            //     label: 'Required', placeholder: 'Enter Required'
            // },


        ]);
    }

    getCategoryNames = async () => {
        return fetchMethodRequest('GET', apiCalls.Categories)
            .then(async (response) => {
                if (response && response.categories && response.categories[0]) {
                    if (response.categories[0].categoryName) {
                        let categoryNameOptions = [];
                        for (let option of response.categories) {
                            categoryNameOptions.push({
                                label: option.categoryName,
                                value: option._id,
                                categoryType: option.categoryType

                            });
                            await this.setState({
                                categoryNameOptions: categoryNameOptions
                            });
                        }
                    }
                }
            });
    }

    getDistributorDrodownValues = () => {
        return fetchMethodRequest('GET', apiCalls.Distributors)
            .then(async (response) => {

                if (response && response.distributors && response.distributors[0]) {
                    if (response.distributors[0].displayName) {
                        let distributorNames = response.distributors;
                        let modifiedDistributorNames = [];
                        for (let distributor of distributorNames) {
                            modifiedDistributorNames.push({
                                label: distributor.displayName,
                                value: distributor._id
                            })
                        }
                        return modifiedDistributorNames
                    }
                }
            });
    }
    getClinicDropDownValues = () => {
        return fetchMethodRequest('GET', apiCalls.Clinics)
            .then(async (response) => {
                if (response && response.clinics && response.clinics[0]) {
                    if (response.clinics[0].name) {
                        let clinicNames = response.clinics;
                        let modifiedClinicNames = [];
                        for (let clinic of clinicNames) {
                            modifiedClinicNames.push({
                                label: clinic.name,
                                value: clinic._id
                            })
                        }
                        return modifiedClinicNames
                    }
                }
            });
    }

    getCategoryDrodownValues = () => {
        return fetchMethodRequest('GET', apiCalls.Categories)
            .then(async (response) => {
                if (response && response.categories && response.categories[0]) {
                    if (response.categories[0].categoryName) {
                        let categoryNames = response.categories;
                        let modifiedCategoryName = [];
                        for (let category of categoryNames) {
                            modifiedCategoryName.push({
                                label: category.categoryName,
                                value: category._id,
                                categoryType: category.categoryType
                            })
                        }
                        return modifiedCategoryName
                    }
                }
            });
    }

    getUseTypeDrodownValues = (settingInfo) => {
        if (settingInfo && settingInfo.useType) {
            let useTypes = settingInfo.useType;
            let modifiedUseTypes = [];
            for (let category of useTypes) {
                modifiedUseTypes.push({
                    label: category,
                    value: category
                })
            }
            return modifiedUseTypes;
        }
    }

    render() {
        return (
            <div>
                <TableRender type={'Product Directory'}
                    globalSearch={'Product/Description/Category'}
                    tableFields={this.getTableFields}
                    addRequried={true}
                    formFields={this.getFormFields} {...this.props}
                    sample={true}
                    bulkApi='product'
                    inventoryTypes={this.state.inventoryTypes}
                />
            </div>
        );
    }
}