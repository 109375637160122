/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import { Container, Row } from 'reactstrap';

import Settings from './components/Settings';
import fetch from '../../../config/service';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
import apiCalls from '../../../config/apiCalls';
import SessionModal from '../../Cruds/Modals/SessionExpirationModal';

class ProfilePage extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            openSessionModal: false,
            paymentTypes: []
        }
    }
    componentDidMount = () => {
        console.log(this.props)
        if (this.props.onRef) {
            console.log('ref')
            this.props.onRef(this);
        }
    }
    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(null);
        }
    }
    // setPaymentTypes= async(paymentTypes)=>{
    //     await this.setState({
    //       paymentTypes:paymentTypes
    //   })
    // }

    // getValues = (e,index,label) =>{

    //     let x = this.state.paymentTypes
    //     if(label == "Plan Name"){
    //        x[index]['label'] = e.target.value
    //     }
    //     if(label == "Amount"){
    //        x[index]['value'] = e.target.value
    //     }
    //     this.setState({
    //        paymentTypes: x
    //     })
    // }
    // setValues = () =>{
    //     let x = this.state.paymentTypes
    //     x.push({label:'',value:''})
    //     this.setState({
    //         paymentTypes: x
    //     })
    // }

    // submit form data
    // handleSubmit = (formValues) => {
    //     if (formValues) {
    //         // if(formValues.length>0){
    //             console.log(formValues)
    //         let values = {};
    //         let objects =[]
    //         for(let vals in formValues){
    //             if(vals.substring(0,vals.length-1) =="plan" && formValues[vals] !== null){
    //                 for(let i in this.state.paymentTypes){
    //                 if(formValues[vals] == this.state.paymentTypes[i].label){
    //                     objects.push(this.state.paymentTypes[i])
    //                 }
    //                 }
    //         }
    //         }
    //         console.log(objects)
    //         // paymentType.push({label:'gold',value:parseInt(formValues.gold)})
    //         // paymentType.push({label:'silver',value:parseInt(formValues.silver)})
    //         // paymentType.push({label:'platinum',value:parseInt(formValues.platinum)})
    //         values.paymentTypes = objects
    //         if(formValues['free'] && formValues['paid']){
    //             objects.unshift({
    //                 label:'Free',
    //                 value:0
    //             })
    //         }
    //         values.paymentTypes = objects
    //         if(formValues['free'] && !formValues['paid']){
    //             console.log('hi')
    //             values.paymentTypes=[]
    //         }
    //         values['enableMails'] = formValues['enableMails'] ? formValues['enableMails']:null
    //         values['sendGridEmail'] = formValues['sendGridEmail'] ? formValues['sendGridEmail']:null
    //         values['sendGridApiKey'] = formValues['sendGridApiKey'] ? formValues['sendGridApiKey'] : null
    //         values['disableMultipleLogin'] =formValues['disableMultipleLogin']
    //         values['paymentType'] = "paid"
    //         values['freePayment'] = formValues['free']
    //         values['paidPayment'] = formValues['paid']
    //         values['paymentAmount'] =formValues['paymentAmount'] ? parseInt(formValues['paymentAmount']):null
    //         // }
    //         let Url
    //         Url = `${apiCalls.Settings}/${formValues._id}`;
    //         return fetch('PUT', Url, values)
    //             .then(async (response) => {
    //                 let sessionexpired = localStorage.getItem("sessionexpired");
    //                 if (sessionexpired == 'true') {
    //                     this.setState({
    //                         openSessionModal: true
    //                     })
    //                     localStorage.setItem('sessionexpired', false);
    //                     return;
    //                 }
    //                 if (response && response.respCode && response.respCode === 205) {
    //                     console.log('hiiiiii')
    //                     this.getUserData();
    //                     showToasterMessage(response.respMessage, 'success');

    //                 } else if (response && response.errorMessage) {
    //                     showToasterMessage(response.errorMessage, 'error');
    //                 }
    //             }).catch((err) => {
    //                 return err;
    //             });
    //     } else {
    //         return;
    //     }
    // };

    render() {
        return (
            <Container>
                <Row>
                    <Settings
                    // onRef={(ref) => { this.settingsRef = ref }}
                    // onSubmit={this.handleSubmit}
                    // getValues = {this.getValues}
                    // setValues={this.setValues}
                    // setPaymentTypes={this.setPaymentTypes}
                    />
                </Row>
                {/* Session Expiration Modal */}
                <SessionModal openSessionModal={this.state.openSessionModal} />
            </Container>
        );
    }
}

export default ProfilePage;
