import React from 'react';
import classNames from 'classnames';
import {
    Button, Modal, ModalHeader, ModalBody,
    Card, CardBody, ButtonToolbar, ButtonGroup
} from 'reactstrap';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage'
import FormModal from '../../Form/FormModal';
import apiCalls from '../../../config/apiCalls';
import fetchMethodRequest from '../../../config/service';
import configMessages from '../../../config/configMessages'
import { load as loadAccount } from './../../../redux/reducers/commonReducer';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import validate from '../../Validations/validate';
import Loader from '../../App/Loader';
import DefaultInput from '../../../shared/components/form/DefaultInput';
import DatePicker from '../../../shared/components/form/DatePicker';

import AutoComplete from '../../Form/components/AutoComplete';

import Select from '../../../shared/components/form/Select';

const required = value => (value || typeof value === 'string' ? undefined : configMessages.fillField);

export default class AddCalendarEventModal1 extends React.Component {
    constructor(props) {
        super(props);
        this.categoryTypes = [];
        this.useTypes = [];
        this.state = {
            isLoading: false,
            formFields: [],
            colored: false,
            header: true,
            isAutoReOrder: false,
            isManualReOrder: false,
            showCategoryType: false

        };
        this.props.load({})
    }

    componentDidMount = async () => {
        this.changeValues()
        let formFields = [
            {
                required: true, value: '', type: 'text', name: 'companyId',
                label: 'Company Name', id: 'company', placeholder: 'Select company',
                searchApi: apiCalls.ActiveCompanies, searchField: 'name', disable: true
            },
        ];
        this.setState({
            formFields: formFields,

        })
        this.props.onRef(this);
    }

    changeValues = async () => {
        await this.setState({
            locationId: this.props.cart,
            inventoryCartId: this.props.id,
            clinicId: this.props.facility,
            companyName: this.props.companyName,
            companyId: this.props.companyId
        })
        this.props.change('locationId', this.state.locationId)
        this.props.change('clinicId', this.state.clinicId)
        this.props.change('companyId', this.state.companyName)
    }

    componentWillUnmount = () => {
        this.props.onRef(null)
    }

    handleAutoCompleteData = async (value, name) => {
        if (name == 'companyId') {
            if (value && value._id) {
                this.setState({
                    companyId: value._id
                })
            }
        }
        if (name == 'productName') {
            if (value && value.title1) {
                this.setState({
                    productName: value.title1
                })
            }
        }
        if (name == 'clinicId') {
            if (value) {
                this.setState({
                    clinicName: value.name
                })
            }
        }

        if (name == 'description') {
            this.props.change('limit', value['required'] ? value['required'] : 0)
            this.props.change('volume', value['dosage'])
            if (value.categoryType) {
                if (value['categoryType'] == 'Medicine' && !value.batteryExists) {
                    this.setState({
                        isMedicine: true,
                        isNonMedicine: false,
                        isBattery: false
                    })
                } else if (value['categoryType'] == 'Non-Medicine' && !value.batteryExists) {
                    this.setState({
                        isMedicine: false,
                        isNonMedicine: true,
                        isBattery: false
                    })
                } else if (value.batteryExists) {
                    this.setState({
                        isMedicine: false,
                        isNonMedicine: false,
                        isBattery: true
                    })
                }
            }
            await this.setState({
                productId: value['_id']
            })
            // this.getCountData()
            this.getClinicsData()

        }
        if (name == "cartSubLocation") {
            await this.setState({
                cartSubLocation: value.cartParts
            });
            this.getCountData();
        }
    }

    getCountData = () => {
        if (this.state.inventoryCartId && this.state.productId
            && this.state.cartSubLocation
        ) {
            let body = {
                locationId: this.state.inventoryCartId,
                cartSubLocation: this.state.cartSubLocation,
                productId: this.state.productId
            }
            return fetchMethodRequest('POST', 'quantities/currentInventoryCount', body)
                .then(async (response) => {
                    if (response && (response.count || response.count == 0)) {
                        this.props.change('count2', response.count)
                    }
                })
        }
    }

    getClinicsData = async () => {
        return fetchMethodRequest('GET', `clinics?companyId=${this.state.companyId}`)
            .then(async (response) => {
                let clinicss = [];
                if (response && response.clinics) {
                    for (let clinics of response.clinics) {
                        clinicss.push({
                            label: clinics.name,
                            value: clinics._id,
                        })
                    }
                }
                await this.setState({
                    facilityValues: clinicss
                })
            });
    }

    submit = async (values) => {
        this.setState({
            isLoading: true
        })
        values.cartSubLocation = values.cartSubLocation.cartParts
        values.description = values.description.description
        values.locationId = this.state.inventoryCartId
        values.productId = this.state.productId
        values.company_id = values.companyId._id ? values.companyId._id : this.props.companyId
        values.productName = values.productName.title
        values.expirationDate1 = values['expirationDate'] ? values['expirationDate'].slice(6, 10) + '-' + values['expirationDate'].slice(0, 2) + '-' + values['expirationDate'].slice(3, 5) : null
        if (this.props.locationType) {
            values.locationType = this.props.locationType ?
                this.props.locationType == 'Cart Location' ? 'crashcart' :
                    this.props.locationType == 'Supply Cabinet' ? 'storage' :
                        this.props.locationType == 'OR Suite' ? 'operatingRoom' : null
                : null;
        } else {
            values.locationType = values.locationType ? values.locationType :
                this.state.locationType ? this.state.locationType : null;
        }
        if (values.clinicId) {
            for (let i of this.state.facilityValues) {
                if (i.label == values.clinicId) {
                    values.clinicId = i.value
                }
            }
        }
        if (values && Object.keys(values).length > 0) {
            return fetchMethodRequest('POST', apiCalls.Quantities, values)
                .then(async (response) => {
                    if (response && response.respCode) {
                        showToasterMessage(response.respMessage, 'success');

                    } else if (response && response.errorMessage) {
                        showToasterMessage(response.errorMessage, 'error');

                    }
                    this.props.reset();
                    this.props.closeInventoryModal();
                    this.clearFormFields();
                    this.setState({
                        isLoading: false
                    });
                }).catch((err) => {
                    return err;
                });
        }
    }

    clearFormFields = () => {
        this.props.reset();
    }

    render() {
        const { handleSubmit } = this.props;
        const modalClass = classNames({
            'modal-dialog--colored': this.state.colored,
            'modal-dialog--header': this.state.header,
        });
        return (
            <div style={{ display: 'flex' }}>
                <Modal
                    isOpen={this.props.openInventoryModal}
                    className={`modal-dialog-centered modal-dialog--primary  ${modalClass}`}
                >
                    <ModalHeader className="modal__header">
                        <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.props.closeInventoryModal} />
                        <p className="bold-text  modal__title">{'Add DashInventory'}</p>
                    </ModalHeader>
                    <ModalBody className="modal__body mb-0">
                        <Card className='m-0 p-0'>
                            <CardBody className='m-0 p-0'>
                                <Loader loader={this.state.isLoading} />
                                <form key={this.props.type} onSubmit={handleSubmit(this.submit)}
                                    autoComplete={'off'}
                                    style={this.props.formType == 'edit' && ((this.props.type == 'Leaves' && isLeaveDisabled) ||
                                        (this.props.type == 'Schedule' && this.state.isPreviousDate)) ? { pointerEvents: "none" } : null}>
                                    <div className='col-sm-12'>
                                        <ButtonToolbar className=" modal__footer footerData">
                                            {/* Cancel */}
                                            < Button color='primary' type="button"
                                                onClick={this.props.closeInventoryModal}>Cancel</Button>
                                            {/* Save */}
                                            <Button
                                                color='primary'
                                                outline
                                                type="submit">
                                                Save
                                            </Button>
                                        </ButtonToolbar>
                                    </div>
                                    <div className="row form" >
                                        {this.state.formFields && this.state.formFields.length > 0 ?
                                            this.state.formFields.map((item, i) => {
                                                return <div key={this.props.type + i} className={(item.type == 'editor' ? 'col-sm-12' : 'col-sm-6')}  >
                                                    <div className="form__form-group" >
                                                        <label className="form__form-group-label">{item.label}</label>
                                                        {item.type == 'dropDown' ?
                                                            <div className="form__form-group-field">
                                                                <Field key={i}
                                                                    name={item.name}
                                                                    component={Select}
                                                                    options={item.options}
                                                                    defaultValue={item.defaultValue}
                                                                    placeholder={item.placeholder}
                                                                    getLocationSubParts={this.getLocationSubParts}
                                                                    getCategoryProductType={this.getCategoryProductType}
                                                                    handlePermissionedRole={this.handlePermissionedRole}
                                                                    getCategoryTypes={this.getCategoryTypes}
                                                                    getLocationDropdownData={this.getLocationDropdownData}
                                                                    type={this.props.type}
                                                                />

                                                            </div>
                                                            : item.type == 'text' ? <Field key={i}
                                                                name={item.name ? item.name : null}
                                                                component={DefaultInput}
                                                                isDisable={item.disable}
                                                                type={item.type ? item.type : "text"}
                                                                id={item.id ? item.id : null}
                                                                placeholder={item.placeholder ? item.placeholder : null}
                                                                validate={(item.required) ? [required] : null}
                                                            /> : item.type == 'buttonGroup' ?
                                                                item.options && item.options.length > 0 ?
                                                                    <ButtonToolbar className='mt-0'>
                                                                        <ButtonGroup size="sm" >
                                                                            {item.options.map((button, index) => {
                                                                                return <Button style={{ paddingTop: 3, paddingBottom: 3 }}
                                                                                    key={index}
                                                                                    color="primary"
                                                                                    outline size="sm"
                                                                                    active={this.state[`is${button.label}ReOrder`]}
                                                                                    onClick={() => this.handleReOrdering(button.value)}>{button.label}</Button>
                                                                            })
                                                                            }
                                                                        </ButtonGroup>
                                                                    </ButtonToolbar> : null :
                                                                item.type == 'autoComplete' ?
                                                                    <div className='row'>
                                                                        <div className={item.name == 'categoryId' ? 'col-11' : 'col-12'}>
                                                                            <Field key={i}
                                                                                name={item.name ? item.name : null}
                                                                                component={AutoComplete}
                                                                                type={item.type}
                                                                                filterField={item.filterField}
                                                                                filterValue={item.filterValue}
                                                                                filterType={item.isNotEq}
                                                                                multiple={item.isMultiple}
                                                                                placeholder={item.placeholder ? item.placeholder : ''}
                                                                                searchApi={item.searchApi}
                                                                                searchField={item.searchField}
                                                                                handleAutoCompleteData={this.handleAutoCompleteData}
                                                                                screen={this.props.type}
                                                                                allow={this.state.allowDuplicates}
                                                                                filterFieldType={item.filterFieldType ? item.filterFieldType : null}
                                                                                disabled={false}
                                                                                formType={this.props.formType}
                                                                                selectedCategory={this.state.selectedCategory}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    : null}
                                                    </div>
                                                </div>
                                            })
                                            : null}
                                        {
                                            <div className='col-6'>
                                                <div className="form__form-group" >
                                                    <label className="form__form-group-label">Products</label>
                                                    <div className="form__form-group-field">
                                                        <Field name={'productName'}
                                                            component={AutoComplete}
                                                            id={'productName'}
                                                            placeholder='Products'
                                                            searchApi={`products/productList?companyId=${this.state.companyId ? this.state.companyId : this.props.companyId}`}
                                                            screen={'DashInventory'}
                                                            searchField={'title1'}
                                                            call={'GET'}
                                                            companyId={this.state.companyId ? this.state.companyId : this.props.companyId}
                                                            handleAutoCompleteData={this.handleAutoCompleteData}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {this.state.productName ?
                                            <div className='col-6'>
                                                <div className="form__form-group" >
                                                    <label className="form__form-group-label">Description</label>
                                                    <div className="form__form-group-field">
                                                        <Field name={'description'}
                                                            component={AutoComplete}
                                                            id={'description'}
                                                            placeholder='Description'
                                                            searchApi={apiCalls.Descriptions}
                                                            productName={this.state.productName}
                                                            screen={'DashInventory'}
                                                            searchField={'description'}
                                                            apiUrl={'products'}
                                                            call={'POST'}
                                                            companyId={this.state.companyId}
                                                            handleAutoCompleteData={this.handleAutoCompleteData}
                                                        />
                                                    </div>
                                                </div>
                                            </div> : null
                                        }
                                        {((this.state.isMedicine || this.state.isNonMedicine) && !this.state.isBattery) ?
                                            <div className='col-6'>
                                                <div className="form__form-group" >
                                                    <label className="form__form-group-label">Volume</label>
                                                    <div className="form__form-group-field">
                                                        <Field name={'volume'}
                                                            component={DefaultInput}
                                                            id={'volume'}
                                                            placeholder='Volume'
                                                            isDisable={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            : null}
                                        {/* {(((this.state.isMedicine || this.state.isNonMedicine) && !this.state.isBattery)) ?
                                            <div className='col-6'>
                                                <div className="form__form-group" >
                                                    <label className="form__form-group-label">Required Inventory</label>
                                                    <div className="form__form-group-field">
                                                        <Field name={'limit'}
                                                            component={DefaultInput}
                                                            placeholder={'Required Inventory'}
                                                            validate={required}

                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            : null} */}

                                        {
                                            <div className='col-6'>
                                                <div className="form__form-group" >
                                                    <label className="form__form-group-label">LotId</label>
                                                    <div className="form__form-group-field">
                                                        <Field name={'lotId'}
                                                            validate={required}
                                                            component={DefaultInput}
                                                            id={'lotId'}
                                                            placeholder='Lot Id'
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        }
                                        {
                                            <div className='col-6'>
                                                <div className="form__form-group" >
                                                    <label className="form__form-group-label">Expiration Date</label>
                                                    <div className="form__form-group-field">
                                                        <Field name={'expirationDate'}
                                                            component={DatePicker}
                                                            placeholder={'Expiration Date'}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            <div className='col-6'>
                                                <div className="form__form-group" >
                                                    <label className="form__form-group-label">Facility</label>
                                                    <div className="form__form-group-field">
                                                        <Field name={'clinicId'}
                                                            component={DefaultInput}
                                                            isDisable={true}
                                                            id={'clinic'}
                                                            placeholder='Facilities'
                                                            searchApi={`clinics?companyId=${this.state.companyId ? this.state.companyId : this.props.companyId}`}
                                                            screen={'DashInventory'}
                                                            searchField={'name'}
                                                            call={'GET'}
                                                            companyId={this.props.companyId}
                                                            handleAutoCompleteData={this.handleAutoCompleteData}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {

                                            <div className='col-6'>
                                                <div className="form__form-group" >
                                                    <label className="form__form-group-label">Storage Unit</label>
                                                    <div className="form__form-group-field">
                                                        <Field name={'locationId'}
                                                            component={DefaultInput}
                                                            isDisable={true}
                                                            id={'location'}
                                                            placeholder='Search Storage Unit'
                                                            searchApi={`locations?companyId=${this.state.companyId ? this.state.companyId : this.props.companyId}`}
                                                            screen={'DashInventory'}
                                                            searchField={'cart'}
                                                            call={'GET'}
                                                            companyId={this.props.companyId}
                                                            facilityName={this.state.clinicName}
                                                            handleAutoCompleteData={this.handleAutoCompleteData}
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        }
                                        {

                                            <div className='col-6'>
                                                <div className="form__form-group" >
                                                    <label className="form__form-group-label">Cart SubLocations</label>
                                                    <div className="form__form-group-field">
                                                        <Field name={'cartSubLocation'}
                                                            component={AutoComplete}
                                                            id={'subLocations'}
                                                            type='Inventory'
                                                            placeholder='Cart Sub Location'
                                                            validate={required}
                                                            searchApi={`locations?companyId=${this.state.companyId ? this.state.companyId : this.props.companyId}`}
                                                            cartName={this.props.cart}
                                                            screen={'DashInventory'}
                                                            searchField={'cartParts'}
                                                            apiUrl={'locations'}
                                                            call={'GET'}
                                                            handleAutoCompleteData={this.handleAutoCompleteData}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            <div className='col-6'>
                                                <div className="form__form-group" >
                                                    <label className="form__form-group-label">Current Inventory</label>
                                                    <div className="form__form-group-field">
                                                        <Field name={'count2'}
                                                            component={DefaultInput}
                                                            placeholder='Current Inventory'
                                                            isDisable={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            <div className='col-6'>
                                                <div className="form__form-group" >
                                                    {/* <label className="form__form-group-label">Inventory Being Added</label> */}
                                                    <div className="form__form-group-field">
                                                        <Field name={'addedInventory'}
                                                            component={DefaultInput}
                                                            placeholder='Inventory'
                                                            isDisable={false}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </form>
                            </CardBody>
                        </Card>
                    </ModalBody >
                </Modal >
                <FormModal
                    formType='product'
                />

            </div >
        );
    }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
AddCalendarEventModal1 = reduxForm({
    form: "Add Inventory Form", // a unique identifier for this form
    validate,
    enableReinitialize: true,
})(AddCalendarEventModal1);

// You have to connect() to any reducers that you wish to connect to yourself
AddCalendarEventModal1 = connect(
    state => ({
        initialValues: state.commonData.data // pull initial values from account reducer
    }),
    { load: loadAccount } // bind account loading action creator
)(AddCalendarEventModal1);
