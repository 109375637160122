import React, { PureComponent } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';
import config from '../../../config/config';
const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;
const noProfileImage = require('../../../assets/img/profile/userProfile.png');
import { connect } from 'react-redux';
import { loginUserInfo } from '../../../redux/actions/authActions';

const mapStateToProps = state => {
  return { userData: state.loginUser }
};

class TopbarProfile extends PureComponent {
  constructor() {
    super();
    this.state = {
      collapse: false,
      userData: {}
    };
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  componentDidMount = async () => {
    let userData = await localStorage.getItem('loginCredentials');
    userData = JSON.parse(userData);
    await this.props.updateLoginUserInfo(userData);

  }

  render() {
    const { collapse } = this.state;
    const { userData } = this.props;
    const profileImage = userData && userData.photo ? userData.photo : null;
    return (
      <div className="topbar__profile">
        <button className="topbar__avatar" type="button" onClick={this.toggle}>
          <img className="topbar__avatar-img"
            src={profileImage ? `${config.imgUrl}employee/${profileImage}` : noProfileImage} />
          <p className="topbar__avatar-name">{userData && userData.displayName ?
            userData.displayName : 'No Name'}</p>
          <DownIcon className="topbar__icon" />
        </button>
        {collapse && <button className="topbar__back" type="button" onClick={this.toggle} />}
        <Collapse isOpen={collapse} className="topbar__menu-wrap">
          <div className="topbar__menu topbarMenu">
            <div className='topBarImageAlignment'>
              <img src={profileImage ? `${config.imgUrl}employee/${profileImage}` : noProfileImage}
                className='topBarImage' />
              <div >
                {userData && userData.displayName ?
                  userData.displayName : 'No Name'}
                <div className='themeColorText'>{userData &&
                  userData.role ?
                  userData.role : ''}</div>
              </div>
            </div>

            <div className="topbar__menu-divider" />
            <div className="topbar__menu">
              <TopbarMenuLink title="My Profile" icon="user" path="/profile" toggleClose={this.toggle} />
              {/* <TopbarMenuLink title="Account Settings" icon="cog" path="/settings" toggleClose={this.toggle} /> */}
              <TopbarMenuLink title="Change Password" icon="bubble" path="/changePassword" toggleClose={this.toggle} />
              <TopbarMenuLink title="Settings" icon="cog" path="/settings" toggleClose={this.toggle} />

              <div className="topbar__menu-divider" />
              <TopbarMenuLink title="Log Out" icon="exit" path="/log_in" toggleClose={this.toggle} />
            </div>
          </div>
        </Collapse>
      </div >
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateLoginUserInfo: (userInfo) => dispatch(loginUserInfo(userInfo))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopbarProfile);
