import React from "react";
import RolePermissions from "../../../DataTable/Permissions";
import apiCalls from '../../../../../config/apiCalls';

// Table
import TableRender from "../../../DataTable/TableRender";

export default class CompaniesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      states: [

        { label: "Alabama - AL", value: "Alabama - AL" },
        { label: "Alaska - AK", value: "Alaska - AK" },
        { label: "Arizona - AZ", value: "Arizona - AZ" },
        { label: "Arkansas - AR", value: "Arkansas - AR" },
        { label: "California - CA", value: "California - CA" },
        { label: "Colorado - CO", value: "Colorado - CO" },
        { label: "Connecticut - CT", value: "Connecticut - CT" },
        { label: "Delaware - DE", value: "Delaware - DE" },
        { label: "Florida - FL", value: "Florida - FL" },
        { label: "Georgia - GA", value: "Georgia - GA" },
        { label: "Hawaii - HI", value: "Hawaii - HI" },
        { label: "Idaho - ID", value: "Idaho - ID" },
        { label: "Illinois - IL", value: "Illinois - IL" },
        { label: "Indiana - IN", value: "Indiana - IN" },
        { label: "Iowa - IA", value: "Iowa - IA" },
        { label: "Kansas - KS", value: "Kansas - KS" },
        { label: "Kentucky - KY", value: "Kentucky - KY" },
        { label: "Louisiana - LA", value: "Louisiana - LA" },
        { label: "Maine - ME", value: "Maine - ME" },
        { label: "Maryland - MD", value: "Maryland - MD" },
        { label: "Massachusetts - MA", value: "Massachusetts - MA" },
        { label: "Michigan - MI", value: "Michigan - MI" },
        { label: "Minnesota - MN", value: "Minnesota - MN" },
        { label: "Mississippi - MS", value: "Mississippi - MS" },
        { label: "Missouri - MO", value: "Missouri - MO" },
        { label: "Montana - MT", value: "Montana - MT" },
        { label: "Nebraska - NE", value: "Nebraska - NE" },
        { label: "Nevada - NV", value: "Nevada - NV" },
        { label: "New Hampshire - NH", value: "New Hampshire - NH" },
        { label: "New Jersey - NJ", value: "New Jersey - NJ" },
        { label: "New Mexico - NM", value: "New Mexico - NM" },
        { label: "New York - NY", value: "New York - NY" },
        { label: "North Carolina - NC", value: "North Carolina - NC" },
        { label: "North Dakota - ND", value: "North Dakota - ND" },
        { label: "Ohio - OH", value: "Ohio - OH" },
        { label: "Oklahoma - OK", value: "Oklahoma - OK" },
        { label: "Oregon - OR", value: "Oregon - OR" },
        { label: "Pennsylvania - PA", value: "Pennsylvania - PA" },
        { label: "Rhode Island - RI", value: "Rhode Island - RI" },
        { label: "South Carolina - SC", value: "South Carolina - SC" },
        { label: "South Dakota - SD", value: "South Dakota - SD" },
        { label: "Tennessee - TN", value: "Tennessee - TN" },
        { label: "Texas - TX", value: "Texas - TX" },
        { label: "Utah - UT", value: "Utah - UT" },
        { label: "Vermont - VT", value: "Vermont - VT" },
        { label: "Virginia - VA", value: "Virginia - VA" },
        { label: "Washington - WA", value: "Washington - WA" },
        { label: "West Virginia - WV", value: "West Virginia - WV" },
        { label: "Wisconsin - WI", value: "Wisconsin - WI" },
        { label: "Wyoming - WY", value: "Wyoming - WY" },
      ]
    };

  }




  getTableFields = () => {
    let status = [
      { label: "All", value: null },
      { label: "suspend", value: "suspend" },
      { label: "Active", value: "Active" },
      { label: "Blocked", value: "Blocked" },
    ];

    let data = [
      {
        textAlign: "left",
        width: 135,
        field: "name",
        header: "Name",
        filter: true,
        sortable: true,
        placeholder: "Search",
      },
      {
        textAlign: "left",
        width: 135,
        field: "email",
        header: "Email",
        filter: true,
        sortable: true,
        placeholder: "Search",
      },
      {
        textAlign: "left",
        width: 110,
        field: "trialPeriod",
        header: "Trial Period",
        filter: true,
        sortable: true,
        placeholder: "MM-DD-YYYY",
      },
      {
        textAlign: "left",
        width: 155,
        field: "maxCarts",
        header: "Max carts",
        sortable: true,
      },
      {
        textAlign: "left",
        width: 145,
        field: "address",
        header: "Address",
        filter: true,
        sortable: true,
        placeholder: "Search",
      },
      {
        textAlign: "left",
        width: 185,
        field: "phone",
        header: "Phone Number",
        filter: true,
        sortable: true,
        placeholder: "Search",
      },
      {
        textAlign: "left",
        width: 185,
        field: "contactPersonPhone",
        header: "Contact Person Phone",
        filter: true,
        sortable: true,
        placeholder: "Search",
      },
      {
        textAlign: "left",
        width: 145,
        field: "contactPersonDesignation",
        header: "Designation of POC",
        filter: true,
        sortable: true,
        placeholder: "Search",
      },
      {
        textAlign: "left",
        width: 90,
        field: "city",
        header: "City",
        filter: true,
        sortable: true,
        placeholder: "Search",
      },
      {
        textAlign: "left",
        width: 90,
        field: "paymentAmount",
        header: "Amount",
        filter: true,
        sortable: true,
        placeholder: "Search",
      },
      {
        textAlign: "center",
        width: 105,
        field: "status",
        header: "Status",
        filterElement: status,
        filter: true,
        sortable: false,
      },
      {
        textAlign: "center",
        width: 90,
        field: "Actions",
        header: "Actions",
        filter: false,
        sortable: false,
      },
    ];
    let screenPermissions = RolePermissions.screenPermissions("Companies");
    if (screenPermissions && screenPermissions != "edit") {
      data.pop();
    }
    return data;
  };

  getFormFields = () => {
    return [
      {
        required: true,
        value: "",
        type: "text",
        name: "name",
        label: "Company Name",
        id: "companyName",
        placeholder: "Company Name",
      },
      {
        required: true,
        value: "",
        type: "input",
        name: "email",
        label: "Company Email",
        id: "email",
        placeholder: "email@yopmail.com",
      },
      {
        value: "",
        type: "date",
        name: "trialPeriod",
        label: "Trial Period",
        id: "trialPeriod",
        placeholder: "Trial period",
      },
      {
        value: "",
        type: "input",
        name: "maxCarts",
        label: "Maximum Number of carts",
        id: "maxCarts",
        placeholder: "Maximum Number of carts",
      },

      {
        value: "",
        type: "input",
        name: "address",
        label: "Company Address",
        id: "address",
        placeholder: "Address",
      },

      {
        value: "",
        type: "input",
        name: "phone",
        label: "Company Phone Number",
        id: "maxCarts",
        placeholder: "Company Phone",
      },
      {
        value: "",
        type: "input",
        name: "contactPersonPhone",
        label: "Contact Person Phone Number",
        id: "email",
        placeholder: "contactPersonPhone",
      },
      {
        value: "",
        type: "input",
        name: "contactPersonEmail",
        label: "Contact Person Email",
        id: "Contact Person Email",
        placeholder: "email@yopmail.com",
      },
      {
        value: "",
        type: "input",
        name: "contactPersonDesignation",
        label: "Designation of POC",
        id: "designation",
        placeholder: "Contact Person Designation",
      },
      {
        value: "",
        type: "input",
        name: "city",
        label: "City",
        id: "city",
        placeholder: "City",
      },
      {
        value: "",
        type: "input",
        name: "paymentAmount",
        label: "Payment Amount",
        id: "amount",
        placeholder: "Amount",
        //required: true
      },
      {
        value: "",
        type: "dropDown",
        options: this.state.states,
        searchField: "name",
        name: "state",
        label: "State",
        id: "state",
        placeholder: "State",
        //  disabled: true,
      },
      // {
      //   // required: true,
      //   value: "",
      //   type: "dropDown",
      //   isMultiSelect: true,
      //   name: "requiredInventory",
      //   label: "ProductName",
      //   id: "productName",
      //   // options: this.state.staffRoles,
      //   placeholder: "Product Name",
      // },
    ];
  };

  render() {
    return (
      <div>
        <TableRender
          type="Companies"
          globalSearch="Company Name/Email"
          tableFields={this.getTableFields}
          formFields={this.getFormFields}
          {...this.props}
        />
      </div>
    );
  }
}
