import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ButtonToolbar, Card, CardBody } from 'reactstrap';
import DefaultTextArea from '../../../shared/components/form/DefaultTextArea';
import classNames from 'classnames';
import { Field, reduxForm } from 'redux-form';
import PropTypes, { element } from 'prop-types';
import { connect } from 'react-redux';
import { load as loadAccount } from '../../../redux/reducers/commonReducer';


class RejectModal extends React.Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
    };
    constructor(props) {
        super(props);
        this.state = {
            colored: false,
            header: true,
        };
    }

    deleteSelectedRow = () => {
        this.props.deleteSelectedRow();
    }

    handleData = (value, name) => {
        this.setState({ reasonValue: value })
    }

    submit = (value) => {
        value.status = 'Rejected';
        if (this.props.leaveStatus == 'Approve') {
            value.status = 'Approved';
        }
        this.props.changeLeaveStatus(value, 'reason');
    }
    render() {
        const { handleSubmit } = this.props;
        const modalClass = classNames({
            'modal-dialog--colored': this.state.colored,
            'modal-dialog--header': this.state.header,
        });
        return (
            <Modal
                isOpen={this.props.openRejectModal}
                className={`modal-dialog-centered modal-dialog--primary ${modalClass}`}
            >
                <ModalHeader className="modal__header">
                    <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.props.closeRejectModal} />
                    <div className="bold-text  modal__title">{this.props.leaveStatus} Reason</div>
                </ModalHeader>
                <Card>
                    <CardBody className='cardHeader'>
                        <form className="form form--horizontal" onSubmit={handleSubmit(this.submit)} >
                            <div className="form__form-group col-sm-8 pt-3">
                                <span className="form__form-group-label">Reason</span>
                                <div className="form__form-group-field">
                                    <Field
                                        name='Reason'
                                        component={DefaultTextArea}
                                        id='reason'
                                        placeholder='Reason'
                                    />
                                </div>
                            </div>
                            <div className='col-sm-12 '>
                                <ButtonToolbar className="modal__footer footerData mb-2">
                                    <Button color='primary' outline onClick={this.props.closeRejectModal}>Cancel</Button>
                                    <Button color='primary' type='submit' outline >Save</Button>
                                </ButtonToolbar>
                            </div>

                        </form>
                    </CardBody>
                </Card >

            </Modal>
        );
    }
}
RejectModal = reduxForm({
    form: "Reject Form", // a unique identifier for this form
    enableReinitialize: true,
})(RejectModal);

// You have to connect() to any reducers that you wish to connect to yourself
RejectModal = connect(
    state => ({
        initialValues: state.commonData.data // pull initial values from account reducer
    }),
    { load: loadAccount } // bind account loading action creator
)(RejectModal);
export default RejectModal;