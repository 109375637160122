import React from 'react';
import FormModal from '../../Form/FormModal';
import apiCalls from '../../../config/apiCalls';
import fetchMethodRequest from '../../../config/service';

// config file
export default class AddCalendarEventModal1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            useTypes: [],
            categoryTypes: []
        };
    }

    getCategoryOptions = () => {
        return fetchMethodRequest('GET', apiCalls.Settings)
            .then(async (response) => {
                if (response && response.settings && response.settings[0]) {
                    if (response.settings[0].categoryType) {
                        let categoryTypes = response.settings[0].categoryType;
                        let useTypes = response.settings[0].useType;

                        let modifiedCategoryTypes = [];
                        for (let category of categoryTypes) {
                            modifiedCategoryTypes.push({
                                label: category,
                                value: category
                            })
                        }
                        let modifiedUseTypes = [];
                        for (let use of useTypes) {
                            modifiedUseTypes.push({
                                label: use,
                                value: use
                            })
                        }
                        await this.setState({
                            useTypes: modifiedUseTypes,
                            categoryTypes: modifiedCategoryTypes
                        })
                    }
                }
            });
    }

    getFormFields = async () => {
        await this.getCategoryOptions();
        return ([
            {
                required: true, value: '', type: 'text', name: 'categoryName', label: 'Category Name',
                id: 'categoryName', placeholder: 'Category Name'
            },
            {
                required: true, value: '', type: 'dropDown', name: 'categoryType', label: 'Category Type',
                id: 'categoryType', placeholder: 'Category Type', options: this.state.categoryTypes
            },
            {
                required: true, value: '', type: 'dropDown', name: 'useType', label: 'Use Type',
                id: 'useType', placeholder: 'Use Type', options: this.state.useTypes
            }
        ]);
    }

    render() {
        return (
            <FormModal
                openFormModal={this.props.isOpenCategoryModal}
                closeFormModal={this.props.closeCategoryModal}
                type={'Category'}
                modalType={'addCategoryFromProducts'}
                formType={this.props.formType}
                formFields={this.getFormFields}
                apiUrl={apiCalls.Categories}
            />
        );
    }
}