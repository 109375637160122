const imagePath = '../assets/';
const configImages = {
    logoBackground: require(`../shared/img/404/bg_404.png`),
    logoImage: require(`../shared/img/logo/inmedtoryLogo.png`),
    defaultImg: require(`../assets/img/profile/userProfile.png`),
    employeesSampleXL: require(`../assets/files/scheduleSampleUpload.xlsx`),
    doctorsSampleXL: require(`../assets/files/doctorsSampleUpload.xlsx`),
    publicHolidaysSampleXL: require(`../assets/files/publicHolidaysSampleUpload.xlsx`)
};
export default configImages;
