
import React, { PureComponent } from 'react';
import RolePermissions from '../../../DataTable/Permissions';

import PropTypes from 'prop-types';
import fetchMethodRequest from '../../../../../config/service';
import TableRender from '../../../DataTable/TableRender';
import apiCalls from '../../../../../config/apiCalls';
import configImages from '../../../../../config/configImages'

export default class Reports extends PureComponent {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    constructor() {
        super();
        this.state = {
            tabType: 'Categories',
            apiUrl: apiCalls.Bills,
            settingInfo: {},
            useTypes: [],
            categoryTypes: [],
            inventoryTypes: []
        };
    }

    componentDidMount = async () => {
        // let categoryTypes = await this.getCategoryDrodownValues();
        // return fetchMethodRequest('GET', apiCalls.Settings)
        //     .then(async (response) => {
        //         if (response && response.settings && response.settings[0]) {
        //             if (response.settings[0]) {
        //                 let useTypes = this.getUseTypeDrodownValues(response.settings[0]);
        //                 this.setState({
        //                     categoryTypes: categoryTypes,
        //                     useTypes: useTypes,
        //                     inventoryTypes: response.settings[0].inventoryTypes
        //                 });
        //             }
        //         }
        //     });
    }

    getTableFields = () => {
        let data = [
            { textAlign: 'left', width: 105, field: 'billId', header: 'Bill Id', filter: true, sortable: false },
            { textAlign: 'left', width: 105, field: 'companyName', header: 'Company Name', filter: true, sortable: false },
            { textAlign: 'left', width: 135, field: 'companyEmail', header: 'Company Email', filter: true, sortable: true },
            { textAlign: 'left', width: 135, field: 'description', header: 'Description', filter: true, sortable: true },
            { textAlign: 'left', width: 85, field: 'amount', header: 'Amount', filter: false, sortable: false, placeholder: 'Search' },
            { textAlign: 'left', width: 105, field: 'validateFrom', header: 'Validate From', filter: false, sortable: false,dataType:"Date"  },
            { textAlign: 'left', width: 105, field: 'validateTo', header: 'Validate To', filter: false, sortable: false, placeholder: 'Search',dataType:"Date" },
            { textAlign: 'left', width: 85, field: 'numberOfCarts', header: 'Number Of Carts', filter: false, sortable: false, placeholder: 'Search' }, 
            { textAlign: 'left', width: 105, field: 'created', header: 'Created', filter: false, sortable: false, placeholder: 'Search',dataType:"Date"  },        
            { textAlign: 'center', width: 90, field: 'Actions', header: 'Actions', filter: false, sortable: false },
        ];
        let screenPermissions = RolePermissions.screenPermissions('Bills');
        if (screenPermissions && screenPermissions != 'edit') {
            data.pop();
        }
        return data;
    };


    getFormFields = async () => {
        return ([
            // {
            //     required: false, value: '', type: 'autoComplete', name: 'companyId',
            //     label: 'Company Name', id: 'company', placeholder: 'Search company',
            //     searchApi: apiCalls.ActiveCompanies, searchField: 'name', disabled: true
            // },
            // {
            //     required: true, value: '', type: 'text', name: 'productName', label: 'Product Name',
            //     id: 'productName', placeholder: 'Product Name'
            // },
            // {
            //     required: true, value: '', type: 'text', name: 'description', label: 'Description',
            //     id: 'description', placeholder: 'Description'
            // },
            // {
            //     required: true, value: '', type: 'dropDown', name: 'categoryId',
            //     label: 'Category', id: 'categoryId', placeholder: 'Select Category'
            // },
            // {
            //     required: false, value: '', type: 'number', name: 'required', label: 'Required',
            //     id: 'required', placeholder: 'Required'
            // },
            // // {
            //     required: false, value: '', type: 'buttonGroup', name: 'reorder',
            //     label: 'Re-Ordering', id: 'reordering', placeholder: 'Select',
            //     options: [{ value: 'auto', label: 'Auto' }, { value: 'manual', label: 'Manual' }],
            // },
            // {
            //     required: true, value: '', type: 'text', name: 'required',
            //     label: 'Required', placeholder: 'Enter Required'
            // },


        ]);
    }



    render() {
        return (
            <div>
                <TableRender type={'Bills'}
                    globalSearch={'BillID/Email/Company Name'}
                    tableFields={this.getTableFields}
                    // formFields={this.getFormFields} {...this.props}
                    sample={false}
                    // bulkApi='product'
                    // inventoryTypes={this.state.inventoryTypes}
                />
            </div>
        );
    }
}