import React from 'react';
import TableRender from '../../../DataTable/TableRender';
import RolePermissions from '../../../DataTable/Permissions';
import apiCalls from '../../../../../config/apiCalls'

export default class FacilitiesTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    getTableFields = () => {
        let data = [
            { textAlign: 'left', width: 115, field: 'name', header: 'Facility Name', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 110, field: 'email', header: 'Email', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 110, field: 'companyName', header: 'Company', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 125, field: 'createdByName', header: 'Created By', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 70, field: 'Actions', header: 'Actions', filter: false, sortable: false },
        ];

        let screenPermissions = RolePermissions.screenPermissions('Facilities');
        if (screenPermissions && screenPermissions != 'edit') {
            data.pop();
        }
        return data;
    };

    getFormFields = () => {
        return ([
            {
                required: true, value: '', type: 'autoComplete', name: 'companyId',
                label: 'Company Name', id: 'company', placeholder: 'Search company',
                searchApi: apiCalls.ActiveCompanies, searchField: 'name',
            },
            { required: true, value: '', type: 'input', name: 'name', label: 'Facility Name', id: 'clinicName', placeholder: 'Facility Name' },
            { required: true, value: '', type: 'input', name: 'email', label: 'Facility Email', id: 'Email', placeholder: 'Email' },
            { required: true, value: '', type: 'text', name: 'street', label: 'Street', id: 'street', placeholder: 'Street' },
            { required: true, value: '', type: 'text', name: 'city', label: 'City', id: 'City', placeholder: 'City' },
            { required: true, value: '', type: 'text', name: 'state', label: 'State', id: 'State', placeholder: 'State ' },
            { required: true, value: '', type: 'text', name: 'zip', label: 'Zip', id: 'Zip', placeholder: 'Zip' },
        ]);
    }

    render() {
        return (
            <div>
                <TableRender type='Facilities'
                    globalSearch='Facility Name/Email'
                    tableFields={this.getTableFields}
                    formFields={this.getFormFields} {...this.props}
                />
            </div>
        );
    }
}