import React from 'react';

// Table 
import TableRender from '../../../DataTable/TableRender';
import RolePermissions from '../../../DataTable/Permissions';

export default class MissingItems extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            apiUrl: 'orders/ccorderlist?type=unchecked',
            filterCriteria: { limit: 20, page: 1, criteria: [], sortfield: 'productName', direction: 'asc' },
            settingInfo: {},
            tabsList: [{ name: 'Orders List' }, { name: 'Placed Orders' }, { name: 'Fulfilled Orders' }],
            tabType: 'Orders List'
        };
    }

    getTableFields = (tabType) => {
        if (tabType) {
            let apiUrl = this.state.apiUrl;
            if (tabType == 'Orders List') {
                apiUrl = 'orders/ccorderlist?type=unchecked';
            } else if (tabType == 'Placed Orders') {
                apiUrl = 'orders?type=checked';
            } else if (tabType == 'Fulfilled Orders') {
                apiUrl = 'orders?type=fulfilled';
            }
            apiUrl = `${apiUrl}&filter=${JSON.stringify(this.state.filterCriteria)}`;
            this.setState({
                tabType: tabType,
                apiUrl: apiUrl
            })
        }

        let data = [];
        if (tabType == 'Orders List') {
            data = this.getOrdersFields();
        } else if (tabType == 'Placed Orders') {
            data = this.getPlacedOrdersTableFields();
        } else if (tabType == 'Fulfilled Orders') {
            data = this.getFulfilledOrdersTableFields();
        } else {
            data = this.getOrdersFields();
        }
        return data;
    };

    getFormFields = (tabType) => {
        this.setState({
            tabType: tabType,
        })
        let data = [];
        if (tabType == 'Orders List') {
            data = this.getOrdersFormFields();
        } else if (tabType == 'Placed Orders') {
            data = this.getPlacedOrdersFormFields();
        }
        return data;
    };

    getOrdersFields = () => {
        let data = [
            { textAlign: 'left', width: 100, field: 'productName', header: 'Product Name', sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 70, field: 'description', header: 'Description', sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 70, field: 'concentration', header: 'Volume', sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 70, field: 'distributorEmail', header: 'Distributor Email', sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 70, field: 'required', header: 'Quantity', sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 70, field: 'orderedBy', header: 'Company Name', sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 115, field: 'sendEmail', header: 'Submit' },
            { textAlign: 'left', width: 50, field: 'Actions', header: 'Actions', filter: false, sortable: false },
        ];
        let screenPermissions = RolePermissions.screenPermissions('Orders');
        if (screenPermissions && screenPermissions != 'edit') {
            data.pop();
        }
        return data;
    };
    getPlacedOrdersTableFields = () => {
        let data = [
            { textAlign: 'left', width: 100, field: 'productName', header: 'Product Name', sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 70, field: 'distributorEmail', header: 'Distributor Email', sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 70, field: 'required', header: 'Ordered Inventory', sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 70, field: 'orderedBy', header: 'Ordered by', sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 70, field: 'created', header: 'Ordered On', sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 70, field: 'invoiceNumber', header: 'Invoice Number', sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 175, field: 'reSendEmail', header: 'Re-Send Email' },
            { textAlign: 'left', width: 50, field: 'Actions', header: 'Actions', filter: false, sortable: false },
        ];
        let screenPermissions = RolePermissions.screenPermissions('Orders');
        if (screenPermissions && screenPermissions != 'edit') {
            data.pop();
        }
        return data;
    };
    getFulfilledOrdersTableFields = () => {
        let data = [
            { textAlign: 'left', width: 100, field: 'productName', header: 'Product Name', sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 70, field: 'distributorEmail', header: 'Distributor Email', sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 70, field: 'required', header: 'Ordered Inventory', sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 70, field: 'orderedBy', header: 'Ordered by', sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 70, field: 'created', header: 'Fulfilled On', sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 70, field: 'invoiceNumber', header: 'Invoice Number', sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 60, field: 'Actions', header: 'Actions', filter: false, sortable: false },
        ];
        let screenPermissions = RolePermissions.screenPermissions('Orders');
        if (screenPermissions && screenPermissions != 'edit') {
            data.pop();
        }
        return data;
    };
    getPlacedOrdersFormFields = async () => {
        return ([
            {
                required: true, value: '', type: 'number', name: 'required1', label: 'Number of Items Recieved',
                id: 'required', placeholder: 'Number of items Recieved'
            },
            {
                value: '', type: 'text', name: 'invoiceNumber', label: 'Invoice Number',
                id: 'required', placeholder: 'Invoice Number'
            },
        ]);
    }
    getOrdersFormFields = async () => {
        return ([
            {
                required: true, value: '', type: 'text', name: 'productName', label: 'Product Name',
                id: 'required', placeholder: 'Product Name', disable: true
            },
            {
                required: true, value: '', type: 'text', name: 'distributorEmail', label: 'Distributor Email',
                id: 'required', placeholder: 'Distributor Email', disable: true
            },
            {
                required: true, value: '', type: 'number', name: 'required', label: 'Ordered Inventory',
                id: 'required', placeholder: 'Ordered'
            },
            {
                value: '', type: 'text', name: 'orderedBy', label: 'Ordered By',
                id: 'required', placeholder: 'Ordered By', disable: true
            },

        ]);
    }
    render() {
        return (
            <div>
                <TableRender type='Orders'
                    tableFields={this.getTableFields}
                    apiUrl={this.state.apiUrl}
                    companyId={this.props.match.params.company}
                    tabType={this.state.tabType}
                    formFields={this.getFormFields}
                    tabsList={this.state.tabsList}
                />
            </div>
        );
    }
}