import React from 'react';

// Data Table
import DataTables from './DataTable';

export default class TableRender extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            employeeData: [],
            isOpenDeleteModal: false,
            isOpenEditModal: false,
            isshowfield: false,
            singleRowData: {}
        };
    }

    render() {
        return (
            <div key={this.props.type}>
                <DataTables {...this.props}
                    AllData={this.state.employeeData}
                    globalSearch={this.props.globalSearch}
                    onRef={(ref) => (this.DataTablesRef = ref)}
                    type={this.props.type}
                    getTableFields={this.props.tableFields}
                    formFields={this.props.formFields}
                    states={this.props.states}
                    sample={this.props.sample}
                    bulkApi={this.props.bulkApi}
                    isshowfield={this.state.isshowfield}
                    clinics={this.props.clinics}
                    allClinicsName={this.props.allClinicsName}
                    isSelectdRow={this.props.isSelectdRow}
                    companiesList={this.props.companiesList} />
            </div>
        );
    }
}