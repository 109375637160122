import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import BillsTable from './components/BillsTable'


const BillsTableList = (props) => (

    <Container>
        <BillsTable {...props} />
    </Container>
);

BillsTableList.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(BillsTableList);
