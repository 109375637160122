import React from 'react';
import RolePermissions from '../../../DataTable/Permissions';

// Table 
import TableRender from '../../../DataTable/TableRender';

// config file
import apiCalls from '../../../../../config/apiCalls';

export default class MissingItems extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clinics: [],
            products: [],
            locations: []
        };
    }

    componentDidMount = () => {
        if (this.props.match.path == '/track/:locationId') {
            window.history.pushState(null, null, window.location.pathname);
            window.addEventListener('popstate', this.onBackButtonEvent);
        }
    }
    onBackButtonEvent = () => {
        if (this.props.match.path == '/track/:locationId') {

            if (window.location.pathname != 'inmedtory/home') {
                window.location.pathname = 'inmedtory/home'
            }
        }
        else {
            return window
        }
    }

    getTableFields = () => {
        let data = [
            { textAlign: 'left', width: 105, field: 'productName', header: 'Product Name', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 105, field: 'description', header: 'Description', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 105, field: 'lotId', header: 'Lot Id', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 105, field: 'contextType', header: 'Context Type', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 140, field: 'locationId', header: 'Storage Unit', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 105, field: 'created', header: 'Date', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 60, field: 'Actions', header: 'Actions', filter: false, sortable: false },

        ];
        let screenPermissions = RolePermissions.screenPermissions('Track Items');
        if (screenPermissions && screenPermissions != 'edit') {
            data.pop();
        }
        return data;
    };

    getFormFields = () => {
        return ([
            {
                required: true, value: '', type: 'text', name: 'lotId',
                label: 'Lot Id', id: 'Lot Id', placeholder: 'Lot Id',
            },
            {
                required: true, value: '', type: 'autoComplete', name: 'productName',
                label: 'Product', id: 'products', placeholder: 'Search Product',
                searchApi: apiCalls.Products, searchField: 'title',
            },
            {
                required: true, value: '', type: 'textarea', name: 'reason',
                label: 'Reason', id: 'reason', placeholder: 'Type the Reason'
            }
        ]);
    }

    render() {
        return (
            <div>
                <TableRender type='Track Items'
                    globalSearch='Context Type'
                    tableFields={this.getTableFields}
                    formFields={this.getFormFields} {...this.props}
                />
            </div>
        );
    }
}