import React, { Component } from 'react';
import Scrollbar from 'react-smooth-scrollbar';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SidebarContent from './SidebarContent';
import { SidebarProps } from '../../../shared/prop-types/ReducerProps';
import fetchMethodRequest from '../../../config/service';
import SessionModal from '../../Cruds/Modals/SessionExpirationModal';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuList: {},
      openSessionModal: false
    };
  }

  componentDidMount = async () => {
    await this.getMenuListFromServer();
    let rolePermissions = localStorage.getItem("rolePermissions");
    rolePermissions = JSON.parse(rolePermissions);
    if (rolePermissions) {
      let modifiedMenuList = [];
      let permissions = Object.keys(rolePermissions);
      if (this.state.menuList && this.state.menuList.length > 0) {
        for (let item of this.state.menuList) {
          let permissionKey = permissions[permissions.indexOf(item.title)];
          let modifiedSubmenus = [], isSubmenuExist = false;
          if (permissionKey) {
            if (item.submenus.length > 0) {
              isSubmenuExist = true;
              for (let value of item.submenus) {
                let submenuPermissionKey = permissions[permissions.indexOf(value.title)];
                if (value.title == submenuPermissionKey && submenuPermissionKey) {
                  if (rolePermissions[submenuPermissionKey] != "noView") {
                    modifiedSubmenus.push(value);
                  }
                }
              }
              item.submenus = [];
              item.submenus = [...modifiedSubmenus];
            }
            if (item.title == permissionKey) {
              if (rolePermissions[permissionKey] != "noView" && (
                !isSubmenuExist && modifiedSubmenus.length == 0 || isSubmenuExist && modifiedSubmenus.length > 0)) {
                modifiedMenuList.push(item);
              }
            }
          }
        }
      }
      this.setState({
        menuList: modifiedMenuList
      });
    }
  };

  getMenuListFromServer = async () => {
    let filterCriteria = {
      sortfield: 'sequenceNo',
      direction: 'asc'
    };
    let url = `menuLists?filter=${JSON.stringify(filterCriteria)}`;
    return fetchMethodRequest('GET', url)
      .then(async (res) => {
        let sessionexpired = localStorage.getItem("sessionexpired");
        if (sessionexpired == 'true') {
          this.setState({
            openSessionModal: true
          })
          localStorage.setItem('sessionexpired', false);
          return;
        }
        if (res && res.menus && res.menus.length > 0) {
          await this.setState({
            menuList: res.menus
          })
        }
      })
  }

  render() {

    const { changeToDark, changeToLight, changeMobileSidebarVisibility, sidebar, } = this.props;
    const sidebarClass = classNames({
      sidebar: true,
      'sidebar--show': sidebar.show,
      'sidebar--collapse': sidebar.collapse,
    });
    return (
      <div className={sidebarClass}>
        <button className="sidebar__back" type="button" onClick={changeMobileSidebarVisibility} />
        <Scrollbar className="sidebar__scroll scroll">
          <div className="sidebar__wrapper sidebar__wrapper--desktop">
            <SidebarContent
              onClick={() => { }}
              changeToDark={changeToDark}
              changeToLight={changeToLight}
              menuList={this.state.menuList}
            />
          </div>
          <div className="sidebar__wrapper sidebar__wrapper--mobile">
            <SidebarContent
              onClick={changeMobileSidebarVisibility}
              changeToDark={changeToDark}
              changeToLight={changeToLight}
              menuList={this.state.menuList}
            />
          </div>
        </Scrollbar>

        {/* Session Expiration Modal */}
        <SessionModal openSessionModal={this.state.openSessionModal} />
      </div>
    );
  }
};

Sidebar.propTypes = {
  sidebar: SidebarProps.isRequired,
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
};

export default Sidebar;