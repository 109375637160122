/* eslint indent: "off" */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MainWrapper from './MainWrapper';
import Layout from '../Layout';

// Login
import Landing from '../Landing/LogIn';
import LoginCheck from '../Landing/loginCheck';

// Dashboard
import Home from '../Dashboard';

// Profile
import Profile from '../Account/Profile';
import Settings from '../Account/Settings';
import ChangePassword from '../Account/ChangePassword';
import ChangeRecoverPassword from '../Account/ChangeRecoverPassword';
import ForgotPassword from '../Account/ForgotPassword';
import LoginChangePassword from '../Account/LoginChangePassword';

// Payment
import PaymentForm from '../Account/PaymentForm';

// Tables
import Companies from '../Cruds/List/Companies';
import EmployeeRoles from '../Cruds/List/EmployeeRoles';
import Employees from '../Cruds/List/Employees';

// Facilities
import CartLocations from '../Cruds/List/StorageUnits';
import Facilities from '../Cruds/List/Facilities';

// Inventory
import InventoryCheckList from '../Cruds/List/InventoryCheckList/InventoryChecks';
import BatteryChecks from '../Cruds/List/BatteryChecks';
import CartItems from '../Cruds/List/CartItems';
import MissingItems from '../Cruds/List/MissingItems';
import Track from '../Cruds/List/Track';
import Expirations from '../Cruds/List/ExpirationDates';
import Recalls from '../Cruds/List/Recalls';
import CartChecks from '../Cruds/List/CartChecks';

// Administration
import Orders from '../Cruds/List/Orders';
import Activities from '../Cruds/List/Activities';
import Categories from '../Cruds/List/Categories';
import Products from '../Cruds/List/Products';
import Distributors from '../Cruds/List/Distributers';
import Payment from '../Cruds/List/Payment';
import FacilityStaffRolesList from '../Cruds/List/FacilityStaffRoles';
import FacilityStaff from '../Cruds/List/FacilityStaff';
import States from "../Cruds/List/States";
import RecommendedInventories from "../Cruds/List/RecommendedInventories"
import CartPayments from '../Cruds/List/CartPayments';
import Bills from '../Cruds/List/Bills';

import AutoRecalls from "../Cruds/List/AutoRecalls"
// Reports
import Reports from '../Cruds/List/Reports';

// Tickets
import Tickets from '../Cruds/List/Tickets';
import TicketSubjects from '../Cruds/List/TicketSubjects';

// 404 page not found 
import ErrorNotFound from '../Account/404';
import LoginNotFound from '../Account/404/LoginError';

// Common Companies List
import ActiveCompanies from '../Cruds/List/ActiveCompanies';

const Tables = () => {
    return <Switch>
        <Route exact path="/home" component={Home} />

        <Route path="/storageunititemslist/:company/:name/:path" render={(props) => <CartItems
            {...props} />} />
        <Route path="/inventorychecklistlist/:company/:name/:path" render={(props) => <InventoryCheckList
            {...props} />} />
        <Route path="/missing/:company/:name/:path" render={(props) => <MissingItems
            {...props} />} />
        <Route path="/expirations/:company/:name/:path" render={(props) => <Expirations
            {...props} />} />
        <Route path="/recalls/:company/:name/:path" render={(props) => <Recalls
            {...props} />} />
        <Route path="/storageunitchecks/:company/:name/:path" render={(props) => <CartChecks
            {...props} />} />
        <Route path="/batterycheckitems/:company/:name/:path" render={(props) => <BatteryChecks
            {...props} />} />
        <Route path="/trackitems/:company/:name/:path" render={(props) => <Track
            {...props} />} />
        <Route path="/facility/:company/:name/:path" render={(props) => <Facilities
            {...props} />} />
        <Route path="/carts/:company/:name/:path" render={(props) => <CartLocations
            {...props} />} />
        <Route path="/facilityStaffList/:company/:name/:path" render={(props) => <FacilityStaff
            {...props} />} />
        <Route path="/orderlist/:company/:name/:path" render={(props) => <Orders
            {...props} />} />
        <Route path="/facilityStaffRolesList/:company/:name/:path" render={(props) => <FacilityStaffRolesList
            {...props} />} />
        <Route path="/activitylist/:company/:name/:path" render={(props) => <Activities
            {...props} />} />
        <Route path="/categorieslist/:company/:name/:path" render={(props) => <Categories
            {...props} />} />
        {/* <Route path="/productslist/:company/:name/:path" render={(props) => <Products
            {...props} />} /> */}
        <Route path="/distributorslist/:company/:name/:path" render={(props) => <Distributors
            {...props} />} />
        <Route path="/reports" component={Reports} />

        {/* Employees */}
        <Route path="/employeeRoles" component={EmployeeRoles} />
        <Route exact path="/employees" component={Employees} />

        {/* Companies */}
        <Route path="/companies" component={Companies} />

        {/* Facility */}
        <Route path="/facilities" component={ActiveCompanies} />
        <Route path="/locations" component={ActiveCompanies} />

        {/* Administration */}
        <Route path="/facilityStaff" component={ActiveCompanies} />
        <Route path="/facilitystaffroles" component={ActiveCompanies} />
        <Route path="/orders" component={ActiveCompanies} />
        <Route path="/activities" component={ActiveCompanies} />
        <Route path="/categories" component={ActiveCompanies} />
        {/* <Route path="/productdirectory" component={ActiveCompanies} />Products */}
        <Route path="/productdirectory" component={Products} />

        <Route path="/distributors" component={ActiveCompanies} />
        <Route path="/payment" component={Payment} />
        <Route path="/cart_payments" component={CartPayments} />
        <Route path="/bills" component={Bills} />

        {/* Inventory */}
        <Route path="/inventorychecklist" component={ActiveCompanies} />
        <Route path="/storageunititems" component={ActiveCompanies} />
        <Route path="/expirationdates" component={ActiveCompanies} />
        <Route path="/missingItems" component={ActiveCompanies} />
        <Route path="/recall" component={ActiveCompanies} />
        <Route path="/checks" component={ActiveCompanies} />
        <Route path="/batterychecks" component={ActiveCompanies} />
        <Route path="/track" component={ActiveCompanies} />
        <Route path="/states" component={States} />
        <Route path="/recommended" component={RecommendedInventories} />

        <Route path="/autoRecall" component={AutoRecalls} />

        {/* Tickets */}
        <Route path="/tickets" component={Tickets} />
        <Route path="/ticketSubjects" component={TicketSubjects} />

        {/* Account */}
        <Route path="/" component={Account} />
    </Switch >
}

const ErrorCheck = () => (
    <MainWrapper>
        <main className='error'>
            <Switch>
                <ErrorNotFound />
            </Switch>
        </main>
    </MainWrapper>
);

const Account = () => (
    <Switch>
        <Route path="/changepassword" component={ChangePassword} />
        <Route path="/profile" component={Profile} />
        <Route path="/settings" component={Settings} />
        <Route path="/PaymentForm" component={PaymentForm} />
        <Route path='/' component={ErrorCheck} />
    </Switch>
);

const wrappedRoutes = () => {
    let loginCredentials = localStorage.loginCredentials ? JSON.parse(localStorage.loginCredentials) : false;
    if (loginCredentials) {
        return <div>
            <Layout />
            <div className="container__wrap" >
                <Route path="/" component={Tables} />
            </div>
        </div>
    } else {
        return <LoginNotFound />
    }
}

const Router = () => (
    <MainWrapper>
        <main>
            <Switch>
                <Route path="/changeRecoverPassword/:enEmail" render={(props) => <ChangeRecoverPassword
                    {...props} />} />
                <Route path="/loginCheck/:accessToken" render={(props) => <LoginCheck
                    {...props} />} />
                <Route path="/loginChangePassword" component={LoginChangePassword} />
                <Route exact path="/" component={Landing} />
                <Route path="/log_in" component={Landing} />
                <Route path="/logout" component={Landing} />
                <Route path="/forgot_password" component={ForgotPassword} />
                <Route path="/" component={wrappedRoutes} />
            </Switch>
        </main>
    </MainWrapper>
);

export default Router;
