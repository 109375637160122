import React from 'react';
import classNames from 'classnames';
import {
    Button, Modal, ModalHeader, ModalBody,
    Card, CardBody, ButtonToolbar, ButtonGroup
} from 'reactstrap';
import FormModal from '../../Form/FormModal';
import apiCalls from '../../../config/apiCalls';
import fetchMethodRequest from '../../../config/service';
import configMessages from '../../../config/configMessages'
import { load as loadAccount } from './../../../redux/reducers/commonReducer';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import validate from '../../Validations/validate';
import RadioButton from '../../../shared/components/form/RadioButton'
import Loader from '../../App/Loader';
import DefaultInput from '../../../shared/components/form/DefaultInput';
import Select from '../../../shared/components/form/Select';

const required = value => (value || typeof value === 'string' ? undefined : configMessages.fillField);
const lessThan = otherField =>
    (value, previousValue, allValues) => value < allValues[otherField] ? value : previousValue;
const greaterThan = otherField =>
    (value, previousValue, allValues) => value > allValues[otherField] ? value : previousValue;

export default class AddCalendarEventModal1 extends React.Component {
    constructor(props) {
        super(props);
        this.categoryTypes = [];
        this.useTypes = [];
        this.state = {
            isLoading: false,
            formFields: [],
            colored: false,
            header: true,
            isAutoReOrder: false,
            isManualReOrder: false,
            showCategoryType: false

        };
        this.props.load({})
    }

    componentDidMount = async () => {
        await this.getCategoryDrodownValues()
        await this.getCategoryOptions();
        let formFields = [
            {
                required: true, value: '', type: 'text', name: 'productName', label: 'Product Name',
                id: 'productName', placeholder: 'Product Name'
            },
            {
                required: true, value: 'Cmonncnc', type: 'text', name: 'description', label: 'Description',
                id: 'description', placeholder: 'Description'
            },
            {
                required: true, value: '', type: 'dropDown', name: 'categoryId',
                label: 'Category', id: 'categoryId', placeholder: 'Select Category', options: this.state.categoryDropDownValues
            },

            {
                required: false, value: '', type: 'buttonGroup', name: 'reorder',
                label: 'Re-Ordering', id: 'reordering', placeholder: 'Select',
                options: [{ value: 'auto', label: 'Auto' }, { value: 'manual', label: 'Manual' }],
            },
            {
                required: true, value: '', type: 'text', name: 'required',
                label: 'Required', placeholder: 'Enter Required'
            },
        ];
        this.setState({
            formFields: formFields,

        })

        this.props.onRef(this);
    }

    componentWillUnmount = () => {
        this.props.onRef(null)

    }
    getCategoryDrodownValues = async () => {
        return fetchMethodRequest('GET', `categories?companyId=${this.props.companyId}`)
            .then(async (response) => {
                let modifiedCategoryName = [];
                if (response && response.categories && response.categories.length > 0) {
                    for (let category of response.categories) {
                        modifiedCategoryName.push({
                            label: category.categoryName,
                            value: category._id,
                            categoryType: category.categoryType
                        })
                    }
                }
                await this.setState({
                    categoryDropDownValues: modifiedCategoryName
                })
            });
    }
    handleReOrdering = async (event) => {

        if (event) {
            let isManualReOrder = false, isAutoReOrder = false;
            if (event == 'auto') {
                this.props.change('autoMin', 1);
                this.props.change('autoMax', 10);
                // await this.getCategoryNames();
                isAutoReOrder = true;
                this.setState({
                    reorder: 'auto'
                });
            } else if (event == 'manual') {
                isManualReOrder = true;
                this.setState({
                    reorder: 'manual'
                })
            }
            this.setState({
                isAutoReOrder: isAutoReOrder,
                isManualReOrder: isManualReOrder,
            });
        }

    }
    getCategoryTypes = (selectedCategory, name) => {
        if (selectedCategory && selectedCategory.categoryType == 'Medicine') {
            this.setState({
                showCategoryType: true,
                notMedicine: false
            })
        } else if (selectedCategory.categoryType == 'Non-Medicine') {
            this.setState({
                showCategoryType: false,
                notMedicine: true
            })
        } else if (name == 'category' && selectedCategory && selectedCategory.value) {
            this.setState({
                selectedCategory: selectedCategory.value
            })
        }
    }

    getCategoryOptions = () => {
        return fetchMethodRequest('GET', apiCalls.Settings)
            .then(async (response) => {
                if (response && response.settings && response.settings[0]) {
                    if (response.settings[0].categoryType) {
                        let categoryTypes = response.settings[0].categoryType;
                        let useTypes = response.settings[0].useType;

                        let modifiedCategoryTypes = [];
                        for (let category of categoryTypes) {
                            modifiedCategoryTypes.push({
                                label: category,
                                value: category
                            })
                        }
                        let modifiedUseTypes = [];
                        for (let use of useTypes) {
                            modifiedUseTypes.push({
                                label: use,
                                value: use
                            })
                        }
                        this.categoryTypes = modifiedCategoryTypes;
                        this.useTypes = modifiedUseTypes
                    }
                }
            });
    }

    submit = async (values) => {
        this.setState({
            isLoading: true
        })
        if (values && Object.keys(values).length > 0) {
            values.company_id = this.props.companyId
            await this.props.saveDataToServer(values, 'AddProduct');
            this.props.closeAddProductsModal();
            this.clearFormFields();
            this.setState({
                isLoading: false,
                showCategoryType: false
            });
        }
    }

    clearFormFields = () => {
        this.props.reset();
    }

    closeAddProductsModal = () => {
        this.clearFormFields();
        this.props.closeAddProductsModal();
    }

    render() {
        const { handleSubmit } = this.props;
        const modalClass = classNames({
            'modal-dialog--colored': this.state.colored,
            'modal-dialog--header': this.state.header,
        });

        return (
            <div style={{ display: 'flex' }}>
                <Modal
                    isOpen={this.props.isProductsModalOpen}
                    className={`modal-dialog-centered modal-dialog--primary  ${modalClass}`}
                >
                    <ModalHeader className="modal__header">
                        <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.props.closeCategoryModal} />
                        <p className="bold-text  modal__title">{'Add Product'}</p>
                    </ModalHeader>
                    <ModalBody className="modal__body mb-0">
                        <Card className='m-0 p-0'>
                            <CardBody className='m-0 p-0'>
                                <Loader loader={this.state.isLoading} />
                                <form key={this.props.type} onSubmit={handleSubmit(this.submit)}
                                    autoComplete={'off'}
                                    style={this.props.formType == 'edit' && ((this.props.type == 'Leaves' && isLeaveDisabled) ||
                                        (this.props.type == 'Schedule' && this.state.isPreviousDate)) ? { pointerEvents: "none" } : null}>
                                    <div className='col-sm-12'>
                                        <ButtonToolbar className=" modal__footer footerData">
                                            {/* Cancel */}
                                            < Button color='primary' type="button"
                                                onClick={this.closeAddProductsModal}>Cancel</Button>
                                            {/* Save */}
                                            <Button
                                                color='primary'
                                                outline
                                                type="submit"> {'Save'}</Button>

                                        </ButtonToolbar>
                                    </div>
                                    <div className="row form" >
                                        {this.state.formFields && this.state.formFields.length > 0 ?
                                            this.state.formFields.map((item, i) => {
                                                return <div key={this.props.type + i} className={(item.type == 'editor' ? 'col-sm-12' : 'col-sm-6')}  >
                                                    <div className="form__form-group" >
                                                        <label className="form__form-group-label">{item.label}</label>
                                                        {item.type == 'dropDown' ?
                                                            <div className="form__form-group-field">
                                                                <Field key={i}
                                                                    name={item.name}
                                                                    component={Select}
                                                                    options={item.options}
                                                                    defaultValue={item.defaultValue}
                                                                    placeholder={item.placeholder}
                                                                    getLocationSubParts={this.getLocationSubParts}
                                                                    getCategoryProductType={this.getCategoryProductType}
                                                                    handlePermissionedRole={this.handlePermissionedRole}
                                                                    getCategoryTypes={this.getCategoryTypes}
                                                                    getLocationDropdownData={this.getLocationDropdownData}
                                                                    type={this.props.type}
                                                                />

                                                            </div>
                                                            : item.type == 'text' ? <Field key={i}
                                                                name={item.name ? item.name : null}
                                                                component={DefaultInput}
                                                                isDisable={item.disable}
                                                                type={item.type ? item.type : "text"}
                                                                id={item.id ? item.id : null}
                                                                placeholder={item.placeholder ? item.placeholder : null}
                                                                validate={(item.required) ? [required] : null}
                                                            /> : item.type == 'buttonGroup' ?
                                                                    item.options && item.options.length > 0 ?
                                                                        <ButtonToolbar className='mt-0'>
                                                                            <ButtonGroup size="sm" >
                                                                                {item.options.map((button, index) => {
                                                                                    return <Button style={{ paddingTop: 3, paddingBottom: 3 }}
                                                                                        key={index}
                                                                                        color="primary"
                                                                                        outline size="sm"
                                                                                        active={this.state[`is${button.label}ReOrder`]}
                                                                                        onClick={() => this.handleReOrdering(button.value)}>{button.label}</Button>
                                                                                })
                                                                                }
                                                                            </ButtonGroup>
                                                                        </ButtonToolbar> :
                                                                        null : null
                                                        }



                                                    </div>
                                                </div>

                                            })
                                            : null}
                                        {this.state.isAutoReOrder ?
                                            <div className='col-6'>
                                                <div className="form__form-group" >
                                                    <label className="form__form-group-label">Auto Re-order at X minimum</label>
                                                    <div className="form__form-group-field">
                                                        <Field name={'autoMin'}
                                                            component={DefaultInput}
                                                            placeholder={'Auto Re-order at X minimum'}
                                                            type='number'
                                                            id='number'
                                                            normalize={lessThan('autoMax')}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            : null}
                                        {this.state.isAutoReOrder ?
                                            <div className='col-6'>
                                                <div className="form__form-group" >
                                                    <label className="form__form-group-label">Auto Re-order to X maximum</label>
                                                    <div className="form__form-group-field">
                                                        <Field name={'autoMax'}
                                                            component={DefaultInput}
                                                            placeholder={'Auto Re-order to X maximum'}
                                                            type='number'
                                                            id='number'
                                                            normalize={greaterThan('autoMin')}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            : null}
                                        {this.state.isManualReOrder ?
                                            <div className='col-6'>
                                                <div className="form__form-group" >
                                                    <label className="form__form-group-label">Manually Reorder to Maximum</label>
                                                    <div className="form__form-group-field">
                                                        <Field name={'manualMax'}
                                                            component={DefaultInput}
                                                            placeholder={'Enter'}
                                                            type='number'
                                                            id='number'
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            : null}
                                        {this.state.showCategoryType ?
                                            <div className='col-6'>
                                                <div className="form__form-group" >
                                                    <label className="form__form-group-label">Inventory Type</label>
                                                    <div className="form__form-group-field">
                                                        <Field name={'inventoryType'}
                                                            component={Select}
                                                            options={this.props.inventoryTypes}
                                                            placeholder={'Select'}
                                                            getCategoryTypes={this.getCategoryTypes}
                                                            validate={required}
                                                            type={'Add Product'}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            : null}

                                        {this.state.showCategoryType ?
                                            <div className='col-6'>
                                                <div className="form__form-group" >
                                                    <label className="form__form-group-label">Volume</label>
                                                    <div className="form__form-group-field">
                                                        <Field name={'dosage'}
                                                            component={DefaultInput}
                                                            placeholder={'Volume'}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            : null}
                                        {this.state.notMedicine ?
                                            <div className='col-6'>
                                                <div className="form__form-group" >
                                                    <label className="form__form-group-label">Battery Exists</label>
                                                    <div className="form__form-group-field">
                                                        <Field name={'batteryExists'}
                                                            component={RadioButton}
                                                            label={'Yes'}
                                                            radioValue={true}
                                                        />
                                                        <Field name={'batteryExists'}
                                                            component={RadioButton}
                                                            label={'No'}
                                                            radioValue={false}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            : null}


                                    </div>
                                </form>
                            </CardBody>
                        </Card>
                    </ModalBody >
                </Modal >
                <FormModal
                    formType='product'
                />

            </div >
        );
    }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
AddCalendarEventModal1 = reduxForm({
    form: "Product Form", // a unique identifier for this form
    validate,
    enableReinitialize: true,
})(AddCalendarEventModal1);

// You have to connect() to any reducers that you wish to connect to yourself
AddCalendarEventModal1 = connect(
    state => ({
        initialValues: state.commonData.data // pull initial values from account reducer
    }),

    { load: loadAccount } // bind account loading action creator
)(AddCalendarEventModal1);




