import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import StateTable from './components/States'


const StateTableList = (props) => (

    <Container>
        <StateTable {...props} />
    </Container>
);

StateTableList.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(StateTableList);

