import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Payment from './components/Payment'


const PaymentList = (props) => (

    <Container>
        <Payment {...props} />
    </Container>
);

PaymentList.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(PaymentList);