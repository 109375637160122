import React from 'react';
import {
    Nav, NavItem, NavLink
} from 'reactstrap';

import classnames from 'classnames';

export default class TabTypes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0
        };
    }

    // Handle Selected Group
    handleOrderTypes = (tab, OrderTabType) => {
        const { activeTab } = this.state;
        if (activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
        this.props.getSelectedTabInfo(OrderTabType);
    };

    render() {
        const { activeTab } = this.state;
        const { tabsList } = this.props;

        return (
            <div>
                <div className="tabs tabs--justify tabs--bordered-top">
                    <div className="tabs__wrap">
                        <Nav tabs>
                            {tabsList && tabsList.length > 0 ?
                                tabsList.map((orderTabType, index) => {
                                    return (
                                        <NavItem key={index}>
                                            <NavLink
                                                className={classnames({ active: activeTab === index })}
                                                onClick={() => {
                                                    this.handleOrderTypes(index, orderTabType.name);
                                                }}>
                                                <span style={{ fontSize: 15 }}>{orderTabType.name}</span>
                                            </NavLink>
                                        </NavItem>
                                    )
                                })
                                : null}
                        </Nav>
                    </div>
                </div>
            </div>
        );
    }
}