import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';

import fetchRequest from '../../../config/service';
import apiCalls from '../../../config/apiCalls';

class Topbar extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      settingsInfo: {}
    }
  }

  componentDidMount = () => {
    this.getSettingsInfo();
  }

  getSettingsInfo = () => {
    return fetchRequest('GET', apiCalls.Settings)
      .then(async (response) => {
        if (response && response.settings && response.settings[0]) {
          this.setState({
            settingsInfo: response.settings[0]
          })
        }
      }).catch((err) => {
        return err;
      });
  }

  render() {
    const { changeMobileSidebarVisibility, changeSidebarVisibility } = this.props;
    const { settingsInfo } = this.state;
    return (
      <div className="topbar">
        <div className="topbar__wrapper">
          <div className="topbar__left">
            <TopbarSidebarButton
              changeMobileSidebarVisibility={changeMobileSidebarVisibility}
              changeSidebarVisibility={changeSidebarVisibility}
            />
            <Link className='topbar__logo' to="/home" />
          </div>
          <div className="topbar__right">
            <TopbarProfile />
          </div>
        </div>
      </div>
    );
  }
}

export default Topbar;
