import React from 'react';

// Table 
import TableRender from '../../../DataTable/TableRender';
import RolePermissions from '../../../DataTable/Permissions';

export default class MissingItems extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clinics: [],
            products: [],
            locations: []
        };
    }
    componentDidMount() {
        if (this.props.match.path == '/missingItems/:locationId') {
            window.history.pushState(null, null, window.location.pathname);
            window.addEventListener('popstate', this.onBackButtonEvent);
        }
    }

    onBackButtonEvent = () => {
        if (this.props.match.path == '/missingItems/:locationId') {
            if (window.location.pathname != 'inmedtory/home') {
                window.location.pathname = 'inmedtory/home'
            }
        } else {
            return window
        }
    }

    getTableFields = () => {
        let data = [

            { textAlign: 'left', width: 100, field: 'productName', header: 'Product Name', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 100, field: 'description', header: 'Description', filter: true, sortable: true, placeholder: 'Search' },

            { textAlign: 'center', width: 100, field: 'limit', header: 'Required Inventory', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 100, field: 'count', header: 'Current Inventory', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 80, field: 'needed', header: 'Need to Order', filter: true, sortable: true, placeholder: 'Search' },

            { textAlign: 'left', width: 80, field: 'clinicName', header: 'Facilites', filter: true, sortable: true, placeholder: 'Search' },

            { textAlign: 'left', width: 105, field: 'cart', header: 'Storage Unit', filter: true, sortable: true, placeholder: 'Search' },


            { textAlign: 'center', width: 130, field: 'order', header: 'Order' },
            { textAlign: 'center', width: 60, field: 'Actions', header: 'Actions', filter: false, sortable: false },

        ];
        let screenPermissions = RolePermissions.screenPermissions('MissingItems');
        if (screenPermissions && screenPermissions != 'edit') {
            data.pop();
        }
        return data;
    };

    render() {
        return (
            <div>
                <TableRender type='Missing Items'
                    tableFields={this.getTableFields}
                    // globalSearch={'Role'}
                    {...this.props}
                />
            </div>
        );
    }
}